import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import { GetContainersByVoyageParams, UpdateActionRequest } from '../../../global/adapters/crpAPI';
import { changeCommercialReleaseKeys } from './queryKeys';

export const useGetContainersByVoyage = (vesselParams: GetContainersByVoyageParams) => {
  const isSearchEntered =
    (vesselParams.vesselName !== undefined && vesselParams.vesselName !== '') ||
    (vesselParams.voyageNumber !== undefined && vesselParams.voyageNumber !== '') ||
    (vesselParams.imoNumber !== undefined && vesselParams.imoNumber !== '') ||
    (vesselParams.stayNumber !== undefined && vesselParams.stayNumber !== '');

  return useQuery(
    changeCommercialReleaseKeys.containersForChangeCommercialRelease(vesselParams),
    () => api.containers.getContainersByVoyage(vesselParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersChangeCommercialRelease = () => {
  const queryClient = useQueryClient();

  return useMutation(changeCommercialReleaseKeys.postContainersChangeCommercialReleasevoyage(), {
    mutationFn: (data: UpdateActionRequest) =>
      api.commercialreleases.postCommercialreleasesAction(data),
    onSuccess: () => {
      queryClient.invalidateQueries(
        changeCommercialReleaseKeys.containersForChangeCommercialReleaseRoot()
      );
    },
  });
};
