import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { CREDITINFO } from '../../theme/constants';
import { getCreditIdentification, getRemainingCredits } from '../../store/selectors/selectors';
import { connect } from 'react-redux';
import { EXTERNALPRODUCTPAGE } from '../../config/environment';

function CreditInfo(props) {
  const { creditIdentification, remainingCredits } = props;

  const getSeverity = () => {
    if (remainingCredits <= 0) {
      return 'error';
    } else if (remainingCredits <= 10) {
      return 'warning';
    } else {
      return 'info';
    }
  };

  const getCreditInfoText = () => {
    if (remainingCredits <= 0) {
      return CREDITINFO.NO_CREDITS;
    } else if (remainingCredits <= 10) {
      return remainingCredits + CREDITINFO.LOW_CREDITS;
    } else {
      return remainingCredits + CREDITINFO.INFO_CREDITS;
    }
  };

  return creditIdentification != null && !creditIdentification.hasPaidSubscription ? (
    <React.Fragment>
      <div>
        <Alert severity={getSeverity()}>
          {getCreditInfoText()}
          <br />
          <a href={EXTERNALPRODUCTPAGE} target="_new">
            Go to Cinvio Product Page
          </a>
        </Alert>
      </div>
    </React.Fragment>
  ) : (
    ''
  );
}

function mapStateToProps(state) {
  return {
    creditIdentification: getCreditIdentification(state),
    remainingCredits: getRemainingCredits(state),
  };
}

export default connect(mapStateToProps, null)(CreditInfo);
