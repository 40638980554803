import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import { getWeek, setDefaultOptions, startOfWeek, subWeeks } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import { enUS } from 'date-fns/locale';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../../../shipping-line/components/update-expiry-date/styles';

type Props = {
  fieldName: string;
};

const today = new Date();
const twoWeeksAgo = subWeeks(today, 2);

const WeekPicker = ({ fieldName }: Props) => {
  const { t } = useTranslation('common');
  const { setFieldValue } = useFormikContext();
  const [selectedDate, setSelectedDate] = React.useState<MaterialUiPickersDate>(null);
  const classes = useStyles();

  const handleWeekChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setFieldValue(fieldName, {
        startDate: startOfWeek(date, { weekStartsOn: 1 }),
        endDate: endOfWeek(date),
      });
      setSelectedDate(startOfWeek(date, { weekStartsOn: 1 }));
    }
  };

  const formatWeekSelectLabel = (date: MaterialUiPickersDate, invalidLabel: string) => {
    let dateClone = date;

    if (dateClone && isValid(dateClone)) {
      const weekNumber = getWeek(dateClone);
      const startDate = format(startOfWeek(dateClone, { weekStartsOn: 1 }), 'dd MMM');
      const endDate = format(endOfWeek(dateClone, { weekStartsOn: 1 }), 'dd MMM');

      return t('dates.selectedWeek', { weekNumber, startDate, endDate });
    }

    return invalidLabel;
  };

  const renderWrappedWeekDay = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    let dateClone = date;
    let selectedDateClone = selectedDate;

    if (!date || !dateClone || !selectedDate || !selectedDateClone) {
      return <></>;
    }

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  React.useEffect(() => {
    setDefaultOptions({
      locale: enUS,
      weekStartsOn: 1,
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={selectedDate}
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
        minDate={startOfWeek(twoWeeksAgo)}
        variant="inline"
        helperText={t('dates.twoWeeksAgoMax')}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

export default WeekPicker;
