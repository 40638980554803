import { CssBaseline } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import * as signalR from '@microsoft/signalr';
import { HttpTransportType } from '@microsoft/signalr';
import ContainerDetailOverview from 'components/pages/ContainerDetail';
import ContainerRelease from 'components/pages/ContainerRelease';
import Containers from 'components/pages/Containers';
import ContainerSubscriptions from 'components/pages/ContainerSubscriptions';
import ReleaseDetail from 'components/pages/ReleaseDetail';
import SubscriptionDetail from 'components/pages/SubscriptionDetail';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { BetaRouteSwitch } from '../modules/global/components/BetaToggle';
import '../modules/global/config/i18n';
import { ROUTE_PATHS } from '../modules/shipping-line/constants';
import { RegularRoutes, Routes as BetaRoutes } from '../modules/shipping-line/routes';
import {
  RegularTransportOperatorRoutes,
  BetaTransportOperatorRoutes,
} from '../modules/transport-operator/routes';
import { bindConnectionMessages } from '../signalR/connectionMessages';
import { signalRIdReceived, signalRSetupFailed } from '../store/actions/signalRActions';
import {
  loadCreditIdentificationResponse,
  loadUserProfile,
} from '../store/actions/userProfileActions';
import { retrieveSignalRData } from '../store/api/userApi';
import {
  getCreditIdentificationLoading,
  getCreditIdentificationResponse,
  getSelectedUserType,
  getSignalRId,
  getTokenExpired,
  getTokenExpiring,
  getUserProfile,
} from '../store/selectors/selectors';
import store from '../store/store';
import { LABEL } from '../theme/constants';
import { CRPTheme } from '../theme/theme';
import userManager from '../utils/userManager';
import BreadcrumbBar from './molecules/BreadcrumbBar';
import Messages from './molecules/Messages';
import ExpiredToken from './organisms/ExpiredToken';
import Topbar from './organisms/Topbar';
import AuditTrailSubscriptions from './pages/AuditTrailSubscriptions';
import Blocked from './pages/Blocked';
import ErrorPage from './pages/ErrorPage';
import DriverList from './pages/DriverList';
import PairedRedirect from './pages/PairedRedirect';
import PoliceAudit from './pages/PoliceAudit';
import PoliceContainers from './pages/PoliceContainers';
import Releases from './pages/Releases';
import SelectCompanies from './pages/SelectCompanies';
import ScrEventsDetail from './pages/ScrEventsDetail';
import RegularTerminalRoutes from 'modules/terminal/routes/RegularRoutes';
import BetaTerminalRoutes from 'modules/terminal/routes/Routes';
import PoliceContainerSubscriptions from './pages/PoliceContainerSubscriptions';
import { RegularReleasePartyRoutes, BetaReleasePartyRoutes } from 'modules/release-party/routes';
import Alfapass from './pages/Alfapass';
import TermsAndConditions from './organisms/TermsAndConditions';

const styles = (theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(5),
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12),
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
});

class App extends React.Component {
  state = {
    navigate: false,
    errored: false,
    error: null,
    errorInfo: '',
  };

  async initSignalR() {
    if (!this.props.signalRId && !this.state.errored) {
      try {
        const data = await retrieveSignalRData();
        const { url, accessToken } = data;

        const connection = new signalR.HubConnectionBuilder()
          .withUrl(url, {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => accessToken,
          })
          .configureLogging(signalR.LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        await connection.start();

        this.props.signalRIdReceived(connection.connectionId);

        bindConnectionMessages(connection);
      } catch (error) {
        this.setState({ errored: true, error: error });
        this.props.signalRSetupFailed(error);
      }
    }
  }

  componentDidMount() {
    if (store.getState().oidc.user) {
      this.props.loadUserProfile();
      this.props.loadCreditIdentificationResponse();
      this.initSignalR();
    } else {
      userManager.signinRedirect({ state: window.location.pathname });
    }
  }

  render() {
    const {
      classes,
      signalRId,
      userProfile,
      selectedUserType,
      creditIdentificationResponse,
      loadingCreditIdentification,
      tokenExpired,
      tokenExpiring,
    } = this.props;

    let disableNavigation = false;
    if (
      window.location.pathname.includes('/external/pair') ||
      window.location.pathname.includes('/blocked')
    ) {
      disableNavigation = true;
    }

    return (
      <MuiThemeProvider theme={CRPTheme}>
        <CssBaseline />
        <div className={classes.root}>
          {userProfile && selectedUserType !== LABEL.POLICE_LABEL && (
            <TermsAndConditions userProfile={userProfile} />
          )}
          {userProfile && (
            <>
              {!disableNavigation && <Topbar pathname={window.location.pathname} />}

              {userProfile && signalRId && (
                <div className={classes.content}>
                  <BreadcrumbBar />
                  {selectedUserType === LABEL.FORWARDER && (
                    <Switch>
                      <Route
                        path="/containers/detail"
                        exact
                        render={(props) => (
                          <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route
                        path="/releases/detail"
                        exact
                        render={(props) => (
                          <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route path="/containers/:tabId" exact component={ContainerDetailOverview} />
                      <Route path="/containers" exact component={Containers} />
                      <Route path="/releases" exact component={Releases} />
                      <Route
                        path="/container-subscriptions"
                        exact
                        component={ContainerSubscriptions}
                      />
                      <Route
                        path="/container-subscriptions/detail"
                        exact
                        render={(props) => (
                          <SubscriptionDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <BetaRouteSwitch
                        regularRoutes={<RegularReleasePartyRoutes />}
                        betaRoutes={<BetaReleasePartyRoutes />}
                      />
                    </Switch>
                  )}
                  {selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL && (
                    <Switch>
                      <Route path="/external/pair/:id" component={PairedRedirect} />
                      <Route
                        path="/containers/detail"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/releases/detail"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/containers/:tabId"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <ContainerDetailOverview {...props} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/containers"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <Containers {...props} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/releases"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <Releases {...props} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/container-subscriptions"
                        exact
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <ContainerSubscriptions {...props} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/container-subscriptions/detail"
                        exact
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <SubscriptionDetail {...props} selectedUserType={selectedUserType} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route
                        path="/driverlist"
                        render={(props) => {
                          return creditIdentificationIsValid(creditIdentificationResponse) &&
                            loadingCreditIdentification === false ? (
                            <DriverList {...props} />
                          ) : loadingCreditIdentification === true ? (
                            <CircularProgress className={classes.circularProgress} />
                          ) : (
                            <Redirect to="/blocked" />
                          );
                        }}
                      />
                      <Route path="/blocked" exact component={Blocked} />
                      <BetaRouteSwitch
                        regularRoutes={<RegularTransportOperatorRoutes />}
                        betaRoutes={
                          <BetaTransportOperatorRoutes
                            creditIdentificationIsValid={creditIdentificationIsValid(
                              creditIdentificationResponse
                            )}
                          />
                        }
                      />
                    </Switch>
                  )}
                  {selectedUserType === LABEL.POLICE && (
                    <Switch>
                      <Route path="/" exact component={PoliceContainers} />
                      <Route path="/containers" exact component={PoliceContainers} />
                      <Route path="/containers/selected" exact component={PoliceAudit} />
                      <Route path="/container-subscriptions/audit" exact component={PoliceAudit} />
                      <Route
                        path="/audittrail-subscriptions"
                        exact
                        component={AuditTrailSubscriptions}
                      />
                      <Route
                        path="/audittrail-subscriptions/companies"
                        exact
                        component={SelectCompanies}
                      />
                      <Route
                        path="/audittrail-subscriptions/:nxtEntityId/events"
                        exact
                        component={ScrEventsDetail}
                      />
                      <Route
                        path="/container-subscriptions"
                        exact
                        component={PoliceContainerSubscriptions}
                      />
                    </Switch>
                  )}
                  {selectedUserType === LABEL.TERMINAL && (
                    <Switch>
                      <Route path="/containers" exact component={Containers} />
                      <Route
                        path="/containers/detail"
                        exact
                        render={(props) => (
                          <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route
                        path="/releases/detail"
                        exact
                        render={(props) => (
                          <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route
                        path="/container-subscriptions"
                        exact
                        component={ContainerSubscriptions}
                      />
                      <Route
                        path="/container-subscriptions/detail"
                        exact
                        render={(props) => (
                          <SubscriptionDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route path="/alfapass" exact component={Alfapass} />
                      <BetaRouteSwitch
                        regularRoutes={<RegularTerminalRoutes />}
                        betaRoutes={<BetaTerminalRoutes />}
                      />
                    </Switch>
                  )}
                  {selectedUserType === LABEL.SHIPPING && (
                    <Switch>
                      <Route path={ROUTE_PATHS.CONTAINERS} exact component={Containers} />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_DETAIL}
                        exact
                        render={(props) => (
                          <ReleaseDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_DETAIL_OVERVIEW}
                        exact
                        component={ContainerDetailOverview}
                      />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_RELEASE_ADD}
                        exact
                        component={ContainerRelease}
                      />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_RELEASE_UPDATE}
                        exact
                        component={ContainerRelease}
                      />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_SUBSCRIPTIONS}
                        exact
                        component={ContainerSubscriptions}
                      />
                      <Route
                        path={ROUTE_PATHS.CONTAINER_SUBSCRIPTIONS_DETAIL}
                        exact
                        render={(props) => (
                          <SubscriptionDetail {...props} selectedUserType={selectedUserType} />
                        )}
                      />
                      <BetaRouteSwitch
                        regularRoutes={<RegularRoutes />}
                        betaRoutes={<BetaRoutes />}
                      />
                    </Switch>
                  )}
                </div>
              )}
              {this.state.errored && (
                <Switch>
                  <Route path="/" exact component={ErrorPage} />
                  <Route path="/containers/:tabId" exact component={ErrorPage} />
                  <Route path="/containers" exact component={ErrorPage} />
                  <Route path="/releases" exact component={ErrorPage} />
                  <Route path="/archive" exact component={ErrorPage} />
                  <Route path="/containers/selected" exact component={ErrorPage} />
                  <Route path="/container-subscriptions" exact component={ErrorPage} />
                </Switch>
              )}
            </>
          )}
        </div>
        <Messages />
        <ExpiredToken tokenExpired={tokenExpired} tokenExpiring={tokenExpiring} />
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  loadUserProfile: PropTypes.func.isRequired,
  loadCreditIdentificationResponse: PropTypes.func,
  userProfile: PropTypes.object,
  selectedUserType: PropTypes.string,
  signalRId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    selectedUserType: getSelectedUserType(state),
    creditIdentificationResponse: getCreditIdentificationResponse(state),
    signalRId: getSignalRId(state),
    loadingCreditIdentification: getCreditIdentificationLoading(state),
    tokenExpired: getTokenExpired(state),
    tokenExpiring: getTokenExpiring(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signalRIdReceived,
      loadUserProfile,
      loadCreditIdentificationResponse,
      signalRSetupFailed,
    },
    dispatch
  );
}

function creditIdentificationIsValid(creditIdentificationResponse) {
  return (
    creditIdentificationResponse != null &&
    (creditIdentificationResponse.isTransportCompanyOnly === false ||
      creditIdentificationResponse.creditIdentification != null)
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App)));
