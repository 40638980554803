import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'modules/global/adapters';
import {
  BulkReleaseRightActionRequest,
  GetContainersByReceivedFromParams,
} from 'modules/global/adapters/crpAPI';
import { acceptReleaseRightKeys } from './queryKeys';
import { ReleaseRightStatus } from 'modules/global/adapters/mainAPI';

export const useGetActiveTerminals = () => {
  return useQuery(acceptReleaseRightKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetPendingContainers = () => {
  return useQuery(acceptReleaseRightKeys.getPendingContainersForReleaseRightAction(), () =>
    api.containers.getPendingContainersForReleaseRightAction()
  );
};

export const useGetContainersAcceptReleaseRight = (
  receivedFromParams: GetContainersByReceivedFromParams
) => {
  const isSearchEntered =
    (receivedFromParams.terminalNxtEntityId !== undefined &&
      receivedFromParams.terminalNxtEntityId !== '') ||
    (receivedFromParams.identificationCode !== undefined &&
      receivedFromParams.identificationCode !== '' &&
      receivedFromParams.identificationType !== undefined &&
      receivedFromParams.identificationType !== '');

  receivedFromParams.status = ReleaseRightStatus.Pending;

  return useQuery(
    acceptReleaseRightKeys.containersForUpdate(receivedFromParams),
    () => api.containers.getContainersByReceivedFromParams(receivedFromParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersAccept = () => {
  const queryClient = useQueryClient();

  return useMutation(acceptReleaseRightKeys.postContainersAccept(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersAccept(data),
    onSuccess: () => {
      queryClient.invalidateQueries(acceptReleaseRightKeys.containersForUpdateRoot());
    },
  });
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(acceptReleaseRightKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};
