import { GetContainersByVoyageParams } from '../../../global/adapters/crpAPI';

export const changeCommercialReleaseKeys = {
  root: ['change-commercial-release-voyage'] as const,
  containersForChangeCommercialReleaseRoot: () =>
    [...changeCommercialReleaseKeys.root, 'containers-for-change-commercial-release'] as const,
  containersForChangeCommercialRelease: (vesselParams: GetContainersByVoyageParams) =>
    [
      ...changeCommercialReleaseKeys.containersForChangeCommercialReleaseRoot(),
      vesselParams,
    ] as const,
  postContainersChangeCommercialReleasevoyage: () =>
    [...changeCommercialReleaseKeys.root, 'containers-change-commercial-release-voyage'] as const,
};
