import { CONSTANTS, LABEL } from '../../theme/constants';
import { Put } from './authorizedRequest';

const endpoint = '/' + CONSTANTS.PORT_LOCODE + '/containers/';

async function singleReleaseActionCall(obj) {
  let container = obj.containers[0];
  let isRelease = obj.actionType.toUpperCase() == LABEL.RELEASE_LABEL;

  const url = endpoint + container.equipmentNumber.trim() + '/import/commercial-release/';
  const body = {
    releaseIdentification: container.releaseIdentification.trim(),
    releaseOrderReference: container.releaseOrderReference,
    equipmentNumber: container.equipmentNumber.trim(),
    equipmentType: container.equipmentType,
    emptyReturnReference: container.emptyReturnReference,
    billOfLadingNumbers: isRelease
      ? container.billOfLadingNumbers.map((bl) => (bl = bl.blNumber.trim()))
      : container.billOfLadingNumbers,
    portLoCode: CONSTANTS.PORT_LOCODE,
    terminalCode: container.terminalCode,
    actionType: obj.actionType,
    carrier: {
      identificationType: 'NxtEntityId',
      identificationCode: isRelease ? obj.nxtEntityId : container.originatorNxtEntityId,
    },
    releaseTo: {
      identificationType: 'NxtEntityId',
      identificationCode: isRelease ? obj.nxtEntityId : container.releaseToNxtEntityId,
    },
    releaseDateTimeUtc: container.releaseDateTimeUtc,
    expirationDateTimeUtc: container.expirationDateTimeUtc,
    equipmentSupplier: container.equipmentSupplier,
    equipmentStatus: container.equipmentStatus,
    equipmentOperator: container.equipmentOperator,
    fullEmptyIndicator: container.fullEmptyIndicator,
    isCarrierHaulage: container.isCarrierHaulage == true || container.isCarrierHaulage == 'true',
    vesselCallSign: container.vesselCallSign,
    vesselName: container.vesselName,
    imoNumber: container.imoNumber,
    stayNumber: container.stayNumber,
    voyageNumber: container.voyageNumber,
    reason: obj.reason,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

async function multipleReleaseActionCall(obj) {
  let releaseRightIdentifiers = [];

  let isRelease = obj.actionType.toUpperCase() == LABEL.RELEASE_LABEL;
  obj.containers.map(function (container, index) {
    releaseRightIdentifiers[index] = {
      billOfLadingNumbers: isRelease
        ? container.billOfLadingNumbers.map((bl) => (bl = bl.blNumber.trim()))
        : container.billOfLadingNumbers,
      equipmentNumber: container.equipmentNumber.trim(),
      releaseIdentification: container.releaseIdentification.trim(),
      terminalCode: container.terminalCode,
    };
  });

  const url = endpoint + 'import/commercial-release/';
  const body = {
    portLoCode: CONSTANTS.PORT_LOCODE,
    actionType: obj.actionType,
    releaseRights: releaseRightIdentifiers,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

export async function submitReleaseAction(obj) {
  let multipleContainers = obj.containers.length > 1;

  if (!multipleContainers) {
    singleReleaseActionCall(obj).then((response) => {
      return response;
    });
  } else {
    multipleReleaseActionCall(obj).then((response) => {
      return response;
    });
  }
}
