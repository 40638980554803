import { Button, List, ListItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Clear as DeleteForeverIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContainersFilters } from '../../store/actions/containerActions';
import { clearSelectedContainers } from '../../store/actions/selectedContainerActions';
import { LABEL } from '../../theme/constants';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  tabsRoot: {
    width: '100%',
  },
  tab: {
    minWidth: 'initial',
    '& span': {
      padding: 0,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  inputLabel: {
    backgroundColor: 'white',
    padding: '8px',
    marginTop: '-8px',
  },
  element: {
    marginTop: '10px',
  },
  horizontalDivider: {
    width: theme.spacing(20),
  },
  filterButton: {
    marginRight: theme.spacing(1),
  },
  selectType: {
    lineHeight: '2.7em !important',
  },
});

class ContainersFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      errors: {},
    };
  }

  onChange = (key) => (event) => {
    const filters = { ...this.state.filters, [key]: event.target.value };
    this.setState({ filters });
  };

  onFilter = () => {
    this.props.clearSelectedContainers();
    this.props.loadContainersFilters(this.state.filters);
  };

  onFilterClear = () => {
    const filters = this.state.filters;
    for (let key in filters) {
      filters[key] = '';
    }
    this.setState(filters);
    this.props.loadContainersFilters(this.state.filters);
  };

  render() {
    const { classes, terminal, shipping } = this.props;
    const { filters, errors } = this.state;

    return (
      <List>
        <ListItem>
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.BILL_OF_LADING}
            value={filters.billOfLading}
            onChange={this.onChange('billOfLading')}
            fullWidth
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.EQUIPMENT_NUMBER}
            value={filters.equipmentNumber}
            onChange={this.onChange('equipmentNumber')}
            fullWidth
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.SHIPPING_AGENT}
            value={filters.shippingAgent}
            onChange={this.onChange('shippingAgent')}
            fullWidth
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {!terminal && !shipping && (
            <div style={{ display: 'contents' }}>
              <div className={classes.horizontalDivider} />
              <TextField
                label={LABEL.RECEIVED_FROM}
                value={filters.receivedFrom}
                onChange={this.onChange('receivedFrom')}
                fullWidth
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.onFilter();
                  }
                }}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          {!terminal && (
            <div style={{ display: 'contents' }}>
              <div className={classes.horizontalDivider} />
              <TextField
                label={LABEL.TERMINAL}
                value={filters.terminal}
                onChange={this.onChange('terminal')}
                fullWidth
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.onFilter();
                  }
                }}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          <div className={classes.horizontalDivider} />
          <Button
            style={{ padding: '14px 12px' }}
            size="large"
            variant="contained"
            color="primary"
            onClick={this.onFilter}
            fullWidth={true}
            className={classes.filterButton}
          >
            {LABEL.FILTER}
            <SearchIcon className={classes.rightIcon} />
          </Button>
          <Button
            style={{ padding: '14px 0px' }}
            variant="contained"
            color="primary"
            onClick={this.onFilterClear}
          >
            <DeleteForeverIcon />
          </Button>
        </ListItem>
      </List>
    );
  }
}

ContainersFilter.propTypes = {
  loadContainersFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  terminal: PropTypes.bool.isRequired,
  overviewTypeCallback: PropTypes.func.isRequired,
  overviewType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    filters: state.containers.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadContainersFilters,
      clearSelectedContainers,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContainersFilter));
