import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { TerminalContainer } from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import { usePostContainersAccept } from 'modules/transport-operator/adapters/accept-releaseright/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/transport-operator/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterStep from './FilterStep';
import ResultStep from '../shared/ResultStep';
import UpdateStep from './UpdateStep';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';

type Props = {
  data: TerminalContainer[] | undefined;
  selectedRows: Row<TerminalContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<TerminalContainer[], unknown>>;
};

const StepperAcceptReleaseRight = ({
  data,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  refetch,
}: Props) => {
  const { t } = useTranslation(['acceptReleaseRight', 'common', 'useCase', 'validation']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const resetFormAndContainersUpdated = async () => {
    await refetch();
    setRowSelection({});
    setContainersUpdated(0);
  };

  const { mutate, status } = usePostContainersAccept();

  const acceptReleaseRight = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      releaseIds: listOfCheckedContainers,
    });
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.ACCEPT_RELEASERIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectPendingContainersFlavorText'),
            nextCustomText: t('common:general.next'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('acceptReleaseRight:general.title'),
            stepFlavorText: t('useCase:containers.acceptContainersFlavorText'),
            nextCallBack: acceptReleaseRight,
            nextCustomText: t('useCase:containers.acceptContainers', {
              numberOfContainersSelected: numberOfContainersProcessed,
            }),
            disableNext: numberOfContainersSelected < 1,
            content: <UpdateStep numberOfContainersSelected={numberOfContainersSelected} />,
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={acceptReleaseRight}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                customReturnToSearchText="common:general.backToSelection"
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                })}
                hasNextStep={false}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperAcceptReleaseRight;
