import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { bindActionCreators } from 'redux';
import LocalStorageHelper from 'utils/localStorage';
import history from '../../history/browserHistory';
import {
  loadCompanies,
  loadCompaniesFilters,
  loadCompaniesPage,
  loadCompaniesRowsPerPage,
} from '../../store/actions/companiesActions';
import {
  createCompanySubscription,
  setPoliceCase,
} from '../../store/actions/policeContainerActions';
import {
  getCompanies,
  getCompaniesLoading,
  getCompaniesPaging,
  getPoliceContainersPoliceCase,
  getCompanySubscriptionIsCreated,
} from '../../store/selectors/selectors';
import { DIALOG, DIALOGTYPES, FORMATTING, LABEL, VALIDATION } from '../../theme/constants';
import DateTimeHelper from '../../utils/datetime';
import ActionDialog from '../organisms/ActionDialog';
import CompanyFilter from '../organisms/CompanyFilter';
import CompanyTable from '../organisms/CompanyTable';
import CreateConfirmationDialog from '../organisms/ConfirmationDialog';

const styles = (theme) => ({
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  companiesCircularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
});

const columnsCompanies = [
  { id: 'name', label: LABEL.NAME_LABEL },
  { id: 'taxId', label: LABEL.TAX_ID_LABEL },
  { id: 'apcs-code', label: LABEL.APCS_CODE },
  { id: 'duns', label: LABEL.DUNS },
  { id: 'eori', label: LABEL.EORI },
  { id: 'Subscribe', label: LABEL.SUBSCRIBE },
];

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '60px',
    marginTop: '-35px',
  },
  buttonRight: {
    alignSelf: 'flex-end',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  tooltip: {
    margin: 0,
  },
}));

const emptyCompany = { name: '', nxtEntityId: '' };
const emptySubscription = {
  reference: '',
  dossier: '',
  fromDate: '',
  toDate: '',
  company: { name: '', nxtEntityId: '' },
};
const initialInputError = { dateFromError: false, dateToError: false };

const SelectCompanies = ({
  companies,
  paging,
  loading,
  loadCompanies,
  loadCompaniesPage,
  loadCompaniesRowsPerPage,
  createCompanySubscription,
  policeCase,
  setPoliceCase,
  isCreated,
}) => {
  const [isOpenSubscribeDialog, setValueOpenSubscribeDialog] = useState(false);
  const [openCreateConfirmation, setOpenCreateConfirmation] = useState(false);
  const [selectedCompany, setSelectCompany] = useState(emptyCompany);
  const [newSubscription, setNewSubscription] = useState(emptySubscription);
  const [inputError, setInputError] = useState(initialInputError);
  const classes = useStyles();

  useEffect(() => {
    loadCompanies();
    if (policeCase.number === '' && policeCase.referenceNumber === '') {
      const policeCaseFromStorage = LocalStorageHelper.getPoliceCase();
      setPoliceCase(policeCaseFromStorage);
    }
  }, []);

  useEffect(() => {
    if (isCreated) {
      history.push('/audittrail-subscriptions');
    }
  }, [isCreated]);

  const validFromDate = (date) => {
    var isValid = date != null && date != '' && DateTimeHelper.isFromToday(date);
    setInputError({ ...inputError, dateFromError: !isValid });
    return isValid;
  };

  const validToDate = (date) => {
    var isValid =
      DateTimeHelper.isFromToday(date) &&
      DateTimeHelper.greaterThan(date, newSubscription.fromDate);
    setInputError({ ...inputError, dateToError: !isValid });
    return isValid;
  };

  const checkValidation = () => {
    var isValid = validFromDate(newSubscription.fromDate) && validToDate(newSubscription.toDate);
    isValid =
      isValid &&
      selectedCompany.name != null &&
      selectedCompany.name.length > 0 &&
      selectedCompany.nxtEntityId != null &&
      selectedCompany.nxtEntityId.length > 0;
    return isValid;
  };

  const resetValidation = () => {
    setInputError(initialInputError);
  };

  const closeSubscribeDialog = () => {
    setValueOpenSubscribeDialog(false);
    resetValidation();
    setNewSubscription(emptySubscription);
  };

  const onChangeFromDate = (e) => {
    setNewSubscription({ ...newSubscription, fromDate: e.target.value });
    validFromDate(e.target.value);
  };

  const onChangeToDate = (e) => {
    setNewSubscription({ ...newSubscription, toDate: e.target.value });
    validToDate(e.target.value);
  };

  const handleCreateSubscription = () => {
    if (!checkValidation()) return;
    setValueOpenSubscribeDialog(false);
    newSubscription.company.nxtEntityId = selectedCompany.nxtEntityId;
    newSubscription.dossier = policeCase.number;
    newSubscription.reference = policeCase.referenceNumber;
    createCompanySubscription(newSubscription);
  };

  const createSubscription = (e) => {
    e.stopPropagation();
    if (reactLocalStorage.get(DIALOGTYPES.CREATE_COMPANY_SUBSCRIPTION) === 'true') {
      handleCreateSubscription();
    } else {
      if (checkValidation()) setOpenCreateConfirmation(true);
    }
  };

  const createSuscriptionDialogActions = (
    <div>
      <Button onClick={closeSubscribeDialog}>{LABEL.CANCEL}</Button>
      <Button onClick={(e) => createSubscription(e)} disabled={false}>
        {LABEL.CREATE}
      </Button>
    </div>
  );

  const createSubscriptionDialogFields = (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          id="inpValidFromDate"
          label={LABEL.RELEASE_VALID_FROM}
          error={inputError.dateFromError}
          type="date"
          value={newSubscription.fromDate}
          helperText={
            inputError.dateFromError
              ? VALIDATION.DATE_MUST_BE_GRATER_THAN_TODAY
              : LABEL.ENTER_VALID_FROM_DATE
          }
          onChange={(e) => onChangeFromDate(e)}
          InputLabelProps={{
            shrink: true,
            style: { color: 'black' },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="inpValidUntilDate"
          label={LABEL.RELEASE_VALID_UNTIL}
          error={inputError.dateToError}
          type="date"
          value={newSubscription.toDate}
          helperText={
            inputError.dateToError
              ? VALIDATION.DATE_MUST_BE_GRATER_THAN_FROM_DATE
              : LABEL.ENTER_VALID_TO_DATE
          }
          onChange={(e) => onChangeToDate(e)}
          InputLabelProps={{
            shrink: true,
            style: { color: 'black' },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <span>&nbsp;</span>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Paper className={classes.paper}>
        <CompanyFilter />
      </Paper>
      <Paper className={classes.paper}>
        <CompanyTable
          columns={columnsCompanies}
          items={companies}
          paging={paging}
          loadCompanies={loadCompanies}
          loadCompaniesPage={loadCompaniesPage}
          loadCompaniesRowsPerPage={loadCompaniesRowsPerPage}
          headerTitle={LABEL.SUBSCRIBE_ON_A_COMPANY}
          onSelect={(selectedStakeholder) => {
            setSelectCompany({
              name: selectedStakeholder.name,
              nxtEntityId: selectedStakeholder.nxtEntityId,
            });
            setValueOpenSubscribeDialog(true);
          }}
        />
      </Paper>
      <ActionDialog
        dialogTitle={DIALOG.CREATE_SUBSCRIPTION_ON_COMPANY}
        contentText={
          selectedCompany == null
            ? ''
            : DIALOG.CREATE_SUBSCRIPTION_ON_COMPANY_DESCRIPTION.replace(
                FORMATTING.COMPANY,
                selectedCompany.name + '(' + selectedCompany.nxtEntityId + ')'
              )
        }
        contentFields={createSubscriptionDialogFields}
        actions={createSuscriptionDialogActions}
        handleClose={closeSubscribeDialog}
        open={isOpenSubscribeDialog}
      />
      <CreateConfirmationDialog
        open={openCreateConfirmation}
        dialogType={DIALOGTYPES.CREATE_COMPANY_SUBSCRIPTION}
        dialogTitle={DIALOG.CREATE_SUBSCRIPTION_ON_COMPANY}
        contentText={DIALOG.CREATE_SUBSCRIPTION_ON_COMPANY_CONFIRMATION_TEXT.replace(
          FORMATTING.COMPANY,
          selectedCompany.name
        )}
        callback={handleCreateSubscription}
        setOpen={setOpenCreateConfirmation}
      />
    </>
  );
};

SelectCompanies.propTypes = {
  classes: PropTypes.object.isRequired,
  loadCompaniesFilters: PropTypes.func.isRequired,
  loadCompaniesPage: PropTypes.func.isRequired,
  loadCompaniesRowsPerPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    companies: getCompanies(state),
    paging: getCompaniesPaging(state),
    loading: getCompaniesLoading(state),
    policeCase: getPoliceContainersPoliceCase(state),
    isCreated: getCompanySubscriptionIsCreated(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCompanies,
      loadCompaniesFilters,
      loadCompaniesPage,
      loadCompaniesRowsPerPage,
      createCompanySubscription,
      setPoliceCase,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectCompanies));
