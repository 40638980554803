import * as React from 'react';
import { InputLabel, TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReasonTypes, Reason } from './ReasonTypes';

type Props = {
  SetOverruleReason: Function;
  SetOverruleFreeText: Function;
  FreeText: string | undefined;
  Reasons: Reason[];
};

const ConfirmStep = ({ SetOverruleReason, SetOverruleFreeText, FreeText, Reasons }: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);
  const [reasonItems, setReasonItems] = React.useState<Reason[]>(Reasons);
  const [selectedReason, setSelectedReason] = React.useState<string | undefined>(
    Reasons[0].reasonType
  );
  const [freeText, setFreeText] = React.useState<string | undefined>(FreeText);

  const getReasons = () => {
    let reasons: Reason[] = [];
    ReasonTypes.forEach((item) => {
      reasons.push(new Reason(t('overruleCustomsLight:sidebarConfirm.reasons.' + item), item));
    });
    return reasons;
  };

  React.useEffect(() => {
    setReasonItems(getReasons());
  }, []);

  const handleChange = (change: any) => {
    var reason = change.target.value;
    if (ReasonTypes.indexOf(reason) >= 0) {
      SetOverruleReason(reason);
      setSelectedReason(reason);
    }
  };

  const handleTextChange = (evt: React.ChangeEvent<any>) => {
    const { value } = evt.target;
    setFreeText(value);
    SetOverruleFreeText(value);
  };

  return (
    <React.Fragment>
      {reasonItems.length > 0 && (
        <div>
          <p>{t('overruleCustomsLight:sidebarConfirm.AddReasonForYour')}</p>
          <FormControl style={{ minWidth: '300px', border: 0 }}>
            <InputLabel style={{ marginTop: '-8px', marginLeft: '15px' }} id="reason-label">
              {t('overruleCustomsLight:sidebarConfirm.reasonTexthelper')}
            </InputLabel>
            <Select
              style={{ border: 0 }}
              labelId="reason-label"
              variant="outlined"
              label={t('overruleCustomsLight:sidebarConfirm.reasonTexthelper')}
              onChange={(change) => {
                handleChange(change);
              }}
              value={selectedReason != null ? selectedReason : reasonItems[0].reasonType}
            >
              {reasonItems.map((item) => {
                return (
                  <MenuItem key={item.reasonType} value={item.reasonType}>
                    {item.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: '300px', marginTop: '20px' }}>
            <TextField
              label="Additional info"
              variant="outlined"
              onChange={(e) => handleTextChange(e)}
              value={freeText}
            />
          </FormControl>
        </div>
      )}
    </React.Fragment>
  );
};

export default ConfirmStep;
