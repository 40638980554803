import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MutationStatus } from '@tanstack/react-query';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { maxNumberOfContainersProcessed, ROUTE_PATHS } from '../../constants';
import useStyles from './styles';

type Props = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  numberOfContainersUpdated: number;
  status: MutationStatus;
  retryFunction: VoidFunction;
  setDisplayGrid?: React.Dispatch<React.SetStateAction<boolean>>;
  resultText: string;
  callBackOnReturnToSearch?: VoidFunction;
  customResultAction?: string;
  customReturnToSearchText?: string;
  hasNextStep?: boolean;
};

const ResultStep = ({
  setActiveStep,
  retryFunction,
  numberOfContainersUpdated,
  resultText,
  status,
  setDisplayGrid,
  callBackOnReturnToSearch,
  customResultAction,
  customReturnToSearchText,
  hasNextStep,
}: Props) => {
  const { t } = useTranslation(['common', 'validation', 'useCase']);
  const classes = useStyles();

  const isLoading = status === 'loading';

  const containersToUpdate =
    numberOfContainersUpdated > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersUpdated;

  const getResultStatus = () => {
    switch (status) {
      case 'loading':
        return <LinearProgress />;

      case 'error':
        return (
          <React.Fragment>
            <Alert severity="error">{t('validation:errorPostUpdateExpiryDate')}</Alert>
            <Button
              style={{ marginTop: '15px' }}
              variant="outlined"
              color="primary"
              onClick={retryFunction}
            >
              {t('useCase:containers.retryFunction', {
                numberOfContainersSelected: containersToUpdate,
              })}
            </Button>
          </React.Fragment>
        );

      case 'success':
        return (
          <React.Fragment>
            <Alert severity="success">
              {t('validation:update.containersUpdateAlert', {
                numberOfContainersUpdated,
                action: customResultAction || t('validation:update.updated'),
              })}
            </Alert>
            <Typography className={classes.subText}>{resultText}</Typography>
          </React.Fragment>
        );

      default:
        break;
    }
  };

  return hasNextStep ? (
    <>{getResultStatus()}</>
  ) : (
    <React.Fragment>
      {getResultStatus()}
      <Box marginTop={6}>
        <Link to={ROUTE_PATHS.BASE_ROUTE}>
          <Button disabled={isLoading} variant="outlined" style={{ marginRight: '10px' }}>
            {t('common:general.backHome')}
          </Button>
        </Link>
        <Button
          disabled={isLoading}
          variant="outlined"
          color="primary"
          onClick={() => {
            setActiveStep(0);
            setDisplayGrid?.(false);
            callBackOnReturnToSearch?.();
          }}
        >
          {customReturnToSearchText
            ? t(customReturnToSearchText)
            : t('common:general.backToSearchOverview')}
        </Button>
      </Box>
    </React.Fragment>
  );
};
export default ResultStep;
