import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { TerminalContainer } from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import {
  usePostContainersAccept,
  usePostContainersAssign,
} from 'modules/transport-operator/adapters/accept-releaseright/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/transport-operator/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AssignPickUpStep from './AssignPickUpStep';
import FilterStep from './FilterStep';
import ResultStep from '../shared/ResultStep';
import UpdateStep from './UpdateStep';
import { connect } from 'react-redux';
import { getCreditIdentification, getRemainingCredits } from 'store/selectors/selectors';

type Props = {
  data: TerminalContainer[] | undefined;
  selectedRows: Row<TerminalContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  creditIdentification: any;
  remainingCredits: number;
};

const StepperAcceptReleaseRight = ({
  data,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  creditIdentification,
  remainingCredits,
}: Props) => {
  const { t } = useTranslation(['acceptReleaseRight', 'common', 'useCase', 'validation']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);
  const [newMeansOfTransport, setMeansOfTransport] = React.useState('');

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const canAcceptContainers = creditIdentification.hasPaidSubscription || remainingCredits > 0;

  const resetFormAndContainersUpdated = () => {
    setRowSelection({});
    setContainersUpdated(0);
    setMeansOfTransport('');
  };

  const { mutate: mutateAccept, status: acceptStatus } = usePostContainersAccept();
  const { mutate: mutateAssign, status: assignStatus } = usePostContainersAssign();

  const acceptReleaseRight = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutateAccept({
      releaseIds: listOfCheckedContainers,
    });
  };

  const assignPickUp = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutateAssign({
      releaseIds: listOfCheckedContainers,
      meansOfTransport: newMeansOfTransport,
    });
    setRowSelection({});
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.ACCEPT_RELEASERIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectPendingContainersFlavorText'),
            nextCustomText: t('common:general.next'),
            disableNext: numberOfContainersSelected === 0 || !canAcceptContainers,
            noBackButton: true,
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('acceptReleaseRight:general.title'),
            stepFlavorText: t('useCase:containers.acceptContainersFlavorText'),
            nextCallBack: acceptReleaseRight,
            nextCustomText: t('useCase:containers.acceptContainers', {
              numberOfContainersSelected: numberOfContainersProcessed,
            }),
            disableNext: numberOfContainersSelected < 1,
            content: <UpdateStep numberOfContainersSelected={numberOfContainersSelected} />,
          },
          {
            stepTitle: t('common:general.result'),
            nextCustomText: t('acceptReleaseRight:assignPickUp.actions.continue'),
            disableBack: true,
            content: (
              <ResultStep
                status={acceptStatus}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={acceptReleaseRight}
                setDisplayGrid={setDisplayGrid}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                })}
                hasNextStep={true}
              />
            ),
          },
          {
            stepTitle: t('acceptReleaseRight:assignPickUp.title'),
            stepFlavorText: t('acceptReleaseRight:assignPickUp.flavorText'),
            nextCallBack:
              newMeansOfTransport !== undefined && newMeansOfTransport !== ''
                ? assignPickUp
                : undefined,
            nextCustomText:
              newMeansOfTransport !== undefined && newMeansOfTransport !== ''
                ? t('acceptReleaseRight:assignPickUp.actions.assign')
                : t('acceptReleaseRight:assignPickUp.actions.continue'),
            disableNext: numberOfContainersSelected < 1,
            content: (
              <AssignPickUpStep
                numberOfContainersSelected={numberOfContainersSelected}
                setMeansOfTransport={setMeansOfTransport}
                newMeansOfTransport={newMeansOfTransport}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={assignStatus}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={assignPickUp}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                resultText={t('sideBar.containersAssigned', {
                  numberOfContainersUpdated: containersUpdated,
                })}
                customReturnToSearchText="common:general.backToSelection"
                hasNextStep={false}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

function mapStateToProps(state: any) {
  return {
    creditIdentification: getCreditIdentification(state),
    remainingCredits: getRemainingCredits(state),
  };
}

export default connect(mapStateToProps)(StepperAcceptReleaseRight as React.ComponentType<Props>);
