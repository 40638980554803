import {
  Paper,
  Divider,
  Table,
  TableBody,
  Typography,
  Toolbar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTableRow } from '../../theme/theme';
import TableHead from '../molecules/ContainerTableHead';
import CenterTableCell from '../atoms/CenterTableCell';
import { getPoliceSelected } from '../../store/selectors/selectors';
import * as moment from 'moment';
import { policeBlockContainer } from '../../store/actions/policeContainerActions';
import { ACTIONTYPES, DIALOG, LABEL } from '../../theme/constants';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  wideCell: {
    padding: '20px 15px',
  },
  spacer: {
    flex: '1 1 100%',
  },
  breadBar: {
    paddingBottom: theme.spacing(2),
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  blockButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: 'white',
  },
  action: {
    display: 'inline-flex',
    width: '150px',
    justifyContent: 'space-between',
  },
});

let columns = [
  { id: 'date', label: LABEL.DATE.toUpperCase() },
  { id: 'time', label: LABEL.TIME.toUpperCase() },
  { id: 'action', label: LABEL.ACTION.toUpperCase() },
  { id: 'fromCompany', label: LABEL.FROM_COMPANY.toUpperCase() },
  { id: 'toCompany', label: LABEL.TO_COMPANY.toUpperCase() },
  { id: 'doneBy', label: LABEL.DONE_BY.toUpperCase() },
  { id: 'remarks', label: LABEL.REMARKS.toUpperCase() },
];

class PoliceAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
      blockDialogOpen: false,
      unBlockDialogOpen: false,
    };
  }

  handleCloseBlockDialog = () => {
    this.setState({ blockDialogOpen: false });
  };

  openBlockDialog = () => {
    this.setState({ blockDialogOpen: true });
  };

  blockContainer = () => {
    this.props.policeBlockContainer(
      ACTIONTYPES.BLOCK,
      this.state.selected.releaseIdentification,
      this.state.selected.billOfLadingNumbers,
      this.state.selected.equipmentNumber
    );
    this.setState({ blockDialogOpen: false });
  };

  handleCloseUnBlockDialog = () => {
    this.setState({ unBlockDialogOpen: false });
  };

  openUnBlockDialog = () => {
    this.setState({ unBlockDialogOpen: true });
  };

  unBlockContainer = () => {
    this.props.policeBlockContainer(
      ACTIONTYPES.UNBLOCK,
      this.state.selected.releaseIdentification,
      this.state.selected.billOfLadingNumbers,
      this.state.selected.equipmentNumber
    );
    this.setState({ unBlockDialogOpen: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" id="tableTitle" component={'div'}>
              {this.state.selected.equipmentNumber}
            </Typography>
            <div className={classes.action}>
              <Chip
                label={this.props.selected.operationalStatus === 5 ? LABEL.ARCHIVED : LABEL.ACTIVE}
                color="primary"
                variant="outlined"
              />
              {/* {this.props.selected.policeStatus !== 1 &&
                this.props.selected.operationalStatus !== 5 && (
                  <Button className={classes.blockButton} onClick={this.openBlockDialog}>
                    {ACTIONTYPES.BLOCK}
                  </Button>
                )}
              {this.props.selected.policeStatus == 1 && (
                <Button color="primary" onClick={this.openUnBlockDialog}>
                  {ACTIONTYPES.UNBLOCK}
                </Button>
              )} */}
            </div>
          </Toolbar>
          <Divider />
          <Table aria-labelledby="tableTitle" style={{ width: '100%' }}>
            <TableHead className={classes.wideCell} centerAlign={true} columns={columns} />
            <TableBody>
              {this.state.selected.audit.items.map((record, index) => {
                return (
                  <StyledTableRow key={index}>
                    <CenterTableCell text={moment(record.timestamp).format('DD-MM-YYYY')} />
                    <CenterTableCell text={moment(record.timestamp).format('HH:mm')} />
                    <CenterTableCell text={record.eventType} />
                    <CenterTableCell text={record.fromName} />
                    <CenterTableCell text={record.toName} />
                    <CenterTableCell text={record.stakeholderName} />
                    <CenterTableCell text={record.remarks} />
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <Dialog
          open={this.state.blockDialogOpen}
          onClose={this.handleCloseBlockDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{DIALOG.BLOCK_CONTAINER_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {DIALOG.BLOCK_CONTAINER_TEXT}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseBlockDialog} color="primary">
              {LABEL.CANCEL.toUpperCase()}
            </Button>
            <Button onClick={this.blockContainer} color="primary" autoFocus>
              {LABEL.YES}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.unBlockDialogOpen}
          onClose={this.handleCloseUnBlockDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{DIALOG.UNBLOCK_CONTAINER_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {DIALOG.UNBLOCK_CONTAINER_TEXT}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseUnBlockDialog} color="primary">
              {LABEL.CANCEL.toUpperCase()}
            </Button>
            <Button onClick={this.unBlockContainer} color="primary" autoFocus>
              {LABEL.YES}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

PoliceAudit.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selected: getPoliceSelected(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      policeBlockContainer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PoliceAudit));
