import { Dialog, MenuItem, Select, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BulkPickUpActionRequestItem,
  MeansOfTransport,
  ModalContainer,
} from '../../../global/adapters/crpAPI';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';
import { PickupModal } from '../shared';
import { LABEL } from 'theme/constants';

type Props = {
  numberOfContainersSelected: number;
  selectedContainers: ModalContainer[] | undefined;
  setMeansOfTransport: React.Dispatch<React.SetStateAction<string>>;
  setPickupAssignments: React.Dispatch<React.SetStateAction<BulkPickUpActionRequestItem[]>>;
  newMeansOfTransport: string;
};

const UpdateStep: React.FC<Props> = ({
  selectedContainers,
  setMeansOfTransport,
  setPickupAssignments,
  newMeansOfTransport,
  numberOfContainersSelected,
}: Props) => {
  const { t } = useTranslation('assignPickUp');
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleMeansOfTransportChange = (
    _event: React.ChangeEvent<{}>,
    meansOfTransport: string
  ) => {
    setMeansOfTransport(meansOfTransport);
    if (meansOfTransport === MeansOfTransport.Truck) {
      setModalOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
      <Select
        defaultValue={newMeansOfTransport}
        value={newMeansOfTransport}
        fullWidth
        variant="outlined"
        onChange={(e) => {
          handleMeansOfTransportChange(e, e.target.value as string);
        }}
      >
        {Object.keys(MeansOfTransport).map((status) => (
          <MenuItem value={status} key={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
      <Dialog open={modalOpen} className={classes.dialog} maxWidth="lg" fullWidth>
        <PickupModal
          action={LABEL.ASSIGN}
          setModalOpen={setModalOpen}
          setMeansOfTransport={setMeansOfTransport}
          setPickupAssignments={setPickupAssignments}
          selectedContainers={selectedContainers}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateStep;
