import React from 'react';
import { Route } from 'react-router-dom';
import store from 'store/store';
import { api, mainAPI } from '../../global/adapters';
import { ROUTE_PATHS } from '../constants';
import {
  AssignReleaseParty,
  ChangeCommercialReleaseVoyage,
  ChangeTerminal,
  ChangeVessel,
  UpdateExpiryDate,
  UseCases,
  SearchContainers,
  CreateCommercialReleaseUploadCsv,
  OverruleCustomsLight,
} from '../pages';
import DisplayNotifications from 'modules/terminal/pages/DisplayNotifications';
import DisplayBillingInfo from '../../release-party/pages/DisplayBillingInfo';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const BetaRoutes: React.FunctionComponent<Props> = () => {
  const userState = store.getState().oidc.user;
  api.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });
  mainAPI.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });

  return (
    <React.Fragment>
      <Route path={ROUTE_PATHS.CHANGE_TERMINAL} exact component={ChangeTerminal} />
      <Route path={ROUTE_PATHS.UPDATE_EXPIRY_DATE} exact component={UpdateExpiryDate} />
      <Route path={ROUTE_PATHS.UPDATE_VOYAGE} exact component={ChangeVessel} />
      <Route path={ROUTE_PATHS.DISPLAY_NOTIFICATIONS} exact component={DisplayNotifications} />
      <Route
        path={ROUTE_PATHS.BLOCK_VOYAGE}
        exact
        render={() => <ChangeCommercialReleaseVoyage commercialReleaseAction="BLOCK" />}
      />
      <Route
        path={ROUTE_PATHS.UNBLOCK_VOYAGE}
        exact
        render={() => <ChangeCommercialReleaseVoyage commercialReleaseAction="UNBLOCK" />}
      />
      <Route
        path={ROUTE_PATHS.DELETE_VOYAGE}
        exact
        render={() => <ChangeCommercialReleaseVoyage commercialReleaseAction="DELETE" />}
      />
      <Route
        path={ROUTE_PATHS.REVOKE_VOYAGE}
        exact
        render={() => <ChangeCommercialReleaseVoyage commercialReleaseAction="REVOKE" />}
      />
      <Route
        path={ROUTE_PATHS.CREATE_MULTIPLE_RELEASES_UPLOAD}
        exact
        render={() => (
          <CreateCommercialReleaseUploadCsv commercialReleaseAction="CREATEUPLOADCSV" />
        )}
      />
      <Route path={ROUTE_PATHS.OVERRULE_CUSTOMS_LIGHT} exact component={OverruleCustomsLight} />
      <Route path={ROUTE_PATHS.ASSIGN_RELEASE_PARTY} exact component={AssignReleaseParty} />
      <Route path={ROUTE_PATHS.SEARCH_PER_VOYAGE} exact component={SearchContainers} />
      <Route path={ROUTE_PATHS.BASE_ROUTE} exact component={UseCases} />
    </React.Fragment>
  );
};

export default BetaRoutes;
