import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import {
  AssignFirstReleasePartyRequest,
  GetContainersByVoyageParams,
  ReleaseToPartyByRole,
} from '../../../global/adapters/crpAPI';
import { assignReleasePartyKeys } from './queryKeys';

export const useGetContainersAssignReleaseParty = (vesselParams: GetContainersByVoyageParams) => {
  const isSearchEntered =
    (vesselParams.vesselName !== undefined && vesselParams.vesselName !== '') ||
    (vesselParams.voyageNumber !== undefined && vesselParams.voyageNumber !== '') ||
    (vesselParams.imoNumber !== undefined && vesselParams.imoNumber !== '') ||
    (vesselParams.stayNumber !== undefined && vesselParams.stayNumber !== '');

  return useQuery(
    assignReleasePartyKeys.containersForUpdate(vesselParams),
    () => api.containers.getContainersByVoyage(vesselParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetRelevantParties = (currrentRole: string) => {
  return useQuery(assignReleasePartyKeys.getRelevantParties(currrentRole), () => {
    var releaseToPartyByRole = new ReleaseToPartyByRole(currrentRole);
    return api.releaseparties.getReleaseToParties(releaseToPartyByRole);
  });
};

export const usePostContainersUpdateReleaseParty = () => {
  const queryClient = useQueryClient();

  return useMutation(assignReleasePartyKeys.postContainersForUpdateReleaseParty(), {
    mutationFn: (data: AssignFirstReleasePartyRequest) =>
      api.containers.postContainersAssignfirstreleaseparty(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(assignReleasePartyKeys.containersForUpdateRoot());
    },
  });
};
