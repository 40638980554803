export const ROUTE_PATHS = {
  BASE_ROUTE: '/',
  DISPLAY_NOTIFICATIONS: '/display-notifications',
  OVERRULE_CUSTOMS_LIGHT: '/overrule-customs-light',
  SEARCH_CONTAINERS: '/search-containers',
};

export const BASE_FAQ_URL = 'https://documentation.nxtport.com/certified-pickup';
export const FAQ_PAGES = {
  OVERRULE_LIGHT: `${BASE_FAQ_URL}/overrule`,
};
