import React, { useEffect } from 'react';
import { Paper, CircularProgress } from '@material-ui/core';
import { ACTIONTYPES } from '../../theme/constants';
import Container from '../../utils/validation/container';
import { withStyles } from '@material-ui/styles';
import {
  getUserProfile,
  getTerminals,
  getSelectedContainer,
} from '../../store/selectors/selectors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  createNewRelease,
  updateCommercialRelease,
} from '../../store/actions/commercialReleaseActions';
import { loadContainer } from '../../store/actions/containerActions';
import CreateContainerRelease from './partials/CreateContainerRelease';
import UpdateContainerRelease from './partials/UpdateContainerRelease';
import ArrayHelper from '../../utils/array';
import history from '../../history/browserHistory';
import { LABEL } from '../../theme/constants';

const styles = (theme) => ({
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridMargin: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
});

function ContainerRelease(props) {
  const {
    classes,
    createNewRelease,
    updateCommercialRelease,
    loadContainer,
    selectedContainer,
    terminals,
    userProfile,
  } = props;

  const createEmptyContainer = () => {
    return {
      equipmentNumber: '',
      billOfLadingNumbers: [],
      releaseIdentification: '',
      terminalCode: '',
      terminalName: '',
      equipmentType: '',
      releaseOrderReference: '',
      emptyReturnReference: '',
      equipmentSupplier: '',
      equipmentStatus: '',
      fullEmptyIndicator: LABEL.FULLEMPTYINDICATOR_FULL,
      equipmentOperator: '',
      vesselCallSign: '',
      vesselName: '',
      voyageNumber: '',
      imoNumber: '',
      stayNumber: '',
      isCarrierHaulage: false,
      releaseDateTimeUtc: null,
      expirationDateTimeUtc: null,
    };
  };

  const getQueryParams = () => {
    const urlSearchParams = window.location.search;
    const queryParams = new URLSearchParams(urlSearchParams);
    return queryParams;
  };

  const queryStringRef = React.useRef('');
  const [container, setContainer] = React.useState(createEmptyContainer());
  const [containerLoadedFromApi, setContainerLoaded] = React.useState(false);

  useEffect(() => {
    let queryParams = getQueryParams();
    let queryString = queryParams.toString();

    // If queryString is not empty --> Update
    if (queryString != queryStringRef.current) {
      queryStringRef.current = queryString;
      const query = {
        equipmentNumber: queryParams.get('equipmentNumber'),
        releaseIdentification: queryParams.get('releaseIdentification'),
        billOfLadingNumbers: queryParams.get('billOfLadingNumbers'),
      };
      loadContainer(query);
    }
    // Second time queryString is same as queryStringRef AND selectedContainer is done loading
    // AND control parameter 'containerLoadedFromApi' is set to ensure logic is not executed again
    else if (
      queryString != '' &&
      queryString == queryStringRef.current &&
      selectedContainer.loading == false &&
      containerLoadedFromApi == false
    ) {
      let numberOfGroups = Math.ceil(selectedContainer.additionalDataFields.length / 4);
      selectedContainer.additionalDataFields = ArrayHelper.createGroups(
        selectedContainer.additionalDataFields,
        numberOfGroups
      );
      setContainer(selectedContainer);
      setContainerLoaded(true);
    }
  });

  const [equipmentNumberError, setEquipmentNumberError] = React.useState(false);
  const [billOfLadingNumbersError, setBillOfLadingNumbersError] = React.useState(false);
  const [terminalCodeError, setTerminalCodeError] = React.useState(false);
  const [releaseDateTimeUtcError, setReleaseDateTimeUtcError] = React.useState(false);
  const [expirationDateTimeUtcError, setExpirationDateTimeUtcError] = React.useState(false);
  const [releaseIdentificationError, setReleaseIdentificationError] = React.useState(false);

  const resetErrors = (event) => {
    if (event !== undefined) event.stopPropagation();
    setEquipmentNumberError(false);
    setBillOfLadingNumbersError(false);
    setTerminalCodeError(false);
    setReleaseDateTimeUtcError(false);
    setExpirationDateTimeUtcError(false);
    setReleaseIdentificationError(false);
  };

  const setErrors = (error) => {
    switch (error.field) {
      case 'equipmentNumber':
        setEquipmentNumberError(true);
        break;
      case 'billOfLadingNumbers':
        setBillOfLadingNumbersError(true);
        break;
      case 'terminalCode':
        setTerminalCodeError(true);
        break;
      case 'releaseDateTimeUtc':
        setReleaseDateTimeUtcError(true);
        break;
      case 'expirationDateTimeUtc':
        setExpirationDateTimeUtcError(true);
        break;
      case 'releaseIdentification':
        setReleaseIdentificationError(true);
        break;
      default:
        break;
    }
  };

  const hasErrors = (event) => {
    event.stopPropagation();
    resetErrors(event);
    let errors = isUpdate()
      ? Container.ValidateUpdate(container)
      : Container.ValidateCreate(container);
    let hasErrors = errors.length > 0;
    if (hasErrors) {
      errors.forEach((error) => {
        setErrors(error);
      });
    }
    return hasErrors;
  };

  const isUpdate = () => {
    return Container.KeyIsFilledIn(container) && containerLoadedFromApi;
  };

  const handleSubmit = (event) => {
    event.stopPropagation();
    if (!hasErrors(event)) {
      if (isUpdate()) {
        updateCommercialRelease({
          containers: [container],
          actionType: ACTIONTYPES.UPDATE,
        });
      } else {
        createNewRelease({
          containers: [container],
          actionType: ACTIONTYPES.RELEASE,
          nxtEntityId: userProfile.nxtEntityId,
        });
      }
      history.push({ pathname: '/', search: '' });
    }
  };

  // Input setters
  const handleInpContainerNumberChange = (event) => {
    event.stopPropagation();
    setEquipmentNumberError(false);
    setContainer({ ...container, equipmentNumber: event.target.value });
  };

  const handleInpBillOfLadingNumbersChange = (blNumbers) => {
    setBillOfLadingNumbersError(false);
    setContainer({ ...container, billOfLadingNumbers: blNumbers });
  };

  const handleInpContainerTypeChange = (event) => {
    event.stopPropagation();
    setContainer({ ...container, equipmentType: event.target.value });
  };

  const handleInpReleaseValidFromChange = (event) => {
    event.stopPropagation();
    setReleaseDateTimeUtcError(false);
    setExpirationDateTimeUtcError(false);
    setContainer({ ...container, releaseDateTimeUtc: event.target.value });
  };

  const handleInpReleaseValidUntilChange = (event) => {
    event.stopPropagation();
    setExpirationDateTimeUtcError(false);
    setReleaseDateTimeUtcError(false);
    setContainer({ ...container, expirationDateTimeUtc: event.target.value });
  };

  const handleInpReleaseIdentification = (event) => {
    event.stopPropagation();
    setReleaseIdentificationError(false);
    setContainer({ ...container, releaseIdentification: event.target.value });
  };

  const handleInpReleaseOrderReference = (event) => {
    event.stopPropagation();
    setContainer({ ...container, releaseOrderReference: event.target.value });
  };

  const handleInpEmptyReturnReference = (event) => {
    event.stopPropagation();
    setContainer({ ...container, emptyReturnReference: event.target.value });
  };

  const handleInpEquipmentSupplier = (event) => {
    event.stopPropagation();
    setContainer({ ...container, equipmentSupplier: event.target.value });
  };

  const handleInpEquipmentStatus = (event) => {
    event.stopPropagation();
    setContainer({ ...container, equipmentStatus: event.target.value });
  };

  const handleInpFullEmptyIndicator = (event) => {
    event.stopPropagation();
    setContainer({ ...container, fullEmptyIndicator: event.target.value });
  };

  const handleInpEquipmentOperator = (event) => {
    event.stopPropagation();
    setContainer({ ...container, equipmentOperator: event.target.value });
  };

  const handleInpVesselCallSign = (event) => {
    event.stopPropagation();
    setContainer({ ...container, vesselCallSign: event.target.value });
  };

  const handleInpVesselName = (event) => {
    event.stopPropagation();
    setContainer({ ...container, vesselName: event.target.value });
  };

  const handleInpImoNumber = (event) => {
    event.stopPropagation();
    setContainer({ ...container, imoNumber: event.target.value });
  };

  const handleInpStayNumber = (event) => {
    event.stopPropagation();
    setContainer({ ...container, stayNumber: event.target.value });
  };

  const handleInpVoyageNumber = (event) => {
    event.stopPropagation();
    setContainer({ ...container, voyageNumber: event.target.value });
  };

  const handleInpIsCarrierHaulage = (event) => {
    event.stopPropagation();
    setContainer({ ...container, isCarrierHaulage: event.target.checked }); //!checked
  };

  const setAutoCompleteValue = (event, value) => {
    event.stopPropagation();
    if (value == undefined) {
      return;
    }
    setTerminalCodeError(false);
    let terminal = terminals.find((x) => x.code === value.code);
    setContainer({ ...container, terminalCode: terminal.code });
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {selectedContainer.loading == true ? (
          <CircularProgress fontSize="small" />
        ) : (
          <>
            {isUpdate() == false && (
              <CreateContainerRelease
                classes={classes}
                container={container}
                terminals={terminals}
                handleInpContainerNumberChange={handleInpContainerNumberChange}
                handleInpReleaseIdentification={handleInpReleaseIdentification}
                handleInpBillOfLadingNumbersChange={handleInpBillOfLadingNumbersChange}
                handleInpContainerTypeChange={handleInpContainerTypeChange}
                setAutoCompleteValue={setAutoCompleteValue}
                handleInpReleaseValidFromChange={handleInpReleaseValidFromChange}
                handleInpReleaseValidUntilChange={handleInpReleaseValidUntilChange}
                handleInpEmptyReturnReference={handleInpEmptyReturnReference}
                handleInpReleaseOrderReference={handleInpReleaseOrderReference}
                handleInpEquipmentSupplier={handleInpEquipmentSupplier}
                handleInpEquipmentStatus={handleInpEquipmentStatus}
                handleInpFullEmptyIndicator={handleInpFullEmptyIndicator}
                handleInpEquipmentOperator={handleInpEquipmentOperator}
                handleInpVesselCallSign={handleInpVesselCallSign}
                handleInpVesselName={handleInpVesselName}
                handleInpImoNumber={handleInpImoNumber}
                handleInpStayNumber={handleInpStayNumber}
                handleInpVoyageNumber={handleInpVoyageNumber}
                handleInpIsCarrierHaulage={handleInpIsCarrierHaulage}
                handleSubmit={handleSubmit}
                equipmentNumberError={equipmentNumberError}
                releaseIdentificationError={releaseIdentificationError}
                billOfLadingNumbersError={billOfLadingNumbersError}
                terminalCodeError={terminalCodeError}
                releaseDateTimeUtcError={releaseDateTimeUtcError}
                expirationDateTimeUtcError={expirationDateTimeUtcError}
              />
            )}
            {isUpdate() && (
              <UpdateContainerRelease
                classes={classes}
                container={container}
                terminals={terminals}
                handleInpContainerTypeChange={handleInpContainerTypeChange}
                setAutoCompleteValue={setAutoCompleteValue}
                handleInpReleaseValidFromChange={handleInpReleaseValidFromChange}
                handleInpReleaseValidUntilChange={handleInpReleaseValidUntilChange}
                handleInpEmptyReturnReference={handleInpEmptyReturnReference}
                handleInpReleaseOrderReference={handleInpReleaseOrderReference}
                handleInpEquipmentSupplier={handleInpEquipmentSupplier}
                handleInpEquipmentStatus={handleInpEquipmentStatus}
                handleInpFullEmptyIndicator={handleInpFullEmptyIndicator}
                handleInpEquipmentOperator={handleInpEquipmentOperator}
                handleInpVesselCallSign={handleInpVesselCallSign}
                handleInpVesselName={handleInpVesselName}
                handleInpImoNumber={handleInpImoNumber}
                handleInpStayNumber={handleInpStayNumber}
                handleInpVoyageNumber={handleInpVoyageNumber}
                handleInpIsCarrierHaulage={handleInpIsCarrierHaulage}
                handleSubmit={handleSubmit}
                equipmentNumberError={equipmentNumberError}
                releaseIdentificationError={releaseIdentificationError}
                billOfLadingNumbersError={billOfLadingNumbersError}
                terminalCodeError={terminalCodeError}
                releaseDateTimeUtcError={releaseDateTimeUtcError}
                expirationDateTimeUtcError={expirationDateTimeUtcError}
              />
            )}
          </>
        )}
      </Paper>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    terminals: getTerminals(state),
    selectedContainer: getSelectedContainer(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNewRelease,
      updateCommercialRelease,
      loadContainer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContainerRelease));
