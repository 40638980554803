import { createColumnHelper, FilterFn, Row } from '@tanstack/react-table';
import i18n from '../../../global/config/i18n';
import { ContainerBillingInfo } from '../../../global/adapters/crpAPI';

const columnHelper = createColumnHelper<ContainerBillingInfo>();

export const billingInfoColumns = [
  columnHelper.accessor('equipmentNumber', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:containerNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('billOfLadingNumbers', {
    cell: (info) => info.getValue(),
    size: 200,
    header: () => i18n.t('glossary:billOfLadingNumbers'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('shippingAgent', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:shippingAgentName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('terminalName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:terminalName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('vesselName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:vesselName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('imoNumber', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:imoNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('stayNumber', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:stayNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('gateOutDateTimeUtc', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:gateOperation'),
    filterFn: 'includesString',
  }),
];
