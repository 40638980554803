import { IUploadReleaseRightsFileRequestUi, ReleaseParty } from '../../../global/adapters/crpAPI';

export const uploadReleaseRightsFileRequestUiKeys = {
  root: ['upload-releaserights-csv'] as const,
  uploadFile: (
    uploadCsvParams: IUploadReleaseRightsFileRequestUi,
    releaseTo: ReleaseParty | undefined
  ) => [uploadReleaseRightsFileRequestUiKeys.uploadFile, uploadCsvParams, releaseTo] as const,

  getTerminals: () =>
    [...uploadReleaseRightsFileRequestUiKeys.root, 'upload-releaserights-csv'] as const,
};
