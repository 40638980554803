import ActionTypes from './actionTypes';

export const errorMessage = getMessageAction(ActionTypes.MESSAGE_ERROR);
export const successMessage = getMessageAction(ActionTypes.MESSAGE_SUCCESS);
export const apiErrorsMessage = getMessageAction(ActionTypes.MESSAGE_API_ERRORS);
export const apiSuccessMessage = getMessageAction(ActionTypes.MESSAGE_API_SUCCESS);
export const broadcastMessage = getMessageAction(ActionTypes.MESSAGE_BROADCAST_MESSAGE);

function getMessageAction(type) {
  return function (content) {
    return {
      type,
      payload: content,
    };
  };
}
