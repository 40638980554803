import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IContainersToBeOverruled, TerminalContainer } from '../../../global/adapters/crpAPI';
import StepperOverruleCustomsLight from './StepperOverruleCustomsLight';
import EmptyState from '../../../global/components/EmptyState/EmptyState';
import { DataGrid } from '../../../global/components';
import { Drawer } from '../../../global/components/Drawer';
import ContainerDetailDrawer from './containerDetail/ContainerDetailDrawer';
import { baseTerminalColumnsWithCheckbox } from './columns/terminalColumns';

import { useLocalTableValues } from '../../../global/hooks';
import {
  useGetContainersToBeOverruled,
  setCustomsLightManualOverwriteRequest,
} from './adapters/queries';

const initialFields: IContainersToBeOverruled = {
  containers: [],
};

type Props = {
  partyPath: string;
};

const MainOverruleCustomsLight: React.FC<Props> = ({ partyPath }: Props) => {
  const { t } = useTranslation(['common', 'validation', 'overruleCustomsLight']);
  const formRef = useRef<FormikProps<IContainersToBeOverruled>>();
  const [displayGrid, setDisplayGrid] = React.useState(false);

  const getRowSelection = React.useRef<(() => RowModel<TerminalContainer>) | null>(null);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [selectedRows, setSelectedRows] = React.useState<Row<TerminalContainer>[] | null>(null);
  const [confirmError, setConfirmError] = React.useState<string | null>(null);
  const [confirmMessage, setConfirmMessage] = React.useState<string | null>(null);

  const { data, isFetching, isError } = useGetContainersToBeOverruled(
    partyPath,
    formRef?.current?.values.containers
  );

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-update-voyage',
    {}
  );

  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: TerminalContainer;
  }>({
    open: false,
    data: undefined,
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };
  const handleCellClick = (myRowData: TerminalContainer) => {
    setShowDetail({ open: true, data: myRowData });
  };

  const confirmSelection = async (reason: string, text: string) => {
    setConfirmError(null);
    setConfirmMessage(null);
    var alfapassToken = localStorage.getItem('alfapassToken');
    if (alfapassToken == null) {
      setConfirmMessage(null);
      setConfirmError(t('overruleCustomsLight:sidebarResult.noAccess'));
      return;
    }
    const tokenObject: any = JSON.parse(alfapassToken);
    var token = tokenObject['access_token'];
    var releaseIds: string[] = [];
    selectedRows?.forEach((r) => {
      var releaseId = r.original.releaseId;
      releaseIds.push(releaseId);
    });
    await setCustomsLightManualOverwriteRequest(releaseIds, reason, text, token)
      .then((r) => {
        setConfirmMessage(t('overruleCustomsLight:sidebarResult.confirmed'));
        setConfirmError(null);
      })
      .catch((e) => {
        setConfirmMessage(null);
        setConfirmError(e.error.Message);
      });
  };

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  return (
    <Grid container style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
      <Grid item style={{ minWidth: '420px', margin: '5px' }}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<IContainersToBeOverruled>>}
          onSubmit={handleOnSubmit}
        >
          {({ errors, submitForm, resetForm, values }: FormikProps<IContainersToBeOverruled>) => {
            return (
              <StepperOverruleCustomsLight
                errors={errors}
                resetForm={resetForm}
                submitForm={submitForm}
                setDisplayGrid={setDisplayGrid}
                values={values}
                selectedRows={selectedRows}
                isFetching={isFetching}
                confirmSelection={confirmSelection}
                confirmError={confirmError}
                confirmMessage={confirmMessage}
                partyPath={partyPath}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item style={{ width: 'auto', border: 'none', marginTop: '5px' }}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={baseTerminalColumnsWithCheckbox}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={false}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                  onCellClick={handleCellClick}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <div style={{ marginLeft: '50px', width: 'auto' }}>
            <EmptyState
              title={t('overruleCustomsLight:emptyState.title')}
              subTitle={t('overruleCustomsLight:emptyState.subTitle')}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default MainOverruleCustomsLight;
