import ActionTypes from './actionTypes';
import { LABEL } from '../../theme/constants';

export function loadContainer(query) {
  return {
    type: ActionTypes.CONTAINER_REQUESTED,
    payload: query
  };
}

export function loadEventHistory(query) {
  return {
    type: ActionTypes.EVENT_HISTORY_REQUESTED,
    payload: query
  }
}

export function loadContainerSucceeded(containers) {
  return {
    type: ActionTypes.CONTAINER_REQUEST_SUCCEEDED,
    payload: containers,
  };
}

export function loadEventHistorySucceeded(eventHistory) {
  return {
    type: ActionTypes.EVENT_HISTORY_REQUESTED_SUCCEEDED,
    payload: eventHistory
  }
}

export function loadContainers(loading) {
  return {
    type: ActionTypes.CONTAINERS_REQUESTED,
    payload: { loading: loading },
  };
}

export function loadContainersPage(page) {
  return {
    type: ActionTypes.CONTAINERS_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadContainersRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.CONTAINERS_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadContainersFilters(filters) {
  return {
    type: ActionTypes.CONTAINERS_REQUESTED,
    payload: { filters: filters },
  };
}

export function loadContainersSucceeded(containers) {
  return {
    type: ActionTypes.CONTAINERS_REQUEST_SUCCEEDED,
    payload: containers,
  };
}

export function revokeContainers(payload) {
  return {
    type: ActionTypes.CONTAINERS_REVOKE,
    payload: payload
  };
}

export function revokeContainersSucceeded() {
  return {
    type: ActionTypes.CONTAINERS_REVOKE_SUCCEEDED,
  };
}

export function forwardContainers(containers, selectedCompany) {
  return {
    type: ActionTypes.CONTAINERS_FORWARD,
    payload: {
      containers: containers,
      selectedCompany: selectedCompany,
      actionType: LABEL.TRANSFER.toUpperCase()
    },
  };
}

export function forwardContainerSucceeded() {
  return {
    type: ActionTypes.CONTAINERS_FORWARD_SUCCEEDED,
  };
}

export function selectContainersForScan(containers) {
  return {
    type: ActionTypes.CONTAINERS_SELECT_FOR_SCAN,
    payload: containers
  }
}

export function selectContainersForScanSucceeded() {
  return {
    type: ActionTypes.CONTAINERS_SELECT_FOR_SCAN_SUCCEEDED,
  }
}

export function closePickupConfirmation() {
  return {
    type: ActionTypes.CLOSE_PICKUP_CONFIRMATION,
  }
}

export function closePickupRevokeConfirmation() {
  return {
    type: ActionTypes.CLOSE_PICKUP_REVOKE_CONFIRMATION,
  }
}

export function performReleaseAction(container, menuItem) {
  return {
    type: ActionTypes.PERFORM_RELEASE_ACTION,
    payload: {
      container: container,
      menuItem: menuItem
    }
  }
}

export function setRefreshContainers(refresh) {
  return {
    type: ActionTypes.SET_REFRESH_CONTAINERS,
    payload: refresh
  }
}

export function refreshContainers(obj) {
  return {
    type: ActionTypes.REFRESH_CONTAINERS,
    payload: obj
  }
}

export function setAdditionalFields(additionalFields) {
  return {
    type: ActionTypes.SET_ADDITIONAL_FIELDS,
    payload: additionalFields
  }
}