import { NotificationDetail } from 'modules/global/adapters/mainAPI';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { LABEL } from '../../../../theme/constants';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import React from 'react';

type Props = {
  open: boolean;
  data: NotificationDetail | undefined;
  showDialog: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
    }>
  >;
};

const NotificationMessageDialog = ({ showDialog, open, data }: Props) => {
  const { t } = useTranslation(['dialog']);
  const classes = useStyles();

  const handleClose = () => {
    showDialog({ open: false });
  };

  const handleCloseButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    showDialog({ open: false });
  };

  const actions = (
    <div>
      <Button onClick={(e) => handleCloseButton(e)}>{LABEL.CLOSE}</Button>
    </div>
  );

  const message = data != undefined ? JSON.parse(data.message) : '';

  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth={'lg'}
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('dialog:notificationMessageDialog:title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines}>
            <pre>{JSON.stringify(message, null, 2)}</pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationMessageDialog;
