import { API_URL, BFF_API_ROOT_URL, SUBSCRIPTION_KEY } from '../../../config/environment';
import { Api, ApiConfig } from './crpAPI';
import { MainAPI } from './mainAPI';

type SecurityDataType = {
  token: string;
  tokenType: string;
};

/**
 * A function that adds security data to the request.
 * @param securityData - The security data to add to the request.
 * @returns A headers config with security data added.
 */

export const securityWorker = (securityData: SecurityDataType | null) => {
  if (securityData) {
    return {
      headers: {
        Authorization: `${securityData.tokenType} ${securityData.token}`,
      },
    };
  }
};

export const defaultRequestConfig: ApiConfig<SecurityDataType> = {
  baseUrl: BFF_API_ROOT_URL,
  baseApiParams: {
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY || '',
    },

    // Default set to true, as this API is only used after login
    secure: true,
  },
  securityWorker,
};

export const mainApiRequestConfig: ApiConfig<SecurityDataType> = {
  baseUrl: API_URL,
  baseApiParams: {
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY || '',
    },

    // Default set to true, as this API is only used after login
    secure: true,
  },
  securityWorker,
};

/**
 * A class that provides a wrapper for the BFF API.
 * To add security data to the request, use the `setSecurityData` method and pass a token and type.
 *
 * To remove security data, use `setSecurityData` with `null` as the argument
 * To remove security data for a single request, pass { secure: false } with the request params
 *
 * @example
 * // Set security data for all future requests
 * api.setSecurityData({ token: "token-value", tokenType: "Bearer" })
 *
 * // Remove security data for a single request
 * api.posts.list({ ... }, { secure: false });
 *
 */

const api = new Api({
  ...defaultRequestConfig,
});

const mainAPI = new MainAPI({
  ...mainApiRequestConfig,
});

export { api, mainAPI };
