import { Button, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadContainersPage,
  loadContainersRowsPerPage,
  setPoliceCase,
} from '../../store/actions/policeContainerActions';
import { loadTerminals } from '../../store/actions/terminalActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import {
  getPoliceContainers,
  getPoliceContainersLoading,
  getPoliceContainersPaging,
  getPoliceContainersPoliceCase,
  getSelectedUserType,
} from '../../store/selectors/selectors';
import { LABEL, DIALOG } from '../../theme/constants';
import PoliceContainersFilter from '../organisms/PoliceContainersFilter';
import PoliceContainerTable from '../organisms/PoliceContainerTable';
import LoaderTimeout from '../organisms/LoaderTimeout';
import { reactLocalStorage } from 'reactjs-localstorage';
import FormikDialog from '../organisms/formikDialog';
import policeCaseSchema from '../../utils/validation/schemes/policeCase';
import LocalStorageHelper from 'utils/localStorage';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  caseContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '15px',
    padding: '10px 16px',
    justifyContent: 'space-between',
  },
  caseDetail: {
    display: 'inline-flex',
    width: '50%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  caseNumbers: {
    fontSize: '16px',
    color: 'slategray',
  },
});

let columns = [
  { id: 'billOfLading', label: LABEL.BILL_OF_LADING_LABEL },
  { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
  { id: 'shippingAgent', label: LABEL.SHIPPING_AGENT_LABEL },
  { id: 'terminal', label: LABEL.TERMINAL_LABEL },
  { id: 'releaseDate', label: LABEL.RELEASE_DATE.toUpperCase() },
  { id: 'geteOutDate', label: LABEL.GATE_OUT_DATE.toUpperCase() },
  { id: 'byBroker', label: LABEL.BY_BROKER },
  { id: 'blocked', label: LABEL.BLOCKED.toUpperCase() },
  { id: 'archived', label: LABEL.ARCHIVED.toUpperCase() },
  { id: 'action', label: LABEL.ACTION_LABEL, colSpan: 2 },
];

function PoliceContainers(props) {
  const {
    classes,
    items,
    paging,
    loadTerminals,
    loadContainers,
    loadContainersRowsPerPage,
    loadContainersPage,
    loading,
    selectedUserType,
    setPoliceCase,
    policeCase,
  } = props;

  const createEmptyCase = () => {
    return {
      number: '',
      referenceNumber: '',
    };
  };

  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [localPoliceCase, setLocalPoliceCase] = useState(createEmptyCase());
  const [searchExpand, setSearchExpand] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const getAndSetPoliceCaseFromStorage = () => {
    const policeCaseFromStorage = LocalStorageHelper.getPoliceCase();
    setLocalPoliceCase(policeCaseFromStorage);
    setPoliceCase(policeCaseFromStorage);
  };

  useEffect(() => {
    loadTerminals();
    if (reactLocalStorage.get('policeCase') == null && openActionDialog == false) {
      setOpenActionDialog(true);
    } else {
      getAndSetPoliceCaseFromStorage();
    }
  }, []);

  useEffect(() => {
    if (loading === true) {
      setSearchExpand(false);
    }
  }, [loading]);

  const handleCloseActionDialog = (e, reason) => {
    e.stopPropagation();
    if (reason !== 'backdropClick') setOpenActionDialog(false);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setEditMode(true);
    getAndSetPoliceCaseFromStorage();
    setOpenActionDialog(true);
  };

  const submit = (values) => {
    var json = JSON.stringify(values);
    reactLocalStorage.set('policeCase', json);
    setPoliceCase(values);
    setOpenActionDialog(false);
  };

  return (
    <>
      {reactLocalStorage.get('policeCase') && (
        <Paper>
          <div className={classes.caseContainer}>
            <Typography variant="h6" component={'div'}>
              Case
            </Typography>
            <div className={classes.caseDetail}>
              <span className={classes.caseNumbers}>
                {LABEL.NUMBER}: {policeCase.number.toUpperCase()}
              </span>
              <span className={classes.caseNumbers}>
                {LABEL.REFERENCE_FIELD_NUMBER}: {policeCase.referenceNumber.toUpperCase()}
              </span>
              <Button onClick={(e) => handleEdit(e)} variant="contained" color="primary">
                Edit
              </Button>
            </div>
          </div>
        </Paper>
      )}
      <PoliceContainersFilter
        openActionDialog={openActionDialog}
        closeActionDialog={handleCloseActionDialog}
        searchExpand={searchExpand}
        setSearchExpand={setSearchExpand}
      />
      <div className={classes.divider} />
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <Paper>
          <PoliceContainerTable
            columns={columns}
            items={items}
            paging={paging}
            loadContainers={loadContainers}
            loadContainersPage={loadContainersPage}
            loadContainersRowsPerPage={loadContainersRowsPerPage}
            selectedUserType={selectedUserType}
            headerTitle={LABEL.SEARCH_RESULTS}
            containers
          />
        </Paper>
      )}
      <LoaderTimeout loading={loading} />
      <FormikDialog
        initialValues={localPoliceCase}
        open={openActionDialog}
        handleClose={handleCloseActionDialog}
        handleSubmit={submit}
        dialogTitle={DIALOG.LOGGING_TITLE}
        dialogContentText={DIALOG.LOGGING_TEXT}
        inEditMode={editMode}
        schema={policeCaseSchema}
        classes={classes}
        columnSize={6}
      />
    </>
  );
}

PoliceContainers.propTypes = {
  items: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  loadContainersPage: PropTypes.func.isRequired,
  loadContainersRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  selectedUserType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    items: getPoliceContainers(state),
    paging: getPoliceContainersPaging(state),
    loading: getPoliceContainersLoading(state),
    selectedUserType: getSelectedUserType(state),
    policeCase: getPoliceContainersPoliceCase(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadTerminals,
      loadContainersPage,
      loadContainersRowsPerPage,
      errorMessage,
      successMessage,
      setPoliceCase,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PoliceContainers));
