import {
  Paper,
  Table,
  Typography,
  Tooltip,
  Button,
  TableBody,
  TablePagination,
  Box,
  TableRow,
  Divider,
} from '@material-ui/core';
import TableHead from '../molecules/ContainerTableHead';
import CenterTableCell from '../atoms/CenterTableCell';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ReportIcon from '@material-ui/icons/Report';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTableRow } from '../../theme/theme';
import ActionDialog from '../organisms/ActionDialog';
import {
  loadSubscriptions,
  addSubscription,
  removeSubscription,
  loadSubscriptionsPage,
  loadSubscriptionsRowsPerPage,
} from '../../store/actions/subscriptionActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import {
  getCreditIdentificationResponse,
  getSubscriptions,
  getSubscriptionsLoading,
  getSubscriptionsLoadingNew,
  getSubscriptionsPaging,
} from '../../store/selectors/selectors';
import history from '../../history/browserHistory';
import * as moment from 'moment';
import { COLORS, LABEL, TOOLTIPS, DIALOG } from '../../theme/constants';
import LoaderTimeout from '../organisms/LoaderTimeout';
import CreditInfo from '../molecules/CreditInfo';
import CheckIcon from '@material-ui/icons/Check';
import FormikDialog from '../organisms/formikDialog';
import createSubscriptionSchema from '../../utils/validation/schemes/createSubscription';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  titleBar: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  iconText: {
    margin: '0px',
    marginTop: '5px',
    fontSize: '11px',
    color: COLORS.GREY,
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  xsTextPlaceholder: {
    width: '100%',
    maxWidth: '60px',
    height: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  smTextPlaceholder: {
    width: '100%',
    maxWidth: '90px',
    height: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  mdTextPlaceholder: {
    width: '100%',
    maxWidth: '120px',
    height: theme.spacing(2),
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  iconButtonPlaceholder: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  statusIconPlaceholder: {
    width: theme.spacing(6),
    height: theme.spacing(5),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  '@keyframes shine': {
    '0%': { backgroundPosition: '100%' },
    '100%': { backgroundPosition: '-100%' },
  },
});

const skeletalRows = (number, classes) => {
  const rows = [];
  for (let index = 0; index < number; index++) {
    rows.push(
      <TableRow>
        <CenterTableCell text={<div className={classes.mdTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.smTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.statusIconPlaceholder} />} />
        <CenterTableCell text={<div className={classes.iconButtonPlaceholder} />} />
      </TableRow>
    );
  }
  return rows;
};

const statusLogo = (status, classes) => {
  switch (status) {
    case 2:
      return (
        <Tooltip title={LABEL.RELEASED} aria-label={LABEL.RELEASED}>
          <div className={classes.icons}>
            <CheckCircleIcon fontSize={'default'} htmlColor={'#4AB55F'} />
            <p className={classes.iconText}>{LABEL.RELEASED}</p>
          </div>
        </Tooltip>
      );
    case 6:
      return (
        <Tooltip title={LABEL.WAITING_FOR_PICKUP} aria-label={LABEL.WAITING_FOR_PICKUP}>
          <div className={classes.icons}>
            <DepartureBoardIcon fontSize={'default'} htmlColor={'#4AB55F'} />
            <p className={classes.iconText}>{LABEL.WAITING_FOR_PICKUP}</p>
          </div>
        </Tooltip>
      );
    case 7:
      return (
        <Tooltip title={LABEL.SELECT_SCAN} aria-label={LABEL.SELECT_SCAN}>
          <div className={classes.icons}>
            <WarningIcon fontSize={'default'} htmlColor={'#F2A33A'} />
            <p className={classes.iconText}>{LABEL.SELECT_SCAN}</p>
          </div>
        </Tooltip>
      );
    case 8:
      return (
        <Tooltip title={LABEL.WAITING_FOR_RELEASE} aria-label={LABEL.WAITING_FOR_RELEASE}>
          <div className={classes.icons}>
            <DepartureBoardIcon fontSize={'default'} htmlColor={'#B54A56'} />
            <p className={classes.iconText}>{LABEL.WAITING_FOR_RELEASE}</p>
          </div>
        </Tooltip>
      );
    case 9:
      return (
        <Tooltip title={LABEL.WAITING_FOR_PICKUP_SCAN} aria-label={LABEL.WAITING_FOR_PICKUP_SCAN}>
          <div className={classes.icons}>
            <DepartureBoardIcon fontSize={'default'} htmlColor={'#F2A33A'} />
            <p className={classes.iconText}>{LABEL.WAITING_FOR_PICKUP_SCAN}</p>
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={LABEL.BLOCKED} aria-label={LABEL.BLOCKED}>
          <div className={classes.icons}>
            <ReportIcon fontSize={'default'} htmlColor={'#B54A56'} />
            <p className={classes.iconText}>{LABEL.BLOCKED}</p>
          </div>
        </Tooltip>
      );
  }
};

function ContainerSubscriptions(props) {
  const {
    classes,
    items,
    paging,
    loading,
    loadingNew,
    creditIdentificationResponse,
    loadSubscriptions,
    addSubscription,
    removeSubscription,
    loadSubscriptionsPage,
    loadSubscriptionsRowsPerPage,
  } = props;

  useEffect(() => {
    loadSubscriptions();
  }, []);

  let columns = [
    { id: 'subscribedOn', label: LABEL.SUBSCRIBED_ON },
    { id: 'billOfLadingNumber', label: LABEL.BILL_OF_LADING_LABEL },
    { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
    { id: 'shippingAgent', label: LABEL.SHIPPING_AGENT_LABEL },
    { id: 'terminal', label: LABEL.TERMINAL_LABEL },
    { id: 'status', label: LABEL.RELEASE_STATUS.toUpperCase() },
    { id: 'isArchived', label: LABEL.IS_ARCHIVED.toUpperCase() },
    { id: 'action', label: LABEL.ACTION_LABEL },
  ];

  const createEmptySubscription = () => {
    return {
      equipmentNumber: '',
      billOfLadingNumber: '',
    };
  };

  const [newSubscription, setNewSubscription] = React.useState(createEmptySubscription());
  const [selectedSubscription, setSelectedSubscription] = React.useState(createEmptySubscription());
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const handleChangePage = (event, page) => {
    loadSubscriptionsPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    loadSubscriptionsRowsPerPage(event.target.value);
  };

  const handleSubmit = (values) => {
    addSubscription(values);
    closeAddDialog();
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
    setNewSubscription(createEmptySubscription());
  };

  const closeRemoveDialog = () => {
    setRemoveDialogOpen(false);
    setSelectedSubscription(createEmptySubscription());
  };

  const deleteSubscription = () => {
    removeSubscription(selectedSubscription);
    closeRemoveDialog();
  };

  const checkCreditAmount = (response) => {
    if (
      response != null &&
      response.creditIdentification != null &&
      response.creditIdentification.creditsAmount === 0
    ) {
      return true;
    }
    return false;
  };

  const removeSubscriptionActions = (
    <div>
      <Button onClick={closeRemoveDialog}>cancel</Button>
      <Button onClick={deleteSubscription}>Yes</Button>
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.breadBar}>
        <CreditInfo />
      </div>
      <main className={classes.content}>
        <Paper>
          <div className={classes.titleBar}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h6" id="tableTitle">
                  {LABEL.SUBSCRIPTIONS}
                </Typography>
              </Box>
              <Box pl={0.5} pr={0.5}>
                <Button
                  style={{ padding: '14px 12px' }}
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={checkCreditAmount(creditIdentificationResponse)}
                  onClick={() => setAddDialogOpen(true)}
                >
                  <AddIcon /> {LABEL.NEW_SUBSCRIPTION}
                </Button>
              </Box>
            </Box>
          </div>
          <Divider />
          <Table aria-labelledby="tableTitle" style={{ width: '100%' }}>
            <TableHead className={classes.wideCell} columns={columns} centerAlign={true} />
            <TableBody>
              {loading && skeletalRows(25, classes)}
              {!loading && loadingNew && skeletalRows(1, classes)}
              {!loading &&
                items.map((subscription, index) => {
                  const qs = {
                    equipmentNumber: subscription.equipmentNumber,
                    billOfLadingNumbers: [subscription.billOfLadingNumber],
                    releaseIdentification: subscription.releaseIdentification,
                    releaseId: subscription.releaseId,
                  };
                  const detailLink = () => {
                    history.push('container-subscriptions/detail?' + queryString.stringify(qs));
                  };
                  return (
                    <StyledTableRow hover tabIndex={-1} key={index} className={classes.tableRow}>
                      <CenterTableCell
                        text={moment(subscription.subscribedOn).format('DD-MM-YYYY  HH:mm')}
                      />
                      <CenterTableCell text={subscription.billOfLadingNumber} />
                      <CenterTableCell text={subscription.equipmentNumber} />
                      <CenterTableCell text={subscription.shippingAgentName} />
                      <CenterTableCell text={subscription.terminalName} />
                      <CenterTableCell
                        text={
                          subscription.hasRelease
                            ? statusLogo(subscription.operationalStatus, classes)
                            : ''
                        }
                      />
                      <CenterTableCell text={subscription.isArchived ? <CheckIcon /> : ''} />
                      <CenterTableCell
                        text={
                          <main>
                            <Tooltip title={TOOLTIPS.OPEN_DETAIL}>
                              <span>
                                <Button
                                  variant="text"
                                  color="primary"
                                  className={classes.wideCell}
                                  disabled={!subscription.hasRelease || subscription.isArchived}
                                  onClick={() => detailLink()}
                                >
                                  <LaunchIcon />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title={TOOLTIPS.REMOVE_SUBSCRIPTION}>
                              <Button
                                variant="text"
                                color="primary"
                                className={classes.wideCell}
                                onClick={() => {
                                  setRemoveDialogOpen(true);
                                  setSelectedSubscription(subscription);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </main>
                        }
                      />
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
          {paging && (
            <TablePagination
              component="div"
              count={paging.count}
              rowsPerPage={paging.rowsPerPage}
              page={paging.page}
              rowsPerPageOptions={[25, 50, 100, 250]}
              backIconButtonProps={{
                'aria-label': LABEL.PREVIOUS_PAGE,
              }}
              nextIconButtonProps={{
                'aria-label': LABEL.NEXT_PAGE,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </main>
      <FormikDialog
        initialValues={newSubscription}
        open={addDialogOpen}
        handleClose={closeAddDialog}
        handleSubmit={handleSubmit}
        dialogTitle={DIALOG.ADD_SUBSCRIPTION_TITLE}
        dialogContentText={DIALOG.ADD_SUBSCRIPTION_TEXT}
        inEditMode={editMode}
        schema={createSubscriptionSchema}
        classes={classes}
        columnSize={6}
      />
      <ActionDialog
        dialogTitle={DIALOG.REMOVE_SUBSCRIPTION_TITLE}
        contentText={DIALOG.REMOVE_SUBSCRIPTION_TEXT}
        actions={removeSubscriptionActions}
        handleClose={closeRemoveDialog}
        open={removeDialogOpen}
      />
      <LoaderTimeout loading={loading || loadingNew} />
    </React.Fragment>
  );
}

ContainerSubscriptions.propTypes = {
  items: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  loadSubscriptions: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
  removeSubscription: PropTypes.func.isRequired,
  loadSubscriptionsPage: PropTypes.func.isRequired,
  loadSubscriptionsRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    items: getSubscriptions(state),
    creditIdentificationResponse: getCreditIdentificationResponse(state),
    paging: getSubscriptionsPaging(state),
    loading: getSubscriptionsLoading(state),
    loadingNew: getSubscriptionsLoadingNew(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSubscriptions,
      addSubscription,
      removeSubscription,
      loadSubscriptionsPage,
      loadSubscriptionsRowsPerPage,
      errorMessage,
      successMessage,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContainerSubscriptions));
