import { mapEquipmentId, mapPage } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.releases, action) {
  switch (action.type) {
    case ActionTypes.RELEASES_REQUESTED: {
      return {
        ...state,
        filters: action.payload.filters !== undefined ? action.payload.filters : state.filters,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.RELEASES_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        items: mapEquipmentId(action.payload.result),
        paging: mapPage(action.payload),
        filters: action.payload.filters || {
          equipmentNumber: '',
          billOfLading: '',
          shippingAgent: '',
          receivedFrom: '',
          terminal: '',
          status: '',
          userRoleStatus: '',
        }
      };
    }
    default: {
      return state;
    }
  }
}
