import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DIALOG, DIALOGTYPES, FORMATTING, LABEL } from '../../theme/constants';
import history from '../../history/browserHistory';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import Typography from '@material-ui/core/Typography';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getCompanies,
  getCompaniesPaging,
  selectCompanySubscriptions,
} from '../../store/selectors/selectors';
import {
  loadCompanies,
  loadCompaniesFilters,
  loadCompaniesPage,
  loadCompaniesRowsPerPage,
} from '../../store/actions/companiesActions';
import {
  createCompanySubscription,
  fetchCompanySubscriptions,
  deleteCompanySubscription,
} from '../../store/actions/policeContainerActions';
import { Toolbar } from '@material-ui/core';
import { StyledTableRow } from '../../theme/theme';
import CenterTableCell from '../atoms/CenterTableCell';
import { reactLocalStorage } from 'reactjs-localstorage';
import DeleteConfirmationDialog from '../organisms/ConfirmationDialog';

const styles = (theme) => ({
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  companiesCircularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '60px',
    marginTop: '-35px',
  },
  toolBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  wideCell: {
    padding: '20px 15px',
    color: '#A2A2A2',
    textTransform: 'uppercase',
  },
  alignLoadButtonRight: {
    textAlign: 'end',
    marginTop: '10px',
  },
  alignCircularProgressRight: {
    textAlign: 'end',
    marginTop: '20px',
    marginRight: '40px',
  },
}));

const AuditTrailSubscriptions = ({
  fetchCompanySubscriptions,
  subscriptions,
  deleteCompanySubscription,
}) => {
  const classes = useStyles();

  const [pageCount, setPageCount] = React.useState(1);
  const [showLoadMore, setShowLoadMore] = React.useState(true);
  const [showLoadMoreButton, setShowLoadMoreButton] = React.useState(true);

  const [subscriptionList, setSubscriptionList] = React.useState([]);

  useEffect(() => {
    fetchCompanySubscriptions(1);
  }, []);

  useEffect(() => {
    if (
      !subscriptions ||
      subscriptions.subscriptions == null ||
      subscriptions.subscriptions.length == 0
    ) {
      return;
    }
    if (subscriptionList == null || subscriptions.length == 0)
      setSubscriptionList(...subscriptions.subscriptions);
    else setSubscriptionList([...subscriptionList, ...subscriptions.subscriptions]);

    setShowLoadMore(true);
    setShowLoadMoreButton(!subscriptions.endReached);
    setPageCount(subscriptions.pageCount);
  }, [subscriptions]);

  const detailClick = (nxtEntityId) => {
    history.push('/audittrail-subscriptions/' + nxtEntityId + '/events');
  };

  const [row, setSelectedRow] = React.useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  const unsubscribe = (row) => {
    setSelectedRow(row);
    if (reactLocalStorage.get(DIALOGTYPES.DELETE_COMPANY_SUBSCRIPTION) === 'true') {
      deleteCompanySubscription(row.externalId);
    } else {
      setOpenConfirmationDialog(true);
    }
  };

  const buttonSubscriptionClick = (event) => {
    event.stopPropagation();
    history.push('/audittrail-subscriptions/companies');
  };

  const loadMore = (event) => {
    setShowLoadMore(false);
    event.stopPropagation();
    fetchCompanySubscriptions(pageCount + 1);
    setPageCount(pageCount + 1);
  };

  const goToFirstPage = (event) => {
    setSubscriptionList([]);
    setShowLoadMore(false);
    event.stopPropagation();
    fetchCompanySubscriptions(1);
    setPageCount(1);
  };

  return (
    <div>
      <Paper>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" component="div">
            {LABEL.COMPANY_SUBSCRIPTIONS}
          </Typography>
          <div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={(e) => buttonSubscriptionClick(e)}
            >
              <AddIcon /> {LABEL.NEW_SUBSCRIPTION}
            </Button>
          </div>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.wideCell}>
                  Company
                </TableCell>
                <TableCell align="center" className={classes.wideCell}>
                  NxtEntityId&nbsp;
                </TableCell>
                <TableCell align="center" className={classes.wideCell}>
                  Vat Number&nbsp;
                </TableCell>
                <TableCell align="center" className={classes.wideCell}>
                  Apcs Code&nbsp;
                </TableCell>
                <TableCell align="center" className={classes.wideCell}>
                  From&nbsp;
                </TableCell>
                <TableCell align="center" className={classes.wideCell}>
                  To&nbsp;
                </TableCell>
                <TableCell colSpan={2} align="center" className={classes.wideCell}>
                  Actions&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionList &&
                subscriptionList.map((row) => (
                  <StyledTableRow key={row.externalId}>
                    <CenterTableCell text={row.name} />
                    <CenterTableCell text={row.nxtEntityId} />
                    <CenterTableCell text={row.vat} />
                    <CenterTableCell text={row.apcs} />
                    <CenterTableCell text={row.fromDate} />
                    <CenterTableCell text={row.toDate} />
                    <CenterTableCell
                      text={
                        <Tooltip title={LABEL.DETAIL} arial-label={LABEL.DETAIL}>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => detailClick(row.nxtEntityId)}
                          >
                            <LaunchIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                    <TableCell align="center">
                      <Tooltip title={LABEL.UNSUBSCRIBE} aria-label={LABEL.UNSUBSCRIBE}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => unsubscribe(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!showLoadMore && (
        <div className={classes.alignCircularProgressRight}>
          {' '}
          <CircularProgress style={{ height: '20px', width: '20px' }} />{' '}
        </div>
      )}
      {showLoadMore && (
        <div className={classes.alignLoadButtonRight}>
          {pageCount > 1 && (
            <Button variant="text" onClick={(e) => goToFirstPage(e)}>
              <FirstPageIcon />
            </Button>
          )}
          {showLoadMoreButton && (
            <Button variant="text" onClick={(e) => loadMore(e)}>
              {LABEL.LOAD_NEXT}
            </Button>
          )}
        </div>
      )}
      <DeleteConfirmationDialog
        open={openConfirmationDialog}
        dialogType={DIALOGTYPES.DELETE_COMPANY_SUBSCRIPTION}
        dialogTitle={DIALOG.DELETE_SUBSCRIPTION_ON_COMPANY}
        contentText={DIALOG.DELETE_SUBSCRIPTION_ON_COMPANY_CONFIRMATION_TEXT.replace(
          FORMATTING.COMPANY,
          row.name
        )}
        callback={() => deleteCompanySubscription(row.externalId)}
        setOpen={setOpenConfirmationDialog}
      />
    </div>
  );
};

AuditTrailSubscriptions.propTypes = {
  classes: PropTypes.object.isRequired,
  loadCompaniesFilters: PropTypes.func.isRequired,
  loadCompaniesPage: PropTypes.func.isRequired,
  loadCompaniesRowsPerPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    companies: getCompanies(state),
    paging: getCompaniesPaging(state),
    subscriptions: selectCompanySubscriptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCompanies,
      loadCompaniesFilters,
      loadCompaniesPage,
      loadCompaniesRowsPerPage,
      createCompanySubscription,
      fetchCompanySubscriptions,
      deleteCompanySubscription,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AuditTrailSubscriptions));
