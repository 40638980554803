/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { LABEL } from 'theme/constants';
import { mapRoles } from '../helpers/mapper';

export interface ErrorResponse {
  publicReferenceId?: string;
  httpStatusCode: number;
  message: string;
}

export class CustomErrorResponse implements ErrorResponse {
  publicReferenceId?: string;
  httpStatusCode: number = 0;
  message: string = '';
}

export interface PostResponse {
  status?: string;
  publicReferenceId?: string;
  data?: object;
}

/**
 * @example {"id":"bf4c2b20-497b-469c-9118-1e5e235c292d","equipmentNumber":"TSTNOTTERM0111","vesselName":"Vessel X","voyageNumber":"123456","imoNumber":"123456","stayNumber":"123456","releaseRightStatus":"Pending","terminalNxtEntityId":"NXT20000050787","terminalCode":"17000","terminalName":"MPET Terminal's","releaseIdentification":"wxcwxc","billOfLadingNumbers":["TTSPPPSS0145","TTSPPPSS0146"],"releaseOrderReference":"rrrr","releaseDateTimeUTC":"2022-07-10T00:00:00Z","expirationDateTimeUtc":"2022-07-10T00:00:00Z","fullEmpty":"Full","commercialRelease":{"greenLightValue":"OK","color":"Green"},"combinedRelease":"Green"}
 */
export interface Container {
  /** @format uuid */
  releaseId: string;
  equipmentNumber: string;
  vesselName?: string;
  voyageNumber?: string;
  imoNumber?: string;
  stayNumber?: string;
  releaseRightStatus?: ReleaseRightStatus;
  terminalNxtEntityId: string;
  terminalCode: string;
  terminalName: string;
  releaseIdentification: string;
  billOfLadingNumbers: string[];
  releaseOrderReference?: string;

  /** @format date-time */
  releaseDateTimeUtc?: string;

  /** @format date-time */
  expirationDateTimeUtc?: string;
  fullEmpty?: string;
  commercialRelease?: GreenLight;
  combinedRelease?: LightColor;
}

export interface BillingInfoRequest {
  month: string;
  year: number;
}

export interface IBillingInformationRequest {
  month: number;
  year: number;
}

export class BillingInformationRequest implements IBillingInformationRequest {
  month: number = 0;
  year: number = 0;
}

export interface ContainerBillingInfo {
  /** @format uuid */
  releaseId: string;
  equipmentNumber: string;
  shippingAgent?: string;
  shippingAgentNxtEntityId?: string;
  billOfLadingNumbers: string;
  /** @format date-time */
  gateOutDateTimeUtc?: string;
  vesselName?: string;
  imoNumber?: string;
  stayNumber?: string;
  terminalName: string;
  terminalCode: string;
  terminalNxtEntityId: string;
}

export interface ModalContainer {
  /** @format uuid */
  releaseId: string;
  equipmentNumber: string;
  billOfLadingNumbers: string[];
  alfapassNumber: string;
  isValid?: boolean;
  isValidated: boolean;
}

export interface ModalContainerInfo {
  containers: ModalContainer[];
}

export interface BulkUpdatePickUpRequest {
  meansOfTransport: string;
  items: BulkPickUpActionRequestItem[];
}

export interface BulkPickUpActionRequestItem {
  /** @format uuid */
  releaseId: string;
  alfapassNumber: string;
}

export interface TerminalContainer {
  /** @format uuid */
  releaseId: string;
  receivedFrom: string;
  receivedFromName: string;
  equipmentNumber: string;
  billOfLadingNumbers: string[];
  terminalOperationColor: LightColor;
  terminalOperationValue: string;
  terminalReadyColor: LightColor;
  terminalReadyValue: string;
  customsColor: LightColor;
  customsValue: string;
  imoNumber: string;
  stayNumber: string;
  meansOfTransport: string;
  shippingAgentName: string;
  shippingAgentNxtEntityId: string;
  terminalName: string;
}

export interface PickUpContainer {
  /** @format uuid */
  releaseId: string;
  meansOfTransport: string;
  alfapassNumber?: string;
  equipmentNumber: string;
  billOfLadingNumbers: string[];
  terminalOperationColor: LightColor;
  terminalOperationValue: string;
  terminalReadyColor: LightColor;
  terminalReadyValue: string;
  customsColor: LightColor;
  customsValue: string;
  /** @format date */
  creationDate: string;
}

/**
 * @example {"id":"497f6eca-6276-4993-bfeb-53cbbbba6f08","equipmentNumber":"string","vesselName":"string","voyageNumber":"string","terminalNxtEntityId":"string","terminalCode":"string","terminalName":"string","releaseIdentification":"string","billOfLadingNumbers":["string"],"releaseOrderReference":"string","releaseDateTimeUtc":"2019-08-24T14:15:22Z","expirationDateTimeUtc":"2019-08-24T14:15:22Z","fullEmpty":"Full","commercialRelease":{"greenLightValue":"OK","color":"Green"},"releaseRightStatus":"Pending","releaseFromNxtEntityId":"NXT123","releaseFromName":"MSC","releaseToNxtEntityId":"NXT456","releaseToName":"Katoen Natie","pickupRightStatus":"Assigned","terminalOperation":{"greenLightValue":"OK","color":"Green"},"terminalReady":{"greenLightValue":"OK","color":"Green"},"customsLight":{"greenLightValue":"OK","color":"Green"},"pickUpLight":{"greenLightValue":"OK","color":"Green"},"gateOperation":{"greenLightValue":"OK","color":"Green"}}
 */
export type ExtendedContainer = Container & {
  pickUpRightStatus?: 'Assigned' | 'Revoked' | 'Archived';
  releaseFromNxtEntityId?: string;
  releaseFromName?: string;
  releaseToNxtEntityId?: string;
  releaseToName?: string;
  terminalOperation?: GreenLight;
  terminalReady?: GreenLight;
  customsLight?: GreenLight;
  customsProgress?: GreenLight;
  pickUpLight?: GreenLight;
  gateOperation?: GreenLight;
  commercialReleaseLight?: GreenLight;
  meansOfTransport?: string;
  status?: LightColor;
  shippingAgentName?: string;
  alfapassNumber?: string;
};

export type ContainerOverruleResponse = {
  message?: string;
  accepted: boolean;
};

/**
 * @example {"nxtEntityId":"NXTTest","code":"01009","name":"Arlanxeo Belgium","enabled":true}
 */
export interface Terminal {
  nxtEntityId?: string;
  code?: string;
  name?: string;
  enabled?: boolean;
}

/**
 * @example {"nxtEntityId":"NXT22000090151","name":"ANTWERP CONTAINER TRANSPORT INTERNATIONAL BVBA","vat":"BE 0839.101.369","duns":null,"eori":"BE0839101369","scac":null,"apcs":null,"externalId":null}
 */
export interface ReleaseParty {
  nxtEntityId?: string;
  name?: string;
  duns?: string;
  eori?: string;
  vat?: string;
  scac?: string;
  apcs?: string;
  externalId?: string;
  cpuRoles?: string[];
}

export interface ShippingAgent {
  nxtEntityId?: string;
  name?: string;
}

export interface GreenLight {
  greenLightValue?: string;
  color?: LightColor;
}

export enum MeansOfTransport {
  Barge = 'Barge',
  Rail = 'Rail',
  Truck = 'Truck',
}

export enum GreenLightDefinitionType {
  TerminalOperation = 'TerminalOperation',
  TerminalReady = 'TerminalReady',
  Customs = 'Customs',
}
export enum IdentificationType {
  NxtEntityId = 'NxtEntityId',
  Tin = 'Tin',
  Duns = 'Duns',
  Scac = 'SCAC',
  Eori = 'Eori',
  Apcs = 'Apcs',
}

export type LightColor = 'Green' | 'Red' | 'Orange' | 'Grey' | 'Yellow';

export type ReleaseRightStatus =
  | 'Pending'
  | 'Accepted'
  | 'Declined'
  | 'Revoked'
  | 'Archived'
  | 'Expired';

/**
 * @example {"reason":"Incorrect transfer","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface BulkReleaseRightActionRequest {
  reason?: string;
  releaseToNxtEntityId?: string;
  releaseIds?: string[];
}

/**
 * @example {"meansOfTransport":"Barge","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface BulkAssignBargeRailPickUpRequest {
  meansOfTransport?: string;
  releaseIds?: string[];
}

/**
 * @example {"items":[ { "releaseId": "cf91390a-6915-4602-8f08-51cabf64e8c8", alfapassNumber": "45641" } ]}
 */
export interface BulkAssignPickUpRequest {
  items: BulkPickUpActionRequestItem[];
}

/**
 * @example {"nxtEntityId":"NXT123","code":"ABC","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface UpdateTerminalRequest {
  nxtEntityId?: string;
  code?: string;
  ids?: string[];
}

/**
 * @example {"identificationCode":"NXT2200009481","identificationType":"NxtEntityId","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface AssignFirstReleasePartyRequest {
  identificationCode?: string;
  identificationType?: 'NxtEntityId' | 'Tin' | 'Duns' | 'SCAC' | 'Eori' | 'Apcs';
  ids?: string[];
}

/**
 * @example {"vesselName":"BigVessel","voyageNumber":"A123","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface UpdateVoyageNumberRequest {
  vesselName?: string;
  voyageNumber?: string;
  stayNumber?: string;
  imoNumber?: string;
  ids?: string[];
}

/**
 * @example {"expirationDateTimeUtc":"2023-03-20T00:00:00.000Z","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface UpdateCommercialExpiryDateRequest {
  /** @format date-time */
  expirationDateTimeUtc?: string;
  ids?: string[];
}

/**
 * @example {"action":"BLOCK","ids":["cf91390a-6915-4602-8f08-51cabf64e8c8","ff22e1cd-a4eb-4cd0-9581-8ce483404e5a"]}
 */
export interface UpdateActionRequest {
  action?: ActionTypes;
  ids?: string[] | null;
  reason?: string;
}

export type ActionTypes = 'BLOCK' | 'UNBLOCK' | 'REVOKE' | 'DELETE' | 'CREATEUPLOADCSV';

export interface IReleaseToPartyByRole {
  userRole: string;
}

export class ReleaseToPartyByRole implements IReleaseToPartyByRole {
  constructor(roleLabel: string) {
    this.userRole = mapRoles(roleLabel);
  }

  userRole!: string;
}

export interface GetSearchContainersParams {
  vesselName?: string;
  releaseRightStatus?: 'Pending' | 'Accepted' | 'Declined' | 'Expired';
  imoNumber?: string;
  stayNumber?: string;
  equipmentNumber?: string;
  billOfLadingNumber?: string;
  shippingAgentNxtEntityId?: string;
  role?: string;
  isArchived: boolean | undefined;
}

export interface GetContainersByVesselParams {
  vesselName?: string;
  releaseRightStatus?: 'Pending' | 'Accepted' | 'Declined' | 'Revoked' | 'Expired';
  imoNumber?: string;
  stayNumber?: string;
  isArchived: boolean | undefined;
}

export interface GetContainersByVoyageParams {
  vesselName?: string;
  voyageNumber?: string;
  imoNumber?: string;
  stayNumber?: string;
  action?: ActionTypes;
  isArchived: boolean | undefined;
}

export interface IUploadReleaseRightsFileRequestUi {
  file?: File | null | undefined;
  isTranshipment: boolean;
  fullEmptyIndicator: string;
  vesselName?: string;
  voyageNumber?: string;
  isCarrierHaulage: boolean;
  terminalNxtEntityId?: string;
  terminals: Terminal[] | undefined;
  releaseDateTimeUtc: undefined | Date;
  expirationDateTimeUtc: undefined | Date;
  imoNumber?: string;
  stayNumber?: string;
}

export interface IUploadReleaseRightsFileRequest {
  file?: string; //base64 string to upload it to the api
  portLoCode?: string;
  isTranshipment: boolean;
  fullEmptyIndicator: string;
  vesselName?: string;
  voyageNumber?: string;
  isCarrierHaulage: boolean;
  terminalNxtEntityId?: string;
  terminalCode?: string;
  releaseToNxtEntityId?: string;
  releaseDateTimeUtc: Date;
  expirationDateTimeUtc?: Date;
  imoNumber?: string;
  stayNumber?: string;
}

export class UploadReleaseRightsFileRequest implements IUploadReleaseRightsFileRequest {
  file?: string;
  portLoCode?: string;
  isTranshipment: boolean = false;
  fullEmptyIndicator: string = 'Full';
  vesselName?: string;
  voyageNumber?: string;
  isCarrierHaulage: boolean = true;
  terminalNxtEntityId?: string;
  terminalCode?: string;
  releaseToNxtEntityId?: string;
  releaseDateTimeUtc: Date = new Date();
  expirationDateTimeUtc: Date = new Date();
  imoNumber?: string;
  stayNumber?: string;
}

export interface IContainersToBeOverruled {
  containers?: string[];
}

export class ContainersToBeOverruled implements IContainersToBeOverruled {
  containers?: string[];
}

export interface GetContainersByCommercialexpirydateParams {
  /** @format date */
  fromDate?: string;

  /** @format date */
  toDate?: string;
}

export interface GetContainersForUpdatevoyagenumberParams {
  vesselName?: string;
  voyageNumber?: string;
}

export interface GetContainersForUpdateterminalParams {
  vesselName?: string;
  voyageNumber?: string;
}

export interface GetContainersForUpdatecommercialexpirydateParams {
  /** @format date */
  fromDate?: string;

  /** @format date */
  toDate?: string;
}

export interface GetContainersByTerminalAndReleaseRightParams
  extends GetContainersByTerminalParams {
  status?: ReleaseRightStatus;
}

export interface GetContainersByTerminalParams {
  terminalNxtEntityId?: string;
  terminalOperationValue?: string;
  terminalReadyValue?: string;
  customsValue?: string;
}

export interface GetContainersByPickUpParams {
  terminalNxtEntityId?: string;
  meansOfTransport?: string;
  /** @format date-time */
  creationDate?: string;
}

export interface GetContainersByShippingAgentParams {
  equipmentNumber?: string;
  shippingAgentNxtEntityId?: string;
  billOfLadingNumber?: string;
}

export interface GetContainersByReceivedFromParams {
  terminalNxtEntityId?: string;
  identificationType?: string;
  identificationCode?: string;
  status?: ReleaseRightStatus;
  billOfLadingNumber?: string;
}

export interface DashboardMetricsResponse {
  numberOfIncomingReleaseRights: number;
  numberOfOutgoingReleaseRights: number;
  numberOfBlockedReleaseRights: number;
  numberOfReleaseRightsWithoutPickUp: number;
  numberOfDeclinedReleaseRights: number;
  numberOfCommercialReleases: number;
}

export interface GreenLightValuesRequest {
  greenLightNames: string[];
}
export interface GreenLightValue {
  color?: LightColor;
  greenLightName?: string;
  value?: string;
}

export interface GreenLightValuesResponse {
  items?: GreenLightValue[];
}

export interface ICustomsLightManualOverwriteRequest {
  releaseIds: string[] | undefined;
  reason: string | undefined;
}

export class CustomsLightManualOverwriteRequest implements ICustomsLightManualOverwriteRequest {
  public releaseIds: string[] | undefined;
  public reason: string | undefined;

  constructor(releaseIds: string[], reason: string) {
    this.releaseIds = releaseIds;
    this.reason = reason;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title CPU Web API BFF
 * @version v1
 *
 * NxtPort Certified Pick-Up WEB API BFF
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  pickuprights = {
    /**
     * @description (Bulk) Update Pick-Up
     *
     * @tags pickuprights
     * @name PostContainersUpdatePickUp
     * @summary POST Update Pick-Up
     * @request POST:/pickuprights/update/bulk/barge-rail
     * @secure
     */ postContainersUpdateBargeRailPickUp: (
      data: BulkUpdatePickUpRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/pickuprights/update/bulk/barge-rail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Update drivers
     *
     * @tags pickuprights
     * @name UpdateDriver
     * @summary POST Update drivers
     * @request POST:/pickuprights/update/bulk
     * @secure
     */
    postContainersUpdatePickUp: (data: BulkUpdatePickUpRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/pickuprights/update/bulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Assign drivers
     *
     * @tags pickuprights
     * @name AssignDriver
     * @summary POST Assign drivers
     * @request POST:/pickuprights/assign/bulk
     * @secure
     */
    postContainersAssignPickUp: (data: BulkAssignPickUpRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/pickuprights/assign/bulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Assign Pick-Up
     *
     * @tags pickuprights
     * @name PostContainersAssignPickUp
     * @summary POST Assign Pick-Up
     * @request POST:/pickuprights/assign/bulk/barge-rail
     * @secure
     */
    postContainersAssignBargeRailPickUp: (
      data: BulkAssignBargeRailPickUpRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/pickuprights/assign/bulk/barge-rail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  releaserights = {
    /**
     * @description (Bulk) Decline Release Right
     *
     * @tags releaserights
     * @name PostContainersDecline
     * @summary POST Decline Release Rights
     * @request POST:/releaserights/decline
     * @secure
     */
    postContainersDecline: (data: BulkReleaseRightActionRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/releaserights/decline`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Accept Release Right
     *
     * @tags releaserights
     * @name PostContainersAccept
     * @summary POST Accept Release Rights
     * @request POST:/releaserights/accept
     * @secure
     */
    postContainersAccept: (data: BulkReleaseRightActionRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/releaserights/accept`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Revoke Release Right
     *
     * @tags releaserights
     * @name PostContainersRevoke
     * @summary POST Revoke Release Rights
     * @request POST:/releaserights/revoke
     * @secure
     */
    postContainersRevoke: (data: BulkReleaseRightActionRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/releaserights/revoke`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description (Bulk) Transfer Release Right
     *
     * @tags releaserights
     * @name PostContainersTransfer
     * @summary POST Transfer Release Rights
     * @request POST:/releaserights/transfer
     * @secure
     */
    postContainersTransfer: (data: BulkReleaseRightActionRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/releaserights/transfer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  containers = {
    /**
     * @description (Bulk) Change Terminal
     *
     * @tags container
     * @name PostContainersUpdateterminal
     * @summary POST Update Terminal
     * @request POST:/containers/updateterminal
     * @secure
     */
    postContainersUpdateterminal: (data: UpdateTerminalRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/containers/updateterminal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description (Bulk) Assign First Release Party
     *
     * @tags container
     * @name PostContainersAssignfirstreleaseparty
     * @summary POST Assign First Release Party Terminal
     * @request POST:/containers/assignfirstreleaseparty
     * @secure
     */
    postContainersAssignfirstreleaseparty: (
      data: AssignFirstReleasePartyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/containers/assignfirstreleaseparty`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description (Bulk) Change VesselName and VoyageNumber
     *
     * @tags container
     * @name PostContainersUpdatevoyagenumber
     * @summary POST Update Voyage Number
     * @request POST:/containers/updatevoyagenumber
     * @secure
     */
    postContainersUpdatevoyagenumber: (
      data: UpdateVoyageNumberRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/containers/updatevoyagenumber`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description (Bulk) Change Commercial Expiry Date
     *
     * @tags container
     * @name PostContainersUpdatecommercialexpirydate
     * @summary POST Update Commercial Expiry Date
     * @request POST:/containers/updatecommercialexpirydate
     * @secure
     */
    postContainersUpdatecommercialexpirydate: (
      data: UpdateCommercialExpiryDateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/containers/updatecommercialexpirydate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get search containers
     *
     * @tags container
     * @name GetContainersByVessel
     * @summary GET Containers by Vessel
     * @request GET:/containers/search_by_vessel
     * @secure
     */
    getSearchContainers: (query: GetSearchContainersParams, params: RequestParams = {}) => {
      const queryParams = { ...query };
      if (
        queryParams.shippingAgentNxtEntityId != null &&
        queryParams.shippingAgentNxtEntityId == LABEL.UNKNOWN
      ) {
        queryParams.shippingAgentNxtEntityId = undefined;
      }
      if (queryParams.isArchived != null && queryParams.isArchived === true) {
        return this.request<Container[], ErrorResponse>({
          path: `/containers/search_containers/archived`,
          method: 'GET',
          query: queryParams,
          secure: true,
          format: 'json',
          ...params,
        });
      }
      return this.request<Container[], ErrorResponse>({
        path: `/containers/search_containers`,
        method: 'GET',
        query: queryParams,
        secure: true,
        format: 'json',
        ...params,
      });
    },

    /**
     * @description Get all containers by Vessel
     *
     * @tags container
     * @name GetContainersByVessel
     * @summary GET Containers by Vessel
     * @request GET:/containers/search_by_vessel
     * @secure
     */
    getContainersByVessel: (query: GetContainersByVesselParams, params: RequestParams = {}) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/search_by_vessel`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all containers by Voyage
     *
     * @tags container
     * @name GetContainersByVoyage
     * @summary GET Containers by Voyage
     * @request GET:/containers/search_by_voyage
     * @secure
     */
    getContainersByVoyage: (query: GetContainersByVoyageParams, params: RequestParams = {}) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/search_by_voyage`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all containers by Commercial Expiry Date
     *
     * @tags container
     * @name GetContainersByCommercialexpirydate
     * @summary GET Containers by Commercial Expiry Date
     * @request GET:/containers/search_by_expiration
     * @secure
     */
    getContainersByCommercialexpirydate: (
      query: GetContainersByCommercialexpirydateParams,
      params: RequestParams = {}
    ) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/search_by_expiration`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all containers for the Update voyageNumber Action
     *
     * @tags container
     * @name GetContainersForUpdatevoyagenumber
     * @summary GET Containers for Update VoyageNumber
     * @request GET:/containers/filter/updatevoyagenumber
     * @secure
     */
    getContainersForUpdatevoyagenumber: (
      query: GetContainersForUpdatevoyagenumberParams,
      params: RequestParams = {}
    ) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/filter/updatevoyagenumber`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all containers for the Change Terminal Action
     *
     * @tags container
     * @name GetContainersForUpdateterminal
     * @summary GET Containers for Update Terminal
     * @request GET:/containers/filter/updateterminal
     * @secure
     */
    getContainersForUpdateterminal: (
      query: GetContainersForUpdateterminalParams,
      params: RequestParams = {}
    ) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/filter/updateterminal`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all containers for the Accept Release right Action
     *
     * @tags container
     * @name GetContainersByTerminalAndReleaseRightParams
     * @summary GET Containers for Accept Release right
     * @request GET:/containers/filter/updateterminal
     * @secure
     */
    getContainersForReleaseRightAction: (
      query: GetContainersByTerminalAndReleaseRightParams,
      params: RequestParams = {}
    ) =>
      this.request<TerminalContainer[], ErrorResponse>({
        path: `/containers/search_by_terminal_params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all pending containers for the Accept/Decline Release right Action
     *
     * @tags container
     * @name getPendingContainersForReleaseRightAction
     * @summary GET all pending containers for the Accept/Decline
     * @request GET:/containers/pending
     * @secure
     */
    getPendingContainersForReleaseRightAction: () =>
      this.request<TerminalContainer[], ErrorResponse>({
        path: `/containers/pending`,
        method: 'GET',
        secure: true,
        format: 'json',
      }),
    /**
     * @description Get all containers by received from params
     *
     * @tags container
     * @name GetContainersByReceivedFromParams
     * @summary GET Containers by received from params
     * @request GET:/containers/search_by_receivedfrom_params
     * @secure
     */
    getUnassignedContainersByReceivedFromParams: (
      query: GetContainersByReceivedFromParams,
      params: RequestParams = {}
    ) =>
      this.request<TerminalContainer[], ErrorResponse>({
        path: `/containers/filter/unassigned`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers by received from params
     *
     * @tags container
     * @name GetContainersByReceivedFromParams
     * @summary GET Containers by received from params
     * @request GET:/containers/search_by_receivedfrom_params
     * @secure
     */
    getContainersByReceivedFromParams: (
      query: GetContainersByReceivedFromParams,
      params: RequestParams = {}
    ) =>
      this.request<TerminalContainer[], ErrorResponse>({
        path: `/containers/search_by_receivedfrom_params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers for Revoke
     *
     * @tags container
     * @name GetContainersByShippingAgentParams
     * @summary GET Containers for Revoke
     * @request GET:/containers/filter/revoke-releaseright
     * @secure
     */
    getContainersForRevokeByShippingAgentParams: (
      query: GetContainersByShippingAgentParams,
      params: RequestParams = {}
    ) =>
      this.request<ExtendedContainer[], ErrorResponse>({
        path: `/containers/filter/revoke-releaseright`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers for Search Containers
     *
     * @tags container
     * @name GetContainersByShippingAgentParams
     * @summary GET Containers for Search Containers
     * @request GET:/containers/search_by_shippingagent_params
     * @secure
     */
    getContainersByShippingAgentParams: (
      query: GetContainersByShippingAgentParams,
      params: RequestParams = {}
    ) =>
      this.request<ExtendedContainer[], ErrorResponse>({
        path: `/containers/search_by_shippingagent_params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers for the Assign Pick-Up Action
     *
     * @tags container
     * @name GetContainersByTerminalParams
     * @summary GET Containers for Assign Pick-Up
     * @request GET:/containers/filter/assign-pickup
     * @secure
     */
    getContainersForAssignPickUp: (
      query: GetContainersByTerminalParams,
      params: RequestParams = {}
    ) =>
      this.request<TerminalContainer[], ErrorResponse>({
        path: `/containers/filter/assign-pickup`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers for the Update Pick-Up Action
     *
     * @tags container
     * @name GetContainersByTerminalParams
     * @summary GET Containers for Update Pick-Up
     * @request GET:/containers/filter/update-pickup
     * @secure
     */
    getContainersForUpdatePickUp: (
      query: GetContainersByPickUpParams,
      params: RequestParams = {}
    ) =>
      this.request<PickUpContainer[], ErrorResponse>({
        path: `/containers/filter/update-pickup`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all containers for the Update Commercial Expiry Date Action
     *
     * @tags container
     * @name GetContainersForUpdatecommercialexpirydate
     * @summary GET Containers for Update Commercial Expiry Date
     * @request GET:/containers/filter/updatecommercialexpirydate
     * @secure
     */
    getContainersForUpdatecommercialexpirydate: (
      query: GetContainersForUpdatecommercialexpirydateParams,
      params: RequestParams = {}
    ) =>
      this.request<Container[], ErrorResponse>({
        path: `/containers/filter/updatecommercialexpirydate`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Container Detail
     *
     * @tags container
     * @name GetContainerDetail
     * @summary GET Container Detail
     * @request GET:/containers/{Id}
     * @secure
     */
    getContainerDetail: (id: string, params: RequestParams = {}) =>
      this.request<ExtendedContainer, ErrorResponse | void>({
        path: `/containers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    checkContainerToBeOverruled: (
      partyPath: string,
      equipmentNumber: string,
      params: RequestParams = {}
    ) =>
      this.request<ContainerOverruleResponse, ErrorResponse | void>({
        path: `/containers/${partyPath}/check-container-overrule?equipmentNumber=${equipmentNumber}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    getContainersToBeOverruled: (
      partyPath: string,
      equipmentNumbers: string[],
      params: RequestParams = {}
    ) =>
      this.request<TerminalContainer[], ErrorResponse | void>({
        path: `/containers/${partyPath}/get-containers-overrule`,
        method: 'POST',
        body: equipmentNumbers,
        secure: true,
        type: ContentType.Json,
        format: 'json',
      }),
  };
  commercialreleases = {
    /**
     * No description
     *
     * @tags commercialreleases
     * @name PostCommercialreleasesAction
     * @summary POST Update Action
     * @request POST:/commercialreleases/action
     * @secure
     */
    postCommercialreleasesAction: (data: UpdateActionRequest, params: RequestParams = {}) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/commercialreleases/action`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  billingInfo = {
    getBillingInfo: (query?: IBillingInformationRequest, params: RequestParams = {}) =>
      this.request<ContainerBillingInfo[], ErrorResponse>({
        path: `/containers/billinginfo`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
    downloadFile: (query?: IBillingInformationRequest, params: RequestParams = {}) =>
      this.request<Blob, ErrorResponse>({
        path: `/containers/billinginfo/csv`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'blob',
        ...params,
      }),
  };
  uploadCsvCreateReleaseRight = {
    postUploadReleaseRightsFileRequest: (
      data: IUploadReleaseRightsFileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse | void>({
        path: `/releaserights/commercial-release/uploadcsv`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }).catch((e) => {
        var err = new CustomErrorResponse();
        if ('status' in e) err.httpStatusCode = e.status;
        else err.httpStatusCode = e.error.HttpStatusCode;
        if ('Message' in e.error) err.message = e.error.Message;
        else if ('statusText' in e) err.message = e.statusText;
        if ('PublicReferenceId' in e.error) err.publicReferenceId = e.error.PublicReferenceId;
        return err;
      }),
  };
  dashboard = {
    /**
     * @description Get all metrics
     *
     * @tags dashboard
     * @name GetDashboardMetrics
     * @summary GET Metrics
     * @request GET:/dashboard
     * @secure
     */
    getShippingAgentDashboard: (params: RequestParams = {}) =>
      this.request<DashboardMetricsResponse, ErrorResponse>({
        path: '/dashboard/shipping-agent',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all metrics
     *
     * @tags dashboard
     * @name GetDashboardMetrics
     * @summary GET Metrics
     * @request GET:/dashboard
     * @secure
     */
    getTransportOperatorDashboard: (params: RequestParams = {}) =>
      this.request<DashboardMetricsResponse, ErrorResponse>({
        path: '/dashboard/transport-operator',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get all metrics
     *
     * @tags dashboard
     * @name GetDashboardMetrics
     * @summary GET Metrics
     * @request GET:/dashboard
     * @secure
     */
    getReleasePartyDashboard: (params: RequestParams = {}) =>
      this.request<DashboardMetricsResponse, ErrorResponse>({
        path: '/dashboard/release-party',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  releaseparties = {
    /**
     * @description Get All ReleaseParties
     *
     * @tags releaseparty
     * @name GetReleaseparties
     * @summary GET ReleaseParties
     * @request GET:/releaseparties
     * @secure
     */
    getReleaseparties: (params: RequestParams = {}) =>
      this.request<ReleaseParty[], ErrorResponse>({
        path: `/releaseparties`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get All Transport Operators
     *
     * @tags releaseparty
     * @name GetReleaseparties
     * @summary GET Transport Operators
     * @request GET:/releaseparties/transport-operator
     * @secure
     */
    getTransportOperators: (params: RequestParams = {}) =>
      this.request<ReleaseParty[], ErrorResponse>({
        path: `/releaseparties/transport-operator`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * @description Get parties where the current role can release to
     *
     * @tags releaseparty
     * @name GetReleaseparties
     * @summary Get parties where the current role can release to
     * @request GET:/releaseparties/releaseto-parties
     * @secure
     */
    getReleaseToParties: (query: IReleaseToPartyByRole, params: RequestParams = {}) =>
      this.request<ReleaseParty[], ErrorResponse>({
        path: `/releaseparties/releaseto-parties`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  greenLights = {
    /**
     * @description Get green light values for green light names
     *
     * @tags greenlights
     * @name GetGreenLightValuesForGreenLightNames
     * @summary GET Green light values
     * @request GET:/greenLights/values
     * @secure
     */
    getGreenLightValues: (data: GreenLightValuesRequest, params: RequestParams = {}) =>
      this.request<GreenLightValuesResponse, ErrorResponse>({
        path: `/greenLights/values`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description Set over write lights
     *
     * @tags greenlights
     * @name SetCustomsLightManualOverwrite
     * @summary Set customs light Manual Overwrite
     * @request PUT:/customslight/manual-overwrite
     * @secure X-Alfapass-Token in header
     */
    setCustomsLightManualOverwrite: (
      data: CustomsLightManualOverwriteRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostResponse, ErrorResponse>({
        path: `/greenLights/customslight/manual-overwrite`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    getAlfapassClientId: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/greenlights/overrule/my-alfapass`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  terminals = {
    /**
     * @description Get All active Terminals
     *
     * @tags terminal
     * @name GetTerminals
     * @summary GET Terminals
     * @request GET:/terminals
     * @secure
     */
    getTerminals: (params: RequestParams = {}) =>
      this.request<Terminal[], ErrorResponse>({
        path: `/terminals`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  agents = {
    /**
     * @description Get All active Shipping Agents
     *
     * @tags agents
     * @name GetShippingAgents
     * @summary GET Shipping Agents
     * @request GET:/shipping-agents
     * @secure
     */
    getShippingAgents: (params: RequestParams = {}) =>
      this.request<ShippingAgent[], ErrorResponse>({
        path: `/identification/shipping-agents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
