import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Home } from '@material-ui/icons';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { LABEL } from '../../theme/constants';

const styles = (theme) => ({
  breadcrumbBar: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
  breadcrumb: {
    color: theme.palette.primary,
    padding: '6px 8px',
    textDecoration: 'underline',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.5',
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  breadcrumbButton: {
    minWidth: '0',
  },
  iconSmall: {
    fontSize: 21,
  },
});

function BreadcrumbBar(props) {
  const { classes } = props;

  const breadcrumbNameMap = {
    '/containers': LABEL.CONTAINERS,
    '/containers/detail': LABEL.DETAIL.toUpperCase(),
    '/containers/company': LABEL.COMPANY,
    '/containers/containers': LABEL.CONTAINERS,
    '/container-subscriptions': LABEL.CONTAINER_SUBSCRIPTIONS,
    '/container-subscriptions/detail': LABEL.DETAIL.toUpperCase(),
    '/container-subscriptions/audit': LABEL.AUDIT_TRAIL.toUpperCase(),
    '/driverlist': LABEL.DRIVERLIST,
    '/releases': LABEL.RELEASES,
    '/releases/detail': LABEL.DETAIL.toUpperCase(),
    '/commercial-release': LABEL.COMMERCIAL_RELEASE.toUpperCase(),
    '/commercial-release/add': LABEL.COMMERCIAL_RELEASE_CREATE_TITLE.toUpperCase(),
    '/commercial-release/update': LABEL.COMMERCIAL_RELEASE_UPDATE_TITLE.toUpperCase(),
    '/audittrail-subscriptions': LABEL.SUBSCRIPTIONS.toUpperCase(),
    '/audittrail-subscriptions/companies': LABEL.SELECT_COMPANY_TITLE.toUpperCase(),
    '/containers/selected': LABEL.AUDIT_TRAIL.toUpperCase(),
    '/change-terminal': LABEL.CHANGE_TERMINAL.toUpperCase(),
    '/update-voyage': LABEL.UPDATE_VOYAGE.toUpperCase(),
    '/block-voyage': LABEL.BLOCK_VOYAGE.toUpperCase(),
    '/unblock-voyage': LABEL.UNBLOCK_VOYAGE.toUpperCase(),
    '/revoke-voyage': LABEL.REVOKE_VOYAGE.toUpperCase(),
    '/search-containers': LABEL.CONTAINER_SEARCH.toUpperCase(),
    '/update-expiry-date': LABEL.UPDATE_EXPIRY_DATE.toUpperCase(),
    '/delete-voyage': LABEL.DELETE_VOYAGE.toUpperCase(),
    '/assign-release-party': LABEL.ASSIGN_RELEASE_PARTY.toUpperCase(),
    '/display-notifications': LABEL.DISPLAY_NOTIFICATIONS.toUpperCase(),
    '/overrule-customs-light': LABEL.OVERRULE_CUSTOMS_LIGHT.toUpperCase(),
    '/display-billing-info': LABEL.DISPLAY_BILLING_INFO.toUpperCase(),
    '/assign-pickup': LABEL.ASSIGN_PICKUP.toUpperCase(),
    '/update-pickup': LABEL.UPDATE_PICKUP.toUpperCase(),
    '/accept-releaseright': LABEL.ACCEPT_RELEASERIGHT.toUpperCase(),
    '/decline-releaseright': LABEL.DECLINE_RELEASERIGHT.toUpperCase(),
    '/transfer-releaseright': LABEL.TRANSFER_RELEASERIGHT.toUpperCase(),
    '/revoke-releaseright': LABEL.REVOKE_RELEASERIGHT.toUpperCase(),
    '/create-multiple-releases-upload': LABEL.CREATE_MULTIPLE_RELEASE_CSV.toUpperCase(),
  };

  const labelsWithoutRoute = [LABEL.COMMERCIAL_RELEASE.toUpperCase()];

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x);
        const isHomePage =
          (pathnames.length === 1 && pathnames[0].toLowerCase() === '') || pathnames.length === 0;

        const isBlockedPage = pathnames.some((x) => x === 'blocked');

        return !isHomePage && !isBlockedPage ? (
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <LinkRouter underline="hover" color="inherit" to={{ pathname: '/', search: '' }}>
              <Home className={classes.iconSmall} />
            </LinkRouter>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              const isWithoutRoute =
                labelsWithoutRoute.find((l) => l == breadcrumbNameMap[to]) != undefined;

              const isPartOfExistingRoute =
                Object.keys(breadcrumbNameMap).filter((k) => k.includes(to)).length > 0;

              return isPartOfExistingRoute == false ? (
                ''
              ) : last || isWithoutRoute ? (
                <Typography color="textPrimary" key={to} className={classes.breadcrumb}>
                  {breadcrumbNameMap[to]}
                </Typography>
              ) : (
                <LinkRouter
                  underline="hover"
                  color="inherit"
                  to={{ pathname: to, search: '' }}
                  key={to}
                  className={classes.breadcrumb}
                >
                  {breadcrumbNameMap[to]}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
        ) : (
          ''
        );
      }}
    </Route>
  );
}

export default withStyles(styles)(BreadcrumbBar);
