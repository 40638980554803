import { Paper, Box, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BillingInfoRequest } from '../../../global/adapters/crpAPI';
import FilterBillingInfo from './FilterBillingInfo';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import moment from 'moment';
import useStyles from './style';
import useDownloadFile from 'modules/release-party/hooks/useDownloadFile';
import { MonthsByDescription } from 'modules/global/helpers/datesHelper';

type Props = {
  isFetching?: boolean;
  resetForm: VoidFunction;
  submitForm: VoidFunction;
  values: BillingInfoRequest;
  isError: Boolean;
  isEmptyData: Boolean;
  numberOfContainers: number | undefined;
};

const StepperDisplayBillingInfo = ({
  isFetching,
  resetForm,
  submitForm,
  values,
  isError,
  isEmptyData,
  numberOfContainers,
}: Props) => {
  const { t } = useTranslation(['displayBillingInfo', 'common', 'useCase']);
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const { onDownload, isLoading, setBillingRequest } = useDownloadFile();

  const resetFormAndValues = () => {
    setActiveStep(1);
    resetForm();
  };

  const getItForm = () => {
    const yr = moment().year();
    if (values.year < yr - 1 || values.year > yr + 1) {
      resetForm();

      return;
    }
    submitForm();
    setBillingRequest({ month: MonthsByDescription.indexOf(values.month) + 1, year: values.year });
  };

  const downloadIt = () => {
    onDownload();
    setActiveStep(0);
  };

  const goBack = () => {
    resetFormAndValues();
  };
  const { month, year } = values;

  const searchAvailable = month !== '' || (year != null && year > 0);

  return (
    <Paper>
      <StepUseCase
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('displayBillingInfo:common.title'),
            stepFlavorText: t('displayBillingInfo:filter.searchFlavorText'),
            backCallBack: resetFormAndValues,
            backCustomText: t('common:general.reset'),
            nextCallBack: getItForm,
            nextCustomText: isFetching
              ? t('displayBillingInfo:actions.fetching')
              : t('displayBillingInfo:actions.search'),
            nextIsLoading: isFetching,
            content: <FilterBillingInfo customHelperText={t('validation:atLeastNonStatusField')} />,
            disableNext: !searchAvailable,
          },
          {
            stepTitle: t('displayBillingInfo:common.exportTitle'),
            stepFlavorText: t('displayBillingInfo:result.resultFlavorText'),
            nextCustomText: t('displayBillingInfo:actions.export'),
            disableNext: Boolean(!isFetching && (isEmptyData || isError)), //DOWNLOAD
            backCallBack: goBack,
            nextCallBack: downloadIt,
            content: (
              <div>
                <Typography className={classes.subText} variant="subtitle1">
                  {t('result.resultContentText', {
                    numberOfContainers: numberOfContainers,
                    month: month,
                  })}
                </Typography>
                {isLoading && (
                  <span>
                    <CircularProgress size={'1rem'} /> {t('common.exportInProgress')}
                  </span>
                )}
              </div>
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperDisplayBillingInfo;
