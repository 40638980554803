import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    fontSize: '20px',
    margin: 'auto',
    maxWidth: '400px',
  },
  subTitle: {
    maxWidth: '600px',
    margin: '30px auto',
  },
  wrapper: {
    textAlign: 'center',
    paddingTop: '100px',
  },
});

export default useStyles;
