import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { LABEL, DIALOG, FORMATTING } from '../../theme/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteDriverAlias } from '../../store/actions/driverListActions';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
  content: {
    '& span': {
      width: '100%',
      display: 'block',
      margin: '0px',
      fontWeight: 'normal',
    },
  },
  heading: {
    width: '100%',
    display: 'block',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    margin: '0px',
  },
  headingContent: {
    width: '100%',
    fontWeight: 'bold',
    display: 'block',
    padding: '10px 0',
  },
  noBlock: {
    display: 'initial !important',
  },
});

const DeleteDriverAliasDialog = (props) => {
  const { openDeleteDriverAliasDialog, closeDialog, deleteDriverAlias, selectedDriverAlias } =
    props;

  const handleYes = (e) => {
    deleteDriverAlias(selectedDriverAlias.externalId);
    closeDialog(e);
  };

  const noYesActions = (
    <div>
      <Button
        onClick={(e) => {
          closeDialog(e);
        }}
        color="primary"
      >
        {LABEL.NO}
      </Button>
      <Button onClick={handleYes} color="primary">
        {LABEL.YES}
      </Button>
    </div>
  );

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDeleteDriverAliasDialog}
        onClose={(e) => closeDialog(e)}
      >
        <DialogTitle id={'confirm-dialog-title-' + selectedDriverAlias.externalId}>
          {DIALOG.DELETE_DRIVER_DESCRIPTION}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {DIALOG.GENERAL_DRIVERALIAS_CONFIRM_TEXT.replace(FORMATTING.ACTION, 'delete')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{noYesActions}</DialogActions>
      </Dialog>
    </div>
  );
};

DeleteDriverAliasDialog.propTypes = {
  openDeleteDriverAliasDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectedDriverAlias: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteDriverAlias,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(DeleteDriverAliasDialog));
