import DateTimeHelper from '../datetime';
import * as moment from 'moment';

export default class Container {
  static ValidateCreate = (container) => {
    let errors = [];

    const SetError = (error) => {
      switch (error.property) {
        case 'equipmentNumber':
          errors.push({ field: 'equipmentNumber', isvalid: false });
          break;
        case 'billOfLadingNumbers':
          errors.push({ field: 'billOfLadingNumbers', isvalid: false });
          break;
        case 'terminalCode':
          errors.push({ field: 'terminalCode', isvalid: false });
          break;
        case 'releaseDateTimeUtc':
          errors.push({ field: 'releaseDateTimeUtc', isvalid: false });
          break;
        case 'expirationDateTimeUtc':
          errors.push({ field: 'expirationDateTimeUtc', isvalid: false });
          break;
        case 'releaseIdentification':
          errors.push({ field: 'releaseIdentification', isvalid: false });
          break;
        default:
          break;
      }
    };

    let rules = [
      {
        property: 'equipmentNumber',
        valid:
          container.equipmentNumber.trim().length > 0 &&
          container.equipmentNumber.trim().length <= 50,
      },
      {
        property: 'billOfLadingNumbers',
        valid:
          container.billOfLadingNumbers.length > 0 &&
          container.billOfLadingNumbers.every(
            (n) =>
              n.blNumber != undefined &&
              n.blNumber.trim().length >= 3 &&
              n.blNumber.trim().length <= 100
          ),
      },
      {
        property: 'terminalCode',
        valid: container.terminalCode.length > 0,
      },
      {
        property: 'releaseDateTimeUtc',
        valid:
          container.releaseDateTimeUtc != null &&
          DateTimeHelper.lessThan(
            container.releaseDateTimeUtc,
            container.expirationDateTimeUtc || moment().add(3, 'M')
          ),
      },
      {
        property: 'expirationDateTimeUtc',
        valid:
          container.expirationDateTimeUtc == null ||
          (DateTimeHelper.isInFuture(container.expirationDateTimeUtc) &&
            DateTimeHelper.greaterThan(
              container.expirationDateTimeUtc,
              container.releaseDateTimeUtc
            )),
      },
      {
        property: 'releaseIdentification',
        valid:
          container.releaseIdentification.trim().length >= 3 &&
          container.releaseIdentification.trim().length <= 100,
      },
    ];

    if (Object.keys(rules).some((x) => !rules[x].valid)) {
      rules.filter((x) => !x.valid).forEach((error, i) => SetError(error));
    }

    return errors;
  };

  static ValidateUpdate = (container) => {
    let errors = [];

    const SetError = (error) => {
      switch (error.property) {
        case 'terminalCode':
          errors.push({ field: 'terminalCode', isvalid: false });
          break;
        case 'releaseDateTimeUtc':
          errors.push({ field: 'releaseDateTimeUtc', isvalid: false });
          break;
        case 'expirationDateTimeUtc':
          errors.push({ field: 'expirationDateTimeUtc', isvalid: false });
          break;
        default:
          break;
      }
    };

    let rules = [
      {
        property: 'terminalCode',
        valid: container.terminalCode.length > 0,
      },
      {
        property: 'releaseDateTimeUtc',
        valid:
          container.releaseDateTimeUtc != null &&
          DateTimeHelper.lessThanOrEqual(
            container.releaseDateTimeUtc,
            container.expirationDateTimeUtc || moment().add(3, 'M')
          ),
      },
      {
        property: 'expirationDateTimeUtc',
        valid:
          container.expirationDateTimeUtc == null ||
          (DateTimeHelper.isInFuture(container.expirationDateTimeUtc) &&
            DateTimeHelper.greaterThanOrEqual(
              container.expirationDateTimeUtc,
              container.releaseDateTimeUtc
            )),
      },
    ];

    if (Object.keys(rules).some((x) => !rules[x].valid)) {
      rules.filter((x) => !x.valid).forEach((error, i) => SetError(error));
    }

    return errors;
  };

  static KeyIsFilledIn = (container) => {
    return (
      container.equipmentNumber != '' &&
      container.billOfLadingNumbers.length > 0 &&
      container.releaseIdentification != ''
    );
  };
}
