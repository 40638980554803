import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '65vh',
  },
  detailWrapper: {
    padding: '40px',

    '& h1': {
      fontSize: '20px',
      marginBottom: '40px',
    },
  },
  subText: {
    color: theme.palette.grey[700],
    whiteSpace: 'break-spaces',
    margin: '10px 0 20px',
    lineHeight: '1.8rem',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.875rem',
  },
  greenLightValueIcon: {
    display: 'flex',

    '& MuiListItemIcon-root': {
      display: 'inline-flex',
      position: 'relative',
      top: '5px',
      justifyContent: 'center',
    },
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  validationCell: {
    textAlign: 'center',
    width: '100px',
  },
}));

export default useStyles;
