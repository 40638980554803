import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EditItem } from './EditableListItem';

type Props = {
  ContainersToBeOverruled?: string[] | undefined;
};

const SelectStep = ({ ContainersToBeOverruled }: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);

  React.useEffect(() => {}, []);

  const checkContainerValidation = (containersList: EditItem[]) => {
    return (
      containersList.find((i) => i.Value === '' || i.Value == null || !i.Validated) === undefined
    );
  };

  return (
    <React.Fragment>
      <div>
        <p>{t('overruleCustomsLight:sidebarSelectContainers.stepFlavorText')}</p>
        <p>{t('overruleCustomsLight:sidebarSelectContainers.beAwareText')}</p>
      </div>
    </React.Fragment>
  );
};

export default SelectStep;
