import {
  Box,
  FormHelperText,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormikSelect from '../../../global/components/FormikSelect/FormikSelect';
import { ShippingAgent } from 'modules/global/adapters/crpAPI';
import useStyles from './styles';
import { LABEL } from 'theme/constants';

type Props = {
  shippingAgents: ShippingAgent[] | undefined;
  hasValidationError: boolean;
  validationMessage: string | undefined;
  isFetching: boolean;
  isError: boolean;
};

const TerminalYardSearchStep = ({
  shippingAgents,
  hasValidationError,
  validationMessage,
  isError,
  isFetching,
}: Props) => {
  const { t } = useTranslation(['glossary']);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box marginTop={2}>
        <Field name="equipmentNumber" id="equipmentNumber">
          {({ field }: FieldProps) => (
            <React.Fragment>
              <TextField
                {...field}
                variant="outlined"
                label={t('equipmentNumber')}
                error={hasValidationError}
                fullWidth
              />
            </React.Fragment>
          )}
        </Field>
      </Box>
      <Box marginTop={2}>
        <Field name="shippingAgentNxtEntityId" id="shippingAgentNxtEntityId">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <FormikSelect
                defaultValue={form.values.shippingAgentNxtEntityId}
                form={form}
                field={field}
                error={hasValidationError}
              >
                <MenuItem value={LABEL.UNKNOWN} disabled={true}>
                  {t('shippingAgentName')}
                </MenuItem>
                {shippingAgents &&
                  shippingAgents.map((agent: ShippingAgent, index: number) => (
                    <MenuItem value={agent.nxtEntityId} key={`agent_${index}`}>
                      {agent.name + '( ' + agent.nxtEntityId + ' )'}
                    </MenuItem>
                  ))}
              </FormikSelect>
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>
          {hasValidationError && validationMessage !== undefined ? validationMessage : ''}
        </FormHelperText>
      </Box>
      <Box marginTop={2}>
        <Field name="isArchived" id="isArchived">
          {({ field }: FieldProps) => (
            <FormControlLabel
              style={{ fontWeight: '200' }}
              control={
                <Checkbox {...field} checked={field.value} name="isArchived" color="primary" />
              }
              label="Archived containers"
            />
          )}
        </Field>
      </Box>
    </React.Fragment>
  );
};
export default TerminalYardSearchStep;
