import ActionTypes from './actionTypes';

export function loadCompanies(loading) {
  return {
    type: ActionTypes.COMPANIES_REQUESTED,
    payload: { loading: loading },
  };
}

export function loadCompaniesPage(page) {
  return {
    type: ActionTypes.COMPANIES_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadCompaniesRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.COMPANIES_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadCompaniesFilters(filters) {
  return {
    type: ActionTypes.COMPANIES_REQUESTED,
    payload: { filters },
  };
}

export function loadCompaniesSucceeded(companies) {
  return {
    type: ActionTypes.COMPANIES_REQUEST_SUCCEEDED,
    payload: companies,
  };
}
