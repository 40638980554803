import { Paper } from '@material-ui/core';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/release-party/constants';
import { ResultStep, TransporterSearchStep } from '../shared';
import {
  ExtendedContainer,
  GetContainersByShippingAgentParams,
} from 'modules/global/adapters/crpAPI';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, RowSelectionState } from '@tanstack/react-table';
import FilterStep from './FilterStep';
import UpdateStep from './UpdateStep';
import {
  useGetActiveShippingAgents,
  usePostContainersRevoke,
} from 'modules/release-party/adapters/revoke-releaseright/queries';
import { FormikErrors } from 'formik';
import { LABEL } from 'theme/constants';

interface Props {
  data: ExtendedContainer[] | undefined;
  errors: FormikErrors<GetContainersByShippingAgentParams>;
  isFetching?: boolean;
  selectedRows: Row<ExtendedContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByShippingAgentParams;
}

const StepperRevokeReleaseRight = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
}: Props) => {
  const { t } = useTranslation(['revokeReleaseRight', 'common', 'useCase', 'validation']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const equipmentNumberError = Boolean(errors.equipmentNumber);
  const shippingAgentNxtEntityIdError = Boolean(errors.shippingAgentNxtEntityId);
  const billOfLadingNumberError = Boolean(errors.billOfLadingNumber);

  const hasValidationError =
    equipmentNumberError || shippingAgentNxtEntityIdError || billOfLadingNumberError;

  const { shippingAgentNxtEntityId, equipmentNumber, billOfLadingNumber } = values;
  const searchAvailable =
    Boolean(shippingAgentNxtEntityId && shippingAgentNxtEntityId !== LABEL.UNKNOWN) ||
    Boolean(equipmentNumber) ||
    Boolean(billOfLadingNumber);

  const {
    data: shippingAgents,
    isFetching: isFetchingAgents,
    isError: isErrorAgents,
  } = useGetActiveShippingAgents();

  const { mutate, status } = usePostContainersRevoke();

  const revokeReleaseRight = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      releaseIds: listOfCheckedContainers,
    });
  };

  const resetFormAndValues = () => {
    resetForm();
    setActiveStep(1);
  };

  const resetFormAndContainersUpdated = () => {
    resetForm();
    setRowSelection({});
    setContainersUpdated(0);
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.REVOKE_RELEASE_RIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.releasePartyRevokeSearchFlavorText'),
            backCallBack: resetFormAndValues,
            nextCallBack: submitForm,
            backCustomText: t('common:general.reset'),
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableNext: !searchAvailable,
            content: (
              <TransporterSearchStep
                shippingAgents={shippingAgents}
                hasValidationError={hasValidationError}
                validationMessage={t('validation:atLeastOneField')}
                isFetching={isFetchingAgents}
                isError={isErrorAgents}
              />
            ),
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectAcceptedContainersToRevokeFlavorText'),
            nextCustomText: t('common:general.next'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('revokeReleaseRight:general.title'),
            stepFlavorText: t('useCase:containers.revokeContainersFlavorText'),
            nextCallBack: revokeReleaseRight,
            nextCustomText: t('useCase:containers.revokeContainers', {
              numberOfContainersSelected: numberOfContainersProcessed,
            }),
            disableNext: numberOfContainersSelected < 1,
            content: <UpdateStep numberOfContainersSelected={numberOfContainersSelected} />,
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={revokeReleaseRight}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                })}
                hasNextStep={false}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperRevokeReleaseRight;
