import {
  AppBar,
  Button,
  CircularProgress,
  Backdrop,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  Toolbar,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { AccountCircle as ProfileIcon, Help } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logOut } from '../../auth/identityAuth';
import ProfileDialog from './ProfileDialog';
import LogoutDialog from './LogoutDialog';
import history from '../../history/browserHistory';
import BetaToggle from '../../modules/global/components/BetaToggle/BetaSwitch';
import { resetTab } from '../../store/actions/tabActions';
import {
  getChangedTab,
  getPendingReleaseCount,
  getSelectedUserType,
  getUserProfile,
} from '../../store/selectors/selectors';
import { LABEL, TABS } from '../../theme/constants';
import UserProfileSelect from './UserProfileSelect';

const styles = (theme) => ({
  logo: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24,
    backgroundImage: 'linear-gradient(to right, #005efd, #009dfe)',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
  },
  profileIcon: {
    marginRight: 5,
  },
  title: {
    flexGrow: 1,
  },
  row: {
    borderStyle: 'hidden',
    marginLeft: '15px',
    display: 'inline-table',
  },
  cellText: {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '3px',
    color: 'white',
  },
  cellTextActive: {
    cursor: 'pointer',
    backgroundColor: '#004bca',
    padding: '10px',
    borderRadius: '3px',
    color: 'white',
  },
  flex: {
    flex: 1,
  },
  cell: {
    padding: '12px',
  },
  userMenu: {
    '& > *': {
      margin: '5px',
    },
  },
});

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      anchorEl2: undefined,
      activeTab: window.location.pathname === '/' ? '/containers' : window.location.pathname,
      isBetaEnabled: localStorage.getItem('isBetaEnabled')?.toString() === 'true' || false,
      showUserProfile: false,
      loggingOut: false,
    };
  }

  containersTab = () => {
    this.setState({ activeTab: '/containers' });
    history.replace('/containers');
  };

  subscriptionTab = () => {
    this.setState({ activeTab: '/container-subscriptions' });
    history.replace('/container-subscriptions');
  };

  auditTrailSubscriptionTab = () => {
    this.setState({ activeTab: '/audittrail-subscriptions' });
    history.replace('/audittrail-subscriptions');
  };

  policeContainerSubscriptionTab = () => {
    this.setState({ activeTab: '/container-subscriptions' });
    history.replace('/container-subscriptions');
  };

  driverlistTab = () => {
    this.setState({ activeTab: '/driverlist' });
    history.replace('/driverlist');
  };

  releasesTab = () => {
    this.setState({ activeTab: '/releases' });
    history.replace('/releases');
  };

  archiveTab = () => {
    this.setState({ activeTab: '/archive' });
    history.replace('/archive');
  };

  handleProfileMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: undefined });
  };

  handleHelpMenuClick = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleHelpMenuClose = () => {
    this.setState({ anchorEl2: undefined });
  };

  openUserProfile = () => {
    this.setState({ anchorEl: undefined });
    this.setState({ showUserProfile: !this.state.showUserProfile });
  };

  componentDidMount() {
    this.setState({ loggingOut: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.setState({
        activeTab: this.props.pathname === '/' ? '/containers' : this.props.pathname,
      });
    }

    if (this.props.changedTab === true && this.props.changedTab !== prevProps.changedTab) {
      let pathname = window.location.pathname;
      this.setState({ activeTab: pathname });
      history.replace(pathname);
      this.props.resetTab();
    }
  }

  onToggle = () => {
    this.setState({
      isBetaEnabled: localStorage.getItem('isBetaEnabled')?.toString() === 'true' || false,
    });
  };

  handleLogout = () => {
    this.setState({ anchorEl: undefined });
    this.setState({ loggingOut: true });
    setTimeout(() => {
      logOut();
    }, '500');
  };

  render() {
    const { classes, userProfile, selectedUserType, pendingReleaseCount } = this.props;
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <div className={classes.list}>
              <Table size="small">
                <TableBody>
                  <TableRow className={classes.row}>
                    {(selectedUserType === LABEL.FORWARDER ||
                      selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL ||
                      selectedUserType === LABEL.POLICE) &&
                      !this.state.isBetaEnabled && (
                        <TableCell className={classes.cell} onClick={this.containersTab}>
                          <ListItemText
                            className={
                              this.state.activeTab.indexOf('/containers') !== -1
                                ? classes.cellTextActive
                                : classes.cellText
                            }
                            primary={TABS.CONTAINER_OVERVIEW}
                          />
                        </TableCell>
                      )}
                    {selectedUserType === LABEL.SHIPPING && !this.state.isBetaEnabled && (
                      <TableCell className={classes.cell} onClick={this.containersTab}>
                        <ListItemText
                          className={
                            this.state.activeTab.indexOf('/containers') !== -1
                              ? classes.cellTextActive
                              : classes.cellText
                          }
                          primary={TABS.CONTAINER_OVERVIEW}
                        />
                      </TableCell>
                    )}
                    {(selectedUserType === LABEL.FORWARDER ||
                      selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL) &&
                      !this.state.isBetaEnabled && (
                        <TableCell className={classes.cell} onClick={this.releasesTab}>
                          <Badge badgeContent={pendingReleaseCount} color="secondary">
                            <ListItemText
                              className={
                                this.state.activeTab.indexOf('/releases') !== -1
                                  ? classes.cellTextActive
                                  : classes.cellText
                              }
                              primary={TABS.PENDING_RELEASES}
                            />
                          </Badge>
                        </TableCell>
                      )}
                    {(selectedUserType === LABEL.FORWARDER ||
                      selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL) &&
                      !this.state.isBetaEnabled && (
                        <TableCell className={classes.cell} onClick={this.subscriptionTab}>
                          <ListItemText
                            className={
                              this.state.activeTab.indexOf('/container-subscriptions') !== -1
                                ? classes.cellTextActive
                                : classes.cellText
                            }
                            primary={TABS.CONTAINER_SUBSCRIPTIONS}
                          />
                        </TableCell>
                      )}
                    {selectedUserType === LABEL.SHIPPING && !this.state.isBetaEnabled && (
                      <TableCell className={classes.cell} onClick={this.subscriptionTab}>
                        <ListItemText
                          className={
                            this.state.activeTab.indexOf('/container-subscriptions') !== -1
                              ? classes.cellTextActive
                              : classes.cellText
                          }
                          primary={TABS.CONTAINER_SUBSCRIPTIONS}
                        />
                      </TableCell>
                    )}
                    {/* {selectedUserType === LABEL.POLICE && (
                      <TableCell className={classes.cell} onClick={this.auditTrailSubscriptionTab}>
                        <ListItemText
                          className={
                            this.state.activeTab.indexOf('/audittrail-subscriptions') !== -1
                              ? classes.cellTextActive
                              : classes.cellText
                          }
                          primary={TABS.AUDIT_TRAIL_SUBSCRIPTIONS}
                        />
                      </TableCell>
                    )} */}
                    {/* {selectedUserType === LABEL.POLICE && (
                      <TableCell
                        className={classes.cell}
                        onClick={this.policeContainerSubscriptionTab}
                      >
                        <ListItemText
                          className={
                            this.state.activeTab.indexOf('/container-subscriptions') !== -1
                              ? classes.cellTextActive
                              : classes.cellText
                          }
                          primary={TABS.CONTAINER_SUBSCRIPTIONS}
                        />
                      </TableCell>
                    )} */}
                    {selectedUserType === LABEL.TERMINAL && (
                      <TableCell className={classes.cell}>
                        <ListItemText className={classes.cellTextActive} primary={LABEL.MY_YARD} />
                      </TableCell>
                    )}
                    {selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL &&
                      !this.state.isBetaEnabled && (
                        <TableCell className={classes.cell} onClick={this.driverlistTab}>
                          <ListItemText
                            className={
                              this.state.activeTab.indexOf('/driverlist') !== -1
                                ? classes.cellTextActive
                                : classes.cellText
                            }
                            primary={TABS.DRIVER_LIST}
                          />
                        </TableCell>
                      )}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.logo} />

            {/* Toggle for the new beta screens, only render for the new Shipping Agent */}
            {(selectedUserType === LABEL.SHIPPING ||
              selectedUserType === LABEL.TERMINAL ||
              selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL ||
              selectedUserType === LABEL.FORWARDER) && <BetaToggle onToggle={this.onToggle} />}

            {userProfile && (
              <span className={classes.userMenu}>
                <Button color="inherit" onClick={this.handleHelpMenuClick}>
                  <Help />
                </Button>
                <Menu
                  id="simple-menu-2"
                  anchorEl={this.state.anchorEl2}
                  open={Boolean(this.state.anchorEl2)}
                  onClose={this.handleHelpMenuClose}
                >
                  <MenuItem
                    onClick={() =>
                      window.open(' https://documentation.nxtport.com/certified-pickup/', '_blank')
                    }
                  >
                    Documentation
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      window.open(
                        'https://documentation.nxtport.com/certified-pickup/release-notes',
                        '_blank'
                      )
                    }
                  >
                    Release Notes
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      window.open(
                        'https://nxtport.atlassian.net/servicedesk/customer/portal/9',
                        '_blank'
                      )
                    }
                  >
                    Support Desk
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      window.open(
                        'https://documentation.nxtport.com/certified-pickup/certified-pick-up-cpu-voorwaarden',
                        '_blank'
                      )
                    }
                  >
                    Terms and Conditions
                  </MenuItem>
                </Menu>
                <Button color="inherit" onClick={this.handleProfileMenuClick}>
                  <ProfileIcon className={classes.profileIcon} />
                  {userProfile.firstName} {userProfile.lastName}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleProfileMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      this.handleLogout();
                    }}
                  >
                    {LABEL.LOGOUT}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.openUserProfile();
                    }}
                  >
                    {LABEL.ORGANISATION_INFO}
                  </MenuItem>
                </Menu>
                {selectedUserType !== LABEL.POLICE && userProfile.roles.length > 1 && (
                  <UserProfileSelect />
                )}
              </span>
            )}
          </Toolbar>
          <ProfileDialog openDialog={this.state.showUserProfile} userProfile={userProfile} />
        </AppBar>
        <LogoutDialog openDialog={this.state.loggingOut} />
      </>
    );
  }
}

Topbar.propTypes = {
  userprofile: PropTypes.object,
  selectedUserType: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    selectedUserType: getSelectedUserType(state),
    pendingReleaseCount: getPendingReleaseCount(state),
    changedTab: getChangedTab(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetTab,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar));
