import { Get, Put, Post, Delete } from './authorizedRequest';

const endpoint = '/driveraliases';

export async function getDriverList() {
  const request = Get(endpoint);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response.json();
}

export async function submitDriverAlias(obj) {
  const body = {
    description: obj.description,
    alfapassNumber: obj.alfapassNumber,
  };

  const request = Post(endpoint, body);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  return response;
}

export async function updateDriverAlias(obj) {
  const driverAlias = {
    driverAliasId: obj.externalId,
    description: obj.description,
  };

  const request = Put(endpoint + '/' + obj.externalId, driverAlias);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response;
}

export async function deleteDriverAlias(obj) {
  const request = Delete(endpoint + '/' + obj);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response;
}
