import ActionTypes from "./actionTypes";

export function signalRIdReceived(signalRId) {
  return {
    type: ActionTypes.SIGNALR_ID_RECEIVED,
    payload: signalRId,
  };
}


export function signalRSetupFailed(error) {
  return {
    type: ActionTypes.SIGNALR_SETUP_FAILED,
    payload: error,
  };
}
