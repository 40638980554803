import {
  Box,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { Field, FieldProps } from 'formik';
import {
  GreenLightValue,
  GreenLightDefinitionType,
  LightColor,
  Terminal,
} from 'modules/global/adapters/crpAPI';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { LightColor as Color } from 'theme/constants';
import useStyles from './styles';

type Props = {
  terminals: Terminal[] | undefined;
  greenLightValues: GreenLightValue[] | undefined;
  terminalNxtEntityIdError: string | undefined;
  isLoadingError: boolean;
  isFetching: boolean;
};

const TerminalSearchStep = ({
  terminals,
  greenLightValues,
  terminalNxtEntityIdError,
  isLoadingError,
  isFetching,
}: Props) => {
  const { t } = useTranslation(['glossary', 'useCase']);
  const classes = useStyles();

  const terminalNxtEntityIdHasError = Boolean(terminalNxtEntityIdError);

  React.useEffect(() => {
    isLoadingError && store.dispatch(errorMessage(t('messages.errorGetAssignPickUpFilterData')));
  }, [isLoadingError, t]);

  const values = greenLightValues === undefined ? [] : greenLightValues;

  const terminalDischargeLights = values
    .filter(
      (gv) =>
        gv.greenLightName === GreenLightDefinitionType.TerminalOperation &&
        gv.value?.toLowerCase() !== 'unknown'
    )
    .sort((a, b) =>
      a.color!.toLowerCase() < b.color!.toLowerCase()
        ? -1
        : b.color!.toLowerCase() > a.color!.toLowerCase()
        ? 1
        : 0
    );
  const terminalReleaseLights = values
    .filter(
      (gv) =>
        gv.greenLightName === GreenLightDefinitionType.TerminalReady &&
        gv.value?.toLowerCase() !== 'unknown'
    )
    .sort((a, b) =>
      a.color!.toLowerCase() < b.color!.toLowerCase()
        ? -1
        : b.color!.toLowerCase() > a.color!.toLowerCase()
        ? 1
        : 0
    );
  const customLights = values
    .filter((gv) => gv.greenLightName === GreenLightDefinitionType.Customs)
    .sort((a, b) =>
      a.color!.toLowerCase() < b.color!.toLowerCase()
        ? -1
        : b.color!.toLowerCase() > a.color!.toLowerCase()
        ? 1
        : 0
    );

  const showIcon = (color: LightColor | undefined) => {
    switch (color) {
      case Color.Grey:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
      case Color.Yellow:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#dfc920'} />;
      case Color.Orange:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#f57b00'} />;
      case Color.Red:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#e60000'} />;
      case Color.Green:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#4AB55F'} />;

      case undefined:
      default:
        return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
    }
  };

  return (
    <React.Fragment>
      <Box marginTop={2}>
        <Field name="terminalNxtEntityId" id="terminalNxtEntityId">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <InputLabel id="terminalName">{t('terminalName')}</InputLabel>
              <FormikSelect
                defaultValue={undefined}
                form={form}
                field={field}
                error={terminalNxtEntityIdHasError}
              >
                {terminals &&
                  terminals.map((terminal: Terminal, index: number) => (
                    <MenuItem value={terminal.nxtEntityId} key={`terminal_${index}`}>
                      {terminal.name + '( ' + terminal.nxtEntityId + ' )'}
                    </MenuItem>
                  ))}
              </FormikSelect>
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>{terminalNxtEntityIdError}</FormHelperText>
      </Box>
      {values.length > 0 && (
        <>
          <Box marginTop={2}>
            <Field name="terminalOperationValue" id="terminalOperation">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <InputLabel id="terminalOperation">{t('terminalOperationValue')}</InputLabel>
                  <FormikSelect defaultValue={undefined} form={form} field={field} error={false}>
                    {terminalDischargeLights.map((lightValue: GreenLightValue) => (
                      <MenuItem value={lightValue.value} key={lightValue.value}>
                        <div className={classes.greenLightValueIcon}>
                          <ListItemIcon>{showIcon(lightValue.color)}</ListItemIcon>
                          <ListItemText>{lightValue.value}</ListItemText>
                        </div>
                      </MenuItem>
                    ))}
                    <MenuItem value={undefined}>{<i>{t('showAll')}</i>}</MenuItem>
                  </FormikSelect>
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box marginTop={2}>
            <Field name="terminalReadyValue" id="terminalReady">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <InputLabel id="terminalReady">{t('terminalReadyValue')}</InputLabel>
                  <FormikSelect defaultValue={undefined} form={form} field={field} error={false}>
                    {terminalReleaseLights.map((lightValue: GreenLightValue) => (
                      <MenuItem value={lightValue.value} key={lightValue.value}>
                        <div className={classes.greenLightValueIcon}>
                          <ListItemIcon>{showIcon(lightValue.color)}</ListItemIcon>
                          <ListItemText>{lightValue.value}</ListItemText>
                        </div>
                      </MenuItem>
                    ))}
                    <MenuItem value={undefined}>{<i>{t('showAll')}</i>}</MenuItem>
                  </FormikSelect>
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box marginTop={2}>
            <Field name="customsValue" id="customsLight">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <InputLabel id="customsLight">{t('customsLight')}</InputLabel>
                  <FormikSelect defaultValue={undefined} form={form} field={field} error={false}>
                    {customLights.map((lightValue: GreenLightValue) => (
                      <MenuItem value={lightValue.value} key={lightValue.value}>
                        <div className={classes.greenLightValueIcon}>
                          <ListItemIcon>{showIcon(lightValue.color)}</ListItemIcon>
                          <ListItemText>{lightValue.value}</ListItemText>
                        </div>
                      </MenuItem>
                    ))}
                    <MenuItem value={undefined}>{<i>{t('showAll')}</i>}</MenuItem>
                  </FormikSelect>
                </React.Fragment>
              )}
            </Field>
          </Box>
        </>
      )}
    </React.Fragment>
  );
};
export default TerminalSearchStep;
