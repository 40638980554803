import * as Yup from 'yup';

const policeCaseSchema = Yup.object().shape({
  number: Yup.string()
    .required('Case number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),

  referenceNumber: Yup.string()
    .required('Reference number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),
});

export default policeCaseSchema;
