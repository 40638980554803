import * as Yup from 'yup';

const addReasonSchema = Yup.object().shape({
  reason: Yup.string()
    .notRequired()
    .min(3)
    .max(254)
    .nullable()
    .transform((x) => (!!x ? x : null)),
});

export default addReasonSchema;
