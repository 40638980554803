import ActionTypes from './actionTypes';

export function loadPendingReleaseCountSucceeded(items) {
    return {
        type: ActionTypes.PENDINGRELEASECOUNT_SUCCEEDED,
        payload: items
    }
}

export function updatePendingReleaseCount(pendingReleaseCount) {
    return {
        type: ActionTypes.UPDATE_PENDINGRELEASECOUNT,
        payload: pendingReleaseCount
    }
}