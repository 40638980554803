import * as React from 'react';
import history from '../../../history/browserHistory';
import { ROUTE_PATHS } from '../../shipping-line/constants';

const useBetaHook = () => {
  const [isBetaEnabled, setIsBetaEnabled] = React.useState<boolean>(
    localStorage.getItem('isBetaEnabled')?.toString() === 'true' || false
  );

  React.useEffect(() => {
    if (localStorage.getItem('isBetaEnabled') === null) {
      localStorage.setItem('isBetaEnabled', 'true');
    }
  });

  const toggleBeta = (betaOverride: boolean | null = null) => {
    setIsBetaEnabled(betaOverride ?? !isBetaEnabled);
    localStorage.setItem('isBetaEnabled', (betaOverride ?? !isBetaEnabled)?.toString());

    history.push(ROUTE_PATHS.BASE_ROUTE);
  };

  const isBetaKeyAvailable = Boolean(localStorage.getItem('isBetaEnabled'));
  const areBetaScreensEnabled =
    localStorage.getItem('isBetaEnabled')?.toString() === 'true' || false;

  return { isBetaEnabled, toggleBeta, areBetaScreensEnabled, isBetaKeyAvailable };
};

export default useBetaHook;
