import ActionTypes from './actionTypes';

export function checkAlfapassNumber(alfapassIdentification) {
  return {
    type: ActionTypes.CHECK_ALFAPASS_NUMBER,
    payload: alfapassIdentification,
  };
}

export function alfapassNumberChecked(status) {
  return {
    type: ActionTypes.ALFAPASS_NUMBER_CHECKED,
    payload: status,
  };
}

export function setAlfapassNumber(payload) {
  return {
    type: ActionTypes.SET_ALFAPASS_NUMBER,
    payload: payload,
  };
}

export function setOldAlfapassNumber(payload) {
  return {
    type: ActionTypes.SET_OLD_ALFAPASS_NUMBER,
    payload: payload,
  };
}

export function resetAlfapassNumber(payload) {
  return {
    type: ActionTypes.RESET_ALFAPASS_NUMBER,
    payload: payload,
  };
}

export function resetAlfapassNumberStatus(payload) {
  return {
    type: ActionTypes.RESET_ALFAPASS_NUMBER_STATUS,
    payload: payload,
  };
}

export function pickupContainer(payload) {
  return {
    type: ActionTypes.ALFAPASS_CONTAINER_PICKUP,
    payload: payload,
  };
}

export function pickupContainerSucceeded(payload) {
  return {
    type: ActionTypes.CONTAINERS_PICKUP_SUCCEEDED,
    payload: payload,
  };
}

export function revokeContainerPickups(containers) {
  return {
    type: ActionTypes.CONTAINERS_PICKUP_REVOKE,
    payload: { containers: containers },
  };
}

export function pickupContainerRevokeSucceeded(payload) {
  return {
    type: ActionTypes.CONTAINERS_PICKUP_REVOKE_SUCCEEDED,
    payload: payload,
  };
}

export function revokePickupContainer(payload) {
  return {
    type: ActionTypes.CONTAINERS_PICKUP_REVOKE,
    payload: payload,
  };
}

export function getAlfapassToken(payload) {
  return {
    type: ActionTypes.GET_ALFAPASS_TOKEN,
    payload: payload,
  };
}

export function getAlfapassTokenSucceeded() {
  return {
    type: ActionTypes.GET_ALFAPASS_TOKEN_SUCCESS,
  };
}
