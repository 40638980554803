import ActionTypes from '../actions/actionTypes'
import InitialState from './initialState'

export default function (state = InitialState.user, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_SELECTED_USER_TYPE: {
      return {
        ...state,
        selectedType: action.payload,
      }
    }
    case ActionTypes.USER_PROFILE_REQUEST_SUCCEEDED: {
      return {
        ...state,
        userProfile: action.payload,
      }
    }
    case ActionTypes.CREDIT_IDENTIFICATION_REQUESTED_SUCCEEDED: {
      return {
        ...state,
        loadingCreditIdentification: false,
        creditIdentificationResponse: action.payload,
        remainingCredits:
          action.payload.creditIdentification != null
            ? action.payload.creditIdentification.creditsAmount
            : 0,
      }
    }
    case ActionTypes.REGISTER_PAYMENT_OPTION_SUCCEEDED: {
      return {
        ...state,
        hasFullSubscription: true
      }
    }
    case ActionTypes.PAIR_CREDIT_IDENTIFICATION_SUCCEEDED: {
      return {
        ...state,
        isPairedWithCinvio: true
      }
    }
    case ActionTypes.CHANGE_CREDIT_AMOUNT: {
      return {
        ...state,
        remainingCredits: action.payload.remainingCredits,
      }
    }
    case ActionTypes.TOKEN_EXPIRED: {
      return {
        ...state,
        tokenExpired: action.payload
      }
    }
    case ActionTypes.TOKEN_EXPIRING: {
      return {
        ...state,
        tokenExpiring: action.payload
      }
    }
    default: {
      return state
    }
  }
}
