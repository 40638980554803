import { LABEL } from '../theme/constants';
import {
  FORWARDER_ROLE_ID,
  TRANSPORTER_ROLE_ID,
  TERMINAL_ROLE_ID,
  POLICE_ROLE_ID,
  SHIPPING_ROLE_ID,
} from '../config/environment';

export function getStatus(selectedRole) {
  switch (selectedRole) {
    case LABEL.FORWARDER:
      return 'active';
    case LABEL.POLICE:
      return 'police';
    case LABEL.TERMINAL:
      return 'terminal';
    case LABEL.SHIPPING:
      return 'shipping';
    case LABEL.TRANSPORTOPERATOR_LABEL:
      return 'active';
    default:
      throw Error('unknown user profile');
  }
}

export function validRoles(roles) {
  // SHIPPING_ROLE_ID is not yet included because requests as shipping agent will result in bad request response
  return roles.filter((r) =>
    [
      parseInt(SHIPPING_ROLE_ID),
      parseInt(FORWARDER_ROLE_ID),
      parseInt(TRANSPORTER_ROLE_ID),
      parseInt(TERMINAL_ROLE_ID),
      parseInt(POLICE_ROLE_ID),
    ].includes(r.id)
  );
}

export function userIdToLabel(id) {
  switch (id) {
    case parseInt(TRANSPORTER_ROLE_ID):
      return LABEL.TRANSPORTOPERATOR_LABEL;
    case parseInt(FORWARDER_ROLE_ID):
      return LABEL.FORWARDER;
    case parseInt(TERMINAL_ROLE_ID):
      return LABEL.TERMINAL;
    case parseInt(SHIPPING_ROLE_ID):
      return LABEL.SHIPPING;
    case parseInt(POLICE_ROLE_ID):
      return LABEL.POLICE;
    default:
      return 'ERROR';
  }
}
