import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAlfapassToken } from '../../store/actions/alfapassActions';
import { Button } from '@material-ui/core';
import * as random from 'randomstring';
import { encode as base64encode } from 'base64-arraybuffer';
import queryString from 'query-string';
import {
  ALFAPASS_AUTHORIZE_ENDPOINT,
  ALFAPASS_CALLBACK_ENDPOINT,
} from '../../config/environment.js';

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const Alfapass = (props) => {
  const { authenticateWithAlfapass, buttonText, buttonColor, buttonVariant, clientId } = props;
  const [challenge, setChallenge] = React.useState(null);
  const [btnText, setBtnText] = React.useState(buttonText);
  const [btnColor, setBtnColor] = React.useState(buttonColor);
  const [btnVariant, setBtnVariant] = React.useState(buttonVariant);

  const authorizeEndpoint = ALFAPASS_AUTHORIZE_ENDPOINT;

  const setQueryParameters = (query) => {
    let parameters = '';
    if (query) {
      parameters = queryString.stringify(query);
    }
    return parameters && '?' + parameters;
  };

  const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = base64encode(digest);
    // you can extract this replacing code to a function
    const codeChallenge = base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    setChallenge(codeChallenge);
  };

  useEffect(() => {
    if (btnText == null) setBtnText('Click Me');
    if (btnVariant == null) setBtnVariant('outlined');
    if (btnColor == null) setBtnColor('primary');

    if (challenge === null) {
      const codeVerifier = random.generate(64);
      localStorage.removeItem('codeVerifier');
      localStorage.setItem('codeVerifier', codeVerifier);
      generateCodeChallenge(codeVerifier);
    }
  });

  const redirectToAlfapass = async (e) => {
    e.stopPropagation();
    const redirectTo = `${authorizeEndpoint}${setQueryParameters({
      client_id: clientId,
      response_type: 'code',
      scope: 'openid profile email',
      redirect_uri: `${window.location.origin}/${ALFAPASS_CALLBACK_ENDPOINT}`,
      code_challenge_method: 'S256',
      code_challenge: challenge,
      state: 'nxtport',
    })}`;
    window.location.href = redirectTo;
  };

  return (
    <>
      <div>
        <Button color={btnColor} variant={btnVariant} onClick={(e) => redirectToAlfapass(e)}>
          {btnText}
        </Button>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      authenticateWithAlfapass: getAlfapassToken,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Alfapass);
