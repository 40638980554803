import { Button, List, ListItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Clear as DeleteForeverIcon } from '@material-ui/icons';
import 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadReleasesFilters } from '../../store/actions/releaseActions';
import { LABEL } from '../../theme/constants';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  tabsRoot: {
    width: '100%',
  },
  tab: {
    minWidth: 'initial',
    '& span': {
      padding: 0,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  filter: {
    flex: 1,
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  inputLabel: {
    backgroundColor: 'white',
    padding: '8px',
    marginTop: '-8px'
  },
  element: {
    marginTop: '10px'
  },
  horizontalDivider: {
    width: theme.spacing(20)
  },
  filterButton: {
    marginRight: theme.spacing(1)
  }
});

class ReleasesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      errors: {},
    };
  }

  onChange = key => (event) => {
    const filters = { ...this.state.filters, [key]: event.target.value };
    this.setState({ filters });
  };

  onFilter = () => {
    this.props.loadReleasesFilters(this.state.filters);
  };

  onFilterClear = () => {
    const filters = this.state.filters;
    for (let key in filters) {
      filters[key] = '';
    }
    this.setState(filters);
    this.props.loadReleasesFilters(this.state.filters);
  };

  render() {
    const { classes } = this.props;
    const { filters, errors } = this.state;

    return (
      <List>
        <ListItem>
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.BILL_OF_LADING}
            value={filters.billOfLading}
            onChange={this.onChange('billOfLading')}
            error={errors.billOfLading}
            fullWidth
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.EQUIPMENT_NUMBER}
            value={filters.equipmentNumber}
            onChange={this.onChange('equipmentNumber')}
            error={errors.equipmentNumber}
            fullWidth
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.SHIPPING_AGENT}
            value={filters.shippingAgent}
            onChange={this.onChange('shippingAgent')}
            error={errors.shippingAgent}
            fullWidth
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.RECEIVED_FROM}
            value={filters.receivedFrom}
            onChange={this.onChange('receivedFrom')}
            error={errors.receivedFrom}
            fullWidth
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            label={LABEL.TERMINAL}
            value={filters.terminal}
            onChange={this.onChange('terminal')}
            error={errors.terminal}
            fullWidth
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <Button
            style={{ padding: '14px 12px' }}
            size="large"
            variant="contained"
            color="primary"
            onClick={this.onFilter}
            fullWidth={true}
            className={classes.filterButton}
          >
            Filter
            <SearchIcon className={classes.rightIcon} />
          </Button>
          <Button
            style={{ padding: '14px 0px' }}
            variant="contained"
            color="primary"
            onClick={this.onFilterClear}
          >
            <DeleteForeverIcon />
          </Button>
        </ListItem>
      </List>
    );
  }
}

ReleasesFilter.propTypes = {
  loadReleasesFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filters: state.releases.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadReleasesFilters,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReleasesFilter));
