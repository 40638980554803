import {
  Box,
  InputLabel,
  MenuItem,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  Button,
} from '@material-ui/core';
import { Field, FieldProps, FormikErrors, useFormikContext } from 'formik';
import { Terminal } from 'modules/global/adapters/crpAPI';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import useStyles from '../shared/styles';
import { IUploadReleaseRightsFileRequestUi } from 'modules/global/adapters/crpAPI';
import DatePicker from 'modules/global/components/DatePicker/DatePicker';
import Cancel from '@material-ui/icons/Cancel';

type Props = {
  terminals: Terminal[] | undefined;
  isLoadingError: boolean;
  isFetching: boolean;
  values: IUploadReleaseRightsFileRequestUi;
  errors: FormikErrors<IUploadReleaseRightsFileRequestUi>;
};

const UploadCsvFormStep = ({ terminals, isLoadingError, isFetching, values, errors }: Props) => {
  const { t } = useTranslation(['uploadcsv']);
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const terminalNxtEntityIdError = Boolean(errors.terminalNxtEntityId);
  const releaseDateTimUtcError = Boolean(errors.releaseDateTimeUtc);
  const expireDateTimeUtcError = Boolean(errors.expirationDateTimeUtc);
  const vesselNameError = Boolean(errors.vesselName);
  const voyageNumberError = Boolean(errors.voyageNumber);
  const stayNumberError = Boolean(errors.stayNumber);
  const imoNumberError = Boolean(errors.imoNumber);

  const { validateForm, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    isLoadingError &&
      store.dispatch(errorMessage(t('messages.errorGetAcceptReleaseRightFilterData')));
  }, [isLoadingError, t]);

  var minReleaseDate = new Date();
  minReleaseDate.setDate(minReleaseDate.getDate() - 1);
  const minReleaseDateIso = minReleaseDate.toISOString();
  var minExpireDate = new Date();
  minExpireDate.setDate(minExpireDate.getDate() + 5);
  const minExpireDateIso = minExpireDate.toISOString();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event != null &&
      event.target != null &&
      event.target.files != null &&
      event.target.files[0] != null
    ) {
      setSelectedFile(event.target.files[0]);
      setFieldValue('file', event.target.files[0]!);
    }
  };

  const cancelUpload = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setSelectedFile(null);
    values.file = null;
    validateForm(values);
  };

  return (
    <React.Fragment>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={4}>
            <Field name="terminalNxtEntityId" id="terminalNxtEntityId">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <InputLabel id="terminalName">
                    {t('uploadcsv:formStep.labelSelectTerminal')}
                  </InputLabel>
                  <FormikSelect
                    defaultValue={undefined}
                    form={form}
                    field={field}
                    error={terminalNxtEntityIdError}
                  >
                    {terminals &&
                      terminals.map((terminal: Terminal, index: number) => (
                        <MenuItem
                          value={`${terminal.nxtEntityId}|${terminal.code}`}
                          key={`terminal_${index}`}
                        >
                          {`${terminal.name} - ${terminal.code}`}
                        </MenuItem>
                      ))}
                  </FormikSelect>
                </React.Fragment>
              )}
            </Field>
            {errors.terminalNxtEntityId ? (
              <div style={{ color: 'darkred' }}>{errors.terminalNxtEntityId}</div>
            ) : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field
              name="releaseDateTimeUtc"
              id="releaseDateTimeUtc"
              component={DatePicker}
              minDate={minReleaseDateIso}
              label={t('uploadcsv:formStep.labelReleaseDateTimeUtc')}
              error={releaseDateTimUtcError}
            ></Field>
            {errors.releaseDateTimeUtc ? (
              <div style={{ color: 'darkred' }}>{errors.releaseDateTimeUtc}</div>
            ) : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field
              name="expirationDateTimeUtc"
              id="expirationDateTimeUtc"
              component={DatePicker}
              minDate={minExpireDateIso}
              label={t('uploadcsv:formStep.labelExpirationDateTimeUtc')}
              error={expireDateTimeUtcError}
            ></Field>
            {errors.expirationDateTimeUtc ? (
              <div style={{ color: 'darkred' }}>{errors.expirationDateTimeUtc}</div>
            ) : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="vesselName" id="vesselname">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('uploadcsv:formStep.labelVesselName')}
                    fullWidth
                    error={vesselNameError}
                  />
                </React.Fragment>
              )}
            </Field>
            {errors.vesselName ? <div style={{ color: 'darkred' }}>{errors.vesselName}</div> : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="voyageNumber" id="voyageNumber">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('uploadcsv:formStep.labelVoyageNumber')}
                    fullWidth
                    error={voyageNumberError}
                  />
                </React.Fragment>
              )}
            </Field>
            {errors.voyageNumber ? (
              <div style={{ color: 'darkred' }}>{errors.voyageNumber}</div>
            ) : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="imoNumber" id="imoNumber">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('uploadcsv:formStep.labelImoNumber')}
                    fullWidth
                    error={imoNumberError}
                  />
                </React.Fragment>
              )}
            </Field>
            {errors.imoNumber ? <div style={{ color: 'darkred' }}>{errors.imoNumber}</div> : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="stayNumber" id="stayNumber">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('uploadcsv:formStep.labelStayNumber')}
                    fullWidth
                    error={stayNumberError}
                  />
                </React.Fragment>
              )}
            </Field>
            {errors.stayNumber ? <div style={{ color: 'darkred' }}>{errors.stayNumber}</div> : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 0 }}>
            <Field name="fullEmptyIndicator" id="fullEmptyIndicator">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <InputLabel id="forLabelFullEmptyIndicator">
                    {t('uploadcsv:formStep.labelFullEmptyIndicator')}
                  </InputLabel>
                  <Select
                    {...field}
                    style={{ minWidth: 150 }}
                    labelId="forLabelFullEmptyIndicator"
                    defaultValue={t('uploadcsv:formStep.labelFullEmptyIndicatorFull')}
                    variant="outlined"
                  >
                    <MenuItem value={t('uploadcsv:formStep.labelFullEmptyIndicatorFull')}>
                      {t('uploadcsv:formStep.labelFullEmptyIndicatorFull')}
                    </MenuItem>
                    <MenuItem value={t('uploadcsv:formStep.labelFullEmptyIndicatorEmpty')}>
                      {t('uploadcsv:formStep.labelFullEmptyIndicatorEmpty')}
                    </MenuItem>
                    <MenuItem value={t('uploadcsv:formStep.labelFullEmptyIndicatorUnknown')}>
                      {t('uploadcsv:formStep.labelFullEmptyIndicatorUnknown')}
                    </MenuItem>
                  </Select>
                </React.Fragment>
              )}
            </Field>
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            {!isFetching && (selectedFile == null || selectedFile.name == null) && (
              <>
                <Button variant="outlined" component="label">
                  {t('uploadcsv:formStep.labelButtonUploadFile')}
                  <Field
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    className="form-control-file"
                  />
                </Button>
              </>
            )}
            {!isFetching && selectedFile != null && selectedFile.name != null && (
              <Grid container spacing={0} alignItems="flex-end">
                <Grid item style={{ padding: 10, color: 'red', cursor: 'pointer' }}>
                  <Cancel onClick={(e) => cancelUpload(e)} />
                </Grid>
                <Grid item>
                  <TextField
                    label={t('uploadcsv:formStep.labelSelectedFile')}
                    id="idForSelectedFileName"
                    variant="outlined"
                    value={selectedFile.name}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            )}
            {errors.file ? (
              <div style={{ color: 'darkred', paddingLeft: 5 }}>{errors.file}</div>
            ) : null}
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="isCarrierHaulage" id="isCarrierHaulage">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        name="isCarrierHaulage"
                        color="primary"
                        checked={values.isCarrierHaulage}
                      />
                    }
                    label={t('uploadcsv:formStep.labelIsCarrieHaulage')}
                  />
                </React.Fragment>
              )}
            </Field>
          </Grid>
          <Grid item xs={8} sm={4} style={{ marginTop: 16 }}>
            <Field name="isTranshipment" id="isTranshipment">
              {({ field, form }: FieldProps) => (
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        name="isTranshipment"
                        color="primary"
                        checked={values.isTranshipment}
                      />
                    }
                    label={t('uploadcsv:formStep.labelIsTranshipment')}
                  />
                </React.Fragment>
              )}
            </Field>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default UploadCsvFormStep;
