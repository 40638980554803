import { withStyles } from '@material-ui/core/styles';
import { Typography, Card, CardActions, CardContent, Button, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUserProfile } from '../../store/actions/userProfileActions';
import { getUserProfile, getIsPairedWithCinvio } from '../../store/selectors/selectors';
import { pairPaymentOption } from '../../store/actions/userProfileActions';
import { EXTERNALPRODUCTPAGE } from '../../config/environment';
import history from '../../history/browserHistory';
import { FORMATTING, LABEL, TEXT } from '../../theme/constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    marginTop: '10%',
  },
  rootForbidden: {
    position: 'fixed' /* or absolute */,
    top: '10%',
    left: '45%',
    fontSize: '20px',
  },
  buttonConfirm: {
    margin: 'auto',
    backgroundColor: '#009dfe',
  },
  titleConfirm: {
    textAlign: 'center',
  },
});

const PairedRedirect = (props) => {
  const { classes, userProfile, pairPaymentOption, isPairedWithCinvio } = props;

  const handlePairButtonClick = (e) => {
    e.stopPropagation();
    pairPaymentOption();
  };

  useEffect(() => {
    if (isPairedWithCinvio == true) {
      window.open(EXTERNALPRODUCTPAGE, '_blank');
      history.push('/');
    }
  }, [isPairedWithCinvio]);

  const nxtEntityId = props.match.params.id;

  let canAccess = userProfile.nxtEntityId === nxtEntityId;

  if (!canAccess) {
    return (
      <React.Fragment>
        <div className={classes.rootForbidden}>Forbidden</div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container spacing={0} justify="center" direction="column" alignItems="center">
          <Grid md={4} item>
            <Card className={classes.cardBlock}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.titleConfirm}
                >
                  {LABEL.PAIR_CINVIO_TITLE}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {TEXT.PAIR_PAGE.replace(
                    FORMATTING.USERPROFILE_FULLNAME,
                    userProfile.firstName + ' ' + userProfile.lastName
                  )}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  className={classes.buttonConfirm}
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlePairButtonClick(e)}
                >
                  {LABEL.CONFIRM}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

PairedRedirect.propTypes = {
  classes: PropTypes.object.isRequired,
  userProfile: PropTypes.object,
  selectedUserType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    isPairedWithCinvio: getIsPairedWithCinvio(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadUserProfile,
      pairPaymentOption,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PairedRedirect));
