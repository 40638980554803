import { Action } from 'history';
import { mapEquipmentId, mapPage } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.policeContainers, action) {
  switch (action.type) {
    case ActionTypes.POLICE_CONTAINERS_REQUESTED: {
      return {
        ...state,
        filters: action.payload.filters !== undefined ? action.payload.filters : state.filters,
        policeCase:
          action.payload.policeCase !== undefined ? action.payload.policeCase : state.policeCase,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
        refreshList: false,
      };
    }
    case ActionTypes.POLICE_CONTAINERS_CLEAR: {
      return {
        ...state,
        filters: {
          equipmentNumber: '',
          billOfLading: '',
          shippingAgent: '',
          terminal: '',
          isArchived: '',
          isBlocked: '',
          company: '',
          dateStart: '',
          dateEnd: '',
        },
        policeCase: {
          number: '',
          referenceNumber: '',
        },
        loading: false,
        items: [],
        paging: {
          page: 0,
          count: 0,
          rowsPerPage: 25,
        },
      };
    }
    case ActionTypes.POLICE_CONTAINERS_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        items: mapEquipmentId(action.payload.rows),
        paging: mapPage(action.payload),
      };
    }
    case ActionTypes.POLICE_AUDIT_REQUESTED: {
      return {
        ...state,
      };
    }
    case ActionTypes.POLICE_AUDIT_REQUEST_SUCCEEDED: {
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: false,
          audit: {
            items: action.payload,
          },
        },
      };
    }
    case ActionTypes.SET_POLICE_CASE: {
      return {
        ...state,
        policeCase: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_EQUIPMENT: {
      return {
        ...state,
        selected: {
          equipmentNumber: action.payload,
        },
      };
    }
    case ActionTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCEEDED: {
      return {
        ...state,
        companySubscriptions: {
          items: action.payload,
        },
      };
    }
    case ActionTypes.GET_SCR_EVENTS_SUCCEEDED: {
      return {
        ...state,
        scrEvents: {
          items: action.payload,
        },
      };
    }
    case ActionTypes.DELETE_COMPANY_SUBSCRIPTION_SUCCEEDED: {
      return {
        ...state,
        companySubscriptions: {
          items: state.companySubscriptions.items.filter((x) => x.externalId != action.payload),
        },
      };
    }
    case ActionTypes.CREATE_COMPANY_SUBSCRIPTION: {
      return {
        ...state,
        companySubscriptions: {
          isCreated: false,
        },
      };
    }
    case ActionTypes.CREATE_COMPANY_SUBSCRIPTION_SUCCEEDED: {
      return {
        ...state,
        companySubscriptions: {
          ...state.companySubscriptions,
          isCreated: true,
        },
      };
    }

    default: {
      return state;
    }
  }
}
