import { ExtendedContainer } from 'modules/global/adapters/crpAPI';
import { ContentFields } from 'modules/global/components/Drawer/Content';
import { GreenLightContentFields } from 'modules/global/components/Drawer/GreenLightContent';
import i18n from '../../../global/config/i18n';

export const containerNumberfield: ContentFields<ExtendedContainer> = [
  {
    accessor: 'equipmentNumber',
    label: () => i18n.t('glossary:containerNumber'),
  },
];

export const fields: ContentFields<ExtendedContainer> = [
  {
    accessor: 'releaseDateTimeUtc',
    label: () => i18n.t('glossary:releaseValidFrom'),
  },
  {
    accessor: 'expirationDateTimeUtc',
    label: () => i18n.t('glossary:releaseValidUntil'),
  },
  {
    accessor: 'terminalName',
    label: () => i18n.t('glossary:terminalName'),
  },
  {
    accessor: 'terminalCode',
    label: () => i18n.t('glossary:terminalCode'),
  },
  {
    accessor: 'fullEmpty',
    label: () => i18n.t('glossary:fullEmpty'),
    formatter: (value) => (Boolean(value) ? String(value) : i18n.t('glossary:unknown')),
  },
  {
    accessor: 'releaseRightStatus',
    label: () => i18n.t('glossary:releaseRightStatus'),
  },
  {
    accessor: 'pickUpRightStatus',
    label: () => i18n.t('glossary:pickUpRightStatus'),
  },
  {
    accessor: 'releaseToName',
    label: () => i18n.t('glossary:releaseToName'),
  },
];

export const greenLightFields: GreenLightContentFields<
  Pick<
    ExtendedContainer,
    | 'commercialReleaseLight'
    | 'terminalOperation'
    | 'terminalReady'
    | 'customsLight'
    | 'pickUpLight'
    | 'gateOperation'
  >
> = [
  {
    accessor: 'commercialReleaseLight',
    label: () => i18n.t('glossary:commercialRelease'),
  },
  {
    accessor: 'terminalOperation',
    label: () => i18n.t('glossary:terminalOperation'),
  },
  {
    accessor: 'terminalReady',
    label: () => i18n.t('glossary:terminalReady'),
  },
  {
    accessor: 'customsLight',
    label: () => i18n.t('glossary:customsLight'),
  },
  {
    accessor: 'pickUpLight',
    label: () => i18n.t('glossary:pickUpLight'),
  },
  {
    accessor: 'gateOperation',
    label: () => i18n.t('glossary:gateOperation'),
  },
];
