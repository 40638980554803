import { FormControlLabel, Switch } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { USE_BETA_SCREENS } from '../../../../config/environment';
import { useBetaHook } from '../../hooks';

type Props = {
  onToggle: any;
};

const BetaSwitch = ({ onToggle }: Props) => {
  const { t } = useTranslation('common');
  const { isBetaEnabled, toggleBeta } = useBetaHook();

  const handleToggle = () => {
    toggleBeta();
    onToggle?.();
  };

  const isBetaScreensFeatureEnabled = USE_BETA_SCREENS?.toLowerCase() === 'true';

  return isBetaScreensFeatureEnabled ? (
    <React.Fragment>
      <FormControlLabel
        control={
          <Switch
            checked={isBetaEnabled}
            onChange={handleToggle}
            name="isBetaEnabled"
            color="secondary"
          />
        }
        label={t('common:beta.enableBeta')}
      />
    </React.Fragment>
  ) : null;
};

export default BetaSwitch;
