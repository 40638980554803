import * as Yup from 'yup';

const createSubscriptionSchema = Yup.object().shape({
  equipmentNumber: Yup.string()
    .required('Equipment Number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),

  billOfLadingNumber: Yup.string()
    .required('Bill Of Lading Number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),
});

export default createSubscriptionSchema;
