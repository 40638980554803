import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LABEL } from '../../theme/constants';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const initialState = {
    identificationCode: "",
    identificationType: LABEL.IDENTIFICATIONTYPE_TIN,
    hasError: false
}

function ToUnknownDialog(props) {
    const classes = useStyles();
    const [identificationState, setIdentificationType] = React.useState(initialState);
    const {
        runCallback,
        handleClickOpen,
        handleClose,
        open,
        buttonTitle
    } = props;

    useEffect(() => {
        setIdentificationType({ ...identificationState, hasError: hasError() });
    }, [identificationState.identificationCode, identificationState.identificationType]);

    const hasError = () => {
        if (identificationState.identificationCode == null || identificationState.identificationCode.trim().length <= 3)
            return true;
        if (identificationState.identificationType == null || identificationState.identificationType.length <= 2)
            return true;
        return false
    }

    const handleButtonShowDialog = (event) => {
        event.stopPropagation();
        handleClickOpen()
    }

    const handleIdentificationTypeChange = (identificationType) => {
        setIdentificationType({ ...identificationState, identificationType: identificationType });
    };

    const handleIdentificationCodeChange = (code) => {
        setIdentificationType({ ...identificationState, identificationCode: code })
    }

    const handleTransfer = (event) => {
        event.stopPropagation();
        if (hasError())
            return;
        const company = {
            isUnknown: true,
            identificationType: identificationState.identificationType,
            identificationCode: identificationState.identificationCode
        }
        runCallback(company);
        handleClose();
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button variant="contained" color="primary" onClick={(e) => { handleButtonShowDialog(e) }}>
                {buttonTitle}
            </Button>
            <Dialog
                fullWidth={false}
                maxWidth={'md'}
                open={open}
                onClose={(e, reason) => { handleClose(); }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {LABEL.IDENTIFICATIONTYPE_TITLE_FIRST_RELEASE_PARTY_TRANSFER}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {LABEL.IDENTIFICATION_POSSIBLE_MEANS}
                    </DialogContentText>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="identification-type-label">{LABEL.IDENTIFICATIONTYPE}</InputLabel>
                        <Select
                            labelId="identification-type-label"
                            id="identification-type-label-select"
                            value={identificationState.identificationType}
                            onChange={(e) => handleIdentificationTypeChange(e.target.value)}
                            label={LABEL.IDENTIFICATIONTYPE}
                        >
                            <MenuItem value={LABEL.IDENTIFICATIONTYPE_TIN}>{LABEL.IDENTIFICATIONTYPE_TIN}</MenuItem>
                            <MenuItem value={LABEL.IDENTIFICATIONTYPE_DUNS}>{LABEL.IDENTIFICATIONTYPE_DUNS}</MenuItem>
                            <MenuItem value={LABEL.IDENTIFICATIONTYPE_SCAC}>{LABEL.IDENTIFICATIONTYPE_SCAC}</MenuItem>
                            <MenuItem value={LABEL.IDENTIFICATIONTYPE_EORI}>{LABEL.IDENTIFICATIONTYPE_EORI}</MenuItem>
                            <MenuItem value={LABEL.IDENTIFICATIONTYPE_APCS}>{LABEL.IDENTIFICATIONTYPE_APCS}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            style={{ minWidth: '250px' }}
                            id="outlined-identification-code"
                            label={LABEL.IDENTIFICATION_CODE}
                            helperText={LABEL.IDENTIFICATION_ENTER_CODE}
                            variant="outlined"
                            onChange={(e) => handleIdentificationCodeChange(e.target.value)}
                            autoFocus
                            maxLength={25}
                        />
                    </FormControl>
                    <br />
                    {identificationState.hasError && <span style={{ color: 'red' }}>
                        {LABEL.IDENTIFICATION_ERROR_MESSAGE}
                    </span>}
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button
                            onClick={e => {
                                e.stopPropagation();
                                handleClose()
                            }}
                            ariant="contained"
                            style={{ background: '#ebebeb', marginRight: '5px' }}
                        >
                            {LABEL.CANCEL}
                        </Button>
                        <Button
                            disabled={identificationState.hasError}
                            onClick={(e) => handleTransfer(e)}
                            color="primary" variant="contained"
                        >
                            {LABEL.TRANSFER}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div >
    );
}

function TransferToUnknownDialog(props) {
    const { buttonTitle, callback } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ToUnknownDialog
            runCallback={callback}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            buttonTitle={buttonTitle}
        />
    )
}

TransferToUnknownDialog.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    callback: PropTypes.func,
};

export default TransferToUnknownDialog;
