import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { loadReleasesSucceeded } from 'store/actions/releaseActions';
import { getReleases } from '../api/releasesApi';
import { getReleasesFilters, getReleasesPaging } from '../selectors/selectors';

function getReleasesQuery(filters, paging) {
  return {
    equipmentnumberfilter: filters.equipmentNumber || undefined,
    billofladingnumberfilter: filters.billOfLading || undefined,
    shippingagentnamefilter: filters.shippingAgent || undefined,
    currentstakeholdernamefilter: filters.receivedFrom || undefined,
    terminalnamefilter: filters.terminal || undefined,
    page: paging.page + 1 || undefined,
    pageSize: paging.rowsPerPage || undefined,
  };
}

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadAllReleases(action) {
  try {
    let paging = yield select(getReleasesPaging);
    let filters = yield select(getReleasesFilters);
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    if (action.payload.filters) {
      filters = action.payload.filters;
    }

    const releases = yield call(getReleases, {
      query: getReleasesQuery(filters, paging),
    });
    yield put(loadReleasesSucceeded(releases));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* releaseSaga() {
  yield takeEvery(ActionTypes.RELEASES_REQUESTED, loadAllReleases);
}

export default releaseSaga;
