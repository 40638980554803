import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import { GetContainersByTerminalParams, TerminalContainer } from 'modules/global/adapters/crpAPI';
import { DataGrid, Drawer } from 'modules/global/components';
import EmptyState from 'modules/global/components/EmptyState/EmptyState';
import { useLocalTableValues } from 'modules/global/hooks';
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { useGetContainersAssignPickUp } from '../adapters/assign-pickup/queries';
import { StepperAssignPickUp } from '../components/assign-pickup';
import { ContainerDetailDrawer } from '../components/shared';
import { transportOperatorColumns } from '../constants/shared/transportOperatorColumns';
import assignPickUpSchema from '../schemas/assignPickUpSchema';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: GetContainersByTerminalParams = {
  terminalNxtEntityId: '',
  terminalOperationValue: '',
  terminalReadyValue: '',
  customsValue: '',
};

const AssignPickUp: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['assignPickUp', 'common', 'validation']);
  const formRef = useRef<FormikProps<GetContainersByTerminalParams>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: TerminalContainer;
  }>({
    open: false,
    data: undefined,
  });

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<TerminalContainer>) | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<Row<TerminalContainer>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-assign-release-party',
    {}
  );

  const { data, isLoading, isFetching, isError } = useGetContainersAssignPickUp({
    terminalNxtEntityId: formRef?.current?.values.terminalNxtEntityId,
    terminalOperationValue: formRef?.current?.values.terminalOperationValue,
    terminalReadyValue: formRef?.current?.values.terminalReadyValue,
    customsValue: formRef?.current?.values.customsValue,
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: TerminalContainer) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          validationSchema={assignPickUpSchema}
          innerRef={formRef as React.Ref<FormikProps<GetContainersByTerminalParams>>}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            submitForm,
            resetForm,
            values,
          }: FormikProps<GetContainersByTerminalParams>) => {
            return (
              <StepperAssignPickUp
                errors={errors}
                selectedRows={selectedRows}
                setRowSelection={setRowSelection}
                setDisplayGrid={setDisplayGrid}
                data={data}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
                submitForm={submitForm}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={transportOperatorColumns}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onCellClick={handleCellClick}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default AssignPickUp;
