export const ROUTE_PATHS = {
  BASE_ROUTE: '/',
  CONTAINERS: '/containers',
  ACCEPT_RELEASE_RIGHT: '/accept-releaseright',
  DECLINE_RELEASE_RIGHT: '/decline-releaseright',
  TRANSFER_RELEASE_RIGHT: '/transfer-releaseright',
  REVOKE_RELEASE_RIGHT: '/revoke-releaseright',
  DISPLAY_NOTIFICATIONS: '/display-notifications',
  DISPLAY_BILLING_INFO: '/display-billing-info',
  SEARCH_CONTAINERS: '/search-containers',
};

export const maxNumberOfContainersProcessed = 1000;

export const BASE_FAQ_URL = 'https://documentation.nxtport.com/certified-pickup';
export const FAQ_PAGES = {
  REVOKE_RELEASE_RIGHT: `${BASE_FAQ_URL}/faq-ui-revoke-release-right`,
};
