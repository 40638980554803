import { Paper } from '@material-ui/core';
import { FormikErrors, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  IUploadReleaseRightsFileRequestUi,
  Terminal,
  ReleaseParty,
  PostResponse,
  ErrorResponse,
} from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import { useGetActiveTerminals } from 'modules/transport-operator/adapters/accept-releaseright/queries';
import { uploadReleaseRightsFileRequestUiRequest } from '../../adapters/upload-releaserights-csv/queries';

import { FAQ_PAGES } from 'modules/transport-operator/constants';
import React, { useEffect } from 'react';
import InputStep from './InputStep';
import ReleaseToStep from './ReleaseToStep';
import UploadCsvStep from './UploadCsvStep';
import ResultStep from './ResultStep';

type Props = {
  data?: Terminal[];
  errors: FormikErrors<IUploadReleaseRightsFileRequestUi>;
  isFetching?: boolean;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: IUploadReleaseRightsFileRequestUi;
  selectedUserType: any;
};

export const identificationType: string[] = ['NxtEntityId', 'Tin', 'Duns', 'SCAC', 'Eori', 'Apcs'];
export const defaultType = identificationType[0];

const StepperCreateCommercialReleaseUploadCsv = ({
  data,
  errors,
  isFetching,
  submitForm,
  resetForm,
  values,
  selectedUserType,
}: Props) => {
  const { t } = useTranslation(['common', 'uploadcsv']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hasEntered, setHasEntered] = React.useState(0);
  const [resultValue, setResultValue] = React.useState<PostResponse | ErrorResponse | any>(
    undefined
  );
  const [selectedReleaseParty, setSelectedReleaseParty] = React.useState<ReleaseParty | undefined>(
    undefined
  );
  const [selectedIdentification, setSelectedIdentification] = React.useState<
    | {
        type: string;
        value: string;
      }
    | undefined
  >({ type: defaultType || '', value: '' });

  const selectedNewRelease: any = selectedIdentification?.value
    ? selectedIdentification
    : selectedReleaseParty;

  const {
    data: terminalData,
    isFetching: isFetchingTerminals,
    isError: isErrorTerminals,
  } = useGetActiveTerminals();

  const checkDisableNext = (errors: FormikErrors<IUploadReleaseRightsFileRequestUi>): boolean => {
    if (hasEntered <= 1) {
      return true;
    }
    if (Object.values(errors).length === 0) {
      return false;
    }

    var hasError =
      errors.voyageNumber != null ||
      errors.terminalNxtEntityId != null ||
      errors.expirationDateTimeUtc != null ||
      errors.releaseDateTimeUtc != null ||
      errors.file != null ||
      errors.vesselName != null ||
      errors.voyageNumber != null ||
      errors.stayNumber != null ||
      errors.imoNumber != null;
    return hasError;
  };

  const { setFieldValue } = useFormikContext();
  const backOnReleaseTo = () => {
    setFieldValue('file', undefined);
    setFieldValue('isCarrierHaulage', values.isCarrierHaulage);
    setFieldValue('isTranshipment', values.isTranshipment);
  };

  async function createReleaseRights(
    uploadReleaseRightsFileRequestUi: IUploadReleaseRightsFileRequestUi,
    releaseParty: ReleaseParty | undefined
  ) {
    var result = await uploadReleaseRightsFileRequestUiRequest({
      uploadCsvParams: uploadReleaseRightsFileRequestUi,
      releaseTo: releaseParty,
    });
    setResultValue(result);
  }

  useEffect(() => {
    setHasEntered(hasEntered + 1);
  }, [errors]);

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.ACCEPT_RELEASERIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('uploadcsv:formStep.title'),
            stepFlavorText: t('uploadcsv:formStep.flavorText'),
            nextCallBack: undefined,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('uploadcsv:formStep.labelNext'),
            nextIsLoading: isFetching,
            disableNext: checkDisableNext(errors),
            noBackButton: true,
            noNextButton: false,
            content: (
              <InputStep
                values={values}
                terminals={terminalData}
                isLoadingError={isErrorTerminals}
                isFetching={isFetchingTerminals}
                errors={errors}
              />
            ),
          },
          {
            stepTitle: t('uploadcsv:selectReleaseToStep.title'),
            stepFlavorText: t('uploadcsv:selectReleaseToStep.flavorText'),
            backCallBack: backOnReleaseTo,
            content: (
              <ReleaseToStep
                setSelectedReleaseParty={setSelectedReleaseParty}
                selectedReleaseParty={selectedReleaseParty}
                selectedIdentification={selectedIdentification}
                setSelectedIdentification={setSelectedIdentification}
                selectedNewRelease={selectedNewRelease}
                selectedUserType={selectedUserType}
              />
            ),
          },
          {
            stepTitle: t('uploadcsv:uploadStep.title'),
            stepFlavorText: t('uploadcsv:uploadStep.flavorText'),
            nextCustomText: t('uploadcsv:uploadStep.labelButtonCreate'),
            nextCallBack: () => createReleaseRights(values, selectedReleaseParty),
            content: <UploadCsvStep values={values} selectedReleaseParty={selectedReleaseParty!} />,
          },
          {
            stepTitle: t('common:general.result'),
            noNextButton: true,
            content: <ResultStep values={values} resultValue={resultValue} />,
          },
        ]}
      />
    </Paper>
  );
};

export default StepperCreateCommercialReleaseUploadCsv;
