import { api } from 'modules/global/adapters';
import { BillingInformationRequest } from 'modules/global/adapters/crpAPI';
import React, { useEffect } from 'react';

function useDownloadFile() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [billingRequest, setBillingRequest] = React.useState(new BillingInformationRequest());
  const onDownload = () => setIsLoading(true);

  useEffect(() => {
    const download = async () => {
      try {
        const response = await api.billingInfo.downloadFile(billingRequest);
        if (response !== undefined) {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'billing.csv');
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode!.removeChild(link);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      download();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return {
    isLoading,
    setBillingRequest,
    onDownload,
  };
}

export default useDownloadFile;
