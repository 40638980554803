import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAlfapassToken } from 'store/actions/alfapassActions';
import history from '../../history/browserHistory';

const LoginCallbackForAlfapass = (props) => {
  const { getAlfapassToken, tokenRetrieved } = props;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const savedCodeVerifier = localStorage.getItem('codeVerifier') || '';

    if (!tokenRetrieved) {
      getAlfapassToken({
        code: code,
        codeVerifier: savedCodeVerifier,
      });
    } else {
      history.push(`${window.location.origin}/overrule-customs-light`);
    }
  }, [tokenRetrieved]);

  return null;
};

function mapStateToProps(state) {
  return {
    tokenRetrieved: state.alfapass.tokenRetrieved,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAlfapassToken,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallbackForAlfapass);
