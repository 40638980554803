import { Get, Put, Post, Delete } from './authorizedRequest';
import { CONSTANTS } from '../../theme/constants';
import { API_URL } from '../../config/environment';

export async function getPoliceContainers(obj) {
  const request = Get('/audit/containers/import/release-rights', obj.query);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return await response.json();
}

export async function getPoliceAudit(obj) {
  const request = Get('/audit/containers/import/trail', obj.query);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return await response.json();
}

export async function putPoliceBlockContainer(obj) {
  const request = Put(
    '/' + CONSTANTS.PORT_LOCODE + '/containers/' + obj.equipmentNumber + '/import/police-block',
    obj
  );
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

export async function postCompanySubscription(obj) {
  const request = Post('/audit/company/subscription', {
    reference: obj.reference,
    dossier: obj.dossier,
    fromDate: obj.fromDate,
    toDate: obj.toDate,
    nxtEntityId: obj.company.nxtEntityId,
  });
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response.ok;
}

export async function unscubscribeCompanySubscription(obj) {
  const request = Delete('/audit/company/subscription/' + obj);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response.ok;
}

export async function getCompanySubscriptions(obj) {
  const request = Get('/audit/company/subscriptions?pageCount=' + obj);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  const result = await response.json();
  return result;
}

export async function getScrEvents(obj) {
  const request = Get('/audit/company/' + obj + '/events');
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  const result = await response.json();
  return result;
}
