import { CONSTANTS } from '../../theme/constants';
import { Post, Put } from "./authorizedRequest";

const validationEndpoint = '/validation/pickup/identification';
const pickUpEndpoint = '/' + CONSTANTS.PORT_LOCODE + '/containers/';

export async function getAlfapassNumberIsValid(obj) {
    const request = Post(validationEndpoint, obj);
    const response = await fetch(request);

    if (!response.ok) {
        let err;
        try {
            err = await response.json();
        } catch {
            throw new Error(response.statusText);
        }
        throw err;
    }

    var isValid = await response.text();
    return isValid === 'true';
}

async function singlePickUpCall(obj) {
    let container = obj.containers[0];

    const url = pickUpEndpoint + container.equipmentNumber + '/import/pickup-rights/';
    const body = {
        portLoCode: CONSTANTS.PORT_LOCODE,
        billOfLadingNumbers: container.billOfLadingNumbers,
        equipmentNumber: container.equipmentNumber,
        releaseIdentification: container.releaseIdentification,
        terminalCode: container.terminalCode,
        pickupParty: {
            identificationType: obj.selectedDriverAlias ? CONSTANTS.DRIVERALIAS : CONSTANTS.ALFAPASS,
            identificationCode: obj.selectedDriverAlias ? obj.selectedDriverAlias.externalId : obj.alfapassNumber,
            driverAliasId: obj.selectedDriverAlias ? obj.selectedDriverAlias.externalId : null,
            partyType: 'Truck'
        },
        actionType: 'Assign'
    };

    const request = Put(url, body);
    const response = await fetch(request);

    if (!response.ok) {
        let err;
        try {
            err = await response.json();
        } catch {
            throw new Error(response.statusText);
        }
        throw err;
    }

    let responseJson = response.json();

    return responseJson;
}

async function multiplePickUpCall(obj) {
    let releaseRightIdentifiers = [];

    obj.containers.map(function (container, index) {
        releaseRightIdentifiers[index] = {
            billOfLadingNumbers: container.billOfLadingNumbers,
            equipmentNumber: container.equipmentNumber,
            releaseIdentification: container.releaseIdentification,
            terminalCode: container.terminalCode
        }
    });

    const url = pickUpEndpoint + 'import/pickup-rights/';
    const body = {
        portLoCode: CONSTANTS.PORT_LOCODE,
        releaseRights: releaseRightIdentifiers,
        pickupParty: {
            identificationType: obj.selectedDriverAlias ? CONSTANTS.DRIVERALIAS : CONSTANTS.ALFAPASS,
            identificationCode: obj.selectedDriverAlias ? obj.selectedDriverAlias.externalId : obj.alfapassNumber,
            driverAliasId: obj.selectedDriverAlias ? obj.selectedDriverAlias.externalId : null,
            partyType: 'Truck'
        },
        actionType: 'Assign'
    };

    const request = Put(url, body);
    const response = await fetch(request);

    if (!response.ok) {
        let err;
        try {
            err = await response.json();
        } catch {
            throw new Error(response.statusText);
        }
        throw err;
    }

    let responseJson = response.json();

    return responseJson;
}

export async function putPickup(obj) {
    let multipleContainers = obj.containers.length > 1;

    if (!multipleContainers) {
        singlePickUpCall(obj).then(response => {
            return response;
        });
    } else {
        multiplePickUpCall(obj).then(response => {
            return response;
        });
    }
}

export async function revokePickup(obj) {
    let failedResponse = null;
    let responses = [];
  
    await Promise.all(
      obj.containers.map(
        async (container) => {
          const url = pickUpEndpoint + container.equipmentNumber + '/import/pickup-rights/';
          const body = {
            portLoCode: CONSTANTS.PORT_LOCODE,
            billOfLadingNumbers: container.billOfLadingNumbers,
            equipmentNumber: container.equipmentNumber,
            releaseIdentification: container.releaseIdentification,
            terminalCode: container.terminalCode,
            actionType: 'Revoke'
          };
  
          const request = Put(url, body);
          const response = await fetch(request);
          if (!response.ok) {
            failedResponse = response;
            return;
          }
          responses = responses.concat(response);
        }
      )
    );
    if (failedResponse) {
      let err;
      try {
        err = await failedResponse.json();
      } catch {
        throw new Error(failedResponse.statusText);
      }
      throw err;
    }
  
    let responsesJson = [];
    await Promise.all(
      responses.map(async (response) => {
        const responseJson = await response.json();
        responsesJson = responsesJson.concat(responseJson);
      })
    );
  
    return responsesJson;
  }