import {
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadPoliceContainersFilters } from '../../store/actions/policeContainerActions';
import { LABEL, VALIDATION, MESSAGETYPES } from '../../theme/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PoliceContainerFilterValidator from '../../utils/validation/policeContainerFilter';
import Message from '../../components/molecules/Message';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  tabsRoot: {
    width: '100%',
  },
  tab: {
    minWidth: 'initial',
    '& span': {
      padding: 0,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  inputLabel: {
    backgroundColor: 'white',
    padding: '8px',
    marginTop: '-8px',
  },
  element: {
    marginTop: '10px',
  },
  horizontalDivider: {
    width: theme.spacing(20),
  },
  filterButton: {
    marginRight: theme.spacing(1),
  },
});

function PoliceContainersFilter(props) {
  const { loadPoliceContainersFilters, searchExpand, setSearchExpand, classes } = props;

  const createEmptyFilters = () => {
    return {
      equipmentNumber: '',
      billOfLading: '',
      shippingAgent: '',
      terminal: '',
      isArchived: '',
      isBlocked: '',
      company: '',
      dateStart: '',
      dateEnd: '',
    };
  };

  const [equipmentNumberError, setEquipmentNumberError] = React.useState(false);
  const [billOfLadingNumbersError, setBillOfLadingNumbersError] = React.useState(false);
  const [shippingAgentError, setShippingAgentError] = React.useState(false);
  const [terminalError, setTerminalError] = React.useState(false);
  const [dateStartError, setDateStartError] = React.useState(false);
  const [dateEndError, setDateEndError] = React.useState(false);
  const [companyError, setCompanyError] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [filters, setFilters] = React.useState(createEmptyFilters());

  const archiveStatuses = [
    { value: true, text: LABEL.ARCHIVED },
    { value: false, text: LABEL.ACTIVE },
    { value: '', text: LABEL.ALL },
  ];

  const blockedStatuses = [
    { value: true, text: LABEL.BLOCKED },
    { value: false, text: LABEL.UNBLOCKED },
    { value: '', text: LABEL.ALL },
  ];

  const filterValProps = {
    equipmentNumber: 'equipmentNumber',
    billOfLadingNumbers: 'billOfLadingNumbers',
    shippingAgent: 'shippingAgent',
    terminal: 'terminal',
    company: 'company',
    dateStart: 'dateStart',
    dateEnd: 'dateEnd',
  };

  const onChange = (key) => (event) => {
    const changedFilters = { ...filters, [key]: event.target.value };
    setFilters(changedFilters);
  };

  const resetErrors = (e) => {
    e.stopPropagation();
    setEquipmentNumberError(false);
    setBillOfLadingNumbersError(false);
    setShippingAgentError(false);
    setTerminalError(false);
    setDateStartError(false);
    setDateEndError(false);
    setCompanyError(false);
  };

  const setErrors = (error) => {
    switch (error.field) {
      case filterValProps.equipmentNumber:
        setEquipmentNumberError(true);
        return;
      case filterValProps.billOfLadingNumbers:
        setBillOfLadingNumbersError(true);
        return;
      case filterValProps.shippingAgent:
        setShippingAgentError(true);
        return;
      case filterValProps.terminal:
        setTerminalError(true);
        return;
      case filterValProps.dateStart:
        setDateStartError(true);
        return;
      case filterValProps.dateEnd:
        setDateEndError(true);
        break;
      case filterValProps.company:
        setCompanyError(true);
        return;
      default:
        return;
    }
  };

  const hasErrors = (e) => {
    e.stopPropagation();
    resetErrors(e);
    let errors = PoliceContainerFilterValidator.Validate(filters);
    let hasErrors = errors.length > 0;
    if (hasErrors) {
      errors.forEach((error) => {
        setErrors(error);
      });
      setDisplayError(true);
      setErrorMessage(errors[0].errorMessage);
    }
    return hasErrors;
  };

  const onFilterClear = (e) => {
    setDisplayError(false);
    resetErrors(e);
    setFilters(createEmptyFilters());
  };

  const onFilter = (e) => {
    e.stopPropagation();
    if (hasErrors(e)) {
      return;
    }
    setDisplayError(false);
    loadPoliceContainersFilters(filters);
  };

  return (
    <>
      {displayError && (
        <Message
          onClose={() => {
            setDisplayError(false);
          }}
          variant={MESSAGETYPES.ERROR}
          message={errorMessage}
        />
      )}
      <Accordion expanded={searchExpand} onChange={() => setSearchExpand(!searchExpand)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography variant="h6">{LABEL.CONTAINER_SEARCH}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                label={LABEL.BILL_OF_LADING}
                value={filters.billOfLading}
                onChange={onChange('billOfLading')}
                error={billOfLadingNumbersError}
                helperText={billOfLadingNumbersError ? VALIDATION.MIN_LENGTH_3 : ''}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.EQUIPMENT_NUMBER}
                value={filters.equipmentNumber}
                onChange={onChange('equipmentNumber')}
                error={equipmentNumberError}
                helperText={equipmentNumberError ? VALIDATION.MIN_LENGTH_3 : ''}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.SHIPPING_AGENT}
                value={filters.shippingAgent}
                onChange={onChange('shippingAgent')}
                error={shippingAgentError}
                helperText={shippingAgentError ? VALIDATION.MIN_LENGTH_3 : ''}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.TERMINALFIELD}
                value={filters.terminal}
                onChange={onChange('terminal')}
                error={terminalError}
                helperText={terminalError ? VALIDATION.MIN_LENGTH_3 : ''}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl margin={'normal'} fullWidth>
                <InputLabel id="isArchived" shrink={true}>
                  {LABEL.ARCHIVED}
                </InputLabel>
                <Select
                  labelId="isArchived"
                  id="isArchived"
                  onChange={onChange('isArchived')}
                  value={filters.isArchived}
                >
                  {archiveStatuses.map((status) => (
                    <MenuItem key={status.text} value={status.value} id={status.text}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl margin={'normal'} fullWidth>
                <InputLabel id="isBlocked" shrink={true}>
                  {LABEL.BLOCKED}
                </InputLabel>
                <Select
                  labelId="isBlocked"
                  id="isBlocked"
                  onChange={onChange('isBlocked')}
                  value={filters.isBlocked}
                >
                  {blockedStatuses.map((status) => (
                    <MenuItem key={status.text} value={status.value} id={status.text}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.ASSIGNEDTO}
                value={filters.company}
                onChange={onChange('company')}
                error={companyError}
                helperText={companyError ? VALIDATION.MIN_LENGTH_3 : ''}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.DATE_FROM}
                value={filters.dateStart}
                onChange={onChange('dateStart')}
                type="date"
                error={dateStartError}
                helperText={dateStartError && VALIDATION.DATE_FROM_BEFORE_UNTIL}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={LABEL.DATE_UNTIL}
                value={filters.dateEnd}
                onChange={onChange('dateEnd')}
                type="date"
                error={dateEndError}
                helperText={dateEndError && VALIDATION.DATE_UNTIL_AFTER_FROM}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={onFilterClear}>
            Clear
          </Button>
          <Button size="small" color="primary" onClick={onFilter}>
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

PoliceContainersFilter.propTypes = {
  loadPoliceContainersFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filters: state.policeContainers.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPoliceContainersFilters,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PoliceContainersFilter));
