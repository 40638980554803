export default {
  USER_EXPIRED: 'redux-oidc/USER_EXPIRED',
  USER_EXPIRING: 'redux-oicd/USER_EXPIRING',

  SUBSCRIPTIONS_REQUESTED: 'SUBSCRIPTIONS_REQUESTED',
  SUBSCRIPTIONS_REQUEST_SUCCEEDED: 'SUBSCRIPTIONS_REQUEST_SUCCEEDED',
  SUBSCRIPTION_ADD: 'SUBSCRIPTION_ADD',
  SUBSCRIPTION_DELETE: 'SUBSCRIPTION_DELETE',

  CONTAINERS_REQUESTED: 'CONTAINERS_REQUESTED',
  CONTAINERS_REQUEST_SUCCEEDED: 'CONTAINERS_REQUEST_SUCCEEDED',
  SET_ADDITIONAL_FIELDS: 'SET_ADDITIONAL_FIELDS',

  CONTAINER_REQUESTED: 'CONTAINER_REQUESTED',
  CONTAINER_REQUEST_SUCCEEDED: 'CONTAINER_REQUEST_SUCCEEDED',

  EVENT_HISTORY_REQUESTED: 'EVENT_HISTORY_REQUESTED',
  EVENT_HISTORY_REQUESTED_SUCCEEDED: 'EVENT_HISTORY_REQUESTED_SUCCEEDED',

  DRIVERLIST_REQUESTED: 'DRIVERLIST_REQUESTED',
  DRIVERLIST_REQUEST_SUCCEEDED: 'DRIVERLIST_REQUEST_SUCCEEDED',
  SUBMIT_DRIVERALIAS: 'SUBMIT_DRIVERALIAS',
  SUBMIT_DRIVERALIAS_SUCCEEDED: 'SUBMIT_DRIVERALIAS_SUCCEEDED',
  SUBMIT_DRIVERALIAS_VALIDATION_FAILED: 'SUBMIT_DRIVERALIAS_VALIDATION_FAILED',
  DELETE_DRIVERALIAS: 'DELETE_DRIVERALIAS',
  UPDATE_DRIVERALIAS: 'UPDATE_DRIVERALIAS',
  REFRESH_DRIVER_LIST: 'REFRESH_DRIVER_LIST',
  ADD_TO_DRIVER_LIST: 'ADD_TO_DRIVER_LIST',
  DELETE_FROM_DRIVER_LIST: 'DELETE_FROM_DRIVER_LIST',
  UPDATE_DRIVER_LIST: 'UPDATE_DRIVER_LIST',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CLEAR_DRIVER_LIST_TEMPORARILY: 'CLEAR_DRIVER_LIST_TEMPORARILY',
  UPDATE_DRIVER_LIST_PAGE: 'UPDATE_DRIVER_LIST_PAGE',
  UPDATE_DRIVER_LIST_PAGE_SIZE: 'UPDATE_DRIVER_LIST_PAGE_SIZE',

  POLICE_CONTAINERS_REQUESTED: 'POLICE_CONTAINERS_REQUESTED',
  POLICE_CONTAINERS_CLEAR: 'POLICE_CONTAINERS_CLEAR',
  POLICE_CONTAINERS_REQUEST_SUCCEEDED: 'POLICE_CONTAINERS_REQUEST_SUCCEEDED',
  POLICE_AUDIT_REQUESTED: 'POLICE_AUDIT_REQUESTED',
  POLICE_AUDIT_REQUEST_SUCCEEDED: 'POLICE_AUDIT_REQUEST_SUCCEEDED',
  SET_SELECTED_EQUIPMENT: 'SET_SELECTED_EQUIPMENT',
  SET_POLICE_CASE: 'SET_POLICE_CASE',
  GET_SCR_EVENTS: 'GET_SCR_EVENTS',
  GET_SCR_EVENTS_SUCCEEDED: 'GET_SCR_EVENTS_SUCCEEDED',

  ARCHIVE_REQUEST_SUCCEEDED: 'ARCHIVE_REQUEST_SUCCEEDED',
  ARCHIVE_REQUESTED: 'ARCHIVE_REQUESTED',
  POLICE_CONTAINER_BLOCK: 'POLICE_CONTAINER_BLOCK',
  CREATE_COMPANY_SUBSCRIPTION: 'CREATE_COMPANY_SUBSCRIPTION',
  CREATE_COMPANY_SUBSCRIPTION_SUCCEEDED: 'CREATE_COMPANY_SUBSCRIPTION_SUCCEEDED',
  DELETE_COMPANY_SUBSCRIPTION: 'DELETE_COMPANY_SUBSCRIPTION',
  DELETE_COMPANY_SUBSCRIPTION_SUCCEEDED: 'DELETE_COMPANY_SUBSCRIPTION_SUCCEEDED',
  GET_COMPANY_SUBSCRIPTIONS: 'GET_COMPANY_SUBSCRIPTIONS',
  GET_COMPANY_SUBSCRIPTIONS_SUCCEEDED: 'GET_COMPANY_SUBSCRIPTIONS_SUCCEEDED',

  CONTAINERS_REVOKE: 'CONTAINER_REVOKE',
  CONTAINERS_REVOKE_SUCCEEDED: 'CONTAINER_REVOKE_SUCCEEDED',
  CONTAINERS_FORWARD: 'CONTAINERS_FORWARD',
  CONTAINERS_FORWARD_SUCCEEDED: 'CONTAINERS_FORWARD_SUCCEEDED',

  RELEASES_ACCEPT: 'RELEASE_ACCEPT',
  RELEASES_ACCEPT_SUCCEEDED: 'RELEASE_ACCEPT_SUCCEEDED',
  RELEASES_DECLINE: 'RELEASE_DECLINE',
  RELEASES_DECLINE_SUCCEEDED: 'RELEASE_DECLINE_SUCCEEDED',

  CONTAINERS_PICKUP: 'CONTAINER_PICKUP',
  CONTAINERS_PICKUP_SUCCEEDED: 'CONTAINER_PICKUP_SUCCEEDED',
  CLOSE_PICKUP_CONFIRMATION: 'CLOSE_PICKUP_CONFIRMATION',
  CONTAINERS_PICKUP_REVOKE: 'CONTAINER_PICKUP_REVOKE',
  CONTAINERS_PICKUP_REVOKE_SUCCEEDED: 'CONTAINER_PICKUP_REVOKE_SUCCEEDED',
  CLOSE_PICKUP_REVOKE_CONFIRMATION: 'CLOSE_PICKUP_REVOKE_CONFIRMATION',

  CONTAINERS_SELECT_FOR_SCAN: 'CONTAINERS_SELECT_FOR_SCAN',
  CONTAINERS_SELECT_FOR_SCAN_SUCCEEDED: 'CONTAINERS_SELECT_FOR_SCAN_SUCCEEDED',

  COMPANIES_REQUESTED: 'COMPANIES_REQUESTED',
  COMPANIES_REQUEST_SUCCEEDED: 'COMPANIES_REQUEST_SUCCEEDED',
  REFRESH_CONTAINERS: 'REFRESH_CONTAINERS',

  SELECTED_CONTAINERS_REQUESTED: 'SELECTED_CONTAINERS_REQUESTED',
  SELECTED_CONTAINERS_REQUEST_SUCCEEDED: 'SELECTED_CONTAINERS_REQUEST_SUCCEEDED',
  CLEAR_SELECTED_CONTAINERS: 'CLEAR_SELECTED_CONTAINERS',

  SELECTED_RELEASES_REQUESTED: 'SELECTED_RELEASES_REQUESTED',
  SELECTED_RELEASES_REQUEST_SUCCEEDED: 'SELECTED_RELEASES_REQUEST_SUCCEEDED',

  RELEASES_REQUESTED: 'RELEASES_REQUESTED',
  RELEASES_REQUEST_SUCCEEDED: 'RELEASES_REQUEST_SUCCEEDED',

  PENDINGRELEASECOUNT_SUCCEEDED: 'PENDINGRELEASECOUNT_SUCCEEDED',
  UPDATE_PENDINGRELEASECOUNT: 'UPDATE_PENDINGRELEASECOUNT',

  MESSAGE_ERROR: 'MESSAGE_ERROR',
  MESSAGE_WARNING: 'MESSAGE_WARNING',
  MESSAGE_INFO: 'MESSAGE_INFO',
  MESSAGE_SUCCESS: 'MESSAGE_SUCCESS',
  MESSAGE_API_ERRORS: 'MESSAGE_API_ERRORS',
  MESSAGE_API_SUCCESS: 'MESSAGE_API_SUCCESS',
  MESSAGE_BROADCAST_MESSAGE: 'MESSAGE_BROADCAST_MESSAGE',

  CREDIT_IDENTIFICATION_REQUESTED: 'CREDIT_IDENTIFICATION_REQUESTED',
  CREDIT_IDENTIFICATION_REQUESTED_SUCCEEDED: 'CREDIT_IDENTIFICATION_REQUESTED_SUCCEEDED',
  PAIR_CREDIT_IDENTIFICATION_SUCCEEDED: 'PAIR_CREDIT_IDENTIFICATION_SUCCEEDED',

  USER_PROFILE_REQUESTED: 'USER_PROFILE_REQUESTED',
  USER_PROFILE_REQUEST_SUCCEEDED: 'USER_PROFILE_REQUEST_SUCCEEDED',
  STAKEHOLDER_REQUEST_SUCCEEDED: 'STAKEHOLDER_REQUEST_SUCCEEDED',
  UPDATE_SELECTED_USER_TYPE: 'UPDATE_SELECTED_USER_TYPE',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  TOKEN_EXPIRING: 'TOKEN_EXPIRING',

  CHECK_PHONE_NUMBER: 'CHECK_PHONE_NUMBER',
  PHONE_NUMBER_CHECKED: 'PHONE_NUMBER_CHECKED',
  RESET_PHONE_NUMBER_STATUS: 'RESET_PHONE_NUMBER_STATUS',
  RESET_PHONE_NUMBER: 'RESET_PHONE_NUMBER',
  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',

  CONTAINERS_BLOCK: 'CONTAINERS_BLOCK',
  CONTAINERS_UNBLOCK: 'CONTAINERS_UNBLOCK',
  CONTAINERS_RELEASE: 'CONTAINERS_RELEASE',
  CONTAINERS_BLOCK_SUCCEEDED: 'CONTAINERS_BLOCK_SUCCEEDED',
  CONTAINERS_RELEASE_SUCCEEDED: 'CONTAINERS_RELEASE_SUCCEEDED',
  SIGNALR_SETUP_FAILED: 'SIGNALR_SETUP_FAILED',
  SIGNALR_ID_RECEIVED: 'SIGNALR_ID_RECEIVED',
  SELECTED_STAKEHOLDER_CHANGED: 'SELECTED_STAKEHOLDER_CHANGED',

  BLOCK_COMMERCIAL_RELEASE: 'BLOCK_COMMERCIAL_RELEASE',
  UNBLOCK_COMMERCIAL_RELEASE: 'UNBLOCK_COMMERCIAL_RELEASE',
  DELETE_COMMERCIAL_RELEASE: 'DELETE_COMMERCIAL_RELEASE',
  REVOKE_COMMERCIAL_RELEASE: 'REVOKE_COMMERCIAL_RELEASE',
  UPDATE_COMMERCIAL_RELEASE: 'UPDATE_COMMERCIAL_RELEASE',
  RELEASE_COMMERCIAL_RELEASE: 'RELEASE_COMMERCIAL_RELEASE',

  TERMINALS_REQUESTED: 'TERMINALS_REQUESTED',
  TERMINALS_REQUEST_SUCCEEDED: 'TERMINALS_REQUEST_SUCCEEDED',

  SET_REFRESH_CONTAINERS: 'SET_REFRESH_CONTAINERS',

  SET_ALFAPASS_NUMBER: 'SET_ALFAPASS_NUMBER',
  RESET_ALFAPASS_NUMBER: 'RESET_ALFAPASS_NUMBER',
  CHECK_ALFAPASS_NUMBER: 'CHECK_ALFAPASS_NUMBER',
  ALFAPASS_NUMBER_CHECKED: 'ALFAPASS_NUMBER_CHECKED',
  ALFAPASS_CONTAINER_PICKUP: 'ALFAPASS_CONTAINER_PICKUP',
  RESET_ALFAPASS_NUMBER_STATUS: 'RESET_ALFAPASS_NUMBER_STATUS',
  SET_OLD_ALFAPASS_NUMBER: 'SET_OLD_ALFAPASS_NUMBER',

  REGISTER_PAYMENT_OPTION: 'REGISTER_PAYMENT_OPTION',
  REGISTER_PAYMENT_OPTION_SUCCEEDED: 'REGISTER_PAYMENT_OPTION_SUCCEEDED',
  PAIR_PAYMENT_OPTION: 'PAIR_PAYMENT_OPTION',
  CHANGE_CREDIT_AMOUNT: 'CHANGE_CREDIT_AMOUNT',

  ADD_DRIVER: 'ADD_DRIVER',
  CHANGED_TAB: 'CHANGED_TAB',
  RESET_CHANGED_TAB: 'RESET_CHANGED_TAB',

  POLICE_SUBSCRIPTIONS_REQUESTED: 'POLICE_SUBSCRIPTIONS_REQUESTED',
  POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED: 'POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED',
  POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED_NO_CONTENT:
    'POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED_NO_CONTENT',

  GET_ALFAPASS_TOKEN: 'GET_ALFAPASS_TOKEN',
  GET_ALFAPASS_TOKEN_SUCCESS: 'GET_ALFAPASS_TOKEN_SUCCESS',
};
