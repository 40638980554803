import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@material-ui/core';
import { Box, TextField } from '@material-ui/core';
import React from 'react';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import { MonthsByDescription } from '../../../global/helpers/datesHelper';
import { Field, FieldProps } from 'formik';
import useStyles from './style';

type Props = {
  customHelperText?: string;
};

const FilterBillingInfo = ({ customHelperText }: Props) => {
  const { t } = useTranslation(['displayBillingInfo', 'glossary', 'validation']);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('filter.searchContentText')}
      </Typography>
      <Box marginTop={2}>
        <Field name="month" id="month" component={FormikSelect}>
          {MonthsByDescription.map((e) => {
            return (
              <MenuItem value={e} key={`month_${e}`}>
                {e}
              </MenuItem>
            );
          })}
        </Field>
      </Box>
      <Box marginTop={2}>
        <Field name="year" id="year" component={FormikSelect}>
          {({ field }: FieldProps) => (
            <TextField {...field} variant="outlined" label={t('year')} fullWidth />
          )}
        </Field>
      </Box>
    </React.Fragment>
  );
};

export default FilterBillingInfo;
