import { takeEvery, call, put } from 'redux-saga/effects';
import { submitReleaseAction } from '../api/commercialReleaseApi';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* allBlockCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      reason: action.payload.reason,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* allUnblockCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* allDeleteCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      reason: action.payload.reason,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* allRevokeCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      reason: action.payload.reason,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* allUpdateCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* allReleaseCommercialRelease(action) {
  try {
    yield call(submitReleaseAction, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
      nxtEntityId: action.payload.nxtEntityId,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* commercialReleaseSaga() {
  yield takeEvery(ActionTypes.BLOCK_COMMERCIAL_RELEASE, allBlockCommercialRelease);
  yield takeEvery(ActionTypes.UNBLOCK_COMMERCIAL_RELEASE, allUnblockCommercialRelease);
  yield takeEvery(ActionTypes.DELETE_COMMERCIAL_RELEASE, allDeleteCommercialRelease);
  yield takeEvery(ActionTypes.REVOKE_COMMERCIAL_RELEASE, allRevokeCommercialRelease);
  yield takeEvery(ActionTypes.UPDATE_COMMERCIAL_RELEASE, allUpdateCommercialRelease);
  yield takeEvery(ActionTypes.RELEASE_COMMERCIAL_RELEASE, allReleaseCommercialRelease);
}

export default commercialReleaseSaga;
