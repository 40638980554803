import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../utils/userManager';

class CallbackPage extends React.Component {
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={(user) => {
          this.props.dispatch(push({ pathname: user.state, search: '' }));
        }}
        errorCallback={(error) => {
          this.props.dispatch(push('/error'));
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
