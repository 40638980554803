/* eslint-disable import/no-anonymous-default-export */
import { ALFAPASSNUMBERSTATUSES, MESSAGETYPES } from '../../theme/constants';

export default {
  selectedStakeholder: {
    name: '',
    taxIdentificationNumber: '',
  },
  signalR: {
    id: null,
    error: null,
  },
  selectedContainers: [],
  selectedReleases: [],
  terminals: {
    items: [],
  },
  companies: {
    loading: true,
    items: [],
    filters: {
      eori: '',
      duns: '',
      vat: '',
      entityName: '',
      apcsCode: '',
    },
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
  },
  policeContainers: {
    loading: false,
    items: [],
    policeCase: {
      number: '',
      referenceNumber: '',
    },
    filters: {
      equipmentNumber: '',
      billOfLading: '',
      shippingAgent: '',
      terminal: '',
      isArchived: '',
      isBlocked: '',
      company: '',
      dateStart: '',
      dateEnd: '',
    },
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
    selected: {
      releasIdentification: '',
      billOfLadingNumbers: [],
      equipmentNumber: '',
      policeStatus: 0,
      operationalStatus: 0,
      audit: {
        items: [],
        paging: {
          page: 0,
          count: 0,
          rowsPerPage: 25,
        },
      },
    },
    companySubscriptions: {
      items: [],
      isCreated: false,
    },
    scrEvents: {
      items: [],
    },
    refresh: false,
  },
  subscriptions: {
    loading: true,
    loadingNew: false,
    items: [],
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
  },
  containers: {
    loading: true,
    items: [],
    filters: {
      equipmentNumber: '',
      billOfLading: '',
      releaseIdentification: '',
      shippingAgent: '',
      receivedFrom: '',
      terminal: '',
      status: '',
      userRoleStatus: '',
      actionStatus: 0,
    },
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
    confirmationDialogs: {
      pickup: {
        open: false,
        containers: [],
      },
      pickupRevoke: {
        open: false,
        containers: [],
      },
    },
    refreshContainers: true,
    selected: {
      equipmentNumber: '',
      releaseIdentification: '',
      releaseOrderReference: '',
      terminalName: '',
      terminalCode: '',
      portLoCode: '',
      billOfLadingNumbers: [],
      lights: [],
      startDate: '',
      releaseDateTimeUtc: null,
      expirationDateTimeUtc: null,
      shippingAgentName: '',
      equipmentType: '',
      emptyReturnReference: '',
      equipmentSupplier: '',
      equipmentOperator: '',
      fullEmptyIndicator: '',
      equipmentStatus: '',
      isCarrierHaulage: false,
      vesselCallSign: '',
      vesselName: '',
      imoNumber: '',
      stayNumber: '',
      voyageNumber: '',
      additionalDataFields: [],
      loading: false,
    },
  },
  archive: {
    loading: true,
    items: [],
    filters: {
      billOfLading: '',
      selectedFromDate: '',
      selectedToDate: '',
      userRoleStatus: '',
    },
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
  },
  releases: {
    loading: true,
    items: [],
    filters: {
      equipmentNumber: '',
      billOfLading: '',
      shippingAgent: '',
      receivedFrom: '',
      terminal: '',
      status: '',
      userRoleStatus: '',
    },
    paging: {
      page: 0,
      count: 0,
      rowsPerPage: 25,
    },
  },
  counts: {
    pendingReleaseCount: 0,
  },
  alfapass: {
    number: '',
    old: '',
    status: ALFAPASSNUMBERSTATUSES.TO_BE_CHECKED,
    tokenRetrieved: false,
  },
  user: {
    userProfile: null,
    selectedType: '',
    creditIdentificationResponse: null,
    loadingCreditIdentification: true,
    remainingCredits: 0,
    isPairedWithCinvio: false,
    hasFullSubscription: false,
    tokenExpired: false,
    tokenExpiring: false,
  },
  stakeholder: null,
  message: {
    type: MESSAGETYPES.INFO,
    content: 'Welcome to Certified Release Process',
  },
  tab: {
    changed: false,
  },
  driverList: {
    items: [],
  },
  eventHistory: {
    result: [],
  },
};
