import { api } from 'modules/global/adapters';
import { searchContainersQueryKeys } from './queryKeys';
import { useQuery } from '@tanstack/react-query';
import { GetSearchContainersParams } from 'modules/global/adapters/crpAPI';
import { LABEL } from 'theme/constants';

export const useGetActiveShippingAgents = () => {
  return useQuery(searchContainersQueryKeys.getShippingAgents(), () =>
    api.agents.getShippingAgents()
  );
};

export const useSearchContainers = (searchParams: GetSearchContainersParams) => {
  const isSearchEntered =
    Boolean(searchParams.shippingAgentNxtEntityId) ||
    Boolean(searchParams.equipmentNumber) ||
    Boolean(searchParams.billOfLadingNumber);

  searchParams.role = LABEL.TRANSPORTOPERATOR_ROLE;

  return useQuery(
    searchContainersQueryKeys.containersForUpdate(searchParams),
    () => api.containers.getSearchContainers(searchParams),
    {
      enabled: isSearchEntered,
    }
  );
};
