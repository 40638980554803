import ActionTypes from './actionTypes';

export function loadReleases(loading) {
  return {
    type: ActionTypes.RELEASES_REQUESTED,
    payload: { loading: loading },
  };
}

export function loadReleasesPage(page) {
  return {
    type: ActionTypes.RELEASES_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadReleasesRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.RELEASES_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadReleasesFilters(filters) {
  return {
    type: ActionTypes.RELEASES_REQUESTED,
    payload: { filters },
  };
}

export function loadReleasesSucceeded(releases) {
  return {
    type: ActionTypes.RELEASES_REQUEST_SUCCEEDED,
    payload: releases,
  };
}

export function acceptReleases(payload) {
  return {
    type: ActionTypes.RELEASES_ACCEPT,
    payload: payload
  };
}

export function acceptReleasesSucceeded(response) {
  return {
    type: ActionTypes.RELEASES_ACCEPT_SUCCEEDED,
    payload: response,
  };
}

export function declineReleases(payload) {
  return {
    type: ActionTypes.RELEASES_DECLINE,
    payload: payload
  };
}

export function declineReleasesSucceeded(response) {
  return {
    type: ActionTypes.RELEASES_DECLINE,
    payload: response,
  };
}
