import React from 'react';
import { Route } from 'react-router-dom';
import store from 'store/store';
import { mainAPI, api } from '../../global/adapters';
import { ROUTE_PATHS } from '../constants';
import DisplayNotifications from '../pages/DisplayNotifications';
import OverruleCustomsLight from '../pages/OverruleCustomsLight';
import UseCases from '../pages/UseCases';
import SearchContainers from '../pages/SearchContainers';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const BetaTerminalRoutes: React.FunctionComponent<Props> = () => {
  const userState = store.getState().oidc.user;
  mainAPI.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });
  api.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });

  return (
    <React.Fragment>
      <Route path={ROUTE_PATHS.OVERRULE_CUSTOMS_LIGHT} exact component={OverruleCustomsLight} />
      <Route path={ROUTE_PATHS.DISPLAY_NOTIFICATIONS} exact component={DisplayNotifications} />
      <Route path={ROUTE_PATHS.SEARCH_CONTAINERS} exact component={SearchContainers} />
      <Route path={ROUTE_PATHS.BASE_ROUTE} exact component={UseCases} />
    </React.Fragment>
  );
};

export default BetaTerminalRoutes;
