import store from '../../store/store';
import { API_URL, IDENTITY_URL, SUBSCRIPTION_KEY } from '../../config/environment';

const apiUrl = API_URL.substr(-1) === '/' ? API_URL.slice(0, -1) : API_URL;

function AuthorizedHeaders() {
  const headers = new Headers();
  const token = store.getState().oidc.user;

  headers.append('Authorization', `${token.token_type} ${token.access_token}`);
  headers.append('Ocp-Apim-Subscription-Key', SUBSCRIPTION_KEY);
  headers.append('Content-Type', 'application/json');

  return headers;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function Get(resource) {
  return new Request(IDENTITY_URL + resource, {
    method: 'GET',
    headers: AuthorizedHeaders(),
  });
}

export async function retrieveSignalRData() {
  const negotiateUrl = apiUrl + '/broadcast/negotiate';

  const request = new Request(negotiateUrl, {
    method: 'GET',
    headers: AuthorizedHeaders(),
  });

  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      const errObject = await response.json();
      err = errObject.error
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  return await response.json();
}
