import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, RowSelectionState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { NotificationDetail, NotificationRequest } from '../../global/adapters/mainAPI';
import { DataGrid } from '../../global/components';
import NotificationMessageDialog from '../../global/components/Dialog/NotificationMessageDialog';
import EmptyState from '../../global/components/EmptyState/EmptyState';
import { useLocalTableValues } from '../../global/hooks';
import { useNotificationRequest } from '../adapters/queries';
import FilterNotifications from '../components/get-notifications/FilterNotifications';
import { notificationColumns } from '../constants/shared/notificationColumns';
import displayNotificationsSchema from '../schemas/displayNotificationsSchema';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: NotificationRequest = {
  notificationEvent: 'Unknown',
  equipmentNumber: '',
  publicReferenceId: '',
  releaseId: '',
  storedOn: undefined,
  timeWindowFrom: undefined,
  timeWindowTo: undefined,
};

const DisplayNotifications: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['displayNotifications', 'common', 'validation']);
  const formRef = useRef<FormikProps<NotificationRequest>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: NotificationDetail;
  }>({
    open: false,
    data: undefined,
  });

  const handleCellClick = (rowData: NotificationDetail) => {
    setShowDetail({
      open: true,
      data: rowData,
    });
  };

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-display-notifications',
    {}
  );

  const formatStoredOn = (storedOn: string | undefined) => {
    if (storedOn !== undefined) return moment(storedOn).toISOString();
    return storedOn;
  };

  const formatTimewindow = (timeWindow: string | undefined) => {
    if (timeWindow !== undefined) return moment(timeWindow).format('HH:mm');
    return timeWindow;
  };

  const { data, isLoading, isFetching, isError } = useNotificationRequest({
    notificationEvent: formRef?.current?.values.notificationEvent,
    equipmentNumber: formRef?.current?.values.equipmentNumber,
    publicReferenceId: formRef?.current?.values.publicReferenceId,
    releaseId: formRef?.current?.values.releaseId,
    storedOn: formatStoredOn(formRef?.current?.values.storedOn),
    timeWindowFrom: formatTimewindow(formRef?.current?.values.timeWindowFrom),
    timeWindowTo: formatTimewindow(formRef?.current?.values.timeWindowTo),
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorDisplayNotifications')));
  }, [isError, t]);

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          validationSchema={displayNotificationsSchema}
          innerRef={formRef as React.Ref<FormikProps<NotificationRequest>>}
          onSubmit={handleOnSubmit}
        >
          {({ errors, submitForm, resetForm, values }: FormikProps<NotificationRequest>) => {
            return (
              <FilterNotifications
                errors={errors}
                setDisplayGrid={setDisplayGrid}
                submitForm={submitForm}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? null : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={notificationColumns}
                  columnSizing={columnSizing}
                  data={data}
                  isLoading={isLoading}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                  onCellClick={handleCellClick}
                />
              </Paper>
              {showDetail.data !== undefined && (
                <NotificationMessageDialog
                  showDialog={setShowDetail}
                  open={showDetail.open}
                  data={showDetail.data}
                />
              )}
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayNotifications;
