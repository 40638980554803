import ActionTypes from './actionTypes'

export function tabChanged() {
    return {
        type: ActionTypes.CHANGED_TAB
    }
}

export function resetTab() {
    return {
        type: ActionTypes.RESET_CHANGED_TAB
    }
}