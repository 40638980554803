import { GetContainersByTerminalAndReleaseRightParams } from 'modules/global/adapters/crpAPI';

export const declineReleaseRightKeys = {
  root: ['decline-releaseright'] as const,
  containersForUpdateRoot: () =>
    [...declineReleaseRightKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (terminalParams: GetContainersByTerminalAndReleaseRightParams) =>
    [...declineReleaseRightKeys.containersForUpdateRoot(), terminalParams] as const,
  postContainersDecline: () =>
    [...declineReleaseRightKeys.root, 'post-containers-decline-releaseright'] as const,
  getTerminals: () => [...declineReleaseRightKeys.root, 'get-terminals'] as const,
  getPendingContainersForReleaseRightAction: () => [
    ...declineReleaseRightKeys.containersForUpdateRoot(),
    'get-pending-containers',
  ],
};
