import { combineReducers } from 'redux';
import archive from './archiveReducer';
import alfapass from './alfapassReducer';
import companies from './companiesReducer';
import containers from './containersReducer';
import subscriptions from './subscriptionsReducer';
import policeContainers from './policeContainersReducer';
import counts from './countReducer';
import message from './messageReducer';
import tab from './tabReducer';
import releases from './releasesReducer';
import selectedContainers from './selectedContainersReducer';
import selectedReleases from './selectedReleasesReducer';
import selectedStakeholder from './selectedStakeholderReducer';
import signalR from './signalRReducer';
import user from './userReducer';
import terminals from './terminalReducer';
import { reducer as oidc } from 'redux-oidc';
import eventHistory from './eventHistoryReducer';
import driverList from './driverListReducer';

export default combineReducers({
  selectedStakeholder,
  archive,
  containers,
  subscriptions,
  policeContainers,
  companies,
  counts,
  selectedContainers,
  selectedReleases,
  releases,
  user,
  message,
  tab,
  signalR,
  alfapass,
  terminals,
  oidc,
  eventHistory,
  driverList,
});
