import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { LABEL } from '../../theme/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    minWidth: 375,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function ProfileDialog(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const { openDialog, userProfile } = props;
  const [open, setOpen] = React.useState(openDialog);
  const [enter, setEnter] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (enter === 0) return;
    setOpen(true);
  }, [openDialog]);

  React.useEffect(() => {
    setEnter(1);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="profile-dialog-slide-title"
        aria-describedby="profile-dialog-slide-description"
      >
        <DialogTitle id="profile-dialog-slide-title">{LABEL.ORGANISATION_INFO}</DialogTitle>
        <DialogContent>
          <DialogContentText id="profile-dialog-slide-description">
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="h8" component="h4">
                  {bull}
                  {LABEL.ORGANISATION_NAME}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {userProfile.firstName == null ? '' : userProfile.firstName}{' '}
                  {userProfile.lastName == null ? '' : userProfile.lastName}
                </Typography>
                <Typography variant="h8" component="h4">
                  {bull}
                  {LABEL.COMPANY_NXTENTITYID}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {userProfile.nxtEntityId}
                </Typography>
              </CardContent>
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {LABEL.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
