import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableSortLabel,
} from '@material-ui/core';
import {
  RowModel,
  ColumnSizingState,
  RowData,
  flexRender,
  HeaderGroup,
  Column,
} from '@tanstack/react-table';
import { VariableSizeGrid, GridChildComponentProps, GridOnScrollProps } from 'react-window';
import useStyles from './style';
import useElementSize from '../../hooks/useElementSize';
import Filter from './Filter';
import EmptyState from '../EmptyState/EmptyState';
import { useTranslation } from 'react-i18next';

const HEADER_HEIGHT = 81;
const ROW_HEIGHT = 42;

export type CustomTableProps = {
  lowerHeight?: boolean;
  highlightSelectedCell?: boolean;
};

type Props<T extends RowData, TValue extends unknown> = React.ComponentPropsWithoutRef<'div'> & {
  data: T[] | undefined;
  getRowSelection?: React.MutableRefObject<(() => RowModel<T>) | null>;
  columnSizing?: ColumnSizingState;
  getHeaderGroups: () => HeaderGroup<T>[];
  getRowModel: () => RowModel<T>;
  getSelectedRowModel: () => RowModel<T>;
  getVisibleLeafColumns: () => Column<T, TValue>[];
  getPreFilteredRowModel: () => RowModel<T>;
  onCellClick?: (rowModel: T) => void;
  customTableProps?: CustomTableProps;
};

const BaseGrid = <T extends RowData, TValue extends unknown>({
  data,
  getRowSelection,
  columnSizing,
  getHeaderGroups,
  getRowModel,
  getSelectedRowModel,
  getVisibleLeafColumns,
  getPreFilteredRowModel,
  onCellClick,
  customTableProps,
}: Props<T, TValue>) => {
  const { t } = useTranslation('common');

  const classes = useStyles(customTableProps);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<VariableSizeGrid>(null);
  const [containerRef, { width: variableWidth, height: variableHeight }] = useElementSize();

  const handleScroll = ({ scrollLeft, scrollUpdateWasRequested }: GridOnScrollProps) => {
    if (!scrollUpdateWasRequested) {
      if (headerRef.current) {
        headerRef.current.style.transform = `translateX(-${scrollLeft}px)`;
      }
    }
  };

  const RenderRow = React.useCallback(
    ({ rowIndex, columnIndex, style }: GridChildComponentProps<T>) => {
      const row = getRowModel()?.rows[rowIndex];
      const cell = row.getVisibleCells?.()?.[columnIndex];
      const selectedRow = row?.getIsSelected?.();

      const cellClasses = [
        classes.cell,
        customTableProps?.highlightSelectedCell && selectedRow ? classes.selectedCell : '',
        onCellClick !== undefined ? classes.cellClickable : '',
      ].join(' ');

      return (
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
            onCellClick?.(row.original);
            customTableProps?.highlightSelectedCell && row.toggleSelected();
          }}
          component="div"
          variant="body"
          key={cell.id}
          style={style}
          className={cellClasses}
        >
          <span className={classes.cellContent}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </span>
        </TableCell>
      );
    },
    [
      classes.cell,
      classes.cellClickable,
      classes.cellContent,
      classes.selectedCell,
      customTableProps?.highlightSelectedCell,
      getRowModel,
      onCellClick,
    ]
  );

  React.useEffect(() => {
    if (getRowSelection) {
      getRowSelection.current = getSelectedRowModel;
    }
  }, [getRowSelection, getSelectedRowModel]);

  React.useEffect(() => {
    if (listRef.current) {
      // TODO: debounce for performance
      listRef.current.resetAfterColumnIndex(1);
    }
  }, [columnSizing]);

  return (
    <TableContainer innerRef={containerRef} className={classes.container}>
      {data && (
        <Table component={'div'} className={classes.table} size="small" stickyHeader>
          <TableHead component={'div'}>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow
                ref={headerRef}
                component={'div'}
                key={headerGroup.id}
                className={classes.row}
              >
                {headerGroup.headers.map((header) => (
                  <TableCell
                    variant="head"
                    component={'div'}
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classes.cell}
                    style={{
                      width: header.column.getSize(),
                    }}
                  >
                    <div className={classes.header}>
                      {header.column.getCanSort() ? (
                        <TableSortLabel
                          active={Boolean(header.column.getIsSorted())}
                          onClick={header.column.getToggleSortingHandler()}
                          direction={header.column.getIsSorted() || undefined}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableSortLabel>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                      {header.column.getCanFilter() && (
                        <Filter
                          column={header.column}
                          getPreFilteredRowModel={getPreFilteredRowModel}
                        />
                      )}
                    </div>
                    {header.column.getCanResize() && (
                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: [
                            classes.resizer,
                            header.column.getIsResizing() ? classes.resizing : '',
                          ].join(' '),
                        }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length === 0 ? (
            <EmptyState title={t('dataGrid.noResults')} subTitle={t('dataGrid.adjustFilters')} />
          ) : (
            <TableBody component="div">
              <VariableSizeGrid
                ref={listRef}
                onScroll={handleScroll}
                rowCount={getRowModel().rows.length}
                height={variableHeight - HEADER_HEIGHT}
                width={variableWidth}
                rowHeight={() => ROW_HEIGHT}
                columnCount={getVisibleLeafColumns().length}
                columnWidth={(index) => getVisibleLeafColumns()[index].getSize()}
              >
                {RenderRow}
              </VariableSizeGrid>
            </TableBody>
          )}
        </Table>
      )}
    </TableContainer>
  );
};

export default BaseGrid;
