import React from 'react';
import { Route } from 'react-router-dom';
import Containers from '../../../components/pages/Containers';
import { ROUTE_PATHS } from '../constants';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const RegularReleasePartyRoutes: React.FunctionComponent<Props> = () => {
  return (
    <React.Fragment>
      <Route path={ROUTE_PATHS.BASE_ROUTE} exact component={Containers} />
    </React.Fragment>
  );
};

export default RegularReleasePartyRoutes;
