import { api } from 'modules/global/adapters';
import { revokeReleaseRightQueryKeys } from './queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BulkReleaseRightActionRequest,
  GetContainersByShippingAgentParams,
} from 'modules/global/adapters/crpAPI';
import { LABEL } from 'theme/constants';

export const useGetActiveShippingAgents = () => {
  return useQuery(revokeReleaseRightQueryKeys.getShippingAgents(), () =>
    api.agents.getShippingAgents()
  );
};

export const useGetContainersByShippingAgent = (
  shippingParams: GetContainersByShippingAgentParams
) => {
  const isSearchEntered =
    Boolean(
      shippingParams.shippingAgentNxtEntityId &&
        shippingParams.shippingAgentNxtEntityId != LABEL.UNKNOWN
    ) ||
    Boolean(shippingParams.equipmentNumber) ||
    Boolean(shippingParams.billOfLadingNumber);

  shippingParams.shippingAgentNxtEntityId =
    shippingParams.shippingAgentNxtEntityId === LABEL.UNKNOWN
      ? ''
      : shippingParams.shippingAgentNxtEntityId;

  return useQuery(
    revokeReleaseRightQueryKeys.containersForUpdate(shippingParams),
    () => api.containers.getContainersForRevokeByShippingAgentParams(shippingParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersRevoke = () => {
  const queryClient = useQueryClient();

  return useMutation(revokeReleaseRightQueryKeys.postContainersRevoke(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersRevoke(data),
    onSuccess: () => {
      queryClient.invalidateQueries(revokeReleaseRightQueryKeys.containersForUpdateRoot());
    },
  });
};
