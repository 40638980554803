import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { transferReleaseRightKeys } from './queryKeys';
import { api } from 'modules/global/adapters';
import {
  BulkReleaseRightActionRequest,
  GetContainersByReceivedFromParams,
} from 'modules/global/adapters/crpAPI';
import { ReleaseRightStatus } from 'modules/global/adapters/mainAPI';
import { ReleaseToPartyByRole } from '../../../global/adapters/crpAPI';

export const useGetActiveTerminals = () => {
  return useQuery(transferReleaseRightKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetContainersTransferReleaseRight = (
  receivedFromParams: GetContainersByReceivedFromParams
) => {
  const isSearchEntered =
    (receivedFromParams.terminalNxtEntityId !== undefined &&
      receivedFromParams.terminalNxtEntityId !== '') ||
    (receivedFromParams.identificationCode !== undefined &&
      receivedFromParams.identificationCode !== '' &&
      receivedFromParams.identificationType !== undefined &&
      receivedFromParams.identificationType !== '');

  receivedFromParams.status = ReleaseRightStatus.Accepted;

  return useQuery(
    transferReleaseRightKeys.containersForUpdate(receivedFromParams),
    () => api.containers.getUnassignedContainersByReceivedFromParams(receivedFromParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetRelevantParties = (currrentRole: string) => {
  return useQuery(transferReleaseRightKeys.getRelevantParties(currrentRole), () => {
    var releaseToPartyByRole = new ReleaseToPartyByRole(currrentRole);
    return api.releaseparties.getReleaseToParties(releaseToPartyByRole);
  });
};

export const usePostContainersTransfer = () => {
  const queryClient = useQueryClient();

  return useMutation(transferReleaseRightKeys.postContainersTransfer(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersTransfer(data),
    onSuccess: () => {
      queryClient.invalidateQueries(transferReleaseRightKeys.containersForUpdateRoot());
    },
  });
};
