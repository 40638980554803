import { call, put, takeLatest } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { getTerminals } from '../api/terminalApi';
import { loadTerminalsSucceeded } from '../actions/terminalActions';

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadTerminals() {
  try {
    const terminalList = yield call(getTerminals);
    yield put(loadTerminalsSucceeded(terminalList));
  } catch (err) {
    //yield put(getErrorAction(err)); todo: conditional load of terminals (not when user only has forwarder rol f.e.)
  }
}

function* terminalSaga() {
  yield takeLatest(ActionTypes.TERMINALS_REQUESTED, loadTerminals);
}

export default terminalSaga;
