import { TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';
import { LABEL } from 'theme/constants';

type Props = {
  numberOfContainersSelected: number;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
};

const UpdateStep: React.FC<Props> = ({ numberOfContainersSelected, reason, setReason }: Props) => {
  const { t } = useTranslation(['changeVoyage', 'validation']);
  const [error, setError] = React.useState(false);
  const classes = useStyles();

  const maxLength = 254;

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleReasonChange = (_event: React.ChangeEvent<{ value: string }>) => {
    const value = _event.target.value;
    if (value.length > maxLength) {
      setError(true);
      return;
    }
    setReason(value);
  };

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.changeCommercialRelease.selectedContainers', {
          numberOfContainersSelected: containersToUpdate,
        })}
      </Typography>
      <TextField
        fullWidth
        multiline
        maxRows={5}
        label={LABEL.REASON}
        error={error}
        helperText={error ? `max ${maxLength} chars long` : ''}
        value={reason}
        onChange={(e) => handleReasonChange(e)}
        variant="outlined"
      />
    </React.Fragment>
  );
};

export default UpdateStep;
