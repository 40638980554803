import { MenuItem, Select, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MeansOfTransport } from '../../../global/adapters/crpAPI';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
  setMeansOfTransport: React.Dispatch<React.SetStateAction<string>>;
  newMeansOfTransport: string;
};

const AssignPickUpStep: React.FC<Props> = ({
  setMeansOfTransport,
  newMeansOfTransport,
  numberOfContainersSelected,
}: Props) => {
  const { t } = useTranslation('assignPickUp');
  const classes = useStyles();

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleMeansOfTransportChange = (
    _event: React.ChangeEvent<{}>,
    meansOfTransport: string
  ) => {
    setMeansOfTransport(meansOfTransport);
  };

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
      <Select
        defaultValue={newMeansOfTransport}
        value={newMeansOfTransport}
        fullWidth
        variant="outlined"
        onChange={(e) => {
          handleMeansOfTransportChange(e, e.target.value as string);
        }}
      >
        {Object.keys(MeansOfTransport)
          .filter((status) => status !== MeansOfTransport.Truck)
          .map((status) => (
            <MenuItem value={status} key={status}>
              {status}
            </MenuItem>
          ))}
      </Select>
    </React.Fragment>
  );
};

export default AssignPickUpStep;
