import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { bindActionCreators } from 'redux';
import {
  closePickupConfirmation,
  closePickupRevokeConfirmation,
} from '../../store/actions/containerActions';
import {
  getPickupConfirmationContainers,
  getPickupConfirmationOpen,
  getPickupRevokeConfirmationOpen,
  getPickupRevokeConfirmationContainers,
  getAlfapassNumber,
  getOldAlfapassNumber,
} from '../../store/selectors/selectors';
import { LABEL, DIALOG, FORMATTING } from '../../theme/constants';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
});

const PickupConfirmationDialog = (props) => {
  const {
    classes,
    pickupConfirmationOpen,
    pickupRevokeConfirmationOpen,
    pickupContainers,
    pickupRevokeContainers,
    closePickupConfirmation,
    closePickupRevokeConfirmation,
    alfapassNumber,
  } = props;

  const [setTextFieldErrored] = React.useState(false);

  const confirmationActions = (
    <div>
      <Button onClick={closePickupConfirmation} color="primary">
        {LABEL.CLOSE}
      </Button>
    </div>
  );

  return (
    <>
      <Dialog
        open={pickupConfirmationOpen}
        onClose={() => {
          closePickupConfirmation();
          setTextFieldErrored(false);
        }}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby={'pickup-confirm'}
      >
        <DialogTitle aria-labelledby={'pickup-confirm-title'}>
          {DIALOG.CONFIRMATION_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines} color="textSecondary">
            {DIALOG.PICKUP_CONFIRMATION_CONTENT.replace(
              FORMATTING.CONTAINER_BL_NUMBER_LIST,
              pickupContainers
                .map((container) => {
                  return (
                    container.equipmentNumber +
                    '\t - \t[' +
                    container.billOfLadingNumbers.join(', ') +
                    ']'
                  );
                })
                .join('\n ')
            ).replace(FORMATTING.PHONE_NUMBER, alfapassNumber)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{confirmationActions}</DialogActions>
      </Dialog>
      <Dialog
        open={pickupRevokeConfirmationOpen}
        onClose={() => {
          closePickupRevokeConfirmation();
          setTextFieldErrored(false);
        }}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby={'pickup-revoke-confirm'}
      >
        <DialogTitle aria-labelledby={'pickup-revoke-confirm-title'}>
          {DIALOG.CONFIRMATION_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines} color="textSecondary">
            {DIALOG.PICKUP_REVOKE_CONFIRMATION_CONTENT.replace(
              FORMATTING.CONTAINER_BL_NUMBER_LIST,
              pickupRevokeContainers
                .map((container) => {
                  return (
                    container.equipmentNumber +
                    '\t - \t[' +
                    container.billOfLadingNumbers.join(', ') +
                    ']'
                  );
                })
                .join('\n ')
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePickupRevokeConfirmation} color="primary">
            {LABEL.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function mapStateToProps(state) {
  return {
    pickupConfirmationOpen: getPickupConfirmationOpen(state),
    pickupRevokeConfirmationOpen: getPickupRevokeConfirmationOpen(state),
    pickupContainers: getPickupConfirmationContainers(state),
    pickupRevokeContainers: getPickupRevokeConfirmationContainers(state),
    alfapassNumber: getOldAlfapassNumber(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closePickupConfirmation,
      closePickupRevokeConfirmation,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PickupConfirmationDialog));
