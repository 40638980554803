import { TextField, TextFieldProps } from '@material-ui/core';
import { Column, RowData, RowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

type Props<T extends RowData, TValue extends unknown> = TextFieldProps & {
  column: Column<T, TValue>;
  getPreFilteredRowModel: () => RowModel<T>;
};

const Filter = <T extends RowData, TValue extends unknown>({
  column,
  getPreFilteredRowModel,
  ...rest
}: Props<T, TValue>) => {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  const firstRowColumnValue = getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  // TODO: Add debounced input
  // TODO: If other values than strings need to be supported, add cases here
  if (typeof firstRowColumnValue === 'string') {
    return (
      <span className={classes.filter}>
        <TextField
          {...rest}
          fullWidth
          variant={'outlined'}
          size={'small'}
          placeholder={t('dataGrid.filterPlaceholder')}
          onChange={(event) => column.setFilterValue(event.target.value)}
        />
      </span>
    );
  } else {
    return (
      <span className={classes.filter}>
        <TextField
          {...rest}
          fullWidth
          variant={'outlined'}
          size={'small'}
          placeholder={t('dataGrid.filterPlaceholder')}
          onChange={(event) => column.setFilterValue(event.target.value)}
        />
      </span>
    );
  }
};

export default Filter;
