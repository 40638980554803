export const getContainersFilters = (state) => state.containers.filters;
export const getContainersPaging = (state) => state.containers.paging;
export const getContainersLoading = (state) => state.containers.loading;
export const getContainers = (state) => state.containers.items;
export const getSelectedContainer = (state) => state.containers.selected;

export const getEventHistory = (state) => state.eventHistory;

export const getDriverList = (state) => state.driverList.items;
export const getDriverCount = (state) => state.driverList.totalElements;
export const getRefreshDriverList = (state) => state.driverList.refreshDriverList;
export const getDriverListLoading = (state) => state.driverList.loading;
export const getDriverListPageNumber = (state) => state.driverList.number;
export const getCurrentDriverListPageSize = (state) => state.driverList.currentPageSize;
export const getCurrentDriverListPage = (state) => state.driverList.currentPage;

export const getSubscriptionsPaging = (state) => state.subscriptions.paging;
export const getSubscriptionsLoadingNew = (state) => state.subscriptions.loadingNew;
export const getSubscriptionsLoading = (state) => state.subscriptions.loading;
export const getSubscriptions = (state) => state.subscriptions.items;

export const getPoliceContainersFilters = (state) => state.policeContainers.filters;
export const getPoliceContainersPoliceCase = (state) => state.policeContainers.policeCase;
export const getPoliceContainersPaging = (state) => state.policeContainers.paging;
export const getPoliceContainersLoading = (state) => state.policeContainers.loading;
export const getPoliceContainers = (state) => state.policeContainers.items;

export const getPoliceSelected = (state) => state.policeContainers.selected;
export const getPoliceSelectedBillOfLadingNumbers = (state) =>
  state.policeContainers.selected.billOfLadingNumbers;
export const getPoliceSelectedReleaseId = (state) => state.policeContainers.selected.releaseId;

export const selectScrEvents = (state) => state.policeContainers.scrEvents.items;

export const getPickupConfirmationOpen = (state) =>
  state.containers.confirmationDialogs.pickup.open;
export const getPickupConfirmationContainers = (state) =>
  state.containers.confirmationDialogs.pickup.containers;
export const getPickupRevokeConfirmationOpen = (state) =>
  state.containers.confirmationDialogs.pickupRevoke.open;
export const getPickupRevokeConfirmationContainers = (state) =>
  state.containers.confirmationDialogs.pickupRevoke.containers;

export const getCompaniesFilters = (state) => state.companies.filters;
export const getCompaniesPaging = (state) => state.companies.paging;
export const getCompaniesLoading = (state) => state.companies.loading;
export const getCompanies = (state) => state.companies.items;

export const getReleasesFilters = (state) => state.releases.filters;
export const getReleasesPaging = (state) => state.releases.paging;
export const getReleasesLoading = (state) => state.releases.loading;
export const getReleases = (state) => state.releases.items;

export const getArchive = (state) => state.archive.items;
export const getArchiveLoading = (state) => state.archive.loading;
export const getArchivePaging = (state) => state.archive.paging;
export const getArchiveFilters = (state) => state.archive.filters;

export const getSelectedContainers = (state) => state.selectedContainers;
export const getSelectedReleases = (state) => state.selectedReleases;

export const getPendingReleaseCount = (state) => state.counts.pendingReleaseCount;

export const getUserProfile = (state) => state.user.userProfile;
export const getSelectedUserType = (state) => state.user.selectedType;
export const getCreditIdentificationResponse = (state) => state.user.creditIdentificationResponse;
export const getCreditIdentification = (state) =>
  state.user.creditIdentificationResponse != null
    ? state.user.creditIdentificationResponse.creditIdentification
    : null;
export const getRemainingCredits = (state) => state.user.remainingCredits;
export const getCreditIdentificationLoading = (state) => state.user.loadingCreditIdentification;
export const getIsPairedWithCinvio = (state) => state.user.isPairedWithCinvio;
export const getHasFullSubscription = (state) => state.user.hasFullSubscription;
export const getTokenExpired = (state) => state.user.tokenExpired;
export const getTokenExpiring = (state) => state.user.tokenExpiring;

export const getSignalRId = (state) => state.signalR.id;
export const getSignalRSetupError = (state) => state.signalR.error;

export const getSelectedStakeholder = (state) => state.selectedStakeholder;

export const getTerminals = (state) => state.terminals.items;
export const getRefreshContainers = (state) => state.containers.refreshContainers;

export const getAlfapassNumber = (state) => state.alfapass.number;
export const getOldAlfapassNumber = (state) => state.alfapass.old;
export const getAlfapassNumberStatus = (state) => state.alfapass.status;

export const getChangedTab = (state) => state.tab.changed;

export const getApiMessage = (state) => state.message;

export const selectCompanySubscriptions = (state) =>
  state.policeContainers.companySubscriptions.items;

export const getRefreshListPoliceContainers = (state) => state.policeContainers.refresh;
export const getRefreshPoliceCompanySubscriptions = (state) =>
  state.policeContainers.companySubscriptions.refresh;
export const getCompanySubscriptionIsCreated = (state) =>
  state.policeContainers.companySubscriptions.isCreated;
