import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../global/adapters';
import { CustomsLightManualOverwriteRequest } from '../../../../global/adapters/crpAPI';
import { overrulingLightsKeys } from './queryKeys';

export const useGetContainersToBeOverruled = (partyPath: string, equipmentNumbers?: string[]) => {
  const isSearchEntered = equipmentNumbers != undefined && equipmentNumbers.length > 0;

  return useQuery(
    overrulingLightsKeys.getContainersForOverrulingLights(equipmentNumbers),
    () => api.containers.getContainersToBeOverruled(partyPath, equipmentNumbers!),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(overrulingLightsKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};

export const checkContainerToBeOverruled = (partyPath: string, equipmentNumber: string) => {
  var response = api.containers.checkContainerToBeOverruled(partyPath, equipmentNumber);
  return response;
};

export const setCustomsLightManualOverwriteRequest = (
  releaseIds: string[],
  reason: string,
  reasonText: string,
  alfapassToken: string
) => {
  var request = new CustomsLightManualOverwriteRequest(releaseIds, reason);
  var response = api.greenLights.setCustomsLightManualOverwrite(request, {
    headers: { 'X-Alfapass-Token': alfapassToken },
  });
  return response;
};
