import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import CollapsibleRow from 'components/molecules/CollapsibleRow';

function CollapsibleTable(props) {
  const { items, parentColumns, title } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {parentColumns &&
              parentColumns.map((column, index) => {
                return index == 0 ? (
                  <TableCell component="th" scope="row">
                    {column.toUpperCase()}
                  </TableCell>
                ) : (
                  <TableCell align="right">{column.toUpperCase()}</TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.length > 0 &&
            items.map((item, index) => {
              return <CollapsibleRow key={`collapsible_row_${index}`} row={item} title={title} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CollapsibleTable;
