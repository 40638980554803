import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import {
  GetContainersForUpdatecommercialexpirydateParams,
  UpdateCommercialExpiryDateRequest,
} from '../../../global/adapters/crpAPI';
import { changeExpiryDateKeys } from './queryKeys';

export const useGetContainersForUpdateCommercialExpiryDate = (
  vesselParams: GetContainersForUpdatecommercialexpirydateParams
) => {
  const isSearchEntered =
    (vesselParams.fromDate !== undefined && vesselParams.fromDate !== '') ||
    (vesselParams.toDate !== undefined && vesselParams.toDate !== '');

  return useQuery(
    changeExpiryDateKeys.containersForUpdate(vesselParams),
    () => api.containers.getContainersForUpdatecommercialexpirydate(vesselParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersUpdatecommercialexpirydate = (successCallback?: Function) => {
  const queryClient = useQueryClient();

  return useMutation(changeExpiryDateKeys.postContainersUpdatecommercialexpirydate(), {
    mutationFn: (data: UpdateCommercialExpiryDateRequest) =>
      api.containers.postContainersUpdatecommercialexpirydate(data),
    onSuccess: () => {
      queryClient.invalidateQueries(changeExpiryDateKeys.containersForUpdateRoot());
      successCallback?.();
    },
  });
};
