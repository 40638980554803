import { Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history/browserHistory';
import { blockContainers, releaseContainers } from '../../store/actions/archiveActions';
import { revokeContainers, selectContainersForScan } from '../../store/actions/containerActions';
import { revokeContainerPickups } from '../../store/actions/alfapassActions';
import {
  blockCommercialRelease,
  revokeCommercialRelease,
  deleteCommercialRelease,
  unblockCommercialRelease,
} from '../../store/actions/commercialReleaseActions';
import { acceptReleases, declineReleases } from '../../store/actions/releaseActions';
import { ACTIONTYPES, DIALOGTYPES, DIALOG, LABEL, ERROR } from '../../theme/constants';
import PickupDialog from '../organisms/PickupDialog';
import YesNoDialog from '../organisms/YesNoDialog';
import AddIcon from '@material-ui/icons/Add';
import {
  getRemainingCredits,
  getSelectedUserType,
  getCreditIdentification,
} from '../../store/selectors/selectors';
import store from '../../store/store';
import { errorMessage } from '../../store/actions/messageActions';

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  extended: {
    minHeight: theme.spacing(10),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
  },
  formButton: {
    float: 'right',
    marginRight: '10px',
  },
  toolbarButtons: {
    display: 'flex',
    marginLeft: '50px',
  },
  wideCell: {
    marginRight: '15px',
  },
});

const ContainerToolbar = (props) => {
  const {
    selected,
    numItems,
    classes,
    headerTitle,
    containers,
    extended,
    acceptReleases,
    declineReleases,
    revokeContainers,
    revokeContainerPickups,
    blockContainers,
    releaseContainers,
    selectContainersForScan,
    handleSelects,
    selectedPickupContainer,
    pickupCallback,
    blockCommercialRelease,
    unblockCommercialRelease,
    revokeCommercialRelease,
    deleteCommercialRelease,
    selectedUserType,
    remainingCredits,
    creditIdentification,
  } = props;

  const forwardClick = () => {
    history.push('/containers/company');
  };

  const checkValidButton = (action) => {
    return selected
      .map((s) =>
        s.releaseRightActions.filter((a) => a.isEnabled).map((s) => s.label.toUpperCase())
      )
      .every((actions) => actions.includes(action));
  };

  const onClickNewRelease = (e) => {
    e.stopPropagation();
    history.push('/commercial-release/add');
  };

  const performAccept = () => {
    acceptReleases({
      containers: selected,
      actionType: ACTIONTYPES.ACCEPT,
    });
  };

  const acceptCallback = () => {
    handleSelects([]);
    let numberOfSelectedContainers = selected.length;
    if (
      selectedUserType == LABEL.TRANSPORTOPERATOR_LABEL &&
      creditIdentification.hasPaidSubscription == false &&
      remainingCredits < numberOfSelectedContainers
    ) {
      store.dispatch(errorMessage(ERROR.INSUFFICIENT_AMOUNT_CREDITS));
    }
    performAccept();
  };

  return headerTitle ? (
    <Toolbar
      className={classNames(
        classes.root,
        { [classes.highlight]: selected.length > 0 },
        { [classes.extended]: extended }
      )}
    >
      <div className={classes.title}>
        {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
          headerTitle == LABEL.RELEASE_RIGHT_OVERVIEW && (
            <div style={{ float: 'right', marginRight: '10px' }}>
              <Button
                style={{ padding: '14px 12px' }}
                size="large"
                variant="contained"
                color="primary"
                fullWidth={true}
                className="btn btn-primary"
                onClick={(e) => onClickNewRelease(e)}
              >
                <AddIcon /> {LABEL.NEW_RELEASE}
              </Button>
            </div>
          )}
        <div style={{ float: 'left' }}>
          {selected.length > 0 ? (
            <div style={{ display: 'inline-flex' }}>
              <Typography color="inherit" variant="subtitle1" style={{ lineHeight: '2.10' }}>
                {selected.length} {LABEL.SELECTED.toLowerCase()}
              </Typography>
              {containers ? (
                <div className={classes.toolbarButtons}>
                  {checkValidButton(LABEL.TRANSFER.toUpperCase()) && (
                    <Button
                      color="primary"
                      style={{ marginRight: '15px' }}
                      onClick={forwardClick}
                      variant={'outlined'}
                    >
                      {LABEL.TRANSFER_SINGLE_PARTY}
                    </Button>
                  )}
                  {checkValidButton(LABEL.REVOKE_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        revokeContainers({
                          containers: selected,
                          actionType: ACTIONTYPES.REVOKE,
                        });
                      }}
                      dialogType={DIALOGTYPES.REVOKE_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.REVOKE_NEXT_PARTY_TITLE}
                      contentText={DIALOG.REVOKE_NEXT_PARTY_CONTENT}
                      buttonTitle={LABEL.REVOKE_NEXT_PARTY}
                    />
                  )}
                  {checkValidButton(LABEL.PICKUP_LABEL) && (
                    <PickupDialog
                      multiple={true}
                      callback={pickupCallback}
                      variant={'outlined'}
                      className={classes.wideCell}
                      container={selected[0]}
                      selectedContainer={selectedPickupContainer}
                      buttonTitle={LABEL.PICKUP_NEXT_PARTY}
                    />
                  )}
                  {checkValidButton(LABEL.REVOKEPICKUP_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        revokeContainerPickups(selected);
                      }}
                      dialogType={DIALOGTYPES.REVOKE_MULTIPLE}
                      className={classes.wideCell}
                      buttonTitle={LABEL.REVOKEPICKUP_LABEL}
                      dialogTitle={DIALOG.REVOKE_NEXT_PARTY_TITLE}
                      contentText={DIALOG.REVOKE_NEXT_PARTY_CONTENT}
                    />
                  )}
                  {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
                    selected.map((x) => x.releaseLightStatus).every((x) => x === 'Ok') && (
                      <YesNoDialog
                        variant={'outlined'}
                        callback={() => {
                          handleSelects([]);
                          blockCommercialRelease({
                            containers: selected,
                            actionType: ACTIONTYPES.BLOCK,
                          });
                        }}
                        dialogType={DIALOGTYPES.BLOCK_MULTIPLE_COMM_REL}
                        className={classes.wideCell}
                        dialogTitle={DIALOG.BLOCK_COMM_REL_TITLE}
                        contentText={DIALOG.BLOCK_COMM_REL_CONTENT}
                        buttonTitle={LABEL.BLOCK_COMM_REL_LABEL}
                      />
                    )}
                  {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
                    selected.map((x) => x.releaseLightStatus).every((x) => x === 'Blocked') && (
                      <YesNoDialog
                        variant={'outlined'}
                        callback={() => {
                          handleSelects([]);
                          unblockCommercialRelease({
                            containers: selected,
                            actionType: ACTIONTYPES.UNBLOCK,
                          });
                        }}
                        dialogType={DIALOGTYPES.UNBLOCK_MULTIPLE_COMM_REL}
                        className={classes.wideCell}
                        dialogTitle={DIALOG.UNBLOCK_COMM_REL_TITLE}
                        contentText={DIALOG.UNBLOCK_COMM_REL_CONTENT}
                        buttonTitle={LABEL.UNBLOCK_COMM_REL_LABEL}
                      />
                    )}
                  {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
                    selected
                      .map((x) =>
                        x.releaseRightActions
                          .map((x) => x.label.toUpperCase())
                          .every((label) => label !== LABEL.TRANSFER.toUpperCase())
                      )
                      .every((result) => result) && (
                      <YesNoDialog
                        variant={'outlined'}
                        callback={() => {
                          handleSelects([]);
                          revokeCommercialRelease({
                            containers: selected,
                            actionType: ACTIONTYPES.REVOKE,
                          });
                        }}
                        dialogType={DIALOGTYPES.REVOKE_MULTIPLE_COMM_REL}
                        className={classes.wideCell}
                        dialogTitle={DIALOG.REVOKE_COMM_REL_TITLE}
                        contentText={DIALOG.REVOKE_COMM_REL_CONTENT}
                        buttonTitle={LABEL.REVOKE_COMM_REL_LABEL}
                      />
                    )}
                  {selectedUserType === LABEL.SHIPPING_AGENT_LABEL && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        deleteCommercialRelease({
                          containers: selected,
                          actionType: ACTIONTYPES.DELETE,
                        });
                      }}
                      dialogType={DIALOGTYPES.DELETE_MULTIPLE_COMM_REL}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.DELETE_COMM_REL_TITLE}
                      contentText={DIALOG.DELETE_COMM_REL_CONTENT}
                      buttonTitle={LABEL.DELETE_COMM_REL_LABEL}
                    />
                  )}
                  {checkValidButton(LABEL.BLOCK_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        blockContainers({
                          containers: selected,
                          actionType: ACTIONTYPES.BLOCK,
                        });
                      }}
                      dialogType={DIALOGTYPES.BLOCK_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.BLOCK_TITLE}
                      contentText={DIALOG.BLOCK_CONTENT}
                      buttonTitle={LABEL.BLOCK_LABEL}
                    />
                  )}
                  {checkValidButton(LABEL.SELECT_FOR_SCAN_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        selectContainersForScan({
                          containers: selected,
                          actionType: ACTIONTYPES.SCANNING,
                        });
                      }}
                      dialogType={DIALOGTYPES.SELECT_FOR_SCAN_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.SELECT_FOR_SCAN_TITLE}
                      contentText={DIALOG.SELECT_FOR_SCAN_CONTENT}
                      buttonTitle={LABEL.SELECT_FOR_SCAN_LABEL}
                    />
                  )}
                  {checkValidButton(LABEL.RELEASE_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        releaseContainers({
                          containers: selected,
                          actionType: ACTIONTYPES.RELEASE,
                        });
                      }}
                      dialogType={DIALOGTYPES.RELEASE_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.RELEASE_TITLE}
                      contentText={DIALOG.RELEASE_CONTENT}
                      buttonTitle={LABEL.RELEASE_LABEL}
                    />
                  )}
                </div>
              ) : (
                <div className={classes.toolbarButtons}>
                  {checkValidButton(LABEL.ACCEPT_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={acceptCallback}
                      dialogType={DIALOGTYPES.ACCEPT_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.ACCEPT_TITLE}
                      contentText={DIALOG.ACCEPT_CONTENT}
                      buttonTitle={LABEL.ACCEPT_LABEL}
                    />
                  )}
                  {checkValidButton(LABEL.DECLINE_LABEL) && (
                    <YesNoDialog
                      variant={'outlined'}
                      callback={() => {
                        handleSelects([]);
                        declineReleases({
                          containers: selected,
                          actionType: ACTIONTYPES.DECLINE,
                        });
                      }}
                      dialogType={DIALOGTYPES.DECLINE_MULTIPLE}
                      className={classes.wideCell}
                      dialogTitle={DIALOG.DECLINE_TITLE}
                      contentText={DIALOG.DECLINE_CONTENT}
                      buttonTitle={LABEL.DECLINE_LABEL}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {headerTitle}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions} />
    </Toolbar>
  ) : numItems && numItems > 0 ? (
    <div style={{ padding: '10px' }} />
  ) : null;
};

ContainerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  acceptReleases: PropTypes.func,
  declineReleases: PropTypes.func,
  revokeReleases: PropTypes.func,
  releaseContainers: PropTypes.func,
  revokeContainerPickups: PropTypes.func,
  blockContainers: PropTypes.func,
  blockCommercialRelease: PropTypes.func,
  selectContainersForScan: PropTypes.func,
  handleSelects: PropTypes.func,
  headerTitle: PropTypes.string,
  selected: PropTypes.array,
  numItems: PropTypes.number,
  extended: PropTypes.bool,
  selectedUserType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    creditIdentification: getCreditIdentification(state),
    remainingCredits: getRemainingCredits(state),
    selectedUserType: getSelectedUserType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptReleases,
      declineReleases,
      revokeContainers,
      revokeContainerPickups,
      selectContainersForScan,
      blockContainers,
      releaseContainers,
      blockCommercialRelease,
      unblockCommercialRelease,
      revokeCommercialRelease,
      deleteCommercialRelease,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(toolbarStyles)(ContainerToolbar));
