import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import {
  GetContainersByReceivedFromParams,
  IdentificationType,
  TerminalContainer,
} from 'modules/global/adapters/crpAPI';
import { useLocalTableValues } from 'modules/global/hooks';
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { useGetContainersTransferReleaseRight } from '../adapters/transfer-releaseright/queries';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { StepperTransferReleaseRight } from '../components/transfer-releaseright';
import EmptyState from 'modules/global/components/EmptyState/EmptyState';
import { DataGrid, Drawer } from 'modules/global/components';
import { releasePartyColumns } from '../constants/shared/releasePartyColumns';
import { ContainerDetailDrawer } from '../components/shared';
import { connect, ConnectedProps } from 'react-redux';
import { getSelectedUserType } from '../../../store/selectors/selectors';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

interface IPropFromRedux extends PropsFromRedux {
  selectedUserType: any;
}

interface IProps extends IPropFromRedux, Props {}

const initialFields: GetContainersByReceivedFromParams = {
  terminalNxtEntityId: '',
  identificationType: IdentificationType.NxtEntityId,
  identificationCode: '',
  billOfLadingNumber: '',
};

const TransferReleaseRight: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation(['acceptReleaseRight', 'common', 'validation']);
  const formRef = useRef<FormikProps<GetContainersByReceivedFromParams>>();

  const [userType, _] = React.useState(props.selectedUserType);
  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: TerminalContainer;
  }>({
    open: false,
    data: undefined,
  });

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<TerminalContainer>) | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<Row<TerminalContainer>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-assign-release-party',
    {}
  );

  const { data, isLoading, isFetching, isError } = useGetContainersTransferReleaseRight({
    terminalNxtEntityId: formRef?.current?.values.terminalNxtEntityId,
    identificationCode: formRef?.current?.values.identificationCode,
    identificationType: formRef?.current?.values.identificationType,
    billOfLadingNumber: formRef?.current?.values.billOfLadingNumber,
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: TerminalContainer) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<GetContainersByReceivedFromParams>>}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            submitForm,
            resetForm,
            values,
          }: FormikProps<GetContainersByReceivedFromParams>) => {
            return (
              <StepperTransferReleaseRight
                errors={errors}
                selectedRows={selectedRows}
                setRowSelection={setRowSelection}
                setDisplayGrid={setDisplayGrid}
                data={data}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
                submitForm={submitForm}
                selectedUserType={userType}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={releasePartyColumns}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onCellClick={handleCellClick}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.titleRp')} subTitle={t('emptyState.subTitleRp')} />
        )}
      </Grid>
    </Grid>
  );
};

const mapState = (state: any) => {
  return {
    selectedUserType: getSelectedUserType(state),
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransferReleaseRight);
