import { MESSAGETYPES } from '../../theme/constants';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

const messageTypeMapping = {
  [ActionTypes.MESSAGE_ERROR]: MESSAGETYPES.ERROR,
  [ActionTypes.MESSAGE_WARNING]: MESSAGETYPES.WARNING,
  [ActionTypes.MESSAGE_INFO]: MESSAGETYPES.INFO,
  [ActionTypes.MESSAGE_SUCCESS]: MESSAGETYPES.SUCCESS,
  [ActionTypes.MESSAGE_API_ERRORS]: MESSAGETYPES.API_ERRORS,
  [ActionTypes.MESSAGE_API_SUCCESS]: MESSAGETYPES.API_SUCCESS,
  [ActionTypes.MESSAGE_BROADCAST_MESSAGE]: MESSAGETYPES.BROADCAST_MESSAGE,
};

export default function (state = InitialState.message, action) {
  if (messageTypeMapping[action.type]) {
    return {
      type: messageTypeMapping[action.type],
      content: action.payload,
    };
  }
  return state;
}
