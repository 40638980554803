import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    fontSize: '20px',
    marginLeft: '10px',
  },
  flavourText: {
    marginLeft: '10px',
    fontSize: '0.85rem',
    whiteSpace: 'break-spaces',
  },
  button: {
    marginRight: '10px',
  },
  stepper: {
    '& .MuiStep-vertical, .MuiStepConnector-line': {
      marginLeft: '-37px',
    },
    '& .MuiStepContent-root': {
      borderLeft: 'none',
    },
  },
  connector: {
    margin: '10px 0',
  },
  faqButton: {
    margin: '24px 0 0 24px',
  },
});

export default useStyles;
