import ActionTypes from "./actionTypes";

export function blockCommercialRelease(payload) {
    return {
        type: ActionTypes.BLOCK_COMMERCIAL_RELEASE,
        payload: payload
    };
}

export function unblockCommercialRelease(payload) {
    return {
        type: ActionTypes.UNBLOCK_COMMERCIAL_RELEASE,
        payload: payload
    };
}

export function deleteCommercialRelease(payload) {
    return {
        type: ActionTypes.DELETE_COMMERCIAL_RELEASE,
        payload: payload
    };
}

export function revokeCommercialRelease(payload) {
    return {
        type: ActionTypes.REVOKE_COMMERCIAL_RELEASE,
        payload: payload
    };
}

export function updateCommercialRelease(payload) {
    return {
        type: ActionTypes.UPDATE_COMMERCIAL_RELEASE,
        payload: payload
    };
}

export function createNewRelease(payload) {
    return {
        type: ActionTypes.RELEASE_COMMERCIAL_RELEASE,
        payload: payload
    }
}