import ActionTypes from './actionTypes';

export function loadSelectedContainersSucceeded(containers) {
  return {
    type: ActionTypes.SELECTED_CONTAINERS_REQUEST_SUCCEEDED,
    payload: containers,
  };
}

export function clearSelectedContainers() {
  return {
    type: ActionTypes.CLEAR_SELECTED_CONTAINERS,
    payload: []
  }
}
