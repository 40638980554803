import * as Yup from 'yup';

const updatePoliceCaseSchema = Yup.object().shape({
  referenceNumber: Yup.string()
    .required('Reference Number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),

  number: Yup.string()
    .required('Number is required')
    .min(3, 'Minimum length is 3')
    .max(25, 'Maximum length is 25'),
});

export default updatePoliceCaseSchema;
