import ActionTypes from './actionTypes';

export function loadDriverList() {
  return {
    type: ActionTypes.DRIVERLIST_REQUESTED,
  };
}

export function loadDriverListSucceeded(driverlist) {
  return {
    type: ActionTypes.DRIVERLIST_REQUEST_SUCCEEDED,
    payload: driverlist,
  };
}

export function refreshDriverList() {
  return {
    type: ActionTypes.REFRESH_DRIVER_LIST,
  };
}

export function addDriverToList() {
  return {
    type: ActionTypes.ADD_TO_DRIVER_LIST,
  };
}

export function deleteFromDriverList() {
  return {
    type: ActionTypes.DELETE_FROM_DRIVER_LIST,
  };
}

export function updateDriverList() {
  return {
    type: ActionTypes.UPDATE_DRIVER_LIST,
  };
}

export function submitDriverAlias(driverAlias) {
  return {
    type: ActionTypes.SUBMIT_DRIVERALIAS,
    payload: driverAlias,
  };
}

export function submitDriverAliasSucceeded() {
  return {
    type: ActionTypes.SUBMIT_DRIVERALIAS_SUCCEEDED,
  };
}

export function updateDriverAlias(driverAlias) {
  return {
    type: ActionTypes.UPDATE_DRIVERALIAS,
    payload: driverAlias,
  };
}

export function deleteDriverAlias(driverAliasId) {
  return {
    type: ActionTypes.DELETE_DRIVERALIAS,
    payload: driverAliasId,
  };
}
