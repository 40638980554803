import { Paper, Divider } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { FilterNone as ContainersIcon, Info as InfoIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history/browserHistory';
import {
  loadCompanies,
  loadCompaniesFilters,
  loadCompaniesPage,
  loadCompaniesRowsPerPage,
} from '../../store/actions/companiesActions';
import { forwardContainers, setRefreshContainers } from '../../store/actions/containerActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import { loadSelectedContainersSucceeded } from '../../store/actions/selectedContainerActions';
import { selectedStakeholderChanged } from '../../store/actions/selectedStakeholderActions';
import {
  getCompanies,
  getCompaniesLoading,
  getCompaniesPaging,
  getSelectedContainers,
  getSelectedUserType,
} from '../../store/selectors/selectors';
import { LABEL } from '../../theme/constants';
import BreadcrumbBar from '../molecules/BreadcrumbBar';
import CompanyFilter from '../organisms/CompanyFilter';
import CompanyTable from '../organisms/CompanyTable';
import ContainerTable from '../organisms/ContainerTable';
import LoaderTimeout from '../organisms/LoaderTimeout';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  tabBar: {
    flexWrap: 'nowrap',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  tabs: {
    flex: 1,
  },
  paperSlim: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
    // minHeight: '560px',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  companiesCircularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
});

const columns = [
  { id: 'deleteForeverIcon' },
  { id: 'billOfLading', label: LABEL.BILL_OF_LADING_LABEL },
  { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
  { id: 'shippingAgent', label: LABEL.SHIPPIN_AGENT_LABEL },
  { id: 'receivedFrom', label: LABEL.RECEIVED_FROM_LABEL },
  { id: 'receivedOn', label: LABEL.RECEIVED_ON_LABEL },
  { id: 'validUntil', label: LABEL.VALID_UNTIL_LABEL },
  { id: 'terminal', label: LABEL.TERMINAL_LABEL },
  { id: 'status', label: LABEL.STATUS_LABEL },
];

const columnsCompanies = [
  { id: 'nxtEntityId', label: LABEL.NXTENTITY_ID_LABEL },
  { id: 'apcs-code', label: LABEL.APCS_CODE },
  { id: 'eori', label: LABEL.EORI },
  { id: 'duns', label: LABEL.DUNS },
  { id: 'name', label: LABEL.NAME_LABEL },
  { id: 'taxId', label: LABEL.TAX_ID_LABEL },
  { id: 'transfer', label: LABEL.TRANSFER },
];

class ContainerDetail extends React.Component {
  componentDidMount() {
    this.props.loadCompanies();
  }

  getColumns = () => {
    if (this.props.selectedUserType === LABEL.TERMINAL) {
      return columns.filter((column) => column.id !== 'terminal' && column.id !== 'receivedFrom');
    } else if (this.props.selectedUserType === LABEL.POLICE) {
      return columns.map((column) => {
        if (column.id === 'receivedFrom') {
          column.label = LABEL.CURRENT_OWNER_LABEL;
        }
        return column;
      });
    } else if (this.props.selectedUserType === LABEL.SHIPPING_AGENT_LABEL) {
      return columns.filter(
        (column) => column.id !== 'shippingAgent' && column.id !== 'receivedFrom'
      );
    } else {
      return columns;
    }
  };

  handleChangeTab = (event, value) => {
    history.replace('/containers/' + value);
  };

  render() {
    const {
      items,
      paging,
      classes,
      companies,
      loadCompanies,
      loading,
      loadCompaniesPage,
      loadCompaniesRowsPerPage,
      loadSelectedContainersSucceeded,
      forwardContainers,
      selectedUserType,
      setRefreshContainers,
    } = this.props;
    const activeTab = this.props.match.params.tabId;

    return (
      <React.Fragment>
        <main className={classes.content}>
          <Divider />
          <div className={classes.tabBar}>
            <div className={classes.tabs}>
              <Tabs
                value={activeTab}
                onChange={(event, value) => this.handleChangeTab(event, value)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value="company" label={LABEL.COMPANY} icon={<InfoIcon />} />
                <Tab
                  value="containers"
                  label={LABEL.CONTAINERS}
                  icon={
                    <Badge
                      badgeContent={items.length}
                      showZero
                      color={items.length === 0 ? 'secondary' : 'primary'}
                    >
                      <ContainersIcon />
                    </Badge>
                  }
                />
              </Tabs>
            </div>
          </div>
          <Divider />
          <div className={classes.divider} />
          {activeTab === 'containers' && (
            <Paper>
              <ContainerTable
                columns={this.getColumns()}
                items={items}
                paging={paging}
                selected={items}
                loadSelectedSucceeded={loadSelectedContainersSucceeded}
                detailContainers
                selectedUserType={selectedUserType}
              />
            </Paper>
          )}
          {activeTab === 'company' && (
            <main>
              {loading ? (
                <CircularProgress className={classes.companiesCircularProgress} />
              ) : (
                <div>
                  <Paper className={classes.paper}>
                    <CompanyFilter />
                  </Paper>
                  <Paper className={classes.paper}>
                    <CompanyTable
                      columns={columnsCompanies}
                      items={companies}
                      paging={paging}
                      loadCompanies={loadCompanies}
                      loadCompaniesPage={loadCompaniesPage}
                      loadCompaniesRowsPerPage={loadCompaniesRowsPerPage}
                      headerTitle={LABEL.SELECT_COMPANY_TITLE}
                      onTransfer={(selectedStakeholder) => {
                        forwardContainers(items, selectedStakeholder);
                        setRefreshContainers(false);
                        items.length = 0;
                        history.push('/containers');
                      }}
                    />
                  </Paper>
                </div>
              )}
              <main>
                <Button
                  color="primary"
                  style={{ marginRight: '15px' }}
                  variant={'outlined'}
                  onClick={() => history.push('/containers')}
                >
                  {LABEL.CANCEL.toUpperCase()}
                </Button>
              </main>
            </main>
          )}
        </main>
        <LoaderTimeout loading={loading} />
      </React.Fragment>
    );
  }
}

ContainerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  loadCompaniesFilters: PropTypes.func.isRequired,
  loadCompaniesPage: PropTypes.func.isRequired,
  loadCompaniesRowsPerPage: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  successMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    items: getSelectedContainers(state),
    companies: getCompanies(state),
    paging: getCompaniesPaging(state),
    loading: getCompaniesLoading(state),
    selectedUserType: getSelectedUserType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setRefreshContainers,
      loadCompanies,
      loadCompaniesFilters,
      loadCompaniesPage,
      loadCompaniesRowsPerPage,
      loadSelectedContainersSucceeded,
      selectedStakeholderChanged,
      forwardContainers,
      errorMessage,
      successMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContainerDetail));
