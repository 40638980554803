import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { Container, GetSearchContainersParams } from '../../global/adapters/crpAPI';
import { DataGrid } from '../../global/components';
import { Drawer } from '../../global/components/Drawer';
import EmptyState from '../../global/components/EmptyState/EmptyState';
import { useLocalTableValues } from '../../global/hooks';
import { useSearchContainers } from '../adapters/search-containers/queries';
import StepperSearchContainers from '../components/search-containers/StepperSearchContainers';
import ContainerDetailDrawer from '../components/search-containers/ContainerDetailDrawer';
import { containerOverviewColumns } from 'modules/global/constants/shared/containerOverviewColumns';
import shippingAgentParamsSchema from 'modules/global/constants/shared/shippingAgentParamsSchema';
import { LABEL } from 'theme/constants';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: GetSearchContainersParams = {
  equipmentNumber: '',
  shippingAgentNxtEntityId: LABEL.UNKNOWN,
  billOfLadingNumber: '',
  isArchived: false,
};

const SearchContainers: React.FC<Props> = () => {
  const { t } = useTranslation(['searchContainers', 'common', 'validation']);

  const formRef = useRef<FormikProps<GetSearchContainersParams>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: Container;
  }>({
    open: false,
    data: undefined,
  });

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-update-voyage',
    {}
  );

  const { data, isLoading, isFetching, isError } = useSearchContainers({
    equipmentNumber: formRef?.current?.values.equipmentNumber,
    shippingAgentNxtEntityId: formRef?.current?.values.shippingAgentNxtEntityId,
    billOfLadingNumber: formRef?.current?.values.billOfLadingNumber,
    role: LABEL.TRANSPORTOPERATOR_ROLE,
    isArchived: formRef?.current?.values.isArchived,
  });

  const handleOnSubmit = () => {
    // This double setDisplay is used to force a formRef update, to refetch with the new values
    setDisplayGrid(false);
    setDisplayGrid(true);
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  const handleCellClick = (myRowData: Container) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<GetSearchContainersParams>>}
          onSubmit={handleOnSubmit}
          validationSchema={shippingAgentParamsSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, resetForm, values, errors }: FormikProps<GetSearchContainersParams>) => {
            return (
              <StepperSearchContainers
                submitForm={submitForm}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
                errors={errors}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={containerOverviewColumns}
                  columnSizing={columnSizing}
                  data={data}
                  isLoading={isLoading}
                  onColumnSizingChange={setColumnSizing}
                  onCellClick={handleCellClick}
                  rowSelection={{}}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchContainers;
