import { ALFAPASSNUMBERSTATUSES } from '../../theme/constants';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.alfapass, action) {
  switch (action.type) {
    case ActionTypes.ALFAPASS_NUMBER_CHECKED: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case ActionTypes.SET_ALFAPASS_NUMBER: {
      return {
        ...state,
        number: action.payload,
      };
    }
    case ActionTypes.SET_OLD_ALFAPASS_NUMBER: {
      return {
        ...state,
        old: action.payload,
      };
    }
    case ActionTypes.RESET_ALFAPASS_NUMBER: {
      return {
        ...state,
        number: '',
      };
    }
    case ActionTypes.RESET_ALFAPASS_NUMBER_STATUS: {
      return {
        ...state,
        status: ALFAPASSNUMBERSTATUSES.TO_BE_CHECKED,
      };
    }
    case ActionTypes.GET_ALFAPASS_TOKEN_SUCCESS: {
      return {
        ...state,
        tokenRetrieved: true,
      };
    }
    default: {
      return state;
    }
  }
}
