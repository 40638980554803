import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LABEL, ACTIONTYPES, FORMATTING, DIALOG } from '../../theme/constants';
import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  blockCommercialRelease,
  unblockCommercialRelease,
  updateCommercialRelease,
  deleteCommercialRelease,
  revokeCommercialRelease,
} from '../../store/actions/commercialReleaseActions';
import FormikDialog from './formikDialog';
import addReasonSchema from 'modules/shipping-line/schemas/addReasonSchema';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
  errorText: {
    fontSize: '0.875rem',
    color: 'red',
  },
});

const ReleaseActionDialog = (props) => {
  const {
    classes,
    activeItem,
    releaseActionOpen,
    onClose,
    container,
    blockCommercialRelease,
    unblockCommercialRelease,
    updateCommercialRelease,
    deleteCommercialRelease,
    revokeCommercialRelease,
  } = props;

  const [reasonDialogOpen, setReasonDialogOpen] = React.useState(false);

  const handleBlock = (reason) => {
    blockCommercialRelease({
      containers: [container],
      reason: reason,
      actionType: ACTIONTYPES.BLOCK,
    });
  };

  const handleUnblock = () => {
    unblockCommercialRelease({
      containers: [container],
      actionType: ACTIONTYPES.UNBLOCK,
    });
  };

  const handleRevoke = (reason) => {
    revokeCommercialRelease({
      containers: [container],
      reason: reason,
      actionType: ACTIONTYPES.REVOKE,
    });
  };

  const handleUpdate = () => {
    updateCommercialRelease({
      containers: [container],
      actionType: ACTIONTYPES.UPDATE,
    });
  };

  const handleDelete = (reason) => {
    deleteCommercialRelease({
      containers: [container],
      reason: reason,
      actionType: ACTIONTYPES.DELETE,
    });
  };

  const handleConfirmation = (e) => {
    e.stopPropagation();
    if (
      activeItem === ACTIONTYPES.BLOCK ||
      activeItem === ACTIONTYPES.REVOKE ||
      activeItem === ACTIONTYPES.DELETE
    )
      setReasonDialogOpen(true);
    else {
      runCallback();
    }
    onClose(e);
  };

  const handleSubmitReasonDialog = (values) => {
    let reason = values.reason;
    runCallback(reason);
    setReasonDialogOpen(false);
  };

  const handleCloseReasonDialog = () => {
    setReasonDialogOpen(false);
  };

  const runCallback = (reason) => {
    switch (activeItem) {
      case ACTIONTYPES.BLOCK:
        handleBlock(reason);
        break;

      case ACTIONTYPES.UNBLOCK:
        handleUnblock();
        break;

      case ACTIONTYPES.REVOKE:
        handleRevoke(reason);
        break;

      case ACTIONTYPES.UPDATE:
        handleUpdate();
        break;

      case ACTIONTYPES.DELETE:
        handleDelete(reason);
        break;

      default:
        break;
    }
  };

  const confirmationActions = (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onClose(e);
        }}
        color="primary"
      >
        {LABEL.NO}
      </Button>
      <Button onClick={(e) => handleConfirmation(e)} color="primary">
        {LABEL.YES}
      </Button>
    </div>
  );

  return (
    <>
      <Dialog
        open={releaseActionOpen}
        onClose={(e) => {
          onClose(e);
        }}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby={'release-action'}
      >
        <DialogTitle aria-labelledby={'release-action-title'}>
          {activeItem + ' ' + DIALOG.COMMERCIAL_RELEASE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines} color="textSecondary">
            {DIALOG.RELEASE_ACTION_CONFIRM_CONTENT.replace(
              FORMATTING.RELEASE_ACTION,
              activeItem.toLowerCase()
            ).replace(FORMATTING.CONTAINER_NUMBER, container.equipmentNumber)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{confirmationActions}</DialogActions>
      </Dialog>
      <FormikDialog
        initialValues={{ reason: '' }}
        schema={addReasonSchema}
        open={reasonDialogOpen}
        handleSubmit={handleSubmitReasonDialog}
        handleClose={handleCloseReasonDialog}
        dialogTitle={DIALOG.PROVIDE_REASON_TITLE}
        dialogContentText={DIALOG.PROVIDE_REASON_CONTENT}
        classes={classes}
        columnSize={12}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      blockCommercialRelease,
      unblockCommercialRelease,
      updateCommercialRelease,
      deleteCommercialRelease,
      revokeCommercialRelease,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ReleaseActionDialog));
