import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { LABEL } from '../../theme/constants';
import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  parentDiv: {
    position: 'relative',
    minWidth: '250px',
    minHeight: '150px',
  },
  childDiv: {
    margin: 'auto',
    position: 'absolute',
    top: '50px',
    right: '0',
    bottom: '0',
    left: '105px',
  },
  waitDiv: {
    margin: 'auto',
    position: 'absolute',
    top: '95px',
    right: '0',
    bottom: '0',
    left: '80px',
  },
  titleDiv: {
    margin: 'auto',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '95px',
  },
});
export default function LogoutDialog(props) {
  const classes = useStyles();
  const { openDialog } = props;
  const [open, setOpen] = React.useState(openDialog);
  const [enter, setEnter] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (enter === 0) return;
    setOpen(true);
  }, [openDialog]);

  React.useEffect(() => {
    setEnter(1);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="profile-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="profile-dialog-slide-description">
            <div className={classes.parentDiv}>
              <div className={classes.titleDiv}>
                <Typography variant="h8" component="h4">
                  {LABEL.LOGOUT}
                </Typography>
              </div>
              <div className={classes.childDiv}>
                <CircularProgress />
              </div>
              <div className={classes.waitDiv}>{LABEL.PLAESE_WAIT}</div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
