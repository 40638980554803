import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.terminals, action) {
    switch (action.type) {
        case ActionTypes.TERMINALS_REQUESTED: {
            return {
                ...state,
                items: state.items
            };
        }
        case ActionTypes.TERMINALS_REQUEST_SUCCEEDED: {
            return {
                ...state,
                items: action.payload
            };
        }
        default: {
            return state;
        }
    }
}