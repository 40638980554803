import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.tab, action) {
    switch (action.type) {
        case ActionTypes.CHANGED_TAB: {
            return {
                ...state,
                changed: true
            };
        }
        case ActionTypes.RESET_CHANGED_TAB: {
            return {
                ...state,
                changed: false
            }
        }
        default: {
            return state;
        }
    }
}