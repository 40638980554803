import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchStep, UpdateStep } from '.';
import { Container } from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { usePostContainersUpdatecommercialexpirydate } from '../../adapters/update-expiry-date/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from '../../constants';
import { ExpiryDateWeek } from '../../types';
import { FilterStep, ResultStep } from '../shared';

type Props = {
  data: Container[] | undefined;
  isFetching?: boolean;
  selectedRows: Row<Container>[] | null;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

const StepperUpdateExpiryDate = ({
  data,
  isFetching,
  selectedRows,
  setDisplayGrid,
  submitForm,
  setRowSelection,
}: Props) => {
  const { t } = useTranslation(['updateExpiryDate', 'common', 'useCase', 'validation']);
  const [newExpiryDate, setNewExpiryDate] = React.useState<string | undefined>(undefined);
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const { values, errors, resetForm } = useFormikContext<ExpiryDateWeek>();

  const weekFilterError = Boolean(errors.weekFilter);
  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleSuccessUpdate = () => {
    setRowSelection({});
  };

  const { mutate, status } = usePostContainersUpdatecommercialexpirydate(handleSuccessUpdate);

  const updateExpiryDate = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      ids: listOfCheckedContainers,
      expirationDateTimeUtc: newExpiryDate,
    });
  };

  const resetFormAndDate = () => {
    setNewExpiryDate(undefined);
    resetForm();
    setContainersUpdated(0);
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.UPDATE_EXPIRY_DATE}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('sideBar.searchFlavorText'),
            disableBack: true,
            content: <SearchStep />,
            nextCallBack: submitForm,
            nextIsLoading: isFetching,
            nextCustomText: isFetching
              ? t('useCase:containers.loadingContainers')
              : t('useCase:containers.searchContainers'),
            disableNext:
              weekFilterError || !values.weekFilter?.startDate || !values.weekFilter?.endDate,
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t('sideBar.selectNewDate'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('common:general.performUpdate'),
            stepFlavorText: t('useCase:containers.performUpdateFlavorText'),
            nextCallBack: updateExpiryDate,
            nextCustomText: t('useCase:containers.updateContainers', {
              numberOfContainersSelected,
            }),
            disableNext: newExpiryDate === undefined || numberOfContainersSelected < 1,
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                setNewExpiryDate={setNewExpiryDate}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={updateExpiryDate}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndDate}
                resultText={t('sideBar.containersUpdated', { newDate: newExpiryDate })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
export default StepperUpdateExpiryDate;
