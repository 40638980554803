import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import {
  GetContainersByReceivedFromParams,
  ReleaseParty,
  TerminalContainer,
} from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import {
  useGetActiveTerminals,
  usePostContainersTransfer,
} from '../../adapters/transfer-releaseright/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/transport-operator/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchStep from './SearchStep';
import FilterStep from './FilterStep';
import ResultStep from '../shared/ResultStep';
import UpdateStep from './UpdateStep';

export const identificationType: string[] = ['NxtEntityId', 'Tin', 'Duns', 'SCAC', 'Eori', 'Apcs'];
export const defaultType = identificationType[0];

type Props = {
  data: TerminalContainer[] | undefined;
  errors: FormikErrors<GetContainersByReceivedFromParams>;
  isFetching?: boolean;
  selectedRows: Row<TerminalContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByReceivedFromParams;
  selectedUserType: any;
};

const StepperTransferReleaseRight = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
  selectedUserType,
}: Props) => {
  const { t } = useTranslation(['transferReleaseRight', 'common', 'useCase', 'validation']);
  const [selectedReleaseParty, setSelectedReleaseParty] = React.useState<ReleaseParty | undefined>(
    undefined
  );

  const [selectedIdentification, setSelectedIdentification] = React.useState<
    | {
        type: string;
        value: string;
      }
    | undefined
  >({ type: defaultType || '', value: '' });

  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const terminalNxtEntityIdError = Boolean(errors.terminalNxtEntityId);

  const selectedNewRelease: any = selectedIdentification?.value
    ? selectedIdentification
    : selectedReleaseParty;

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const resetFormAndContainersUpdated = () => {
    resetForm();
    setRowSelection({});
    setContainersUpdated(0);
  };

  const {
    data: terminalData,
    isFetching: isFetchingTerminals,
    isError: isErrorTerminals,
  } = useGetActiveTerminals();

  const { mutate, status } = usePostContainersTransfer();

  const transferReleaseRight = () => {
    setContainersUpdated(numberOfContainersProcessed);

    if (selectedNewRelease?.nxtEntityId) {
      mutate({
        releaseToNxtEntityId: selectedReleaseParty?.nxtEntityId,
        releaseIds: listOfCheckedContainers,
      });
    } else if (selectedNewRelease?.value) {
      mutate({
        releaseToNxtEntityId: selectedNewRelease.value,
        releaseIds: listOfCheckedContainers,
      });
    }
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.TRANSFER_RELEASERIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.transferSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              terminalNxtEntityIdError ||
              (values.terminalNxtEntityId === '' &&
                (values.identificationCode === '' || values.identificationCode === undefined) &&
                values.billOfLadingNumber === ''),
            content: (
              <SearchStep
                terminals={terminalData}
                terminalNxtEntityIdError={errors.terminalNxtEntityId}
                isLoadingError={isErrorTerminals}
                isFetching={isFetchingTerminals}
              />
            ),
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectAcceptedContainersToTransferFlavorText'),
            nextCustomText: t('common:general.next'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('transferReleaseRight:general.title'),
            stepFlavorText: t('useCase:containers.transferContainersFlavorText'),
            nextCallBack: transferReleaseRight,
            nextCustomText: t('useCase:containers.transferContainers', {
              numberOfContainersSelected: numberOfContainersProcessed,
            }),
            disableNext: numberOfContainersSelected < 1 || !selectedNewRelease,
            content: (
              <UpdateStep
                selectedUserType={selectedUserType}
                setSelectedReleaseParty={setSelectedReleaseParty}
                selectedReleaseParty={selectedReleaseParty}
                selectedIdentification={selectedIdentification}
                setSelectedIdentification={setSelectedIdentification}
                selectedNewRelease={selectedNewRelease}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={transferReleaseRight}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                })}
                hasNextStep={false}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperTransferReleaseRight;
