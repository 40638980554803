import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { getCompanies } from '../api/companiesApi';
import { getCompaniesFilters, getCompaniesPaging } from '../selectors/selectors';

function getCompaniesQuery(filters, paging) {
  return {
    eori: filters.eori || undefined,
    duns: filters.duns || undefined,
    vat: filters.vat || undefined,
    nxtEntityId: filters.nxtEntityId || undefined,
    entityName: filters.entityName || undefined,
    apcsCode: filters.apcsCode || undefined,
    Page: paging.page + 1 || undefined,
    PageSize: paging.rowsPerPage || undefined,
  };
}

function hasActiveFilters(filters) {
  return (
    filters &&
    (filters.entityName !== '' ||
      filters.nxtEntityId !== '' ||
      filters.vat !== '' ||
      filters.eori !== '' ||
      filters.apcsCode !== '' ||
      filters.duns !== '')
  );
}

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadAllCompanies(action) {
  try {
    let paging = yield select(getCompaniesPaging);
    let filters = yield select(getCompaniesFilters);

    if (hasActiveFilters(action.payload.filters)) {
      paging.page = 0;
    }

    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    if (action.payload.filters) {
      filters = action.payload.filters;
    }

    yield call(getCompanies, getCompaniesQuery(filters, paging));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* companiesSaga() {
  yield takeEvery(ActionTypes.COMPANIES_REQUESTED, loadAllCompanies);
}

export default companiesSaga;
