import { FormLabel, TextField, Typography } from '@material-ui/core';
import format from 'date-fns/format';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
  setNewExpiryDate: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const today = new Date();
const todayFormatted = `${format(today, 'yyyy-MM-dd')}`;

const UpdateStep: React.FC<Props> = ({ setNewExpiryDate, numberOfContainersSelected }: Props) => {
  const { t } = useTranslation('updateExpiryDate');
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected })}
      </Typography>
      <FormLabel>{t('sideBar.expiryDate')}</FormLabel>
      <TextField
        fullWidth
        name="expiryDate"
        type="date"
        variant="outlined"
        onChange={(event) => {
          setNewExpiryDate(event.target.value);
        }}
        inputProps={{
          min: todayFormatted,
        }}
      />
    </React.Fragment>
  );
};

export default UpdateStep;
