import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { LABEL, DIALOG } from '../../theme/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submitDriverAlias } from '../../store/actions/driverListActions';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
  content: {
    '& span': {
      width: '100%',
      display: 'block',
      margin: '0px',
      fontWeight: 'normal',
    },
  },
  heading: {
    width: '100%',
    display: 'block',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    margin: '0px',
  },
  headingContent: {
    width: '100%',
    fontWeight: 'bold',
    display: 'block',
    padding: '10px 0',
  },
  noBlock: {
    display: 'initial !important',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '& p': {
      color: 'red',
    },
  },
});

const AddDriverAliasDialog = (props) => {
  const { openAddDriverAliasDialog, closeDialog, submitDriverAlias } = props;

  const cleanState = {
    description: '',
    alfapassNumber: '',
    errorDescription: false,
    errorAlfapassNumber: false,
  };

  const [state, setState] = React.useState(cleanState);

  const handleInpDriverDescriptionChange = (e) => {
    e.stopPropagation();
    setState({ ...state, description: e.target.value });
  };

  const handleInpDriverAlfapassNumberChange = (e) => {
    e.stopPropagation();
    setState({ ...state, alfapassNumber: e.target.value });
  };

  const submit = (e) => {
    e.stopPropagation();
    let errDescr = false;
    if (state.description == null || state.description.length < 2) {
      errDescr = true;
    }
    let errAlfa = false;
    setState({ ...state, errorDescription: false });
    if (state.alfapassNumber == null || state.alfapassNumber.length < 4) {
      errAlfa = true;
    }
    setState({ ...state, errorAlfapassNumber: errAlfa, errorDescription: errDescr });
    if (errDescr || errAlfa) {
      return;
    }
    const driverAlias = {
      description: state.description,
      alfapassNumber: state.alfapassNumber,
    };
    submitDriverAlias(driverAlias);
    closeThisDialog(e);
  };

  const closeThisDialog = (e) => {
    e.stopPropagation();
    setState(cleanState);
    closeDialog(e);
  };

  return (
    <Dialog
      open={openAddDriverAliasDialog}
      onClose={(e) => {
        closeThisDialog(e);
      }}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>{DIALOG.ADD_DRIVER_DESCRIPTION}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1">{DIALOG.ADD_DRIVER_DESCRIPTION_TEXT}</Typography>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                id="inpDriverDescription"
                type="text"
                autoFocus={true}
                label={LABEL.DRIVER_DESCRIPTION_LABEL}
                onChange={(e) => handleInpDriverDescriptionChange(e)}
                inputProps={{ maxLength: 128 }}
                value={state.description}
                helperText={
                  state.errorDescription ? DIALOG.DRIVER_ERROR_DESCRIPTION_VALIDATION : ''
                }
                fullWidth
              />
            </Grid>
            <Grid item sm={5}>
              <TextField
                id="inpDriverAlfapassNumber"
                type="number"
                label={LABEL.DRIVER_ALFAPASS_NUMBER_LABEL}
                onChange={(e) => handleInpDriverAlfapassNumberChange(e)}
                value={state.alfapassNumber}
                helperText={
                  state.errorAlfapassNumber ? DIALOG.DRIVER_ERROR_ALFAPASSNUMBER_VALIDATION : ''
                }
                InputProps={{
                  inputProps: { min: 0, max: 100000000, maxLength: 16 },
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => submit(e)}>{LABEL.SUBMIT}</Button>
        <Button
          onClick={(e) => {
            closeThisDialog(e);
          }}
        >
          {LABEL.CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDriverAliasDialog.propTypes = {
  openAddDriverAliasDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitDriverAlias,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(AddDriverAliasDialog));
