import { Post } from './authorizedRequest';

export async function putDriver(obj) {
  const body = {
    identificationType: 'Alfapass',
    identificationCode: obj.alfapassNumber,
  };
  const request = Post('/driver', body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}
