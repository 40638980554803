import React from 'react';
import {
  getCoreRowModel,
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  RowModel,
  ColumnSizingState,
  useReactTable,
  RowData,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import BaseGrid, { CustomTableProps } from './BaseGrid';

type Props<T extends RowData> = React.ComponentPropsWithoutRef<'div'> & {
  columns: ColumnDef<T, any>[];
  data: T[] | undefined;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState> | undefined;
  getRowSelection?: React.MutableRefObject<(() => RowModel<T>) | null>;
  onColumnSizingChange?: OnChangeFn<ColumnSizingState> | undefined;
  columnSizing?: ColumnSizingState;
  isLoading?: boolean;
  onCellClick?: (rowModel: T) => void;
  customTableProps?: CustomTableProps;
  enableMultiRowSelect?: boolean;
  enableColumnResizing?: boolean;
};

const ClientGrid = <T extends RowData>({
  columns,
  data,
  rowSelection,
  onRowSelectionChange,
  getRowSelection,
  onColumnSizingChange,
  columnSizing,
  isLoading,
  onCellClick,
  customTableProps,
  enableMultiRowSelect,
  enableColumnResizing,
}: Props<T>) => {
  const {
    getHeaderGroups,
    getRowModel,
    getSelectedRowModel,
    getVisibleLeafColumns,
    getPreFilteredRowModel,
  } = useReactTable({
    data: data || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
    enableColumnResizing: enableColumnResizing ?? true,
    columnResizeMode: 'onChange',
    onRowSelectionChange,
    onColumnSizingChange,
    enableMultiRowSelection: enableMultiRowSelect ?? true,
    state: {
      rowSelection,
      columnSizing: columnSizing || {},
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <React.Fragment>
      <BaseGrid
        customTableProps={customTableProps}
        data={data}
        columnSizing={columnSizing}
        getHeaderGroups={getHeaderGroups}
        getRowModel={getRowModel}
        getSelectedRowModel={getSelectedRowModel}
        getVisibleLeafColumns={getVisibleLeafColumns}
        getPreFilteredRowModel={getPreFilteredRowModel}
        getRowSelection={getRowSelection}
        onCellClick={onCellClick}
      />
    </React.Fragment>
  );
};

export default ClientGrid;
