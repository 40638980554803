import * as Yup from 'yup';

const displayNotificationsSchema = Yup.object().shape(
  {
    notificationEvent: Yup.string()
      .trim()
      .ensure()
      .when(['equipmentNumber', 'publicReferenceId', 'releaseId', 'storedOn'], {
        is: (
          equipmentNumber: string,
          publicReferenceId: string,
          releaseId: string,
          storedOn: string
        ) =>
          equipmentNumber === '' && publicReferenceId === '' && releaseId === '' && storedOn === '',
        then: Yup.string().required() && Yup.string().notOneOf(['Unknown']),
      }),
    storedOn: Yup.string()
      .trim()
      .ensure()
      .when(['equipmentNumber', 'publicReferenceId', 'releaseId', 'notificationEvent'], {
        is: (
          equipmentNumber: string,
          publicReferenceId: string,
          releaseId: string,
          notificationEvent: string
        ) =>
          equipmentNumber === '' &&
          publicReferenceId === '' &&
          releaseId === '' &&
          notificationEvent === 'Unknown',
        then: Yup.string().required(),
      })
      .when(['timeWindowFrom', 'timeWindowTo'], {
        is: (timeWindowFrom: string, timeWindowTo: string) => !!timeWindowFrom && !!timeWindowTo,
        then: Yup.string().required('validation:timeWindow.storedOn'),
        otherwise: Yup.string(),
      }),
    equipmentNumber: Yup.string()
      .trim()
      .ensure()
      .when(['notificationEvent', 'publicReferenceId', 'releaseId', 'storedOn'], {
        is: (
          notificationEvent: string,
          publicReferenceId: string,
          releaseId: string,
          storedOn: string
        ) =>
          notificationEvent === 'Unknown' &&
          publicReferenceId === '' &&
          releaseId === '' &&
          storedOn === '',
        then: Yup.string().required(),
      }),
    publicReferenceId: Yup.string()
      .trim()
      .ensure()
      .when(['notificationEvent', 'equipmentNumber', 'releaseId', 'storedOn'], {
        is: (
          notificationEvent: string,
          equipmentNumber: string,
          releaseId: string,
          storedOn: string
        ) =>
          notificationEvent === 'Unknown' &&
          equipmentNumber === '' &&
          releaseId === '' &&
          storedOn === '',
        then: Yup.string().required(),
      }),
    releaseId: Yup.string()
      .trim()
      .ensure()
      .when(['notificationEvent', 'equipmentNumber', 'publicReferenceId', 'storedOn'], {
        is: (
          notificationEvent: string,
          equipmentNumber: string,
          publicReferenceId: string,
          storedOn: string
        ) =>
          notificationEvent === 'Unknown' &&
          equipmentNumber === '' &&
          publicReferenceId === '' &&
          storedOn === '',
        then: Yup.string().required(),
      }),
    timeWindowFrom: Yup.string().when(['timeWindowTo'], {
      is: (timeWindowTo: string) => !!timeWindowTo,
      then: Yup.string()
        .required('validation:timeWindow.fromRequiredWhenToFilledIn')
        .test(
          'is-earlier',
          'validation:timeWindow.fromMustBeEarlierThanTo',
          function (timeWindowFrom) {
            const { timeWindowTo } = this.parent;
            if (!timeWindowFrom || !timeWindowTo) return true; // Skip validation if either field is empty
            return new Date(timeWindowFrom) < new Date(timeWindowTo);
          }
        ),
      otherwise: Yup.string(),
    }),
    timeWindowTo: Yup.string().when(['timeWindowFrom'], {
      is: (timeWindowFrom: string) => !!timeWindowFrom,
      then: Yup.string().required('validation:timeWindow.toRequiredWhenFromFilledIn'),
      otherwise: Yup.string(),
    }),
  },
  [
    ['notificationEvent', 'equipmentNumber'],
    ['notificationEvent', 'publicReferenceId'],
    ['notificationEvent', 'storedOn'],
    ['notificationEvent', 'releaseId'],
    ['equipmentNumber', 'publicReferenceId'],
    ['equipmentNumber', 'storedOn'],
    ['equipmentNumber', 'releaseId'],
    ['publicReferenceId', 'storedOn'],
    ['publicReferenceId', 'releaseId'],
    ['releaseId', 'storedOn'],
    ['timeWindowFrom', 'timeWindowTo'],
    ['timeWindowFrom', 'storedOn'],
    ['timeWindowTo', 'storedOn'],
  ]
);

export default displayNotificationsSchema;
