import { DIALOG, LABEL } from "../../theme/constants";
import React from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Box
} from "@material-ui/core";
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { CRPExpiredTokenTheme } from "../../theme/theme";
import { loadTokenExpired, loadTokenExpiring } from "../../store/actions/userProfileActions";
import store from '../../store/store';
import { logOut } from "../../auth/identityAuth";
import { USERISEXPIRING_WARNING_INMINUTES } from '../../config/environment';
import TimerCountDown from './TimerCountDown';


const styles = theme => ({
    dialogContentWithNewLines: {
        whiteSpace: 'pre-wrap',
    },
    sentimentIcon: {
        fontSize: "60px"
    },
    sentimentIconWrap: {
        textAlign: "center"
    }
});



const BackToLogin = () => {
    const handleToLogin = (e) => {
        e.stopPropagation();
        logOut();
    }
    return <Button onClick={(e) => handleToLogin(e)} variant="contained" color="primary" disableElevation>{LABEL.BACK_TO_LOGIN}</Button>

};

const ContinueWorking = () => {
    const handleContinue = (e) => {
        e.stopPropagation();
        store.dispatch(loadTokenExpiring(false));
        store.dispatch(loadTokenExpired(false));
    }
    return <Button onClick={(e) => handleContinue(e)} variant="contained" color="secondary">{LABEL.CONTINUE_WORKING}</Button>
}

const ExpiredToken = (props) => {
    const {
        tokenExpired,
        tokenExpiring,
        classes
    } = props;

    const handleClose = (e) => {
        e.stopPropagation();
        if (tokenExpired) {
            logOut();
        }
    }

    let dlgContent = "error";
    if (tokenExpired) {
        dlgContent = DIALOG.TOKEN_EXPIRED_CONTENT
    }
    else if (tokenExpiring) {
        dlgContent = DIALOG.TOKEN_IS_EXPIRING_CONTENT
    }

    let tokenIsExpiring = tokenExpiring && !tokenExpired

    return (
        <ThemeProvider theme={CRPExpiredTokenTheme}>
            <div onClick={(e) => e.stopPropagation()}>
                <Dialog
                    open={tokenExpired || tokenExpiring}
                    onClose={(e) => handleClose(e, tokenExpired)}
                    fullWidth={true}
                >
                    <DialogContent>
                        <DialogContentText>
                            <Box type="div" className={classes.sentimentIconWrap}>
                                <SentimentVeryDissatisfiedIcon color="disabled" className={classes.sentimentIcon} />
                            </Box>
                            <Typography variant="h6" align="center">
                                {dlgContent} {tokenIsExpiring && <><br />{DIALOG.TOKEN_TIME_LEFT_TEXT}{' '}<TimerCountDown initialMinute={USERISEXPIRING_WARNING_INMINUTES} initialSeconds={0} /></>}
                            </Typography>
                        </DialogContentText>
                        <DialogActions>
                            <BackToLogin /> {tokenIsExpiring && <ContinueWorking />}
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        </ThemeProvider>
    );
}

export default withStyles(styles)(ExpiredToken);