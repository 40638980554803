import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionTypes,
  Container,
  GetContainersByVoyageParams,
} from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { usePostContainersChangeCommercialRelease } from '../../adapters/change-commercial-release/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from '../../constants';
import { FilterStep, ResultStep, VesselSearchStep } from '../shared';
import UpdateStep from './UpdateStep';

type Props = {
  data: Container[] | undefined;
  errors: FormikErrors<GetContainersByVoyageParams>;
  isFetching?: boolean;
  selectedRows: Row<Container>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByVoyageParams;
  commercialReleaseAction: ActionTypes;
};

const ChangeCommercialReleaseStepper = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
  commercialReleaseAction,
}: Props) => {
  const { t } = useTranslation(['changeVoyage', 'common', 'useCase', 'validation']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);
  const [reason, setReason] = React.useState('');

  const vesselNameError = Boolean(errors.vesselName);
  const voyageNumberError = Boolean(errors.voyageNumber);
  const imoNumberError = Boolean(errors.imoNumber);
  const stayNumberError = Boolean(errors.stayNumber);

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const { mutate, status } = usePostContainersChangeCommercialRelease();

  const actionIsDelete = commercialReleaseAction === 'DELETE';

  const resetFormAndValues = () => {
    resetForm();
    setRowSelection({});
    setContainersUpdated(0);
  };

  const changeCommercialRelease = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      ids: listOfCheckedContainers,
      action: commercialReleaseAction,
      reason: reason,
    });

    if (actionIsDelete) {
      setRowSelection({});
    }
  };

  const generateFaqLink = () => {
    switch (commercialReleaseAction) {
      case 'DELETE':
        return FAQ_PAGES.DELETE_VOYAGE;
      case 'BLOCK':
        return FAQ_PAGES.BLOCK_VOYAGE;
      case 'UNBLOCK':
        return FAQ_PAGES.UNBLOCK_VOYAGE;
      case 'REVOKE':
        return FAQ_PAGES.REVOKE_VOYAGE;
    }
  };
  return (
    <Paper>
      <StepUseCase
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        faqLink={generateFaqLink()}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.voyageSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('useCase:containers.loadingContainers')
              : t('useCase:containers.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              vesselNameError ||
              voyageNumberError ||
              imoNumberError ||
              stayNumberError ||
              (values.vesselName === '' &&
                values.voyageNumber === '' &&
                values.imoNumber === '' &&
                values.stayNumber === ''),
            content: <VesselSearchStep />,
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t(`actions.${commercialReleaseAction}`),
            disableNext: numberOfContainersSelected < 1,
            backCallBack: () => {
              setRowSelection({});
              setDisplayGrid(false);
            },
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('common:general.performUpdate'),
            stepFlavorText: t('useCase:containers.performUpdateFlavorText'),
            nextCallBack: changeCommercialRelease,
            nextCustomText: t(`actions.${commercialReleaseAction}`, {
              numberOfContainersSelected: numberOfContainersSelected,
            }),
            disableNext: numberOfContainersSelected < 1,
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                reason={reason}
                setReason={setReason}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                retryFunction={changeCommercialRelease}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndValues}
                customResultAction={actionIsDelete ? t('validation:update.deleted') : undefined}
                resultText={t('sideBar.changeCommercialRelease.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
export default ChangeCommercialReleaseStepper;
