import {
  Box,
  Paper,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getUserProfile,
  getCreditIdentificationResponse,
  getHasFullSubscription,
} from '../../store/selectors/selectors';
import { red } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import { registerPaymentOption } from '../../store/actions/userProfileActions';
import { bindActionCreators } from 'redux';
import { FORMATTING, LABEL, TEXT } from '../../theme/constants';
import { POACPUPAGE } from '../../config/environment';
import history from '../../history/browserHistory';

const styles = (theme) => ({
  paperContent: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
  },
  cardBlock: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  logicBlock: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const Blocked = (props) => {
  const {
    classes,
    userProfile,
    creditIdentificationResponse,
    hasFullSubscription,
    registerPaymentOption,
  } = props;

  useEffect(() => {
    if (hasFullSubscription == true) {
      history.push('/');
    }
  }, [hasFullSubscription]);

  const handleRegister = (e, selectedOption) => {
    e.stopPropagation();

    if (selectedOption == 1) registerPaymentOption();
    else if (selectedOption == 2) {
      if (
        (creditIdentificationResponse != null) &
        (creditIdentificationResponse.externalRegistrationUrl != null)
      )
        window.location.href = creditIdentificationResponse.externalRegistrationUrl;
    }
  };

  const handleLearnMore = (e) => {
    e.stopPropagation();
    window.location.href = POACPUPAGE;
  };

  return (
    <React.Fragment>
      <Paper>
        <div className={classes.paperContent}>
          <div className={classes.title}>
            <Typography variant="h5" id="blockedTitle" my={2}>
              <Box lineHeight={2} m={1}>
                <BlockIcon style={{ color: red[500] }} /> {LABEL.BLOCKED_PAGE_TITLE}
              </Box>
            </Typography>
          </div>
          <Divider />
          <div className={classes.logicBlock}>
            <Typography variant="h6">
              <Box my={2}>
                {LABEL.ADDRESSING_USER.replace(
                  FORMATTING.USERPROFILE_FIRST_NAME,
                  userProfile.firstName
                ).replace(FORMATTING.USERPROFILE_LAST_NAME, userProfile.lastName)}
              </Box>
            </Typography>
            <Typography variant="body1">{TEXT.BLOCKED_PAGE_INTRO}</Typography>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Card className={classes.cardBlock}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {LABEL.BLOCKED_PAGE_OPTION1_TITLE}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {TEXT.BLOCKED_PAGE_OPTION1_SUBTITLE}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {TEXT.BLOCKED_PAGE_OPTION1_EXPLANATION}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button size="small" color="primary" onClick={(e) => handleRegister(e, 2)}>
                      {LABEL.REGISTER}
                    </Button>
                    <Button size="small" color="primary" onClick={(e) => handleLearnMore(e)}>
                      {LABEL.LEARN_MORE}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card className={classes.cardBlock}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {LABEL.BLOCKED_PAGE_OPTION2_TITLE}
                    </Typography>
                    <Typography variant="subtitle2">
                      {TEXT.BLOCKED_PAGE_OPTION2_SUBTITLE}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {TEXT.BLOCKED_PAGE_OPTION2_EXPLANATION}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e) => handleRegister(e, 1)}
                      disabled
                    >
                      {LABEL.REGISTER}
                    </Button>
                    <Button size="small" color="primary" onClick={(e) => handleLearnMore(e)}>
                      {LABEL.LEARN_MORE}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerPaymentOption,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    creditIdentificationResponse: getCreditIdentificationResponse(state),
    hasFullSubscription: getHasFullSubscription(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Blocked));
