import {
  Button,
  TableBody,
  Grid,
  Divider,
  TextField,
  TablePagination,
  Toolbar,
  Typography,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CRPTableTheme, CRPTheme } from '../../theme/theme';
import CenterTableCell from '../atoms/CenterTableCell';
import ActionDialog from './ActionDialog';
import TableHead from '../molecules/ContainerTableHead';
import {
  loadPoliceReleaseAudit,
  setSelectedEquipment,
} from '../../store/actions/policeContainerActions';
import { LABEL, DIALOG, VALIDATION } from '../../theme/constants';
import LaunchIcon from '@material-ui/icons/Launch';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme) => ({
  wideCell: {
    padding: '20px 15px',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
  },
  root: {
    paddingRight: theme.spacing(1),
  },
  extended: {
    minHeight: theme.spacing(10),
  },
});

let policeReferenceNumberError = false;
let policeCaseNumberError = false;

class PoliceContainerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policeCase: props.policeCase,
      detailDialogOpen: false,
      caseErrors: {},
      selectedRelease: {
        equipmentNumber: null,
        releaseId: null,
      },
    };
  }

  handleChangePage = (event, page) => {
    this.props.loadContainersPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.loadContainersRowsPerPage(event.target.value);
  };

  onChangeCase = (key) => (event) => {
    const policeCase = { ...this.state.policeCase, [key]: event.target.value };
    this.setState({ policeCase }, this.validateCase);
  };

  openDetailDialog = (releaseId, equipmentNumber) => {
    this.setState({
      detailDialogOpen: true,
      selectedRelease: { releaseId },
    });
    this.props.setSelectedEquipment(equipmentNumber);
  };

  closeDetailDialog = () => {
    this.setState({ detailDialogOpen: false, selectedRelease: null });
  };

  requestAudit = () => {
    this.props.loadPoliceReleaseAudit(
      this.state.selectedRelease.releaseId,
      this.state.policeCase,
      true
    );
  };

  resetCaseValidation = () => {
    policeReferenceNumberError = false;
    policeCaseNumberError = false;
  };

  MarkValidationError = (error) => {
    switch (error.property) {
      case 'policeCaseNumber':
        policeCaseNumberError = true;
        break;
      case 'policeReferenceNumber':
        policeReferenceNumberError = true;
        break;
      default:
        return;
    }
  };

  validateCase = () => {
    this.resetCaseValidation();
    let result = this.validateCaseInputWithRules(this.state.policeCase);
    return result;
  };

  validateCaseInputWithRules = (policeCase) => {
    let isValid = true;
    let rules = [
      {
        property: 'policeReferenceNumber',
        valid: policeCase.referenceNumber.length === 0 || policeCase.referenceNumber.length >= 3,
      },
      {
        property: 'policeCaseNumber',
        valid: policeCase.number.length === 0 || policeCase.number.length >= 3,
      },
    ];
    if (Object.keys(rules).some((x) => !rules[x].valid)) {
      isValid = false;
      rules.filter((x) => !x.valid).forEach((error, i) => this.MarkValidationError(error));
    }
    this.setState({
      caseErrors: {
        policeCaseNumber: policeCaseNumberError,
        policeReferenceNumber: policeReferenceNumberError,
      },
    });
    return isValid;
  };

  render() {
    const { classes, columns, paging, items, headerTitle, detailContainers, selectedUserType } =
      this.props;

    const { detailDialogOpen, policeCase, caseErrors } = this.state;
    const validCase =
      !Object.keys(policeCase).some((x) => policeCase[x].length === 0) &&
      !Object.keys(caseErrors).some((x) => caseErrors[x] === true);

    const searchActions = (
      <div>
        <Button onClick={this.closeDetailDialog}>cancel</Button>
        <Button onClick={this.requestAudit} disabled={!validCase}>
          submit
        </Button>
      </div>
    );

    const policeProcedureFields = (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            label={LABEL.REFERENCE_NUMBER}
            value={policeCase.referenceNumber}
            onChange={this.onChangeCase('referenceNumber')}
            error={caseErrors.policeReferenceNumber}
            helperText={caseErrors.policeReferenceNumber ? VALIDATION.MIN_LENGTH_3 : ''}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={LABEL.CASE_NUMBER}
            value={policeCase.number}
            onChange={this.onChangeCase('number')}
            error={caseErrors.policeCaseNumber}
            helperText={caseErrors.policeCaseNumber ? VALIDATION.MIN_LENGTH_3 : ''}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    );

    return (
      <ThemeProvider theme={detailContainers ? CRPTheme : CRPTableTheme}>
        <div>
          <Toolbar className={classNames(classes.root, classes.extended)}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                {headerTitle}
              </Typography>
            </div>
            <div className={classes.spacer} />
          </Toolbar>
          <Divider />
          <Table aria-labelledby="tableTitle" style={{ width: '100%' }}>
            <TableHead
              className={classes.wideCell}
              columns={columns}
              centerAlign={true}
              selectedUserType={selectedUserType}
            />
            <TableBody>
              {items.map((container, index) => {
                const rowStyle = container.isBlocked
                  ? { backgroundColor: '#fff8f8' }
                  : index % 2 != 0
                  ? { backgroundColor: '#fafafa' }
                  : {};
                const cellStyle = container.isBlocked ? { borderColor: '#c00', color: '#c00' } : {};
                return (
                  <TableRow key={container.id} style={rowStyle}>
                    <CenterTableCell
                      style={cellStyle}
                      text={'[' + container.billOfLadingNumbers.join(', ') + ']'}
                    />
                    <CenterTableCell style={cellStyle} text={container.equipmentNumber} />
                    <CenterTableCell style={cellStyle} text={container.shippingAgent} />
                    <CenterTableCell
                      style={cellStyle}
                      text={
                        (container.terminalName ? `${container.terminalName} ` : '') +
                        container.terminalCode
                      }
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={moment(container.releaseDateTimeUtc).format('DD-MM-YYYY  HH:mm')}
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={
                        container.gateOutReceivedDateTimeUtc &&
                        moment(container.gateOutReceivedDateTimeUtc).format('DD-MM-YYYY  HH:mm')
                      }
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={
                        container.dataBrokerNxtEntityId != undefined &&
                        container.dataBrokerNxtEntityId !== '' ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )
                      }
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={container.isBlocked ? <CheckIcon /> : ''}
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={container.isArchived ? <CheckIcon /> : ''}
                    />
                    <CenterTableCell
                      style={cellStyle}
                      text={
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() =>
                            this.openDetailDialog(container.releaseId, container.equipmentNumber)
                          }
                        >
                          <LaunchIcon />
                        </Button>
                      }
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {paging && (
            <TablePagination
              component="div"
              count={paging.count}
              rowsPerPage={paging.rowsPerPage}
              page={paging.page}
              rowsPerPageOptions={[25, 50, 100, 250]}
              backIconButtonProps={{
                'aria-label': LABEL.PREVIOUS_PAGE,
              }}
              nextIconButtonProps={{
                'aria-label': LABEL.NEXT_PAGE,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
        <ActionDialog
          dialogTitle={DIALOG.ACTIONDIALOG_TITLE}
          contentText={DIALOG.ACTIONDIALOG_CONTENT}
          contentFields={policeProcedureFields}
          actions={searchActions}
          handleClose={this.closeDetailDialog}
          open={detailDialogOpen}
        />
      </ThemeProvider>
    );
  }
}

PoliceContainerTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  loadContainersPage: PropTypes.func,
  loadContainersRowsPerPage: PropTypes.func,
  detailContainers: PropTypes.bool,
  selectedUserType: PropTypes.string,
  headerTitle: PropTypes.string,
  paging: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    policeCase: state.policeContainers.policeCase,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPoliceReleaseAudit,
      setSelectedEquipment,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PoliceContainerTable));
