import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.selectedContainers, action) {
  switch (action.type) {
    case ActionTypes.SELECTED_CONTAINERS_REQUEST_SUCCEEDED: {
      return action.payload;
    }
    case ActionTypes.CLEAR_SELECTED_CONTAINERS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
