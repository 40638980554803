import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { TerminalContainer } from 'modules/global/adapters/crpAPI';
import { DataGrid, Drawer } from 'modules/global/components';
import EmptyState from 'modules/global/components/EmptyState/EmptyState';
import { useLocalTableValues } from 'modules/global/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { useGetPendingContainers } from '../adapters/decline-releaseright/queries';
import { StepperDeclineReleaseRight } from '../components/decline-releaseright';
import { ContainerDetailDrawer } from '../components/shared';
import { transportOperatorColumnsWithTerminalName as declineReleaseRightColumns } from '../constants/shared/transportOperatorColumns';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const DeclineReleaseRight: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['declineReleaseRight', 'common', 'validation']);

  const [displayGrid, setDisplayGrid] = React.useState(true);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: TerminalContainer;
  }>({
    open: false,
    data: undefined,
  });

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<TerminalContainer>) | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<Row<TerminalContainer>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-assign-release-party',
    {}
  );

  const { data, isLoading, isFetching, isError } = useGetPendingContainers();

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: TerminalContainer) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <StepperDeclineReleaseRight
          selectedRows={selectedRows}
          setRowSelection={setRowSelection}
          setDisplayGrid={setDisplayGrid}
          data={data}
        />
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={declineReleaseRightColumns}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onCellClick={handleCellClick}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState
            title={t('emptyState.transportOperator.title')}
            subTitle={t('emptyState.transportOperator.subTitle')}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DeclineReleaseRight;
