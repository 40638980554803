import React from 'react';
import { TablePagination, LinearProgress } from '@material-ui/core';
import {
  getCoreRowModel,
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  RowSelectionState,
  RowModel,
  SortingState,
  ColumnSizingState,
  useReactTable,
  RowData,
} from '@tanstack/react-table';
import useStyles from './style';
import BaseGrid from './BaseGrid';

type Props<T extends RowData> = React.ComponentPropsWithoutRef<'div'> & {
  columns: ColumnDef<T, any>[];
  count: number;
  rowsPerPageOptions: number[];
  data: T[] | undefined;
  page: number;
  perPage: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  columnFilters?: ColumnFiltersState;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState> | undefined;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState> | undefined;
  getRowSelection?: React.MutableRefObject<(() => RowModel<T>) | null>;
  onSortingChange?: OnChangeFn<SortingState> | undefined;
  sorting?: SortingState;
  onColumnSizingChange?: OnChangeFn<ColumnSizingState> | undefined;
  columnSizing?: ColumnSizingState;
  isLoading: boolean;
  isFetching: boolean;
};

const ServerGrid = <T extends RowData>({
  columns,
  count,
  rowsPerPageOptions,
  data,
  page,
  perPage,
  onPageChange,
  onPerPageChange,
  columnFilters,
  onColumnFiltersChange,
  rowSelection,
  onRowSelectionChange,
  getRowSelection,
  onSortingChange,
  sorting,
  onColumnSizingChange,
  columnSizing,
  isLoading,
  isFetching,
}: Props<T>) => {
  const classes = useStyles();

  const {
    getHeaderGroups,
    getRowModel,
    getSelectedRowModel,
    getVisibleLeafColumns,
    getPreFilteredRowModel,
  } = useReactTable({
    data: data || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    enableMultiSort: true,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onColumnFiltersChange,
    onRowSelectionChange,
    onSortingChange,
    onColumnSizingChange,
    state: {
      columnFilters,
      rowSelection,
      sorting,
      columnSizing: columnSizing || {},
    },
  });

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  const handlePerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newPerPage = event.currentTarget.getAttribute('data-value');
    if (newPerPage) {
      onPerPageChange(parseInt(newPerPage, 10));
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <React.Fragment>
      <BaseGrid
        data={data}
        columnSizing={columnSizing}
        getHeaderGroups={getHeaderGroups}
        getRowModel={getRowModel}
        getSelectedRowModel={getSelectedRowModel}
        getVisibleLeafColumns={getVisibleLeafColumns}
        getRowSelection={getRowSelection}
        getPreFilteredRowModel={getPreFilteredRowModel}
      />
      <TablePagination
        component="footer"
        count={count}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={perPage}
        className={classes.pager}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePerPageChange}
      />
      {isFetching && <LinearProgress />}
    </React.Fragment>
  );
};

export default ServerGrid;
