import ActionTypes from './actionTypes';

export function loadArchive() {
  return {
    type: ActionTypes.ARCHIVE_REQUESTED,
    payload: {},
  };
}

export function loadArchivePage(page) {
  return {
    type: ActionTypes.ARCHIVE_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadArchiveRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.ARCHIVE_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadArchiveFilters(filters) {
  return {
    type: ActionTypes.ARCHIVE_REQUESTED,
    payload: { filters },
  };
}

export function loadArchiveSucceeded(containers) {
  return {
    type: ActionTypes.ARCHIVE_REQUEST_SUCCEEDED,
    payload: containers,
  };
}

export function blockContainers(payload) {
  return {
    type: ActionTypes.CONTAINERS_BLOCK,
    payload: payload
  };
}

export function releaseContainers(payload) {
  return {
    type: ActionTypes.CONTAINERS_RELEASE,
    payload: payload
  };
}

export function releaseContainerSucceeded(containers) {
  return {
    type: ActionTypes.CONTAINERS_RELEASE_SUCCEEDED,
    payload: containers,
  };
}

export function blockContainerSucceeded(containers) {
  return {
    type: ActionTypes.CONTAINERS_BLOCK_SUCCEEDED,
    payload: containers,
  };
}
