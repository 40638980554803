import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Field, FieldArray, FieldProps, Form, Formik, FormikProps } from 'formik';
import {
  ModalContainer,
  ModalContainerInfo,
  BulkPickUpActionRequestItem,
} from 'modules/global/adapters/crpAPI';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './styles';
import { useValidateAlfapass } from 'modules/transport-operator/hooks/assign-pickup';
import { LABEL } from 'theme/constants';
import pickupModalSchema from 'modules/transport-operator/schemas/pickupModalSchema';

type Props = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMeansOfTransport: React.Dispatch<React.SetStateAction<string>>;
  setPickupAssignments: React.Dispatch<React.SetStateAction<BulkPickUpActionRequestItem[]>>;
  selectedContainers: ModalContainer[] | undefined;
  action: string;
};

const PickupModal = ({
  setModalOpen,
  setMeansOfTransport,
  setPickupAssignments,
  selectedContainers,
  action,
}: Props) => {
  const { t } = useTranslation(['assignPickUp', 'common']);
  const initialValues = { containers: selectedContainers } as ModalContainerInfo;

  const classes = useStyles();

  const { onValidate, setContainer } = useValidateAlfapass();

  const handleCancel = () => {
    setModalOpen(false);
    setMeansOfTransport('');
    setContainer({} as ModalContainer);
  };

  const handleSubmit = (values: ModalContainerInfo) => {
    const validReleases = values.containers
      .filter((item) => item.isValid === true)
      .map((item) => {
        return { releaseId: item.releaseId, alfapassNumber: item.alfapassNumber };
      }) as BulkPickUpActionRequestItem[];

    if (validReleases.length === 0) {
      setModalOpen(false);
      return;
    }

    setPickupAssignments(validReleases);
    setModalOpen(false);
  };

  const handleOnBlur = (event: React.FocusEvent<{}>, container: ModalContainer) => {
    if (!container.isValidated && container.alfapassNumber !== '') {
      setContainer(container);
      onValidate();
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent<{ id: string; value: unknown }>,
    form: FormikProps<any>,
    container: ModalContainer
  ) => {
    container.isValid = undefined;
    container.isValidated = false;
    form.handleChange(event);
  };

  const hasValidContainers = (values: ModalContainerInfo) => {
    return values.containers.some((item) => item.isValid === true);
  };

  return (
    <React.Fragment>
      <DialogTitle>
        {t('assignPickUp:modal.title', { actionType: action })}
        <Typography>{t('assignPickUp:modal.subtitle')}</Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={pickupModalSchema}
        >
          {({ values, errors }: FormikProps<ModalContainerInfo>) => {
            return (
              <Form>
                <FieldArray
                  name="containers"
                  render={(arrayHelpers) => (
                    <>
                      <TableContainer component={'div'}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{LABEL.CONTAINER_NUMBER}</TableCell>
                              <TableCell>{LABEL.BL_NUMBER}</TableCell>
                              <TableCell>{LABEL.ALFAPASS}</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.containers.map((container: ModalContainer, index: number) => (
                              <TableRow key={index}>
                                <TableCell>{container.equipmentNumber}</TableCell>
                                <TableCell>{container.billOfLadingNumbers}</TableCell>
                                <TableCell>
                                  <Field
                                    name={`containers[${index}].alfapassNumber`}
                                    id={`containers[${index}].alfapassNumber`}
                                  >
                                    {({ field, form, meta }: FieldProps) => (
                                      <React.Fragment>
                                        <TextField
                                          id={`containers[${index}].alfapassNumber`}
                                          value={field.value}
                                          error={meta.error !== undefined && meta.error !== ''}
                                          helperText={meta.error}
                                          onChange={(e) => handleOnChange(e, form, container)}
                                          onBlur={(e) => handleOnBlur(e, container)}
                                          autoComplete="false"
                                          fullWidth
                                        />
                                      </React.Fragment>
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell className={classes.validationCell}>
                                  {container && container.isValid ? (
                                    <CheckIcon className={classes.green} />
                                  ) : container.isValid !== undefined &&
                                    container.isValid === false ? (
                                    <ClearIcon className={classes.red} />
                                  ) : (
                                    ''
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <DialogActions>
                        <Button onClick={handleCancel} color="default">
                          {t('common:general.cancel')}
                        </Button>
                        <Button
                          disabled={!hasValidContainers(values)}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t('common:general.apply')}
                        </Button>
                      </DialogActions>
                    </>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </React.Fragment>
  );
};
export default PickupModal;
