import React from 'react';
import { Alert, Color } from '@material-ui/lab';
import { EXTERNALPRODUCTPAGE } from '../../../../config/environment';
import { CREDITINFO } from '../../../../theme/constants';
import {
  getCreditIdentification,
  getRemainingCredits,
} from '../../../../store/selectors/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
  creditIdentification: any;
  remainingCredits: number;
};

const CreditInfo: React.FC<Props> = ({ creditIdentification, remainingCredits }: Props) => {
  const { t } = useTranslation(['acceptReleaseRight']);

  const getSeverity = (): Color => {
    if (remainingCredits <= 0) return 'error';
    if (remainingCredits <= 10) return 'warning';
    return 'info';
  };

  const getCreditInfoText = (): string => {
    if (remainingCredits <= 0) return t('acceptReleaseRight:creditInfo:noCredits');
    if (remainingCredits <= 10)
      return remainingCredits + t('acceptReleaseRight:creditInfo:lowCredits');
    return remainingCredits + t('acceptReleaseRight:creditInfo:infoCredits');
  };

  return creditIdentification != null && !creditIdentification.hasPaidSubscription ? (
    <React.Fragment>
      <div>
        <Alert severity={getSeverity()}>
          {getCreditInfoText()}
          <br />
          <a href={EXTERNALPRODUCTPAGE} target="_new">
            Go to Cinvio Product Page
          </a>
        </Alert>
      </div>
    </React.Fragment>
  ) : (
    <></>
  );
};

function mapStateToProps(state: any) {
  return {
    creditIdentification: getCreditIdentification(state),
    remainingCredits: getRemainingCredits(state),
  };
}

export default connect(mapStateToProps)(CreditInfo as React.ComponentType<Props>);
