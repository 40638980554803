export function mapPage(payload) {
  return {
    page: payload.page - 1,
    count: payload.totalRows,
    rowsPerPage: payload.pageSize,
  };
}

export function mapEnitityFilter(payload) {
  return {
    eori: payload.eori,
    vat: payload.vat,
    nxtEntityId: payload.nxtEntityId,
    duns: payload.duns,
    entityName: payload.entityName,
    apcsCode: payload.apcsCode,
  };
}

let allEquipments = [];

export function needsRefresh(timeStamp, equipmentNumber) {
  if (allEquipments.length === 0) {
    return true;
  }
  var found = null;
  if (allEquipments.length !== 0) {
    found = allEquipments.find((q) => q.equipmentNumber === equipmentNumber);
  }
  if (found != null) {
    let difference = Math.abs((Date.parse(timeStamp) - found.timeStamp) / 100);
    if (difference > 50) return true;
  }
  return false;
}

export function mapEquipmentId(equipments) {
  if (!equipments.length) {
    return [];
  }
  equipments.forEach((el) => {
    el.id =
      el.equipmentNumber + '_' + el.billOfLadingNumbers.toString() + '_' + el.releaseIdentification;
    var found = null;
    if (allEquipments.length !== 0)
      found = allEquipments.find((q) => q.equipmentNumber === el.equipmentNumber);
    if (found == null) {
      allEquipments.push({ equipmentNumber: el.equipmentNumber, timeStamp: Date.now() });
    } else {
      found.timeStamp = Date.now();
    }
  });
  return equipments;
}
