import { Box, Grid, Typography, Link } from '@material-ui/core';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { ErrorResponse, PostResponse, CustomErrorResponse } from 'modules/global/adapters/crpAPI';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../shared/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IUploadReleaseRightsFileRequestUi } from 'modules/global/adapters/crpAPI';

type Props = {
  values: IUploadReleaseRightsFileRequestUi;
  resultValue: PostResponse | ErrorResponse | any;
};

const UploadCsvResult = ({ values, resultValue }: Props) => {
  const { t } = useTranslation(['uploadcsv']);
  const classes = useStyles();

  function instanceOfPostResponse(object: any): object is PostResponse {
    if (object == null) return false;
    return 'data' in object;
  }

  function instanceOfErrorResponse(object: any): object is CustomErrorResponse {
    if (object == null) return false;
    return 'message' in object;
  }

  return (
    <React.Fragment>
      <Box style={{ paddingLeft: 20 }}>
        {resultValue && resultValue !== undefined && instanceOfPostResponse(resultValue) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {t('uploadcsv:uploadResultStep.labelSuccess')}
            </Grid>
            <Grid item xs={12} sm={12} style={{ color: 'green' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Link
                  component={RouterLink}
                  to={'/display-notifications?publicreferenceid=' + resultValue!.publicReferenceId}
                  style={{
                    color: 'green',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <CheckCircle style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                  <Typography style={{ verticalAlign: 'middle' }}>
                    {t('uploadcsv:uploadResultStep.linkIndividualFeedback')}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} style={{ color: 'green' }}>
              {resultValue!.status}
              {' with public reference id: '}
              {resultValue!.publicReferenceId}
            </Grid>
          </Grid>
        )}
        {resultValue && resultValue !== undefined && instanceOfErrorResponse(resultValue) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {t('uploadcsv:uploadResultStep.labelError')}
            </Grid>
            <Grid item xs={12} sm={12} style={{ color: 'red' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Cancel style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                <Typography style={{ verticalAlign: 'middle' }}>{resultValue.message}</Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
      {!instanceOfPostResponse(resultValue) && !instanceOfErrorResponse(resultValue) && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <CircularProgress style={{ verticalAlign: 'middle', marginRight: '8px' }} />
              <Typography style={{ verticalAlign: 'middle' }}>
                {t('uploadcsv:uploadResultStep.waitingForResult')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
export default UploadCsvResult;
