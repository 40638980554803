import { Checkbox } from '@material-ui/core';
import { Cell, RowData } from '@tanstack/react-table';

/**
 * A checkbox to use in a cell when defining
 * the DataGrid columns
 *
 * @example
 * export const columns: ColumnDef<CustomType>[] = [
 *  {
 *      id: 'select'
 *      cell: (props) => <CheckboxRow {...props} />,
 *  },
 */
const CheckboxRow = <T extends RowData, TValue extends unknown>({
  row,
}: ReturnType<Cell<T, TValue>['getContext']>) => (
  <span>
    <Checkbox
      checked={row.getIsSelected()}
      onChange={row.getToggleSelectedHandler()}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  </span>
);

export default CheckboxRow;
