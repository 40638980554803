export const ROUTE_PATHS = {
  BASE_ROUTE: '/',
  CONTAINERS: '/containers',
  ACCEPT_RELEASE_RIGHT: '/accept-releaseright',
  REVOKE_RELEASE_RIGHT: '/revoke-releaseright',
  DECLINE_RELEASE_RIGHT: '/decline-releaseright',
  TRANSFER_RELEASE_RIGHT: '/transfer-releaseright',
  ASSIGN_PICKUP: '/assign-pickup',
  UPDATE_PICKUP: '/update-pickup',
  DISPLAY_NOTIFICATIONS: '/display-notifications',
  OVERRULE_CUSTOMS_LIGHT: '/overrule-customs-light',
  BILLING_INFO: '/billing-info',
  SEARCH_CONTAINERS: '/search-containers',
};

export const maxNumberOfContainersProcessed = 1000;

export const BASE_FAQ_URL = 'https://documentation.nxtport.com/certified-pickup';
export const FAQ_PAGES = {
  ASSIGN_PICKUP: `${BASE_FAQ_URL}/faq-ui-assign-pickup`,
  UPDATE_PICKUP: `${BASE_FAQ_URL}/faq-ui-update-pickup`,
  ACCEPT_RELEASERIGHT: `${BASE_FAQ_URL}/faq-ui-accept-releaseright`,
  DECLINE_RELEASERIGHT: `${BASE_FAQ_URL}/faq-ui-decline-releaseright`,
  TRANSFER_RELEASERIGHT: `${BASE_FAQ_URL}/faq-ui-transfer-releaseright`,
};
