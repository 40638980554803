import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTableProps } from './BaseGrid';

const useStyles = makeStyles({
  container: {
    height: (props?: CustomTableProps) => (props?.lowerHeight ? '35vh' : 'calc(100vh - 218px)'),
  },
  table: {
    width: '100%',
    overflow: 'hidden',
    display: 'block',
    maxHeight: (props?: CustomTableProps) => (props?.lowerHeight ? '35vh' : 'calc(100vh - 218px)'),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyItems: 'flex-start',
    height: '100%',

    '& > span': {
      flexDirection: 'row',
    },
  },
  cell: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
  },
  cellHover: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  selectedCell: {
    background: blue[600],
    color: blue[50],
  },
  cellClickable: {
    cursor: 'pointer',
  },
  rowSelected: {},
  cellContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
  row: {
    width: 'fit-content',
    display: 'flex',
  },
  filter: {
    margin: '4px 0',
  },
  pager: {
    background: 'white',
    width: '100%',
    padding: '0 16px !important',
    borderTop: '1px solid #e0e0e0',
  },
  resizer: {
    position: 'absolute',
    right: 0,
    top: '10%',
    height: '80%',
    width: 2,
    background: 'rgba(0, 0, 0, 0.2)',
    cursor: 'col-resize',
    'user-select': 'none',
    'touch-action': 'none',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  resizing: {
    background: 'blue',
    opacity: 1,
  },
});

export default useStyles;
