import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '20px',
    marginLeft: '10px',
  },
  flavourText: {
    marginLeft: '10px',
    fontSize: '0.85rem',
    whiteSpace: 'break-spaces',
  },
  button: {
    marginRight: '10px',
  },
  errorText: {
    fontSize: '0.875rem',
    color: 'red',
  },
  subText: {
    color: theme.palette.grey[700],
    whiteSpace: 'break-spaces',
    margin: '10px 0 20px',
    lineHeight: '1.8rem',
  },
}));

export default useStyles;
