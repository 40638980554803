import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, GetContainersByVoyageParams } from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { usePostContainersUpdateVoyage } from '../../adapters/update-voyage/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from '../../constants';
import { FilterStep, ResultStep, VesselSearchStep } from '../shared';
import UpdateStep from './UpdateStep';

type Props = {
  data: Container[] | undefined;
  errors: FormikErrors<GetContainersByVoyageParams>;
  isFetching?: boolean;
  selectedRows: Row<Container>[] | null;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByVoyageParams;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

const StepperUpdateVoyage = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
  setRowSelection,
}: Props) => {
  const { t } = useTranslation(['changeVoyage', 'common', 'useCase']);
  const [newVesselName, setNewVesselName] = React.useState<string>('');
  const [newVoyageNumber, setNewVoyageNumber] = React.useState<string>('');
  const [newImoNumber, setNewImoNumber] = React.useState<string>('');
  const [newStayNumber, setNewStayNumber] = React.useState<string>('');

  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const vesselNameError = Boolean(errors.vesselName);
  const voyageNumberError = Boolean(errors.voyageNumber);
  const imoNumberError = Boolean(errors.imoNumber);
  const stayNumberError = Boolean(errors.stayNumber);

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleSuccessUpdate = () => {
    setRowSelection({});
  };

  const { mutate, status } = usePostContainersUpdateVoyage(handleSuccessUpdate);

  const updateVoyageAndVessel = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      vesselName: newVesselName,
      voyageNumber: newVoyageNumber,
      imoNumber: newImoNumber,
      stayNumber: newStayNumber,
      ids: listOfCheckedContainers,
    });
  };

  const resetFormAndValues = () => {
    resetForm();
    setNewVesselName('');
    setNewVoyageNumber('');
    setNewImoNumber('');
    setNewStayNumber('');
    setContainersUpdated(0);
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.UPDATE_VOYAGE}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.voyageSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('useCase:containers.loadingContainers')
              : t('useCase:containers.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              vesselNameError ||
              voyageNumberError ||
              imoNumberError ||
              stayNumberError ||
              (values.vesselName === '' &&
                values.voyageNumber === '' &&
                values.imoNumber === '' &&
                values.stayNumber === ''),
            content: <VesselSearchStep />,
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t('actions.chooseVoyage'),
            disableNext: numberOfContainersSelected < 1,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('common:general.performUpdate'),
            stepFlavorText: t('useCase:containers.performUpdateFlavorText'),
            nextCallBack: updateVoyageAndVessel,
            nextCustomText: t('useCase:containers.updateContainers', {
              numberOfContainersSelected: numberOfContainersSelected,
            }),
            disableNext:
              newVoyageNumber === '' ||
              newVesselName === '' ||
              newImoNumber === '' ||
              newStayNumber === '' ||
              numberOfContainersSelected < 1,
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                setNewVoyageNumber={setNewVoyageNumber}
                setNewVesselName={setNewVesselName}
                setNewImoNumber={setNewImoNumber}
                setNewStayNumber={setNewStayNumber}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={updateVoyageAndVessel}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndValues}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                  newVoyageNumber,
                  newVesselName,
                })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
export default StepperUpdateVoyage;
