import ContainerDetail from '../organisms/ContainerDetail';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import {
  loadContainer,
  loadEventHistory,
  setAdditionalFields,
} from '../../store/actions/containerActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import { getSelectedContainer } from '../../store/selectors/selectors';
import LoaderTimeout from '../organisms/LoaderTimeout';
import ArrayHelper from '../../utils/array';

const styles = (theme) => ({
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
});

function ReleaseDetail(props) {
  const {
    classes,
    container,
    loadContainer,
    loadEventHistory,
    setAdditionalFields,
    selectedUserType,
  } = props;

  const [containerLoaded, setContainerLoaded] = React.useState(false);
  const [fieldsSplitted, setFieldsSplitted] = React.useState(false);
  const [queryParams, setQueryParams] = React.useState(null);

  const splitAdditionalFields = (additionalDataFields) => {
    let numberOfGroups = Math.ceil(additionalDataFields.length / 4);
    return ArrayHelper.createGroups(additionalDataFields, numberOfGroups);
  };

  useEffect(() => {
    if (containerLoaded == false) {
      if (props.location.search == '') {
        return props.history.push('/');
      }
      const query = queryString.parse(props.location.search);
      loadContainer(query);
      setQueryParams(query);
    }
    setContainerLoaded(true);
  });

  useEffect(() => {
    if (containerLoaded && container.loading == false) {
      if (
        container.additionalDataFields &&
        container.additionalDataFields.length > 0 &&
        fieldsSplitted == false
      ) {
        let splittedFields = splitAdditionalFields(container.additionalDataFields);
        setAdditionalFields(splittedFields);
        setFieldsSplitted(true);
      }
    }
  }, [container]);

  const loadEventsAction = () => {
    if (queryParams != null) {
      loadEventHistory(queryParams);
    }
  };

  return (
    <>
      {container.loading && <CircularProgress className={classes.circularProgress} />}
      {!container.loading && containerLoaded && fieldsSplitted && (
        <ContainerDetail
          container={container}
          selectedUserType={selectedUserType}
          loadEventsAction={loadEventsAction}
        />
      )}
      <LoaderTimeout loading={container.loading} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    container: getSelectedContainer(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadContainer,
      loadEventHistory,
      setAdditionalFields,
      errorMessage,
      successMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReleaseDetail));
