import ActionTypes from './actionTypes';

export function loadPoliceContainersFilters(filters) {
  return {
    type: ActionTypes.POLICE_CONTAINERS_REQUESTED,
    payload: { filters },
  };
}

export function clearPoliceContainersFilters(filters, policeCase) {
  return {
    type: ActionTypes.POLICE_CONTAINERS_CLEAR,
  };
}

export function loadContainersPage(page) {
  return {
    type: ActionTypes.POLICE_CONTAINERS_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadContainersRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.POLICE_CONTAINERS_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadPoliceContainersSucceeded(containers) {
  return {
    type: ActionTypes.POLICE_CONTAINERS_REQUEST_SUCCEEDED,
    payload: containers,
  };
}

export function loadPoliceReleaseAudit(releaseId, policeCase, requestedFromSearch) {
  return {
    type: ActionTypes.POLICE_AUDIT_REQUESTED,
    payload: {
      releaseId,
      policeCase,
      requestedFromSearch,
    },
  };
}

export function loadPoliceReleaseAuditSucceeded(audit) {
  return {
    type: ActionTypes.POLICE_AUDIT_REQUEST_SUCCEEDED,
    payload: audit,
  };
}

export function policeBlockContainer(
  policeAction,
  releaseIdentification,
  billOfLadingNumbers,
  equipmentNumber
) {
  return {
    type: ActionTypes.POLICE_CONTAINER_BLOCK,
    payload: { policeAction, releaseIdentification, billOfLadingNumbers, equipmentNumber },
  };
}

export function createCompanySubscription(companySubscriptionRequest) {
  return {
    type: ActionTypes.CREATE_COMPANY_SUBSCRIPTION,
    payload: companySubscriptionRequest,
  };
}

export function createCompanySubscriptionSucceeded() {
  return {
    type: ActionTypes.CREATE_COMPANY_SUBSCRIPTION_SUCCEEDED,
  };
}

export function deleteCompanySubscription(externalId) {
  return {
    type: ActionTypes.DELETE_COMPANY_SUBSCRIPTION,
    payload: externalId,
  };
}

export function deleteCompanySubscriptionSucceeded(externalId) {
  return {
    type: ActionTypes.DELETE_COMPANY_SUBSCRIPTION_SUCCEEDED,
    payload: externalId,
  };
}

export function fetchCompanySubscriptions(pageCount) {
  return {
    type: ActionTypes.GET_COMPANY_SUBSCRIPTIONS,
    payload: pageCount,
  };
}

export function getCompanySubscriptionsSucceeded(subscriptions) {
  return {
    type: ActionTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCEEDED,
    payload: subscriptions,
  };
}

export function setSelectedEquipment(equipmentNumber) {
  return {
    type: ActionTypes.SET_SELECTED_EQUIPMENT,
    payload: equipmentNumber,
  };
}

export function setPoliceCase(policeCase) {
  return {
    type: ActionTypes.SET_POLICE_CASE,
    payload: policeCase,
  };
}

export function loadScrEvents(nxtEntityId) {
  return {
    type: ActionTypes.GET_SCR_EVENTS,
    payload: nxtEntityId,
  };
}

export function loadScrEventsSucceeded(scrEvents) {
  return {
    type: ActionTypes.GET_SCR_EVENTS_SUCCEEDED,
    payload: scrEvents,
  };
}
