import * as Yup from 'yup';

const uploadCsvValidationSchema = Yup.object().shape({
  vesselName: Yup.string()
    .min(2, 'Vessel name is too short')
    .max(150, 'Vessel name is too big')
    .required('Vessel name is required'),
  voyageNumber: Yup.string()
    .min(2, 'Voyage number is too short')
    .max(150, 'Voyage Number is too big')
    .required('Voyage number is required'),
  terminalNxtEntityId: Yup.string().required('Terminal is required'),
  imoNumber: Yup.string()
    .min(2, 'Imo number is too short')
    .max(150, 'Imo Number is too big')
    .required('Imo Number is required'),
  stayNumber: Yup.string()
    .min(2, 'Stay number is too short')
    .max(150, 'Stay Number is too big')
    .required('Stay Number is required'),
  file: Yup.mixed().required('CSV file is required'),
  expirationDateTimeUtc: Yup.date()
    .min(Yup.ref('releaseDateTimeUtc'), 'Expiration date must be greater than release date')
    .required('Expiration date is required'),
  releaseDateTimeUtc: Yup.date()
    .min(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      'Release date must be greater or equal than today'
    )
    .max(Yup.ref('releaseDateTimeUtc'), 'Expiration date must be greater than release date')
    .required('Release date is required'),
});

export default uploadCsvValidationSchema;
