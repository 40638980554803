import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  TextField,
  Select,
  TableRow,
  TableCell,
  Button,
  FormControl,
  Typography,
} from '@material-ui/core';
import { Done as DoneIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

class EditableListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: this.props.isNew,
    };
  }
  render() {
    const { item } = this.props;
    const { editing } = this.state;
    let itemKeyValProps = [];
    var key;
    for (key in item) {
      itemKeyValProps.push({
        key,
        value: item[key],
      });
    }
    return (
      <React.Fragment>
        <TableRow>
          {itemKeyValProps.map((itemProp, index) => {
            const settings = this.props.settings[itemProp.key];
            return (
              <TableCell key={index}>
                {!editing && <Typography color={'textSecondary'}>{itemProp.value}</Typography>}

                {editing && settings && settings.type === 'text' && (
                  <TextField
                    fullWidth
                    name={itemProp.key}
                    margin="normal"
                    value={itemProp.value}
                    onChange={(e) =>
                      this.props.propChangeHandler(
                        e.target.name,
                        e.target.value === '' ? null : e.target.value
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength:
                        this.props.textMaxLength == null || this.props.textMaxLength <= 0
                          ? 100
                          : this.props.textMaxLength,
                    }}
                  />
                )}
                {editing && settings && settings.type === 'number' && (
                  <TextField
                    fullWidth
                    name={itemProp.key}
                    type="number"
                    margin="normal"
                    value={itemProp.value}
                    onChange={(e) =>
                      this.props.propChangeHandler(e.target.name, parseInt(e.target.value))
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                {editing && settings && settings.type === 'select' && (
                  <FormControl fullWidth margin="normal">
                    <Select
                      fullWidth
                      value={itemProp.value}
                      displayEmpty
                      onChange={(e) => this.props.propChangeHandler(e.target.name, e.target.value)}
                      inputProps={{
                        value: itemProp.value,
                        name: itemProp.key,
                      }}
                    >
                      {settings.options.map((option, index) => (
                        <MenuItem value={option.value} key={index}>
                          {' '}
                          {option.label}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
            );
          })}
          {this.props.editable && (
            <TableCell align="right">
              <Button onClick={() => this.setState({ editing: !this.state.editing })}>
                {!this.state.editing && <EditIcon />}
                {this.state.editing && <DoneIcon />}
              </Button>
              <Button onClick={() => this.props.deleteHandler()}>
                <DeleteIcon />
              </Button>
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  }
}

EditableListItem.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  propChangeHandler: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  textMaxLength: PropTypes.number,
};

export default EditableListItem;
