import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  drawer: {
    width: '500px',
  },
  textField: {
    marginBottom: '20px',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default useStyles;
