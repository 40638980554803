import DateTimeHelper from '../datetime';
import { LABEL } from '../../theme/constants';

export default class PoliceContainerFilterValidator {
  static Validate = (filters) => {
    let errors = [];

    const SetError = (error) => {
      switch (error.property) {
        case 'equipmentNumber':
          errors.push({ field: 'equipmentNumber', isvalid: false, errorMessage: LABEL.EQUIPMENT_NUMBER_MUST_HAVE_MIN_LENGTH_3 });
          break;
        case 'billOfLadingNumbers':
          errors.push({ field: 'billOfLadingNumbers', isvalid: false, errorMessage: LABEL.BILLOFLADINGNUMBERS_MUST_HAVE_MIN_LENGTH_3 });
          break;
        case 'shippingAgent':
          errors.push({ field: 'shippingAgent', isvalid: false, errorMessage: LABEL.SHIPPINGAGENT_MUST_HAVE_MIN_LENGTH_3 });
          break;
        case 'terminal':
          errors.push({ field: 'terminal', isvalid: false, errorMessage: LABEL.TERMINAL_MUST_HAVE_MIN_LENGTH_3 });
          break;
        case 'company':
          errors.push({ field: 'company', isvalid: false, errorMessage: LABEL.COMPANY_MUST_HAVE_MIN_LENGTH_3});
          break;
        case 'dateStart':
          errors.push({ field: 'dateStart', isvalid: false, errorMessage: LABEL.DATESTART_MUST_BE_BEFORE_DATEEND });
          break;
        case 'dateEnd':
          errors.push({ field: 'dateEnd', isvalid: false , errorMessage: LABEL.DATESTART_MUST_BE_BEFORE_DATEEND});
          break;
          case 'all':
            errors.push({ field: 'all fields', isvalid: false , errorMessage: LABEL.AT_LEAST_ONE_FIELD_MUST_BE_FILLED_IN});
            break;
        default:
          break;
      }
    };

    let rules = [
      {
        property: 'equipmentNumber',
        valid: filters.equipmentNumber.length === 0 || filters.equipmentNumber.length >= 3,
      },
      {
        property: 'billOfLadingNumbers',
        valid: filters.billOfLading.length === 0 || filters.billOfLading.length >= 3,
      },
      {
        property: 'shippingAgent',
        valid: filters.shippingAgent.length === 0 || filters.shippingAgent.length >= 3,
      },
      {
        property: 'terminal',
        valid: filters.terminal.length === 0 || filters.terminal.length >= 3,
      },
      {
        property: 'company',
        valid: filters.company.length === 0 || filters.company.length >= 3,
      },
      {
        property: 'dateStart',
        valid:
          filters.dateStart.length === 0 ||
          filters.dateEnd.length === 0 ||
          DateTimeHelper.lessThanOrEqual(filters.dateStart, filters.dateEnd),
      },
      {
        property: 'dateEnd',
        valid:
          filters.dateEnd.length === 0 ||
          filters.dateStart.length === 0 ||
          DateTimeHelper.greaterThanOrEqual(filters.dateEnd, filters.dateStart),
      },
      {
        property: 'all',
        valid:
        !(filters.equipmentNumber.length === 0 &&
        filters.billOfLading.length === 0 &&
        filters.shippingAgent.length === 0 &&
        filters.terminal.length === 0 &&
        filters.company.length === 0 &&
        filters.dateStart.length === 0 && filters.dateEnd.length === 0 &&
        filters.isArchived.length === 0 &&
        filters.isBlocked.length === 0),
      }
    ];

    if (Object.keys(rules).some((x) => !rules[x].valid)) {
      rules.filter((x) => !x.valid).forEach((error, i) => {
        SetError(error);
      });
    }

    return errors;
  };
}
