import { all, fork } from 'redux-saga/effects';
import archiveSaga from './archiveSaga';
import companiesSaga from './companiesSaga';
import terminalSaga from './terminalSaga';
import subscriptionSaga from './subscriptionSaga';
import containerSaga from './containerSaga';
import policeContainerSaga from './policeContainerSaga';
import releaseSaga from './releaseSaga';
import userProfileSaga from './userProfileSaga';
import commercialReleaseSaga from './commercialReleaseSaga';
import alfapassSaga from './alfapassSaga';
import driverSaga from './driverSaga';
import driverListSaga from './driverListSaga';

export function* rootSaga() {
  yield all([
    fork(archiveSaga),
    fork(subscriptionSaga),
    fork(containerSaga),
    fork(policeContainerSaga),
    fork(companiesSaga),
    fork(terminalSaga),
    fork(releaseSaga),
    fork(userProfileSaga),
    fork(alfapassSaga),
    fork(commercialReleaseSaga),
    fork(driverSaga),
    fork(driverListSaga),
  ]);
}
