import React from 'react';
import { Select } from '@material-ui/core';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import useStyles from './style';

interface Option {
  name: string;
  value: number;
}

type Props = {
  children: React.ReactNode;
  form: FormikProps<FormikValues>;
  field: FieldInputProps<Option>;
  error: boolean;
  defaultValue?: FormikValues;
};

const FormikSelect = ({ children, form, field, defaultValue, ...props }: Props) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const error = props.error;

  const classes = useStyles();

  return (
    <Select
      defaultValue={defaultValue}
      name={name}
      value={value || ''}
      error={error}
      className={error ? classes.error : ''}
      fullWidth
      variant="outlined"
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

export default FormikSelect;
