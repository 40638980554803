import { functionalUpdate, Updater } from '@tanstack/react-table';
import React from 'react';

/**
 * This hooks helps you to store table state in local storage.
 * 
 * It uses react-table's functional updater internally but exposes an API
 * similar to that of React.useState.
 * 
 * @example
 * const MyComponent: React.FunctionComponent<Props> = () => {
 *  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
        'custom-column-sizing-key',
        {}
      );

    return ( ... )
  }
 */
const useLocalTableValues = <T extends unknown>(
  storeKey: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const defaultState = localStorage.getItem(storeKey);
  const [tableValues, setTableValues] = React.useState<T>(
    defaultState ? JSON.parse(defaultState) : defaultValue
  );

  const handleValueUpdates = React.useCallback(
    (updater: Updater<T>) => {
      const newValue = functionalUpdate(updater, tableValues);
      setTableValues(updater);
      localStorage.setItem(storeKey, JSON.stringify(newValue));
    },
    [tableValues, storeKey]
  );

  return [tableValues, handleValueUpdates];
};

export default useLocalTableValues;
