import { Get } from './authorizedRequest';

const endpoint = '/terminals/';

export async function getTerminals() {
    const request = Get(endpoint);
    const response = await fetch(request);

    if (!response.ok) {
      let err;
      try {
        err = await response.json();
      } catch {
        throw new Error(response.statusText);
      }
      throw err;
    }
    var terminals = response.json();
    return terminals
}