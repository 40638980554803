import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { getArchive } from '../api/archiveApi';
import { getArchiveFilters, getArchivePaging, getUserProfile } from '../selectors/selectors';

function getArchiveQuery(filters, paging) {
  return {
    billofladingnumberfilter: filters.billOfLading || undefined,
    startdatefilter: filters.selectedToDate || undefined,
    enddatefilter: filters.selectedFromDate || undefined,
    page: paging.page + 1 || undefined,
    pageSize: paging.rowsPerPage || undefined,
  };
}

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadAllArchive(action) {
  try {
    let paging = yield select(getArchivePaging);
    let filters = yield select(getArchiveFilters);
    let actor = yield select(getUserProfile);
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    if (action.payload.filters) {
      filters = action.payload.filters;
    }
    yield call(getArchive, {
      query: getArchiveQuery(filters, paging),
      nxtEntityId: actor.nxtEntityId
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* archiveSaga() {
  yield takeEvery(ActionTypes.ARCHIVE_REQUESTED, loadAllArchive);
}

export default archiveSaga;
