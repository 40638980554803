import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.selectedStakeholder, action) {
  switch (action.type) {
    case ActionTypes.SELECTED_STAKEHOLDER_CHANGED: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
