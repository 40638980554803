import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { endOfWeek, startOfWeek } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from '../../../store/actions/messageActions';
import store from '../../../store/store';
import { Container } from '../../global/adapters/crpAPI';
import { DataGrid, Drawer } from '../../global/components';
import EmptyState from '../../global/components/EmptyState/EmptyState';
import { useLocalTableValues } from '../../global/hooks';
import { useGetContainersForUpdateCommercialExpiryDate } from '../adapters/update-expiry-date/queries';
import ContainerDetailDrawer from '../components/shared/ContainerDetailDrawer';
import StepperUpdateExpiryDate from '../components/update-expiry-date/StepperUpdateExpiryDate';
import { updateExpiryDateColumns } from '../constants/shared/shippingLineColumns';
import updateExpiryDateSchema from '../schemas/updateExpiryDateSchema';
import { ExpiryDateWeek } from '../types';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: ExpiryDateWeek = {
  weekFilter: {
    startDate: undefined,
    endDate: undefined,
  },
};

const UpdateExpiryDate: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['updateExpiryDate', 'common', 'validation']);
  const formRef = useRef<FormikProps<ExpiryDateWeek>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: Container;
  }>({
    open: false,
    data: undefined,
  });

  const getRowSelection = React.useRef<(() => RowModel<Container>) | null>(null);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [selectedRows, setSelectedRows] = React.useState<Row<Container>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-update-expiry-date',
    {}
  );

  const fromDate =
    formRef?.current?.values?.weekFilter?.startDate &&
    startOfWeek(formRef.current.values.weekFilter.startDate).toUTCString();
  const toDate =
    formRef?.current?.values?.weekFilter?.endDate &&
    endOfWeek(formRef.current.values.weekFilter.endDate).toUTCString();

  const { data, isLoading, isFetching, isError } = useGetContainersForUpdateCommercialExpiryDate({
    fromDate,
    toDate,
  });

  const handleOnSubmit = () => {
    setRowSelection({});
    setDisplayGrid(true);
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: Container) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          validationSchema={updateExpiryDateSchema}
          innerRef={formRef as React.Ref<FormikProps<ExpiryDateWeek>>}
          onSubmit={handleOnSubmit}
        >
          {({ submitForm }: FormikProps<ExpiryDateWeek>) => {
            return (
              <StepperUpdateExpiryDate
                selectedRows={selectedRows}
                setDisplayGrid={setDisplayGrid}
                submitForm={submitForm}
                data={data}
                isFetching={isFetching}
                setRowSelection={setRowSelection}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={updateExpiryDateColumns}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                  onCellClick={handleCellClick}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default UpdateExpiryDate;
