import { GetContainersByShippingAgentParams } from 'modules/global/adapters/crpAPI';

export const searchContainersQueryKeys = {
  root: ['search-containers'] as const,
  containersForUpdateRoot: () =>
    [...searchContainersQueryKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (shippingAgentParams: GetContainersByShippingAgentParams) =>
    [...searchContainersQueryKeys.containersForUpdateRoot(), shippingAgentParams] as const,
  getShippingAgents: () => [...searchContainersQueryKeys.root, 'get-shipping-agents'] as const,
};
