import { Button, List, ListItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Clear as DeleteForeverIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompaniesFilters } from '../../store/actions/companiesActions';
import { LABEL, VALIDATION } from '../../theme/constants';

const styles = (theme) => ({
  inputLabel: {
    backgroundColor: 'white',
    padding: '8px',
    marginTop: '-8px',
  },
  horizontalDivider: {
    width: theme.spacing(5),
  },
  filterButton: {
    marginRight: theme.spacing(1),
  },
});

class CompanyFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      errors: {},
    };
  }

  onChange = (key) => (event) => {
    const filters = { ...this.state.filters, [key]: event.target.value };
    const errors = {
      ...this.state.errors,
      [key]: event.target.value.length > 0 && event.target.value.length < 3 ? true : false,
    };
    this.setState({ filters, errors });
  };

  onFilter = () => {
    this.props.loadCompaniesFilters(this.state.filters);
  };

  onFilterClear = () => {
    const filters = this.state.filters;
    for (let key in filters) {
      filters[key] = '';
    }
    this.setState(filters, () => this.props.loadCompaniesFilters(this.state.filters));
  };

  render() {
    const { classes } = this.props;
    const { filters, errors } = this.state;
    return (
      <List>
        <ListItem>
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.nxtEntityId}
            label={LABEL.NXTENTITY_ID_LABEL}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            helperText={errors.nxtEntityId ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.nxtEntityId}
            onChange={this.onChange('nxtEntityId')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.apcsCode}
            label={'APCS'}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            helperText={errors.apcsCode ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.apcsCode}
            onChange={this.onChange('apcsCode')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.eori}
            label={'EORI'}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            helperText={errors.eori ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.eori}
            onChange={this.onChange('eori')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.duns}
            label={'DUNS'}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            helperText={errors.duns ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.duns}
            onChange={this.onChange('duns')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.entityName}
            label={LABEL.NAME_LABEL}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (this.state.errors['entityName'] == false) {
                  this.onFilter();
                }
              }
            }}
            helperText={errors.entityName ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.entityName}
            onChange={this.onChange('entityName')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <TextField
            style={{ flex: 2 }}
            value={filters.vat}
            label={LABEL.TAX_ID_LABEL}
            margin="normal"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.onFilter();
              }
            }}
            helperText={errors.vat ? VALIDATION.MIN_LENGTH_3 : ''}
            error={errors.vat}
            onChange={this.onChange('vat')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.horizontalDivider} />
          <Button
            style={{ padding: '14px 12px' }}
            size="large"
            variant="contained"
            color="primary"
            onClick={this.onFilter}
            className={classes.filterButton}
            disabled={Object.values(errors).some((error) => error === true)}
          >
            {LABEL.SEARCH_LABEL}
            <SearchIcon className={classes.rightIcon} />
          </Button>
          <Button
            style={{ padding: '14px 0px' }}
            variant="contained"
            color="primary"
            onClick={this.onFilterClear}
          >
            <DeleteForeverIcon />
          </Button>
        </ListItem>
      </List>
    );
  }
}

CompanyFilter.propTypes = {
  loadCompaniesFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filters: state.companies.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCompaniesFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyFilter));
