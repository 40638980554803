import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

const styles = theme => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
});

function FormDialog(props) {
  const {
    buttonTitle,
    dialogTitle,
    contentText,
    contentFields,
    actions,
    handleClickOpen,
    handleClose,
    open,
    variant,
    buttonClassName,
    disabled,
    fullWidth,
    maxWidth,
    classes,
    dialogType
  } = props;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        disabled={disabled}
        className={buttonClassName}
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleClickOpen()
        }}
        variant={variant}>
        {buttonTitle}
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            reactLocalStorage.set(dialogType, false);
          }
          handleClose();
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.dialogContentWithNewLines}
          >
            {contentText}
          </DialogContentText>
          {contentFields}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </div>
  );
}

FormDialog.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  contentFields: PropTypes.object,
  actions: PropTypes.object.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogType: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default withStyles(styles)(FormDialog);
