import { TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { Terminal } from '../../../global/adapters/crpAPI';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
  setNewTerminal: React.Dispatch<React.SetStateAction<Terminal | undefined>>;
  newTerminal: Terminal | undefined;
  data?: Terminal[];
  isFetching: boolean;
  isError: boolean;
};

const UpdateStep: React.FC<Props> = ({
  setNewTerminal,
  newTerminal,
  numberOfContainersSelected,
  data,
  isFetching,
  isError,
}: Props) => {
  const { t } = useTranslation('changeTerminal');
  const classes = useStyles();

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('messages.errorGetTerminals')));
  }, [isError, t]);

  const handleTerminalChange = (
    _event: React.ChangeEvent<{}>,
    terminal: Terminal | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason.toLowerCase() === 'clear') {
      setNewTerminal(undefined);
    } else {
      terminal && setNewTerminal(terminal);
    }
  };

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
      <Autocomplete
        options={data || []}
        getOptionLabel={(options) => `${options.name} - ${options.code}` || ''}
        defaultValue={newTerminal?.code ? newTerminal : null}
        onChange={handleTerminalChange}
        loading={isFetching}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </React.Fragment>
  );
};

export default UpdateStep;
