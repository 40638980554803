import { Box, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import {
  IdentificationType,
  IUploadReleaseRightsFileRequestUi,
} from 'modules/global/adapters/crpAPI';
import { Field, FieldProps, FormikErrors } from 'formik';

type Props = {
  showText: boolean | undefined;
  label: string | undefined;
  translationKey: string | undefined;
  textLabel: string | undefined;
};

const IdentificationSelect = ({ showText, label, translationKey, textLabel }: Props) => {
  const { t } = useTranslation(translationKey);
  const classes = useStyles();

  return (
    <React.Fragment>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Box flexDirection="row" display="flex">
        <Box marginRight={1}>
          <Field name="identificationType" id="identificationType" error="identificationTypeError">
            {({ field, form }: FieldProps) => (
              <>
                {showText && (
                  <InputLabel className={classes.label}>{t('selectParty.type')}</InputLabel>
                )}
                <Select
                  variant="outlined"
                  labelId="identification-type-label"
                  id="identification-type-label-select"
                  value={field.value}
                  label={label}
                  name="identificationType"
                  onChange={form.handleChange}
                >
                  {Object.values(IdentificationType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Field>
        </Box>
        <Box flexGrow={1}>
          <Field name="identificationCode" id="identificationCode" error="identificationCodeError">
            {({ field, form }: FieldProps) => (
              <>
                {showText && (
                  <InputLabel className={classes.label}>
                    {t('selectParty.identificationTo')}
                  </InputLabel>
                )}
                <TextField
                  onChange={form.handleChange}
                  variant="outlined"
                  value={field.value}
                  name="identificationCode"
                  label={textLabel === undefined ? '' : textLabel}
                  style={{ display: 'flex' }}
                />
              </>
            )}
          </Field>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default IdentificationSelect;
