import { Checkbox } from '@material-ui/core';
import { Header, RowData } from '@tanstack/react-table';

/**
 * A select-all type of checkbox to use in a header when defining
 * the DataGrid columns
 *
 * @example
 * export const columns: ColumnDef<CustomType>[] = [
 *  {
 *      id: 'select'
 *      header: (props) => <CheckboxHeader {...props} />,
 *  },
 */
const CheckboxHeader = <T extends RowData, TValue extends unknown>({
  table,
}: ReturnType<Header<T, TValue>['getContext']>) => (
  <span>
    <Checkbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  </span>
);

export default CheckboxHeader;
