import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AlfaPass from '../../../../components/pages/Alfapass';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';

type Props = {
  clientId: string | undefined;
};

const IdentifyAlfapass = ({ clientId }: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);

  const [agreeCheck, setAgreeCheck] = React.useState<boolean>(false);

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div>
        {!agreeCheck && (
          <div>
            <span>{t('overruleCustomsLight:sidebarAlfapass.termsText')}</span>
            <br />
            <br />
            <span>{t('overruleCustomsLight:sidebarAlfapass.termsTextCheckbox')}</span>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeCheck}
                  onChange={() => {
                    setAgreeCheck(!agreeCheck);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography>
                  {t('overruleCustomsLight:sidebarAlfapass.termCheckbox')}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://documentation.nxtport.com/certified-pickup/annex-6-terms-and-conditions-alfapass"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('overruleCustomsLight:sidebarAlfapass.termsLink')}
                  </a>
                </Typography>
              }
            />
            <br />
          </div>
        )}
        <br />
        {agreeCheck && (
          <div>
            <span>{t('overruleCustomsLight:sidebarAlfapass.redirectText')}</span>
            <br />
            <br />
            <AlfaPass
              clientId={clientId}
              buttonText={t('overruleCustomsLight:sidebarAlfapass.buttonMyAlfaPass')}
              buttonColor={'primary'}
              buttonVariant="outlined"
            />
            <br />
            <span>{t('overruleCustomsLight:sidebarAlfapass.restrictedText')}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default IdentifyAlfapass;
