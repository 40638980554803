import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionScreen } from '../../global/components/ActionScreen';
import { ActionGroup } from '../../global/components/ActionScreen/ActionScreen';
import { DashboardScreen } from '../../global/components/ActionScreen';
import { DashboardGroup } from '../../global/components/ActionScreen/DashboardScreen';
import { ROUTE_PATHS } from '../constants';
import { useDashboardRequest } from '../adapters/dashboard/queries';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const UseCases: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation('useCase');

  const { data, isLoading, isFetching, isError } = useDashboardRequest();

  const incomingReleaseRights = data?.numberOfIncomingReleaseRights;
  const outgoingReleaseRights = data?.numberOfOutgoingReleaseRights;
  const numberOfCommercialReleases = data?.numberOfCommercialReleases;
  const declinedReleaseRights = data?.numberOfDeclinedReleaseRights;

  const releasePartyTiles: DashboardGroup = {
    tiles: [
      {
        title: t('tiles.incomingReleaseRights.title'),
        textBody: t('tiles.incomingReleaseRights.body', { number: incomingReleaseRights }),
      },
      {
        title: t('tiles.outgoingReleaseRights.title'),
        textBody: t('tiles.outgoingReleaseRights.body', { number: outgoingReleaseRights }),
      },
      {
        title: t('tiles.declinedReleaseRights.title'),
        textBody: t('tiles.declinedReleaseRights.body', { number: declinedReleaseRights }),
      },
      {
        title: t('tiles.numberOfCommercialReleases.title'),
        textBody: t('tiles.numberOfCommercialReleases.body', {
          number: numberOfCommercialReleases,
        }),
      },
    ],
    fetchingText: t('tiles.isFetching.body'),
  };

  const releasePartyActions: ActionGroup[] = [
    {
      category: t('actions.manage.title'),
      actions: [
        {
          title: t('actions.manage.acceptReleaseRight.title'),
          textBody: t('actions.manage.acceptReleaseRight.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.ACCEPT_RELEASE_RIGHT,
        },
        {
          title: t('actions.manage.declineReleaseRight.title'),
          textBody: t('actions.manage.declineReleaseRight.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.DECLINE_RELEASE_RIGHT,
        },
        {
          title: t('actions.manage.transferReleaseRight.title'),
          textBody: t('actions.manage.transferReleaseRight.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.TRANSFER_RELEASE_RIGHT,
        },
        {
          title: t('actions.manage.revokeReleaseRight.title'),
          textBody: t('actions.manage.revokeReleaseRight.body'),
          type: 'CREATE',
          url: ROUTE_PATHS.REVOKE_RELEASE_RIGHT,
        },
      ],
    },
    {
      category: t('actions.consult.title'),
      actions: [
        {
          title: t('actions.consult.searchContainer.title'),
          textBody: t('actions.consult.searchContainer.body'),
          type: 'READ',
          url: ROUTE_PATHS.SEARCH_CONTAINERS,
        },
        {
          title: t('actions.consult.billingInfo.title'),
          textBody: t('actions.consult.billingInfo.body'),
          type: 'READ',
          url: ROUTE_PATHS.DISPLAY_BILLING_INFO,
        },
        {
          title: t('actions.consult.notification.title'),
          textBody: t('actions.consult.notification.body'),
          type: 'READ',
          url: ROUTE_PATHS.DISPLAY_NOTIFICATIONS,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <DashboardScreen dashboardGroup={releasePartyTiles} isFetching={isFetching} />
      <ActionScreen actionGroup={releasePartyActions} />
    </React.Fragment>
  );
};

export default UseCases;
