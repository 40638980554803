import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import App from './components/App';
import history from './history/browserHistory';
import * as serviceWorker from './serviceWorker';
import store from 'store/store';
import userManager from './utils/userManager';
import './theme/index.css';
import { Route, Router, Switch } from 'react-router-dom';
import CallbackComponent from './components/pages/Callback';
import { QueryClientProvider } from './modules/global/components';
import LoginCallbackForAlfapass from 'components/pages/LoginCallbackForAlfapass';

window.store = store;

ReactDOM.render(
  <React.Suspense fallback={'...loading'}>
    <QueryClientProvider>
      <Provider store={store}>
        {
          <>
            <OidcProvider store={store} userManager={userManager}>
              <Router history={history}>
                <Switch>
                  <Route path="/callback" component={CallbackComponent} />
                  <Route
                    path="/overrule-customs-light/callback"
                    component={LoginCallbackForAlfapass}
                  />
                  <Route
                    path="/"
                    render={() => {
                      return <App />;
                    }}
                  />
                </Switch>
              </Router>
            </OidcProvider>
          </>
        }
      </Provider>
    </QueryClientProvider>
  </React.Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
