import { Drawer as MuiDrawer, DrawerProps, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Content from './Content';
import GreenLightContent from './GreenLightContent';
import useStyles from './style';

type Props = {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
    }>
  >;
} & DrawerProps;

const Drawer = ({ showDrawer, setShowDrawer, ...props }: Props) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      anchor={props.anchor || 'right'}
      open={showDrawer}
      onClose={() => setShowDrawer({ open: false })}
    >
      <div className={classes.drawer}>
        <IconButton
          aria-label="Close"
          onClick={() => setShowDrawer({ open: false })}
          className={classes.close}
        >
          <CloseIcon />
        </IconButton>
        {props.children}
      </div>
    </MuiDrawer>
  );
};

Drawer.Content = Content;
Drawer.GreenLightContent = GreenLightContent;

export default Drawer;
