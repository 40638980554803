import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.eventHistory, action) {
    switch (action.type) {
        case ActionTypes.EVENT_HISTORY_REQUESTED_SUCCEEDED: {
            return {
                ...state,
                result: action.payload.result
            }
        }
        default: {
            return state;
        }
    }
}