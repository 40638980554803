export const ROUTE_PATHS = {
  BASE_ROUTE: '/',
  CONTAINERS: '/containers',
  UPDATE_EXPIRY_DATE: '/update-expiry-date',
  CHANGE_TERMINAL: '/change-terminal',
  UPDATE_VOYAGE: '/update-voyage',
  DISPLAY_NOTIFICATIONS: '/display-notifications',
  REVOKE_VOYAGE: '/revoke-voyage',
  UNBLOCK_VOYAGE: '/unblock-voyage',
  DELETE_VOYAGE: '/delete-voyage',
  BLOCK_VOYAGE: '/block-voyage',
  ASSIGN_RELEASE_PARTY: '/assign-release-party',
  CONTAINER_DETAIL: '/containers/detail',
  CONTAINER_DETAIL_OVERVIEW: '/containers/:tabId',
  CONTAINER_RELEASE_ADD: '/commercial-release/add',
  CONTAINER_RELEASE_UPDATE: '/commercial-release/update',
  CONTAINER_SUBSCRIPTIONS: '/container-subscriptions',
  CONTAINER_SUBSCRIPTIONS_DETAIL: '/container-subscriptions/detail',
  SEARCH_PER_VOYAGE: '/search-containers',
  BILLING_INFO: '/billing-info',
  CREATE_MULTIPLE_RELEASES_UPLOAD: '/create-multiple-releases-upload',
  OVERRULE_CUSTOMS_LIGHT: '/overrule-customs-light',
};

export const maxNumberOfContainersProcessed = 1000;

export const BASE_FAQ_URL = 'https://documentation.nxtport.com/certified-pickup';
export const FAQ_PAGES = {
  ASSIGN_RELEASE_PARTY: `${BASE_FAQ_URL}/faq-ui-assign-first-release-party`,
  CHANGE_TERMINAL: `${BASE_FAQ_URL}/faq-ui-change-terminal-by-vessel`,
  REVOKE_VOYAGE: `${BASE_FAQ_URL}/faq-ui-revoke-per-vessel`,
  UPDATE_EXPIRY_DATE: `${BASE_FAQ_URL}/faq-ui-update-commercial-expiry-date`,

  BLOCK_VOYAGE: `${BASE_FAQ_URL}/faq-ui-block-per-vessel`,
  DELETE_VOYAGE: `${BASE_FAQ_URL}/faq-ui-delete-per-vessel`,
  UNBLOCK_VOYAGE: `${BASE_FAQ_URL}/faq-ui-unblock-per-vessel`,
  UPDATE_VOYAGE: `${BASE_FAQ_URL}/faq-ui-update-per-vessel`,
};
