import { NotificationRequest } from 'modules/global/adapters/mainAPI';
import { GetContainersByShippingAgentParams } from 'modules/global/adapters/crpAPI';

export const displayNotificationKeys = {
  root: ['display-notifications'] as const,
  getNotifications: (notificationRequest: NotificationRequest) =>
    [...displayNotificationKeys.root, 'get-notifications', notificationRequest] as const,
};

export const searchContainersQueryKeys = {
  root: ['search-containers'] as const,
  getContainerDetail: (id: string) =>
    [...searchContainersQueryKeys.root, 'get-container-detail', id] as const,
  containersForUpdateRoot: () =>
    [...searchContainersQueryKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (shippingAgentParams: GetContainersByShippingAgentParams) =>
    [...searchContainersQueryKeys.containersForUpdateRoot(), shippingAgentParams] as const,
  getShippingAgents: () => [...searchContainersQueryKeys.root, 'get-shipping-agents'] as const,
};
