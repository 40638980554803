import { FiberManualRecord } from '@material-ui/icons';
import { createColumnHelper, FilterFn, Row } from '@tanstack/react-table';
import { LightColor, TerminalContainer } from 'modules/global/adapters/crpAPI';
import { LightColor as Color } from 'theme/constants';
import DataGrid from 'modules/global/components/DataGrid';
import i18n from 'modules/global/config/i18n';

const greenLightValueStyle = { display: 'flex', alignItems: 'center' };

const showIcon = (color: LightColor | undefined) => {
  switch (color) {
    case Color.Grey:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
    case Color.Yellow:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#dfc920'} />;
    case Color.Orange:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#f57b00'} />;
    case Color.Red:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#e60000'} />;
    case Color.Green:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#4AB55F'} />;

    case undefined:
    default:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
  }
};

const arrayFilter: FilterFn<TerminalContainer> = (
  row: Row<TerminalContainer>,
  columnId: string,
  filterValue: string
) => {
  const values: string[] = row.getValue(columnId);

  return values.some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
};

const columnHelper = createColumnHelper<TerminalContainer>();

export const baseTransportOperatorColumns = [
  columnHelper.accessor('equipmentNumber', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:containerNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('billOfLadingNumbers', {
    cell: (info) => info.getValue()?.join(', '),
    header: () => i18n.t('glossary:billOfLadingNumbers'),
    filterFn: arrayFilter,
  }),
  columnHelper.accessor('receivedFrom', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:receivedFrom'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('terminalOperationValue', {
    cell: ({ row, getValue }) => (
      <div style={greenLightValueStyle}>
        {showIcon(row.original.terminalOperationColor)} {getValue<string>()}
      </div>
    ),
    header: () => i18n.t('glossary:terminalOperationValue'),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('terminalReadyValue', {
    cell: ({ row, getValue }) => (
      <div style={greenLightValueStyle}>
        {showIcon(row.original.terminalReadyColor)} {getValue<string>()}
      </div>
    ),
    header: () => i18n.t('glossary:terminalReadyValue'),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('customsValue', {
    cell: ({ row, getValue }) => (
      <div style={greenLightValueStyle}>
        {showIcon(row.original.customsColor)} {getValue<string>()}
      </div>
    ),
    header: () => i18n.t('glossary:customsValue'),
    enableColumnFilter: false,
  }),
];

export const baseTransportOperatorColumnsWithCheckbox = [
  columnHelper.display({
    id: 'actions',
    cell: (cellProps) => <DataGrid.CheckboxRow {...cellProps} />,
    header: (headerProps) => <DataGrid.CheckboxHeader {...headerProps} />,
    size: 60,
    enableResizing: false,
  }),
  ...baseTransportOperatorColumns,
];

export const transportOperatorColumns = [...baseTransportOperatorColumnsWithCheckbox];
export const transportOperatorColumnsWithTerminalName = [
  ...transportOperatorColumns.slice(0, 3),
  columnHelper.accessor('terminalName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:terminalName'),
    filterFn: 'includesString',
  }),
  ...transportOperatorColumns.slice(3),
];
