import ActionTypes from '../actions/actionTypes';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { loadDriverListSucceeded } from '../actions/driverListActions';
import {
  getDriverList,
  submitDriverAlias,
  updateDriverAlias,
  deleteDriverAlias,
} from '../api/driverListApi';

function* loadDriverList() {
  const driverList = yield call(getDriverList);
  yield put(loadDriverListSucceeded(driverList));
}

function* createDriverAlias(action) {
  yield call(submitDriverAlias, action.payload);
}

function* removeDriverAlias(action) {
  yield call(deleteDriverAlias, action.payload);
}

function* editDriverAlias(action) {
  yield call(updateDriverAlias, action.payload);
}

function* driverListSaga() {
  yield takeLatest(ActionTypes.DRIVERLIST_REQUESTED, loadDriverList);
  yield takeEvery(ActionTypes.SUBMIT_DRIVERALIAS, createDriverAlias);
  yield takeEvery(ActionTypes.UPDATE_DRIVERALIAS, editDriverAlias);
  yield takeEvery(ActionTypes.DELETE_DRIVERALIAS, removeDriverAlias);
}

export default driverListSaga;
