import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
};

const UpdateStep: React.FC<Props> = ({ numberOfContainersSelected }: Props) => {
  const { t } = useTranslation('acceptReleaseRight');
  const classes = useStyles();

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
    </React.Fragment>
  );
};

export default UpdateStep;
