import { createColumnHelper } from '@tanstack/react-table';
import i18n from '../../../global/config/i18n';
import { NotificationDetail } from '../../../global/adapters/mainAPI';

const columnHelper = createColumnHelper<NotificationDetail>();

export const notificationColumns = [
  columnHelper.accessor('equipmentNumber', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('displayNotifications:equipmentNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('releaseId', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:releaseId'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('publicReferenceId', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:publicReferenceId'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('externalReferenceId', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:externalReferenceId'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('type', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:type'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('event', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:event'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('storedOn', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('displayNotifications:storedOn'),
    filterFn: 'includesString',
  }),
];
