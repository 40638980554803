import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'modules/global/adapters';
import {
  BulkAssignBargeRailPickUpRequest,
  BulkAssignPickUpRequest,
  GetContainersByTerminalParams,
  GreenLightValuesRequest,
} from 'modules/global/adapters/crpAPI';
import { assignPickUpKeys } from './queryKeys';

export const useGetActiveTerminals = () => {
  return useQuery(assignPickUpKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetGreenLightValues = (greenLightNames: string[]) => {
  const request = {
    greenLightNames: greenLightNames,
  } as GreenLightValuesRequest;

  return useQuery(assignPickUpKeys.getGreenLightValues(), () =>
    api.greenLights.getGreenLightValues(request)
  );
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(assignPickUpKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};

export const useGetContainersAssignPickUp = (terminalParams: GetContainersByTerminalParams) => {
  const isSearchEntered =
    (terminalParams.terminalNxtEntityId !== undefined &&
      terminalParams.terminalNxtEntityId !== '') ||
    (terminalParams.terminalOperationValue !== undefined &&
      terminalParams.terminalOperationValue !== '') ||
    (terminalParams.terminalReadyValue !== undefined && terminalParams.terminalReadyValue !== '') ||
    (terminalParams.customsValue !== undefined && terminalParams.customsValue !== '');

  return useQuery(
    assignPickUpKeys.containersForUpdate(terminalParams),
    () => api.containers.getContainersForAssignPickUp(terminalParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersAssignBargeRailPickUp = () => {
  const queryClient = useQueryClient();

  return useMutation(assignPickUpKeys.postContainersAssignBargeRailPickUp(), {
    mutationFn: (data: BulkAssignBargeRailPickUpRequest) =>
      api.pickuprights.postContainersAssignBargeRailPickUp(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(assignPickUpKeys.containersForUpdateRoot());
    },
  });
};

export const usePostContainersAssignPickUp = () => {
  const queryClient = useQueryClient();

  return useMutation(assignPickUpKeys.postContainersAssignPickUp(), {
    mutationFn: (data: BulkAssignPickUpRequest) =>
      api.pickuprights.postContainersAssignPickUp(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(assignPickUpKeys.containersForUpdateRoot());
    },
  });
};
