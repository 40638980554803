import { Box, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
  setNewVoyageNumber: React.Dispatch<React.SetStateAction<string>>;
  setNewVesselName: React.Dispatch<React.SetStateAction<string>>;
  setNewImoNumber: React.Dispatch<React.SetStateAction<string>>;
  setNewStayNumber: React.Dispatch<React.SetStateAction<string>>;
};

const UpdateStep: React.FC<Props> = ({
  setNewVoyageNumber,
  setNewVesselName,
  setNewImoNumber,
  setNewStayNumber,
  numberOfContainersSelected,
}: Props) => {
  const { t } = useTranslation(['changeVoyage', 'validation']);
  const classes = useStyles();

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
      <Typography variant="body2">{t('validation:vesselAndVoyage')}</Typography>
      <Box marginTop={1}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('sideBar.newVesselName')}
          onChange={(event) => setNewVesselName(event.currentTarget.value)}
        />
      </Box>
      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('sideBar.newVoyage')}
          onChange={(event) => setNewVoyageNumber(event.currentTarget.value)}
        />
      </Box>
      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('sideBar.newImoNumber')}
          onChange={(event) => setNewImoNumber(event.currentTarget.value)}
        />
      </Box>
      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('sideBar.newStayNumber')}
          onChange={(event) => setNewStayNumber(event.currentTarget.value)}
        />
      </Box>
    </React.Fragment>
  );
};

export default UpdateStep;
