import ActionTypes from './actionTypes'

export function registerPaymentOption(option) {
  return {
    type: ActionTypes.REGISTER_PAYMENT_OPTION,
    payload: option,
  }
}

export function pairPaymentOption(option) {
  return {
    type: ActionTypes.PAIR_PAYMENT_OPTION,
    payload: option,
  }
}

export function creditAmountChanged(creditChange) {
  return {
    type: ActionTypes.CHANGE_CREDIT_AMOUNT,
    payload: creditChange,
  }
}
