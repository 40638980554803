import ActionTypes from './actionTypes'

export function loadTerminals() {
    return {
        type: ActionTypes.TERMINALS_REQUESTED
    }
}

export function loadTerminalsSucceeded(terminals) {
    return {
      type: ActionTypes.TERMINALS_REQUEST_SUCCEEDED,
      payload: terminals
    };
  }