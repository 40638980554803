import ActionTypes from './actionTypes';

export function loadSubscriptions(loading) {
  return {
    type: ActionTypes.SUBSCRIPTIONS_REQUESTED,
    payload: { loading: loading },
  };
}

export function loadPoliceSubscriptions(loading) {
  return {
    type: ActionTypes.POLICE_SUBSCRIPTIONS_REQUESTED,
    payload: { loading: loading },
  };
}

export function addSubscription(newSubscription) {
  return {
    type: ActionTypes.SUBSCRIPTION_ADD,
    payload: {
      billOfLadingNumber: newSubscription.billOfLadingNumber,
      equipmentNumber: newSubscription.equipmentNumber,
    },
  };
}

export function addPoliceSubscription(newSubscription) {
  return {
    type: ActionTypes.SUBSCRIPTION_ADD,
    payload: {
      billOfLadingNumber: newSubscription.billOfLadingNumbers,
      equipmentNumber: newSubscription.equipmentNumber,
    },
  };
}

export function removeSubscription(newSubscription) {
  return {
    type: ActionTypes.SUBSCRIPTION_DELETE,
    payload: {
      billOfLadingNumber: newSubscription.billOfLadingNumber,
      equipmentNumber: newSubscription.equipmentNumber,
    },
  };
}

export function removePoliceSubscription(newSubscription) {
  return {
    type: ActionTypes.SUBSCRIPTION_DELETE,
    payload: {
      billOfLadingNumber: newSubscription.billOfLadingNumbers,
      equipmentNumber: newSubscription.equipmentNumber,
    },
  };
}

export function loadSubscriptionsPage(page) {
  return {
    type: ActionTypes.SUBSCRIPTIONS_REQUESTED,
    payload: { paging: { page } },
  };
}

export function loadSubscriptionsRowsPerPage(rowsPerPage) {
  return {
    type: ActionTypes.SUBSCRIPTIONS_REQUESTED,
    payload: { paging: { rowsPerPage } },
  };
}

export function loadSubscriptionsSucceeded(subscriptions) {
  return {
    type: ActionTypes.SUBSCRIPTIONS_REQUEST_SUCCEEDED,
    payload: subscriptions,
  };
}

export function loadPoliceSubscriptionsSucceeded(subscriptions) {
  return {
    type: ActionTypes.POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED,
    payload: subscriptions,
  };
}

export function loadPoliceSubscriptionsSucceededButNoContent() {
  return {
    type: ActionTypes.POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED_NO_CONTENT,
  };
}
