export const MESSAGETYPES = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  API_ERRORS: 'API_ERRORS',
  API_SUCCESS: 'API_SUCCESS',
  BROADCAST_MESSAGE: 'BROADCAST_MESSAGE',
};

export const BROADCAST_API_MESSAGETYPE = {
  UNKNOWN: 'unknown',
  FATALERROR: 'fatalError',
  ERROR: 'error',
  VALIDATIONERROR: 'validationError',
  INFO: 'info',
  WARNING: 'warning',
};

export const CONNECTIONMESSAGES = {
  CREATE_SUBSCRIPTION: 'SubmitEquipmentSubscription',
  GET_SUBSCRIPTIONS: 'GetEquipmentSubscription',
  EQUIPMENT_NUMBERS_CHANGED: 'EquipmentNumbersChanged',
  POLICE_ACTION_COMPLETED: 'PoliceActionCompleted',
  GET_POLICE_EQUIPMENT_RELEASE_RIGHT: 'GetPoliceEquipmentReleaseRight',
  GET_POLICE_AUDIT_TRAIL: 'GetPoliceAuditTrail',
  GET_EQUIPMENT_RELEASE_RIGHTS: 'GetEquipmentReleaseRight',
  GET_EQUIPMENT_RELEASE_RIGHT: 'GetReleaseRightDetail',
  GET_TERMINALS: 'GetTerminals',
  GET_STAKEHOLDERS: 'GetStakeHolders',
  ACTION_COMPLETED: 'ActionCompleted',
  BROADCAST_MESSAGE: 'BroadcastMessage',
  CREDIT_AMOUNT_CHANGED: 'CreditAmountChanged',
  GET_DRIVERSLIST: 'GetDriverAliases',
  SUBMIT_DRIVERALIAS_SUCCEEDED: 'CreateDriverAliasResponse',
  DELETE_DRIVERALIAS_SUCCEEDED: 'DeleteDriverAliasResponse',
  UPDATE_DRIVER_LIST_SUCCEEDED: 'UpdateDriverAliasResponse',
  EVENT_HISTORY_REQUESTED_SUCCEEDED: 'GetReleaseRightEvents',
  UPDATE_PENDING_RELEASE_COUNT: 'UpdatePendingReleaseCount',
  INVALID_TRANSFER_RELEASETO_PARTY: 'InvalidTransferReleaseToParty',
  EXPIRE_RELEASES: 'ExpireReleases',
};

export const PHONENUMBERSTATUSES = {
  TO_BE_CHECKED: 'TO_BE_CHECKED',
  INVALID: 'INVALID',
  NON_EXISTENT: 'NON_EXISTENT',
  OK: 'OK',
};

export const ALFAPASSNUMBERSTATUSES = {
  TO_BE_CHECKED: 'TO_BE_CHECKED',
  INVALID: 'INVALID',
  OK: 'OK',
};

export const DIALOGTYPES = {
  ACCEPT: 'acceptReleaseDialog',
  DECLINE: 'declineReleaseDialog',
  BLOCK: 'blockPoliceDialog',
  RELEASE: 'releasePoliceDialog',
  SELECT_FOR_SCAN: 'selectForScanDialog',
  ASSIGN_CONTAINER_COMPANY: 'assignContainerCompanyDialog',
  SELECT_COMPANY: 'selectCompanyDialog',
  MANUAL_CLAIM_CONTAINER: 'manualClaimContainerDialog',
  REVOKE: 'revokeContainerDialog',
  PICKUP_TERMS_CHANGED: 'pickupTermsContainerDialog',
  PICKUP_LAWS_CHANGED: 'pickupLawsContainerDialog',
  PICKUP_DIALOG: 'pickupContainerDialog',
  PICKUP_CONFIRM: 'pickupConfirmDialog',
  ACCEPT_MULTIPLE: 'acceptMultipleContainersDialog',
  DECLINE_MULTIPLE: 'declineMultipleContainersDialog',
  REVOKE_MULTIPLE: 'revokeMultipleToNextPartyDialog',
  BLOCK_MULTIPLE: 'blockMultipleContainersDialog',
  BLOCK_MULTIPLE_COMM_REL: 'blockMultipleCommercialReleaseDialog',
  RELEASE_MULTIPLE: 'releaseMultipleContainersDialog',
  SELECT_FOR_SCAN_MULTIPLE: 'selectForScanMultipleDialog',
  MANUAL_CLAIM: 'manualClaimContainerDialog',
  REVOKE_MULTIPLE_COMM_REL: 'revokeMultipleCommercialReleaseDialog',
  DELETE_MULTIPLE_COMM_REL: 'deleteMultipleCommercialReleaseDialog',
  UNBLOCK_MULTIPLE_COMM_REL: 'unblockMultipleCommercialReleaseDialog',
  CREATE_COMPANY_SUBSCRIPTION: 'createCompanySubscriptionConfirmationDialog',
  DELETE_COMPANY_SUBSCRIPTION: 'deleteCompanySubscriptionConfirmationDialog',
};

export const COLORS = {
  RED: '#ff7575',
  WHITE: '#FFFFFF',
  GREY: '#999999',
  BLACK: '#222222',
  GREEN: '',
  YELLOW: '',
  ORANGE: '',
};

export const ADDITIONALDATAFIELDS = {
  COREOR_EQUIPMENTOPERATOR: 'COREOR_EQUIPMENTOPERATOR',
  COREOR_EQUIPMENTSTATUS: 'COREOR_EQUIPMENTSTATUS',
  COREOR_EQUIPMENTSUPPLIER: 'COREOR_EQUIPMENTSUPPLIER',
  COREOR_FULLEMPTYINDICATOR: 'COREOR_FULLEMPTYINDICATOR',
  COREOR_VESSELCALLSIGN: 'COREOR_VESSELCALLSIGN',
  COREOR_VESSELNAME: 'COREOR_VESSELNAME',
  COREOR_VOYAGENUMBER: 'COREOR_VOYAGENUMBER',
  COMMERCIAL_RELEASE_ISCARRIERHAULAGE: 'COMMERCIAL_RELEASE_ISCARRIERHAULAGE',
  COMMERCIAL_RELEASE_IMONUMBER: 'COMMERCIAL_RELEASE_IMONUMBER',
  COMMERCIAL_RELEASE_STAYNUMBER: 'COMMERCIAL_RELEASE_STAYNUMBER',
};

export const LABEL = {
  FPOLICE: 'Police',
  FTERMINAL: 'Terminal',
  UNKNOWN: 'Unknown',
  MEANSOFTRANSPORT: 'Means of transport',
  VALIDATE: 'Validate',
  REASON: 'Reason',
  TRANSFER_RELEASERIGHT: 'Transfer Release Right',
  REVOKE_RELEASERIGHT: 'Revoke Release Right',
  DECLINE_RELEASERIGHT: 'Decline Release Right',
  ACCEPT_RELEASERIGHT: 'Accept Release Right',
  ASSIGN: 'Assign',
  ASSIGN_PICKUP: 'Assign Pick-Up',
  UPDATE_PICKUP: 'Update Pick-Up',
  STORED_ON: 'Stored on',
  TIME_WINDOW_FROM: 'Time window from',
  TIME_WINDOW_TO: 'Time window to',
  NXTENTITY_ID_LABEL: 'NXTENTITYID',
  MY_OPTION_IS_NOT_IN_THE_LIST: 'My option is not in the list',
  BACK_TO_LOGIN: 'Back To Login',
  CONTINUE_WORKING: 'Continue Working',
  RELEASES: 'RELEASES',
  IS_ARCHIVED: 'Archived',
  COMMERCIAL_RELEASE_CREATE_TITLE: 'Create Commercial Release',
  COMMERCIAL_RELEASE_UPDATE_TITLE: 'Update Commercial Release',
  CREATE: 'CREATE',
  EQUIPMENT_SUPPLIER: 'Equipment Supplier',
  EQUIPMENT_STATUS: 'Equipment Status',
  FULLEMPTYINDICATOR: 'Full/Empty?',
  FULLEMPTYINDICATOR_FULL: 'Full',
  FULLEMPTYINDICATOR_EMPTY: 'Empty',
  FULLEMPTYINDICATOR_UNKNOWN: 'Unknown',
  EQUIPMENT_OPERATOR: 'Equipment Operator',
  VESSEL_CALLSIGN: 'Callsign',
  VESSEL_NAME: 'Vessel Name',
  VOYAGE_NUMBER: 'Voyage Number',
  STAY_NUMBER: 'Stay Number',
  IMO_NUMBER: 'IMO Number',
  CARRIER_HAULAGE: 'Carrier Haulage',
  EVENTS_OVERVIEW: 'Events Overview',
  GENERAL: 'General',
  EVENT_HISTORY: 'Event History',
  EVENT_NOHISTORY: 'No history found',
  RELEASE_RIGHT_OVERVIEW: 'Release Right Overview',
  EVENT: 'EVENT',
  TIMESTAMP: 'Timestamp',
  DESCRIPTION: 'Description',
  FORBIDDEN: 'Forbidden',
  CONFIRM: 'Confirm',
  PAIR_CINVIO_TITLE: 'Pair your Cinvio account',
  ASSIGN_TO_DRIVER_DESCRIPTION: 'Assign to Driver Description?',
  DRIVERLIST: 'DRIVERLIST',
  ADD_DRIVER_DESCRIPTION: 'Add Driver Description',
  DRIVERLIST_TITLE: 'Driver List Descriptions',
  DRIVER_DESCRIPTION_LABEL: 'DRIVER DESCRIPTION',
  DRIVER_ALFAPASS_MASK_LABEL: 'ALFAPASS MASK',
  DRIVER_ALFAPASS_NUMBER_LABEL: 'ALFAPASS NUMBER',
  EQUIPMENT_TYPE: 'Container type',
  EORI: 'EORI',
  DUNS: 'DUNS',
  APCS_CODE: 'APCS CODE',
  TAX_ID_LABEL: 'TAX ID',
  REVOKE_LABEL: 'REVOKE',
  REVOKEPICKUP_LABEL: 'REVOKE PICKUP',
  ADMIN_LABEL: 'ADMIN',
  BILL_OF_LADING_LABEL: 'BILL OF LADING',
  EQUIPMENT_NUMBER_LABEL: 'CONTAINER NUMBER',
  SHIPPING_AGENT_LABEL: 'SHIPPING AGENT',
  SHIPPING_LABEL: 'SHIPPING',
  DECLINE_LABEL: 'DECLINE',
  FAVORITE_LABEL: 'FAVORITE',
  SEARCH_LABEL: 'SEARCH',
  NORMAL_LABEL: 'NORMAL',
  BLOCK_LABEL: 'BLOCK',
  PAUSE_LABEL: 'PAUSE',
  PAUSED_LABEL: 'PAUSED',
  RELEASE_LABEL: 'RELEASE',
  PENDING_LABEL: 'PENDING',
  TRANSFERRED_LABEL: 'TRANSFERRED',
  NAME_LABEL: 'NAME',
  ORGANISATION_NAME: 'Organisation Name',
  COMPANY_NXTENTITYID: 'Identity',
  STREET_LABEL: 'STREET',
  COUNTRY_LABEL: 'COUNTRY',
  CITY_LABEL: 'CITY',
  ACCEPT_LABEL: 'ACCEPT',
  STATUS_LABEL: 'STATUS',
  TERMINAL_STATUS_LABEL: 'TERMINAL STATUS',
  ACTION_LABEL: 'ACTION',
  ACTIVE_LABEL: 'ACTIVE',
  TYPE_LABEL: 'TYPE',
  RECEIVED_ON_LABEL: 'RECEIVED ON',
  ALFAPASS_NUMBER_MASK_LABEL: 'ALFAPASS NR',
  VALID_UNTIL_LABEL: 'VALID UNTIL',
  TERMINAL_LABEL: 'TERMINAL',
  POLICE_LABEL: 'POLICE',
  ENTER_POLICE_REFERENCE: 'Enter a reference number',
  ENTER_POLICE_DOSSIER: 'Enter a dossier number',
  ENTER_VALID_FROM_DATE: 'Enter a start date (from today)',
  ENTER_VALID_TO_DATE: 'Enter an end date',
  ARCHIVE_LABEL: 'ARCHIVE',
  RECEIVED_FROM_LABEL: 'RECEIVED FROM',
  FIRST_RELEASE_PARTY_LABEL: 'FIRST RELEASE PARTY',
  SENT_TO_LABEL: 'SENT TO',
  PORT_LABEL: 'PORT',
  NUMBER_LABEL: 'NUMBER',
  ASSIGN_LABEL: 'ASSIGN',
  SELECT_LABEL: 'SELECT',
  CANCEL: 'Cancel',
  PICKUP_LABEL: 'PICK-UP',
  SELECT_FOR_SCAN_LABEL: 'SELECT FOR SCAN',
  TERMINAL: 'TERMINAL',
  TERMINALFIELD: 'Terminal',
  SHIPPING: 'SHIPPING AGENT',
  FORWARDER: 'FORWARDER',
  FORWARDER_ROLE: 'CPU_ReleaseParty',
  SHIPPING_ROLE: 'CPU_ShippingAgent',
  TRANSPORTOPERATOR_ROLE: 'CPU_TransportOperator',
  POLICE_ROLE: 'CPU_Police',
  TERMINAL_ROLE: 'CPU_Terminal',
  POLICE: 'POLICE',
  TRANSPORTOPERATOR_LABEL: 'TRANSPORT OPERATOR',
  CURRENT_OWNER_LABEL: 'CURRENT OWNER',
  BLOCK_COMM_REL_LABEL: 'BLOCK RELEASE',
  REVOKE_COMM_REL_LABEL: 'REVOKE RELEASE',
  DELETE_COMM_REL_LABEL: 'DELETE RELEASE',
  UNBLOCK_COMM_REL_LABEL: 'UNBLOCK RELEASE',
  UPDATE_COMM_REL_LABEL: 'UPDATE RELEASE',
  COMMERCIAL_BLOCK: 'COMMERCIAL_BLOCK',
  COMMERCIAL_UNBLOCK: 'COMMERCIAL_UNBLOCK',
  COMMERCIAL_REVOKE: 'COMMERCIAL_REVOKE',
  COMMERCIAL_UPDATE: 'COMMERCIAL_UPDATE',
  COMMERCIAL_DELETE: 'COMMERCIAL_DELETE',
  COMMERCIAL_BLOCK_LABEL: 'Block',
  COMMERCIAL_UNBLOCK_LABEL: 'Unblock',
  COMMERCIAL_REVOKE_LABEL: 'Revoke',
  COMMERCIAL_UPDATE_LABEL: 'Update',
  COMMERCIAL_DELETE_LABEL: 'Delete',
  REFERENCE_NUMBER: 'Reference Number',
  REFERENCE_FIELD_NUMBER: 'Reference',
  CASE_NUMBER: 'Case Number',
  PREVIOUS_PAGE: 'Previous Page',
  NEXT_PAGE: 'Next Page',
  TRANSFER_ACTION: 'Transfer Action',
  COMPANY: 'COMPANY',
  ASSIGNEDTO: 'Assigned To',
  CONTAINERS: 'CONTAINERS',
  DATE_FROM: 'Date from',
  DATE_TO: 'Date to',
  CHANGE_DATE: 'change date',
  FILTER: 'Filter',
  RELEASE_ORDER_REFERENCE: 'Release order reference',
  ADD_BL_NUMBER: 'ADD BL NUMBER',
  PORT_CODE: 'Port Code',
  COMM_REL_VALID_FROM: 'Commercial release valid from',
  COMM_VALID_UNTIL: 'Commercial release valid until',
  PENDING: 'Pending',
  REVOKE: 'Revoke',
  TRANSFER: 'Transfer',
  SUBSCRIBE: 'Subscribe',
  RELEASE_STATUS: 'Status',
  CHOOSE_TERMINAL: 'Choose terminal',
  CONTAINER_NUMBER: 'Container number',
  CONTAINER_TYPE: 'Container type',
  RELEASE_VALID_FROM: 'Release valid from',
  RELEASE_VALID_UNTIL: 'Release valid until',
  NUMBER: 'Number',
  DATE_UNTIL: 'Date until',
  SUBSCRIBED_ON: 'SUBSCRIBED ON',
  CONTAINER_SUBSCRIPTIONS: 'CONTAINER SUBSCRIPTIONS',
  NEW_SUBSCRIPTION: 'New Subscription',
  NEXT_MODE_OF_TRANSPORT: 'NEXT MODE OF TRANSPORT',
  MODE_OF_TRANSPORT: 'Mode Of Transport',
  TRANSPORT_COMPANY: 'Transport Company',
  IDENTIFICATION_ERROR_MESSAGE: 'Select a type and fill in the code please',
  IDENTIFICATION_ENTER_CODE: 'Enter the exact code according the selected type',
  IDENTIFICATION_TYPE: 'Identification Type',
  IDENTIFICATION_CODE: 'Identification Code',
  SLOT_IDENTIFICATION: 'Slot Identification',
  ADD_CONTAINER_SLOT: 'Add container slot',
  ESTIMATED_TIME_OF_PICKUP: 'Estimated Time Of Pickup',
  TRANSPORT_NAME: 'Transport Name',
  TRANSPORT_IDENTIFICATION: 'Transport Identification',
  ALFAPASS_IDENTIFICATION: 'AlfaPass Identification',
  TRANSPORT_STAGE: 'Transport Stage',
  REMARKS: 'Remarks',
  DATE: 'Date',
  TIME: 'Time',
  ACTION: 'Action',
  FROM_COMPANY: 'From Company',
  TO_COMPANY: 'To Company',
  DONE_BY: 'Done By',
  AUDIT: 'Audit',
  ACTIVE: 'Active',
  RELEASE_DATE: 'Release Date',
  GATE_OUT_DATE: 'Gate-Out Date',
  DETAIL: 'Detail',
  ADD: 'Add',
  NEW_RELEASE: 'New Release',
  TRANSFER_SINGLE_PARTY: 'Transfer to single party',
  REVOKE_NEXT_PARTY: 'Revoke from next party',
  PICKUP_NEXT_PARTY: 'Pick-up from next party',
  YES: 'Yes',
  NO: 'No',
  OK: 'Ok',
  SUBMIT: 'Submit',
  COMPANY_INFO: 'Company info',
  NO_COMPANY_FOUND: 'No company information found',
  WARNING_TOOLTIP: 'Warning',
  RED_TOOLTIP: 'Red',
  GREEN_TOOLTIP: 'Green',
  ORANGE_TOOLTIP: 'Orange',
  BLOCKED: 'Blocked',
  UNBLOCKED: 'Unblocked',
  RELEASED: 'Released',
  WAITING_FOR_PICKUP: 'Awaiting pickup',
  WAITING_FOR_PICKUP_SCAN: 'Awaiting pickup (to scan)',
  WAITING_FOR_RELEASE: 'Awaiting release for pickup',
  RELEASED_BY_DEFAULT: 'Released By Default',
  SELECT_SCAN: 'Select scan',
  PENDING_RELEASES: 'Pending releases',
  MANUAL_CLAIM_CONTAINER_BUTTON: 'Manually claim container',
  REMEMBER_MY_CHOICE: 'Remember my choice until my next login',
  BL_NUMBER: 'B/L Number',
  BILL_OF_LADING: 'Bill Of Lading',
  CLAIM: 'Claim',
  CLOSE: 'Close',
  OVERVIEW_CONTAINERS: 'Container Overview',
  ARCHIVE_CONTAINERS: 'Archive containers',
  STATUS: 'STATUS',
  SHIPPING_AGENT: 'Shipping Agent',
  RELEASE_PARTY: 'Release Party',
  TRANSPORT_OPERATOR: 'Transport Operator',
  RECEIVED_FROM: 'Received From',
  EQUIPMENT_NUMBER: 'Container Number',
  RELEASE_TO: 'Release To',
  RELEASE_TO_TYPE: 'Type',
  TERMINAL_NXTENTITYID: 'Terminal NxtEntityId',
  TERMINAL_CODE: 'Terminal Code',
  TERMINAL_NAME: 'Terminal Name',
  RELEASE_IDENTIFICATION: 'Release identification',
  VALIDUNTIL: 'Valid Until',
  PORT: 'Port',
  VAT_EORI_DUNS: 'VAT, EORI or DUNS number',
  COMPANY_NAME: 'Company Name',
  VAT: 'VAT',
  IDENTIFICATIONTYPE_TITLE_FIRST_RELEASE_PARTY_TRANSFER:
    'First Release Party Identification (transfer)',
  IDENTIFICATION_POSSIBLE_MEANS:
    'Please fill in a possible means of identification for the first release party.',
  IDENTIFICATIONCODE: 'Code',
  IDENTIFICATIONTYPE: 'Type',
  IDENTIFICATIONTYPE_TIN: 'Tin',
  IDENTIFICATIONTYPE_EORI: 'Eori',
  IDENTIFICATIONTYPE_DUNS: 'Duns',
  IDENTIFICATIONTYPE_SCAC: 'SCAC',
  IDENTIFICATIONTYPE_APCS: 'Apcs',
  YOUR_FAVORITES: 'Your Favorites',
  ADDRESS: 'Address',
  COMPANIES: 'Companies',
  SELECTED_COMPANY: 'Selected Company',
  REASSIGN_PREVIOUS_PARTY: 'Re-assign to previous party',
  MOBILE_PHONE: 'Mobile phone (*)',
  ALPHAPASS_NUMBER: 'Alphapass number',
  SEND: 'Send',
  PICKUP_BUTTON: 'PICK-UP',
  MY_YARD: 'My Yard',
  CHANGE_USER: 'Change Role',
  SAVE: 'Save',
  ENTRY_ALREADY_EXISTS: 'One or more entries already exist.',
  ACTION_SUCCEEDED: 'The action has successfully completed',
  TRANSFER: 'TRANSFER',
  MANAGED_EXTERNALLY: 'Managed by external application: ',
  GENERATE_BUTTON: 'GENERATE',
  DOWNLOAD_BUTTON: 'DOWNLOAD',
  RELEASE_RIGHT: 'Release Right',
  ACTION_STATUS: 'Action Status',
  ARCHIVED: 'Archived',
  ACTIVE: 'Active',
  SELECT_COMPANY_TITLE: 'Select company',
  SUBSCRIBE_ON_A_COMPANY: 'Subscribe on a company',
  BL_NUMBERS: 'BL numbers',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  ALL: 'All',
  CONTAINER_SEARCH: 'Container search',
  SUBSCRIPTIONS: 'Subscriptions',
  COMPANY_SUBSCRIPTIONS: 'SCR Company subscriptions',
  HELPER_TEXT_TRANSPORT_NAME: 'e.g. ship name',
  HELPER_TEXT_TRANSPORT_IDENTIFICATION: 'call sign, license plate, carriage',
  SEARCH_RESULTS: 'Search results',
  SELECTED: 'Selected',
  COMMERCIAL_RELEASE: 'Commercial Release',
  ALFAPASS_NUMBER: 'Alfapass number',
  ALFAPASS: 'Alfapass',
  DEFAULT_ALFAPASS_NUMBER: 'Default Alfapass number',
  LINK_ALFAPASS_TEXT: 'Link Alfapass to Company',
  ADD_TO_DEFAULT_POOL: 'Add to Default pool',
  BLOCKED_PAGE_TITLE: 'LIMITED ACCESS TO THE CPU WEB INTERFACE',
  BLOCKED_PAGE_OPTION1_TITLE: 'Web Interface Only',
  REGISTER: 'Register',
  LEARN_MORE: 'Learn More',
  BLOCKED_PAGE_OPTION2_TITLE: 'Web Interface + NxtPort API’s',
  ADDRESSING_USER: 'Dear %USERPROFILE_FIRST_NAME% %USERPROFILE_LAST_NAME%,',
  LIGHT_LAST_UPDATED: 'Last update: %LIGHT_LASTUPDATED%',
  EMPTY_RETURN_REFERENCE: 'Empty return reference',
  UPDATE: 'Update',
  DELETE: 'Delete',
  UNSUBSCRIBE: 'Unsubscribe',
  SELECT_DRIVER_ALIAS: 'Select Driver Alias',
  CHANGE_TERMINAL: "Change vessel's terminal",
  UPDATE_VOYAGE: 'Update voyage',
  ASSIGN_RELEASE_PARTY: '(Re)-assign first release party',
  BLOCK_VOYAGE: 'Block voyage',
  UNBLOCK_VOYAGE: 'Unblock voyage',
  DELETE_VOYAGE: 'Delete voyage',
  REVOKE_VOYAGE: 'Revoke voyage',
  UPDATE_EXPIRY_DATE: 'Update commercial expiry date',
  BY_BROKER: 'RELEASE BY BROKER',
  AUDIT_TRAIL: 'Audit Trail',
  AT_LEAST_ONE_FIELD_MUST_BE_FILLED_IN:
    'At least one field must be filled to search for containers.',
  EQUIPMENT_NUMBER_MUST_HAVE_MIN_LENGTH_3: 'Field container number must have a minimum length of 3',
  BILLOFLADINGNUMBERS_MUST_HAVE_MIN_LENGTH_3:
    'Field bill of lading numbers must have a minimum length of 3',
  SHIPPINGAGENT_MUST_HAVE_MIN_LENGTH_3: 'Filed shipping agents must have a minimum length of 3',
  TERMINAL_MUST_HAVE_MIN_LENGTH_3: 'Field terminal must have a minimum length of 3',
  COMPANY_MUST_HAVE_MIN_LENGTH_3: 'Field company must have a minimum length of 3',
  LOAD_NEXT: 'Load next...',
  DATESTART_MUST_BE_BEFORE_DATEEND: 'The start date must be before the end date',
  DISPLAY_NOTIFICATIONS: 'Search Notifications',
  OVERRULE_CUSTOMS_LIGHT: 'Overrule customs light',
  DISPLAY_BILLING_INFO: 'Billing Info',
  CREATE_MULTIPLE_RELEASE_CSV: 'Create Multiple Releases (csv)',
  MAX_ITEMS_REACHED: 'Maximum items reached',
  YOUR_PROFILE: 'Your Profile',
  ORGANISATION_INFO: 'Organisation Info',
  LOGOUT: 'Logout',
  PLAESE_WAIT: 'Please wait...',
};

export const DIALOG = {
  PROVIDE_REASON_TITLE: 'Reason for action',
  PROVIDE_REASON_CONTENT: 'Inform stakeholders of the reason',
  DELETE_DRIVER_DESCRIPTION: 'Delete Driver Description',
  CONFIRM_UPDATE_DRIVER_DESCRIPTION: 'Confirm Update Driver Description',
  UPDATE_DRIVER_DESCRIPTION: 'Update Driver Description',
  ADD_DRIVER_DESCRIPTION: 'Add Driver Description',
  LOADING_TIMEOUT_TITLE: 'Loading...',
  LOGGING_TITLE: 'Case logging',
  ADD_SUBSCRIPTION_TITLE: 'Add Subscription',
  REMOVE_SUBSCRIPTION_TITLE: 'Remove Subscription',
  BLOCK_CONTAINER_TITLE: 'Block Container',
  UNBLOCK_CONTAINER_TITLE: 'Unblock Container',
  CONFIRMATION_TITLE: 'Confirmation',
  UPDATE_CONTAINER_TITLE: 'Update Container',
  SELECT_FOR_SCAN_TITLE: 'Select container(s) for scan',
  REVOKE_NEXT_PARTY_TITLE: 'Revoke right to pick-up container',
  BLOCK_COMM_REL_TITLE: 'Block Commercial Release',
  UNBLOCK_COMM_REL_TITLE: 'Unblock Commercial Release',
  REVOKE_COMM_REL_TITLE: 'Revoke Commercial Release',
  DELETE_COMM_REL_TITLE: 'Delete Commercial Release',
  BLOCK_TITLE: 'Block pick-up container',
  RELEASE_TITLE: 'Release pick-up container',
  ACCEPT_TITLE: 'Accept assigned container(s)',
  DECLINE_TITLE: 'Decline assigned container(s)',
  ACTIONDIALOG_TITLE: 'Lookup confirmation',
  PICKUP_TITLE: 'Assign pick-up to external user or company',
  PICKUP_CONFIRM_TITLE: 'Confirm pick-up to external user or company',
  CONFIRM_ACTION_TITLE: 'Confirm action',
  CONFIRM_ACTION: 'Are you sure you want to %ACTION% the container?',
  MANUAL_CLAIM_CONTAINER_TITLE: 'Claim container',
  TIMEOUT_TEXT: 'The queries are loading....',
  LOGGING_TEXT: 'All actions need to be logged. Please provide following info.',
  UPDATE_CONTAINER_CONFIRMATION_TEXT: 'Are you sure you want to update the container?',
  ADD_SUBSCRIPTION_TEXT:
    'Enter the Bill of lading & Equipment Number combination you wish to subscribe to.',
  REMOVE_SUBSCRIPTION_TEXT: 'Are you sure you want to remove your subscription?',
  BLOCK_CONTAINER_TEXT: 'Are you sure you want to block the container?',
  UNBLOCK_CONTAINER_TEXT: 'Are you sure you want to unblock the container?',
  GENERAL_ERROR_TITLE: 'An error occurred while processing your request',
  COMMERCIAL_RELEASE: 'Commercial Release',
  NEW_COMMERCIAL_RELEASE: 'New Commercial Release',
  ASSIGN_TITLE: 'Assign release right to company',
  SELECT_FOR_SCAN_CONTENT:
    'You are about to select 1 or more containers for scanning. Are you sure?',
  REVOKE_NEXT_PARTY_CONTENT:
    'You are about to revoke the right to pick-up 1 or more containers from their current assigned parties. Are you sure?',
  BLOCK_COMM_REL_CONTENT:
    'You are about to Block the commercial release for the selected container(s). Are you sure you wish to continue?',
  UNBLOCK_COMM_REL_CONTENT:
    'You are about to Delete the commercial release for the selected container(s). Are you sure you wish to continue?',
  REVOKE_COMM_REL_CONTENT:
    'You are about to Revoke the commercial release for the selected container(s). Are you sure you wish to continue?',
  DELETE_COMM_REL_CONTENT:
    'You are about to Delete the commercial release for the selected container(s). Are you sure you wish to continue?',
  DELETE_SUBSCRIPTION_ON_COMPANY: 'Delete subscription',
  DELETE_SUBSCRIPTION_ON_COMPANY_CONFIRMATION_TEXT:
    'Are you sure you want to delete subscription for %COMPANY%?',
  CREATE_SUBSCRIPTION_ON_COMPANY: 'Create subscription',
  CREATE_SUBSCRIPTION_ON_COMPANY_DESCRIPTION:
    'Create a subscription for %COMPANY% to follow any trail.',
  CREATE_SUBSCRIPTION_ON_COMPANY_CONFIRMATION_TEXT:
    'Are you sure you want to create a subscription for %COMPANY%?',
  BLOCK_CONTENT: 'You are about to block this container. Are you sure?',
  RELEASE_CONTENT: 'You are about to release this container. Are you sure?',
  ACCEPT_CONTENT:
    'You are about to accept the right to pick-up 1 or more containers from their current assigned parties. Are you sure?',
  DECLINE_CONTENT:
    'You are about to decline the right to pick-up 1 or more containers from their current assigned parties. Are you sure?',
  ACTIONDIALOG_CONTENT: 'Are you sure? Your action will be logged, please provide following info.',
  PICKUP_CONTENT:
    'You are about to assign the right to pick-up the container with number %CONTAINER_NUMBER% and B/L %BL_NUMBER%.',
  PICKUP_MULTIPLE_CONTENT:
    'You are about to assign the right to pick-up the following containers:\n \n%CONTAINER_BL_NUMBER_LIST% \n',
  PICKUP_CONFIRM_MULTIPLE_CONTENT:
    'You are about to assign the right to pick-up the following containers to %PHONE_NUMBER%:\n \n%CONTAINER_BL_NUMBER_LIST%.',
  PICKUP_CONFIRM_CONTENT:
    'You are about to assign the right to pick-up the container with number %CONTAINER_NUMBER% and B/L %BL_NUMBER% to %PHONE_NUMBER%. ',
  PICKUP_CONFIRMATION_CONTENT:
    'You have assigned the right to pick-up the following container(s) to %PHONE_NUMBER%: \n \n%CONTAINER_BL_NUMBER_LIST% .\nAs long as this container has not been picked up by the driver, you can revoke this assignment through the "overview" screen.',
  PICKUP_REVOKE_CONFIRMATION_CONTENT:
    'You have revoked the assignment of the pick-up right of the following container(s): \n \n%CONTAINER_BL_NUMBER_LIST% \n \nThe container will no longer be available for pick-up by the driver.',
  RELEASE_ACTION_CONFIRM_CONTENT:
    'You are about to %RELEASE_ACTION% the commercial release for %CONTAINER_NUMBER%. Are you sure you want to continue?',
  ASSIGN_CONTENT:
    'You are about to assign the release right of one or multiple containers to %COMPANY%.',
  MANUAL_CLAIM_CONTAINER_CONTENT:
    'You are about to manually claim a container. Please enter the relevant information below.',
  TOKEN_EXPIRED_CONTENT: 'For security reasons you will have to login again.',
  TOKEN_IS_EXPIRING_CONTENT:
    'You have been active for a while. For security reasons you will have to login again.',
  TOKEN_TIME_LEFT_TEXT: 'Time left:',
  GENERAL_DRIVERALIAS_CONFIRM_TEXT: 'You are about to %ACTION% this driver alias. Are you sure?',
  ADD_DRIVER_DESCRIPTION_TEXT:
    'Add a new driver description for Pick-Up selection, the actual validation is done after submitting these fields.',
  DRIVER_ERROR_DESCRIPTION_VALIDATION: 'Description must have a minimum length of 2 characters',
  DRIVER_ERROR_ALFAPASSNUMBER_VALIDATION: 'Alfanumber must have a minimum length of 5 characters',
};

export const TEXT = {
  BLOCKED_PAGE_INTRO:
    'It appears that you haven’t selected your yearly CPu subscription yet, please select one of the following options:',
  BLOCKED_PAGE_OPTION1_EXPLANATION:
    'Use the Web Interface via a token-based crediting system (Cinvio). Different subscription bands are available to you based on the estimated number of container actions you require on an annual bases. The pre-pay subscription purchases are handled through our partner payment platform Cinvio.',
  BLOCKED_PAGE_OPTION2_EXPLANATION: 'API on request via Service Desk',
  BLOCKED_PAGE_OPTION2_SUBTITLE: '(This includes all NxtPort API’s for €2500/year)',
  BLOCKED_PAGE_OPTION1_SUBTITLE: '(volume-dependent pricing; prepayment with CINVIO)',
  PAIR_PAGE:
    'You are logged in as %USERPROFILE_FULLNAME%. \n Your Cinvio account will be paired with the CPU application when you click the confirm button. Afterwards you will be redirected to Cinvio again for purchase options.',
  NO_DRIVERS: 'No Drivers in list.',
  INVALID_TRANSFER_TEXT:
    'The transfer is logged but could not complete because no entity matched the identification %IDENTIFICATION_CODE%',
};

export const CONSTANTS = {
  PORT_LOCODE: 'BEANR',
  ALFAPASS: 'Alfapass',
  DRIVERALIAS: 'DriverAlias',
};

export const VALIDATION = {
  MIN_LENGTH_3: 'Minimum length of 3',
  EQUIPMENT_NUMBER_LENGTH: 'Must be between 0 and 22 chars.',
  CONTAINER_TYPE_LENGTH: 'Must be between 0 and 4 chars.',
  TERMINAL_REQUIRED: 'Select a terminal',
  REL_DATE_MUST_BE_BEFORE_EXP_DATE: 'Date must be in the future and before Valid Until',
  EXP_DATE_MUST_BE_GREATER_THAN: 'Date must be in the future and after Valid From',
  DATE_FROM_BEFORE_UNTIL: 'Date must be in the future and before Date Until',
  DATE_UNTIL_AFTER_FROM: 'Date must be in the future and after Date From',
  RELEASE_ORDER_REF_REQUIRED: 'Release Order Reference must be filled in',
  IDENTIFICATION_TYPE_REQUIRED: 'Select identification type',
  ADD_VALID_BL_NUMBERS: 'Add valid BL numbers. Must between 3 chars and 100 chars.',
  INVALID_ALFAPASS_NUMBER: 'Alfapass number is invalid',
  DATE_MUST_BE_GRATER_THAN_TODAY: 'Required. Date must be greater than today.',
  DATE_MUST_BE_GRATER_THAN_FROM_DATE: 'Required. Date must be greater than from-date.',
};

export const ERROR = {
  INCORRECT_STATUS: 'GetEquipmentReleaseRight: Incorrect release rights status received: ',
  GENERAL_TITLE: 'Something went wrong with our server.',
  GENERAL_TEXT: 'Dear user, please refresh your browser to continue using CPu.',
  INSUFFICIENT_AMOUNT_CREDITS: 'You have an insufficient amount of credits to perform this action.',
};

export const ACTIONTYPES = {
  ACCEPT: 'Accept',
  DECLINE: 'Decline',
  REVOKE: 'Revoke',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
  RELEASE: 'Release',
  SCANNING: 'Scanning',
  UPDATE: 'Update',
  DELETE: 'Delete',
  SELECT_FOR_SCAN: 'Select For Scan',
};

export const ALERT = {
  CONTAINER_DETAIL_LIGHT_CHANGES:
    "All displayed lights are a snapshot in time, if there is no 'Gate Out' all lights can change at any given moment",
};

export const TOOLTIPS = {
  OPEN_DETAIL: 'Open Detail',
  REMOVE_SUBSCRIPTION: 'Remove Subscription',
};

export const FORMATTING = {
  PHONE_NUMBER: '%PHONE_NUMBER%',
  CONTAINER_NUMBER: '%CONTAINER_NUMBER%',
  BL_NUMBER: '%BL_NUMBER%',
  CONTAINER_BL_NUMBER_LIST: '%CONTAINER_BL_NUMBER_LIST%',
  RELEASE_ACTION: '%RELEASE_ACTION%',
  CONTAINER_LENGTH: '%CONTAINER_LENGTH%',
  USERPROFILE_FIRST_NAME: '%USERPROFILE_FIRST_NAME%',
  USERPROFILE_LAST_NAME: '%USERPROFILE_LAST_NAME%',
  LIGHT_LASTUPDATED: '%LIGHT_LASTUPDATED%',
  ACTION: '%ACTION%',
  USERPROFILE_FULLNAME: '%USERPROFILE_FULLNAME%',
  IDENTIFICATION_CODE: '%IDENTIFICATION_CODE%',
  COMPANY: '%COMPANY%',
  ACTION: '%ACTION%',
};

export const TABS = {
  CONTAINER_OVERVIEW: 'Container Overview',
  PENDING_RELEASES: 'Pending Releases',
  CONTAINER_SUBSCRIPTIONS: 'Container Subscriptions',
  AUDIT_TRAIL_SUBSCRIPTIONS: 'Company Subscriptions',
  DRIVER_LIST: 'Driver List',
};

export const CHECKBOX = {
  LAWS: 'I understand that this information will be saved and I know the applicable laws.',
  TERMS_AND_CONDITIONS: 'I agree to the terms and conditions',
};

export const CREDITINFO = {
  NO_CREDITS:
    'Accepting Release Rights or creating new container subscriptions is currently disabled. Please purchase a token subscription through our partner payment platform Cinvio',
  LOW_CREDITS: ' Tokens remaining. Please consider upgrading your current band via Cinvio.',
  INFO_CREDITS: ' Tokens remaining.',
};

export const LightColor = {
  Grey: 'Grey',
  Gray: 'Gray',
  Yellow: 'Yellow',
  Green: 'Green',
  Orange: 'Orange',
  Red: 'Red',
};

export const TERMSANDCONDITIONS = {
  TERMS_CONDITIONS_TITLE: 'Terms and Conditions',
  NETWORK_ERROR: 'Network error',
  NETWORK_MESSAGE:
    'You might have a network problem. Please refresh your browser or check your network.',
  CHECK_MESSAGE_WHILE_LOADING: 'Checking if terms and conditions are accepted...',
  CLICK_HERE_TO_READ: 'Click here to read our terms and conditions.',
  BY_CHECKING_THIS_BOX:
    'By checking this box, you are agreeing to our terms and conditions of our services Certified Pick up (CPu).',
  I_ACCEPT_TC: 'I accept the terms and conditions',
  SAVE_AND_CLOSE: 'Save and Close',
  PLEASE_WAIT: 'Please wait while saving...',
};
