import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import {
  loadPoliceReleaseAuditSucceeded,
  getCompanySubscriptionsSucceeded,
  loadPoliceContainersSucceeded,
  loadScrEventsSucceeded,
  deleteCompanySubscriptionSucceeded,
  createCompanySubscriptionSucceeded,
} from '../actions/policeContainerActions';
import {
  getPoliceContainers,
  getPoliceAudit,
  putPoliceBlockContainer,
  postCompanySubscription,
  getCompanySubscriptions,
  getScrEvents,
  unscubscribeCompanySubscription,
} from '../api/policeApi';
import {
  getPoliceContainersFilters,
  getPoliceContainersPaging,
  getPoliceContainersPoliceCase,
  getPoliceSelectedReleaseId,
  getUserProfile,
} from '../selectors/selectors';
import { push } from 'react-router-redux';

function getContainersQuery(filters, policeCase, paging) {
  return {
    equipmentnumberfilter: filters.equipmentNumber || undefined,
    billofladingnumberfilter: filters.billOfLading || undefined,
    shippingAgentFilter: filters.shippingAgent || undefined,
    terminalCodeFilter: filters.terminal || undefined,
    dateEnd: filters.dateEnd || undefined,
    dateStart: filters.dateStart || undefined,
    page: paging.page + 1 || undefined,
    pageSize: paging.rowsPerPage || undefined,
    assignedToFilter: filters.company || undefined,
    isBlockedFilter: filters.isBlocked + '' || undefined,
    archivedFilter: filters.isArchived + '' || undefined,
    policeReferenceNumber: policeCase.referenceNumber || undefined,
    policeCaseNumber: policeCase.number || undefined,
  };
}

function getAuditQuery(policeCase, releaseId) {
  return {
    releaseId,
    policeReferenceNumber: policeCase.referenceNumber || undefined,
    policeCaseNumber: policeCase.number || undefined,
  };
}

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadAllContainers(action) {
  try {
    let paging = yield select(getPoliceContainersPaging);
    let filters = yield select(getPoliceContainersFilters);
    let policeCase = yield select(getPoliceContainersPoliceCase);
    let actor = yield select(getUserProfile);
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    if (action.payload.filters) {
      filters = action.payload.filters;
    }
    if (action.payload.policeCase) {
      policeCase = action.payload.policeCase;
    }

    const containers = yield call(getPoliceContainers, {
      query: getContainersQuery(filters, policeCase, paging),
      nxtEntityId: actor.nxtEntityId,
    });

    yield put(loadPoliceContainersSucceeded(containers));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* loadContainerAudit(action) {
  try {
    let actor = yield select(getUserProfile);
    const policeCase = action.payload.policeCase
      ? action.payload.policeCase
      : yield select(getPoliceContainersPoliceCase);
    const releaseId = action.payload.releaseId
      ? action.payload.releaseId
      : yield select(getPoliceSelectedReleaseId);
    const auditEvents = yield call(getPoliceAudit, {
      query: getAuditQuery(policeCase, releaseId),
      nxtEntityId: actor.nxtEntityId,
    });
    yield put(loadPoliceReleaseAuditSucceeded(auditEvents));
    if (action.payload.requestedFromSearch) yield put(push('/containers/selected'));
    else yield put(push('/container-subscriptions/audit'));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* blockContainer(action) {
  try {
    const billOfLadingNumbers = action.payload.billOfLadingNumbers;
    const releaseIdentification = action.payload.releaseIdentification;
    const equipmentNumber = action.payload.equipmentNumber;
    const policeAction = action.payload.policeAction;

    yield call(putPoliceBlockContainer, {
      policeAction,
      equipmentNumber,
      releaseIdentification,
      billOfLadingNumbers,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* createCompanySubscription(action) {
  try {
    const success = yield call(postCompanySubscription, action.payload);
    if (success) yield put(createCompanySubscriptionSucceeded());
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* deleteCompanySubscription(action) {
  try {
    const success = yield call(unscubscribeCompanySubscription, action.payload);
    if (success) yield put(deleteCompanySubscriptionSucceeded(action.payload));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* fetchCompanySubscriptions(action) {
  try {
    const subscriptions = yield call(getCompanySubscriptions, action.payload);
    yield put(getCompanySubscriptionsSucceeded(subscriptions));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* fetchScrEvents(action) {
  try {
    const scrEvents = yield call(getScrEvents, action.payload);
    yield put(loadScrEventsSucceeded(scrEvents));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* containerSaga() {
  yield takeEvery(ActionTypes.POLICE_CONTAINERS_REQUESTED, loadAllContainers);
  yield takeEvery(ActionTypes.POLICE_AUDIT_REQUESTED, loadContainerAudit);
  yield takeEvery(ActionTypes.POLICE_CONTAINER_BLOCK, blockContainer);
  yield takeEvery(ActionTypes.CREATE_COMPANY_SUBSCRIPTION, createCompanySubscription);
  yield takeEvery(ActionTypes.DELETE_COMPANY_SUBSCRIPTION, deleteCompanySubscription);
  yield takeEvery(ActionTypes.GET_COMPANY_SUBSCRIPTIONS, fetchCompanySubscriptions);
  yield takeEvery(ActionTypes.GET_SCR_EVENTS, fetchScrEvents);
}

export default containerSaga;
