import { CONSTANTS } from '../../theme/constants';
import { Get } from './authorizedRequest';

const endpoint = '/' + CONSTANTS.PORT_LOCODE + '/containers/';

export async function getReleases(obj) {
  obj.query['status'] = 'pending';
  const request = Get(endpoint + 'import/release-rights', obj.query);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return await response.json();
}
