import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { LABEL } from '../../theme/constants';
import ChoiceCheckbox from '../molecules/ChoiceCheckbox';
import FormDialog from '../molecules/FormDialog';

function YesNoDialog(props) {
  const {
    buttonTitle,
    dialogTitle,
    contentText,
    className,
    disabled,
    variant,
    dialogType,
    callback,
    callBackNo,
  } = props;
  const [open, setOpen] = React.useState(false);

  const runCallback = () => {
    if (callback) {
      callback();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contentFields = <ChoiceCheckbox storageId={dialogType} text={LABEL.REMEMBER_MY_CHOICE} />;

  const actions = (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          reactLocalStorage.set(dialogType, false);
          handleClose();
          callBackNo();
        }}
        variant="contained"
        style={{ background: '#ebebeb', marginRight: '5px' }}
      >
        {LABEL.NO}
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          runCallback();
          handleClose();
        }}
        color="primary"
        variant="contained"
      >
        {LABEL.YES}
      </Button>
    </div>
  );

  return reactLocalStorage.get(dialogType) === 'true' ? (
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        disabled={disabled}
        className={className}
        variant={variant}
        onClick={(e) => {
          e.stopPropagation();
          runCallback();
        }}
        color="primary"
      >
        {buttonTitle}
      </Button>
    </div>
  ) : (
    <FormDialog
      dialogType={dialogType}
      variant={variant}
      buttonClassName={className}
      buttonTitle={buttonTitle}
      dialogTitle={dialogTitle}
      contentText={contentText}
      contentFields={contentFields}
      actions={actions}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      open={open}
      disabled={disabled}
    />
  );
}

YesNoDialog.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  dialogType: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
};

export default YesNoDialog;
