import { useQuery } from '@tanstack/react-query';
import { NotificationRequest } from 'modules/global/adapters/mainAPI';
import { mainAPI, api } from '../../global/adapters';
import { displayNotificationKeys } from './queryKeys';

import { searchContainersQueryKeys } from './queryKeys';
import { GetSearchContainersParams } from 'modules/global/adapters/crpAPI';
import { LABEL } from 'theme/constants';

export const useNotificationRequest = (notificationRequest: NotificationRequest) => {
  const isSearchEntered =
    (Boolean(notificationRequest.notificationEvent) &&
      notificationRequest.notificationEvent !== 'Unknown') ||
    Boolean(notificationRequest.publicReferenceId) ||
    Boolean(notificationRequest.equipmentNumber) ||
    Boolean(notificationRequest.releaseId) ||
    Boolean(notificationRequest.storedOn) ||
    (Boolean(notificationRequest.storedOn) &&
      Boolean(notificationRequest.timeWindowFrom) &&
      Boolean(notificationRequest.timeWindowTo));

  return useQuery(
    displayNotificationKeys.getNotifications(notificationRequest),
    () => mainAPI.notifications.getNotifications(notificationRequest),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetActiveShippingAgents = () => {
  return useQuery(searchContainersQueryKeys.getShippingAgents(), () =>
    api.agents.getShippingAgents()
  );
};

export const useSearchContainers = (searchParams: GetSearchContainersParams) => {
  const isSearchEntered =
    Boolean(searchParams.shippingAgentNxtEntityId) ||
    Boolean(searchParams.equipmentNumber) ||
    Boolean(searchParams.billOfLadingNumber);

  searchParams.role = LABEL.TERMINAL_ROLE;

  return useQuery(
    searchContainersQueryKeys.containersForUpdate(searchParams),
    () => api.containers.getSearchContainers(searchParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(searchContainersQueryKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};
