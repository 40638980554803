import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableHead,
  Typography,
} from '@material-ui/core';
import { AddBox as AddIcon } from '@material-ui/icons';
import EditableListItem from '../atoms/EditableListItem';
import { LABEL } from '../../theme/constants';

const styles = (theme) => ({
  addIcon: {
    marginLeft: theme.spacing(1),
  },
});

class EditableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items ? [...this.props.items].map((prop) => (prop = { ...prop })) : [],
      lastItemIsNew: false,
    };
  }

  propChangeHandler(name, value, itemIndex) {
    const { items } = this.state;
    let itemsActual = [
      ...items.slice(0, itemIndex),
      {
        ...items[itemIndex],
        [name]: value,
      },
      ...items.slice(itemIndex + 1),
    ];
    this.setState({ items: itemsActual });
    this.props.onChange(itemsActual);
  }

  delete(index) {
    let items = [...this.state.items];
    items.splice(index, 1);
    this.setState({ items });
    this.props.onChange(items);
  }

  add() {
    let items = [...this.state.items];
    if (this.props.maxItems != null && items.length >= this.props.maxItems) return;
    const newItem = { ...this.props.newItem };
    items.push(newItem);
    this.setState({ items, lastItemIsNew: true });
    this.props.onChange(items);
  }

  render() {
    const { editable, headers, classes } = this.props;
    const { items } = this.state;
    return (
      <Table className={this.props.className}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>
                <Typography color={editable ? 'textPrimary' : 'textSecondary'}>{header}</Typography>
              </TableCell>
            ))}
            {editable && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => {
            return (
              <EditableListItem
                key={index}
                item={item}
                editable={editable}
                propChangeHandler={(name, value) => this.propChangeHandler(name, value, index)}
                deleteHandler={() => this.delete(index)}
                settings={this.props.settings}
                isNew={index === items.length - 1 && this.state.lastItemIsNew}
                textMaxLength={this.props.textMaxLength}
              />
            );
          })}
          {editable && (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                {(this.props.maxItems == null ||
                  this.state.items.length == 0 ||
                  this.props.maxItems > this.state.items.length) && (
                  <Button color="primary" onClick={() => this.add()}>
                    {this.props.addLabel ? this.props.addLabel : LABEL.ADD}
                    <AddIcon className={classes.addIcon} fontSize="small" />
                  </Button>
                )}
                {this.props.maxItems != null && this.state.items.length >= this.props.maxItems && (
                  <p style={{ color: 'red' }}>
                    {LABEL.MAX_ITEMS_REACHED + ' (' + this.props.maxItems + ')'}
                  </p>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}

EditableList.propTypes = {
  items: PropTypes.array,
  addLabel: PropTypes.string,
  newItem: PropTypes.object,
  headers: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  maxItems: PropTypes.number,
  textMaxLength: PropTypes.number,
};

export default withStyles(styles)(EditableList);
