import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MESSAGETYPES, LABEL, BROADCAST_API_MESSAGETYPE } from '../../theme/constants';
import ApiErrors from './ApiErrors';
import Message from './Message';

class Messages extends React.Component {
  state = {
    open: false,
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { message } = this.props;
    let duration = null;
    if (
      message.type === MESSAGETYPES.SUCCESS ||
      message.type === MESSAGETYPES.API_SUCCESS ||
      message.type === MESSAGETYPES.BROADCAST_MESSAGE
    )
      duration = 4000;
    switch (message.type) {
      case MESSAGETYPES.API_ERRORS:
        return (
          <ApiErrors open={this.state.open} onClose={this.handleClose} message={message.content} />
        );
      case MESSAGETYPES.API_SUCCESS:
        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={duration}
            onClose={this.handleClose}
          >
            <Message
              onClose={this.handleClose}
              variant={MESSAGETYPES.SUCCESS}
              message={message.content ? message.content : LABEL.ACTION_SUCCEEDED}
            />
          </Snackbar>
        );
      case MESSAGETYPES.BROADCAST_MESSAGE:
        let variantType = '';
        switch (message.content.messageType) {
          case BROADCAST_API_MESSAGETYPE.FATALERROR:
          case BROADCAST_API_MESSAGETYPE.ERROR:
          case BROADCAST_API_MESSAGETYPE.VALIDATIONERROR:
            variantType = MESSAGETYPES.ERROR;
            break;
          case BROADCAST_API_MESSAGETYPE.INFO:
            variantType = MESSAGETYPES.INFO;
            break;
          case BROADCAST_API_MESSAGETYPE.WARNING:
            variantType = MESSAGETYPES.WARNING;
            break;
          default:
            variantType = MESSAGETYPES.SUCCESS;
        }
        return (
          <Snackbar
            style={{ whiteSpace: 'pre-wrap' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={duration}
          >
            <Message
              onClose={this.handleClose}
              variant={variantType}
              message={message.content.message}
            />
          </Snackbar>
        );
      default:
        return (
          <Snackbar
            style={{ whiteSpace: 'pre-wrap' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={duration}
            onClose={this.handleClose}
          >
            <Message onClose={this.handleClose} variant={message.type} message={message.content} />
          </Snackbar>
        );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message) {
      this.setState({ open: true });
    }
  }
}

Messages.propTypes = {
  message: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    message: state.message,
  };
}

export default connect(mapStateToProps)(Messages);
