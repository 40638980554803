import React from 'react';
import { Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { TEXT } from '../../theme/constants';

export default function DataTable(props) {
  const rowsPerPageOptions = [25, 50, 100];

  const NoRowsOverlay = () => {
    return (
      <Box sx={{ display: 'flex' }} height="100%" alignItems="center" justifyContent="center">
        {TEXT.NO_DRIVERS}
      </Box>
    );
  };

  return (
    <div style={{ height: 600, width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={props.loading}
            className={props.classes.dataGrid}
            components={{ NoRowsOverlay }}
            columns={props.columns}
            rows={props.rows}
            pageSize={props.pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={(value) => props.setPageCallback(value)}
            onPageSizeChange={(value) => props.setPageSizeCallback(value)}
            checkboxSelection={props.enableSelection}
            disableSelectionOnClick={props.disableRowClick}
            disableColumnMenu={props.disableColumnMenu}
            disableColumnFilter={props.disableColumnFilter}
          />
        </div>
      </div>
    </div>
  );
}
