import { useEffect } from 'react';
import { loadScrEvents } from 'store/actions/policeContainerActions';
import { bindActionCreators } from 'redux';
import CollapsibleTable from 'components/organisms/CollapsibleTable';
import React from 'react';
import { connect } from 'react-redux';
import { selectScrEvents } from 'store/selectors/selectors';

function ScrEventsDetail(props) {
  const { loadScrEvents, items } = props;

  useEffect(() => {
    loadScrEvents(props.match.params.nxtEntityId);
  }, []);

  const parentColumns = ['Container', 'Release Identification', 'Last Event at'];
  const childColumns = ['Release', 'Type', 'From', 'To', 'When', 'What'];

  return (
    <>
      {items && (
        <CollapsibleTable
          parentColumns={parentColumns}
          chilColumns={childColumns}
          items={items}
          title={'SCR - Audit trail'}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    items: selectScrEvents(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadScrEvents,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrEventsDetail);
