import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

export type StepProps = {
  /** Main title for the step, displayed next to the number */
  stepTitle: string;
  /** Subtitle or small description of the steps main purpose */
  stepFlavorText?: string;
  /** Content to be shown in the StepContent block */
  content: React.ReactNode;
  /** Callback to be set for the onClick event of the back (or previous) button */
  backCallBack?: Function;
  /** Disables the next button */
  disableNext?: boolean;
  /** Customize the text of the back button */
  backCustomText?: string;
  /** Disables the back (or previous) button */
  disableBack?: boolean;
  /** Callback to be set for the onClick event of the next button */
  nextCallBack?: Function;
  /** When enabled, it'll show a loader in the next button */
  nextIsLoading?: boolean;
  /** Customize the text of the next button */
  nextCustomText?: string;
  /** When enabled, no next and previous buttons are displayed. Make sure to use your own buttons then. */
  noStepButtons?: boolean;
  /** When enabled, no previous button are displayed**/
  noBackButton?: boolean;
  noNextButton?: boolean;
  hideStep?: boolean;
};

type Props = {
  faqLink?: string;
  steps: StepProps[];
  /** Override internal use of active step, when you need to have access to the activestep, outside of the component  **/
  activeStep?: number;
  /** Override internal use of set active step, when you need to have access to the setactivestep function, outside of the component  **/
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
};

const StepUseCase = ({ steps, activeStep, setActiveStep, faqLink }: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [localActiveStep, localSetActiveStep] = React.useState(0);

  const internalActiveStep = activeStep ?? localActiveStep;
  const internalSetActiveStep = setActiveStep ?? localSetActiveStep;

  const handleNext = (nextCallBack?: Function) => {
    internalSetActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (typeof nextCallBack === 'function') {
      nextCallBack();
    }
  };

  const handleBack = (backCallBack?: Function) => {
    internalSetActiveStep((prevActiveStep) => prevActiveStep - 1);
    backCallBack?.();
  };

  return (
    <React.Fragment>
      {faqLink && (
        <Chip
          className={classes.faqButton}
          label={t('general.faq')}
          clickable
          onClick={() => window.open(faqLink, '_blank')}
          variant="outlined"
          icon={<Help />}
        />
      )}
      <Stepper
        className={classes.stepper}
        activeStep={internalActiveStep}
        orientation="vertical"
        connector={<span className={classes.connector} />}
      >
        {steps.map((singleStep, index) => {
          return (
            singleStep.hideStep !== true && (
              <Step key={index}>
                <StepLabel>
                  <Typography className={classes.title}>{singleStep.stepTitle}</Typography>
                  <Typography className={classes.flavourText}>
                    {singleStep.stepFlavorText}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <React.Fragment>
                    {singleStep.content}
                    {singleStep.noStepButtons ? null : (
                      <Box marginTop={6}>
                        {!singleStep.noBackButton && (
                          <Button
                            variant="outlined"
                            className={classes.button}
                            disabled={singleStep.disableBack}
                            onClick={() => handleBack(singleStep.backCallBack?.())}
                          >
                            {singleStep.backCustomText || t('general.back')}
                          </Button>
                        )}
                        {!singleStep.noNextButton && (
                          <Button
                            type="submit"
                            startIcon={singleStep.nextIsLoading && <CircularProgress size={20} />}
                            variant="outlined"
                            color="primary"
                            disabled={singleStep.disableNext || singleStep.nextIsLoading}
                            onClick={() => handleNext(singleStep.nextCallBack?.())}
                          >
                            {singleStep.nextCustomText || t('general.next')}
                          </Button>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                </StepContent>
              </Step>
            )
          );
        })}
      </Stepper>
    </React.Fragment>
  );
};
export default StepUseCase;
