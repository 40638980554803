import queryString from 'query-string';
import { ALFAPASS_TOKEN_ENDPOINT, BFF_API_ROOT_URL } from '../../config/environment';
import { GetBase } from './authorizedRequest';

const tokenEndpoint = ALFAPASS_TOKEN_ENDPOINT;

async function getClientId() {
  const url = '/greenlights/overrule/my-alfapass';
  const request = GetBase(BFF_API_ROOT_URL, url);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  const json = await response.json();
  return json;
}

export async function getAlfapassToken(obj) {
  var id = await getClientId();
  const request = new Request(tokenEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      code: obj.code,
      code_verifier: obj.codeVerifier,
      redirect_uri: `${window.location.origin}/overrule-customs-light/callback`,
      client_id: id,
    }),
  });
  const response = await fetch(request);
  const json = await response.json();
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  localStorage.removeItem('alfapassToken');
  localStorage.setItem('alfapassToken', JSON.stringify(json));
  return response;
}
