import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
    color: 'black',
  },
});

function ActionDialog(props) {
  const {
    dialogTitle,
    contentText,
    contentFields,
    actions,
    handleClose,
    open,
    fullWidth,
    maxWidth,
    classes,
  } = props;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={(e, reason) => {
        handleClose(e, reason);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText className={classes.dialogContentWithNewLines}>
          {contentText}
        </DialogContentText>
        {contentFields}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

ActionDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  contentFields: PropTypes.object,
  actions: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default withStyles(styles)(ActionDialog);
