import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    LABEL,
    ACTIONTYPES,
    FORMATTING,
    DIALOG,
    DIALOGTYPES
} from "../../theme/constants";
import React from 'react';
import { Button, withStyles } from "@material-ui/core";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { blockContainers, releaseContainers } from "../../store/actions/archiveActions";
import { selectContainersForScan } from "../../store/actions/containerActions";
import ChoiceCheckbox from '../molecules/ChoiceCheckbox';
const styles = theme => ({
    dialogContentWithNewLines: {
        whiteSpace: 'pre-wrap',
    },
});

function TerminalActionDialog(props) {
    const {
        classes,
        activeItem,
        terminalActionOpen,
        onClose,
        container,
        blockContainers,
        releaseContainers,
        selectContainersForScan
    } = props;

    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogContent, setDialogContent] = React.useState('');
    const [dialogType, setDialogType] = React.useState('');

    const setDialogDetails = () => {
        switch (activeItem.toUpperCase()) {
            case LABEL.BLOCK_LABEL:
                setDialogType(DIALOGTYPES.BLOCK)
                setDialogTitle(DIALOG.BLOCK_TITLE);
                setDialogContent(DIALOG.BLOCK_CONTENT);
                break;
            case LABEL.RELEASE_LABEL:
                setDialogType(DIALOGTYPES.RELEASE)
                setDialogTitle(DIALOG.RELEASE_TITLE);
                setDialogContent(DIALOG.RELEASE_CONTENT);
                break;
            case LABEL.SELECT_FOR_SCAN_LABEL:
                setDialogType(DIALOGTYPES.SELECT_FOR_SCAN)
                setDialogTitle(DIALOG.SELECT_FOR_SCAN_TITLE);
                setDialogContent(DIALOG.SELECT_FOR_SCAN_CONTENT);
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        setDialogDetails();
    })

    const handleBlock = () => {
        blockContainers({
            containers: [container],
            actionType: ACTIONTYPES.BLOCK
        });
    }

    const handleRelease = () => {
        releaseContainers({
            containers: [container],
            actionType: ACTIONTYPES.RELEASE
        });
    }

    const handleSelectForScan = () => {
        selectContainersForScan({
            containers: [container],
            actionType: ACTIONTYPES.SCANNING
        });
    }

    const runCallback = () => {
        console.log(activeItem);
        switch (activeItem) {
            case ACTIONTYPES.BLOCK:
                handleBlock();
                break;

            case ACTIONTYPES.RELEASE:
                handleRelease();
                break;

            case ACTIONTYPES.SELECT_FOR_SCAN:
                handleSelectForScan();
                break;

            default:
                break;
        }
    };

    const contentFields =
        <ChoiceCheckbox
            storageId={dialogType}
            text={LABEL.REMEMBER_MY_CHOICE}
        />;

    const confirmationActions =
        <div>
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onClose(e);
                }}
                variant="contained" style={{ background: '#ebebeb', marginRight: '5px' }}
            >
                {LABEL.NO}
            </Button>
            <Button
                onClick={e => {
                    e.stopPropagation();
                    runCallback();
                    onClose(e);
                }}
                color="primary" variant="contained"
            >
                {LABEL.YES}
            </Button>
        </div>;

    return (
        <>
            <Dialog
                open={terminalActionOpen}
                onClose={(e) => {
                    onClose(e);
                }}
                fullWidth={true}
                maxWidth={'xs'}
                aria-labelledby={"terminal-action"}
            >
                <DialogTitle
                    aria-labelledby={"terminal-action-title"}
                >
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentWithNewLines} color="textSecondary">
                        {dialogContent}
                    </DialogContentText>
                    {contentFields}
                </DialogContent>
                <DialogActions>
                    {confirmationActions}
                </DialogActions>
            </Dialog>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        blockContainers,
        releaseContainers,
        selectContainersForScan
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(TerminalActionDialog));