import { useEffect, useState } from 'react';
import { BFF_API_ROOT_URL, TERMS_AND_CONDITIONS_VERSION } from '../../config/environment';
import { GetWeb, PostWeb } from '../../store/api//authorizedRequest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Link';
import { TERMSANDCONDITIONS } from '../../theme/constants';

const getTermsAndConditionsRequest = () => {
  const request = GetWeb(
    `/identification/termsandconditions?termsAndConditionsVersion=${TERMS_AND_CONDITIONS_VERSION}`
  );
  return request;
};

const setTermsAndConditionsRequest = (nxtEntityId) => {
  const body = {
    termsAndConditionsVersion: TERMS_AND_CONDITIONS_VERSION,
    nxtEntityId: nxtEntityId,
  };
  const request = PostWeb('/identification/termsandconditions/set', body);
  return request;
};

const TermsAndConditions = (props) => {
  const nxtEntityId = props.userProfile.nxtEntityId;
  const [checkedTermsAndConditions, setCheckedTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(async () => {
    const request = getTermsAndConditionsRequest();
    await fetch(request)
      .then((response) => response.json())
      .then((result) => {
        if (result === true) {
          setOpen(false);
        } else {
          setOpen(true);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setIsError(true);
      });
  }, []);

  const handleCheckBoxChange = (event) => {
    setCheckboxValue(event.target.checked);
  };

  const handleSaveAndClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsSaving(true);
    const request = setTermsAndConditionsRequest(nxtEntityId);
    await fetch(request)
      .then((response) => response.json())
      .then((result) => {
        setIsSaving(false);
        if (result === true) {
          setOpen(false);
        } else {
          setIsError(true);
        }
      })
      .catch((e) => {
        setIsSaving(false);
        setIsError(true);
      });
  };

  return (
    <Dialog open={open}>
      {!isError && !loading && (
        <DialogTitle>{TERMSANDCONDITIONS.TERMS_CONDITIONS_TITLE}</DialogTitle>
      )}
      {isError && (
        <DialogTitle>
          <p style={{ color: 'red' }}>{TERMSANDCONDITIONS.NETWORK_ERROR}</p>
        </DialogTitle>
      )}
      <DialogContent>
        {isError && (
          <DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginBottom: '20px' }}>{TERMSANDCONDITIONS.NETWORK_MESSAGE}</div>
            </div>
          </DialogContentText>
        )}
        {!isError && (
          <DialogContentText>
            {loading && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>{TERMSANDCONDITIONS.CHECK_MESSAGE_WHILE_LOADING}</div>
                <div>
                  <CircularProgress size="20px" />
                </div>
              </div>
            )}
            {!loading && !checkedTermsAndConditions && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ cursor: 'pointer' }}>
                  <Typography>
                    <a
                      style={{ textDecoration: 'underline' }}
                      href="https://documentation.nxtport.com/certified-pickup/certified-pick-up-cpu-terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {TERMSANDCONDITIONS.CLICK_HERE_TO_READ}
                    </a>
                  </Typography>
                </div>
                <div style={{ marginTop: '15px' }}>{TERMSANDCONDITIONS.BY_CHECKING_THIS_BOX}</div>
                <div style={{ marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        name="checkBoxTAndC"
                        color="primary"
                        checked={checkboxValue}
                        onChange={handleCheckBoxChange}
                        disabled={isSaving}
                      />
                    }
                    label={TERMSANDCONDITIONS.I_ACCEPT_TC}
                  />
                </div>
              </div>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {isSaving && (
          <div>
            <div style={{ float: 'left', marginRight: '12px' }}>
              <CircularProgress size="15px" />
            </div>
            <div style={{ float: 'right' }}>{TERMSANDCONDITIONS.PLEASE_WAIT}</div>
          </div>
        )}
        {!isError && !isSaving && !loading && checkboxValue && (
          <Button onClick={handleSaveAndClose} color="primary" autoFocus>
            {TERMSANDCONDITIONS.SAVE_AND_CLOSE}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditions;
