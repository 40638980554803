import { TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LABEL } from 'theme/constants';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainersSelected: number;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  newReason: string;
};

const UpdateStep: React.FC<Props> = ({
  numberOfContainersSelected,
  setReason,
  newReason,
}: Props) => {
  const { t } = useTranslation('declineReleaseRight');
  const classes = useStyles();

  const containersToUpdate =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const handleReasonChange = (_event: React.ChangeEvent<{}>, reason: string) => {
    setReason(reason);
  };

  return (
    <React.Fragment>
      <Typography className={classes.subText} variant="subtitle1">
        {t('sideBar.selectedContainers', { numberOfContainersSelected: containersToUpdate })}
      </Typography>
      <TextField
        id="decline-reason-multiline"
        label={LABEL.REASON}
        defaultValue={newReason}
        onChange={(e) => handleReasonChange(e, e.target.value as string)}
        multiline
        fullWidth
        variant="outlined"
        maxRows={4}
      />
    </React.Fragment>
  );
};

export default UpdateStep;
