import { LABEL } from '../../../theme/constants';

export const mapRoles = (roleLabel: string): string => {
  switch (roleLabel) {
    case LABEL.FORWARDER:
      return LABEL.RELEASE_PARTY.replaceAll(' ', '');
    case LABEL.SHIPPING:
      return LABEL.SHIPPING_AGENT.replaceAll(' ', '');
    case LABEL.TRANSPORTOPERATOR_LABEL:
      return LABEL.TRANSPORT_OPERATOR.replaceAll(' ', '');
    default:
      return '';
  }
};

export const mapToFriendlyRoles = (roleCode: string): string => {
  switch (roleCode) {
    case LABEL.FORWARDER_ROLE:
      return LABEL.RELEASE_PARTY;
    case LABEL.SHIPPING_ROLE:
      return LABEL.SHIPPING_AGENT;
    case LABEL.TRANSPORTOPERATOR_ROLE:
      return LABEL.TRANSPORT_OPERATOR;
    case LABEL.POLICE_ROLE:
      return LABEL.FPOLICE;
    case LABEL.TERMINAL_ROLE:
      return LABEL.FTERMINAL;
    default:
      return '';
  }
};
