import React from 'react';
import { USE_BETA_SCREENS } from '../../../../config/environment';
import { useBetaHook } from '../../hooks';

type Props = {
  regularRoutes: React.ReactNode;
  betaRoutes: React.ReactNode;
};

const BetaRouteSwitch = ({ regularRoutes, betaRoutes }: Props) => {
  const isBetaScreensFeatureEnabled = USE_BETA_SCREENS?.toLowerCase() === 'true';
  const { areBetaScreensEnabled } = useBetaHook();

  return isBetaScreensFeatureEnabled ? (
    <React.Fragment>{areBetaScreensEnabled ? betaRoutes : regularRoutes}</React.Fragment>
  ) : (
    <React.Fragment>{regularRoutes}</React.Fragment>
  );
};
export default BetaRouteSwitch;
