import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'modules/global/adapters';
import {
  BulkReleaseRightActionRequest,
  BulkAssignBargeRailPickUpRequest,
  GetContainersByTerminalAndReleaseRightParams,
  GreenLightValuesRequest,
} from 'modules/global/adapters/crpAPI';
import { ReleaseRightStatus } from 'modules/global/adapters/mainAPI';
import { acceptReleaseRightKeys } from './queryKeys';

export const useGetActiveTerminals = () => {
  return useQuery(acceptReleaseRightKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetGreenLightValues = (greenLightNames: string[]) => {
  const request = {
    greenLightNames: greenLightNames,
  } as GreenLightValuesRequest;

  return useQuery(acceptReleaseRightKeys.getGreenLightValues(), () =>
    api.greenLights.getGreenLightValues(request)
  );
};

export const useGetPendingContainers = () => {
  return useQuery(acceptReleaseRightKeys.getPendingContainersForReleaseRightAction(), () =>
    api.containers.getPendingContainersForReleaseRightAction()
  );
};

export const useGetContainersAcceptReleaseRight = (
  terminalParams: GetContainersByTerminalAndReleaseRightParams
) => {
  const isSearchEntered =
    (terminalParams.terminalNxtEntityId !== undefined &&
      terminalParams.terminalNxtEntityId !== '') ||
    (terminalParams.terminalOperationValue !== undefined &&
      terminalParams.terminalOperationValue !== '') ||
    (terminalParams.terminalReadyValue !== undefined && terminalParams.terminalReadyValue !== '') ||
    (terminalParams.customsValue !== undefined && terminalParams.customsValue !== '');

  terminalParams.status = ReleaseRightStatus.Pending;

  return useQuery(
    acceptReleaseRightKeys.containersForUpdate(terminalParams),
    () => api.containers.getContainersForReleaseRightAction(terminalParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersAccept = () => {
  // const queryClient = useQueryClient();

  return useMutation(acceptReleaseRightKeys.postContainersAccept(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersAccept(data),
  });
};

export const usePostContainersAssign = () => {
  const queryClient = useQueryClient();

  return useMutation(acceptReleaseRightKeys.postContainersAssign(), {
    mutationFn: (data: BulkAssignBargeRailPickUpRequest) =>
      api.pickuprights.postContainersAssignBargeRailPickUp(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(acceptReleaseRightKeys.containersForUpdateRoot());
    },
  });
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(acceptReleaseRightKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};
