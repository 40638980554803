import { Button, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReleaseActionDialog from '../organisms/ReleaseActionDialog';
import { ACTIONTYPES, DIALOGTYPES, DIALOG, FORMATTING, LABEL } from '../../theme/constants';
import 'moment-timezone';
import history from '../../history/browserHistory';
import PickupDialog from '../organisms/PickupDialog';
import queryString from 'query-string';
import { reactLocalStorage } from 'reactjs-localstorage';
import ConfirmationDialog from '../organisms/ConfirmationDialog';
import FormikDialog from '../organisms/formikDialog';
import addReasonSchema from 'modules/shipping-line/schemas/addReasonSchema';

function ContextMenu(props) {
  const {
    classes,
    container,
    selectedUserType,
    loadSelectedSucceeded,
    revokeContainers,
    revokeContainerPickups,
    releaseContainers,
    declineReleases,
    acceptReleases,
    selectContainersForScan,
    blockContainers,
    pickupCallback,
    setActiveItemCallback,
    setContextMenuContainer,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [releaseActionOpen, setReleaseActionOpen] = React.useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = React.useState(false);
  const [pickupDialogOpen, setPickupDialogOpen] = React.useState(false);
  const [containerToPickup, setContainerToPickup] = React.useState(null);
  const [activeItem, setItem] = React.useState('');
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const handleSelects = (newSelected) => {
    loadSelectedSucceeded(newSelected);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleItemClick = (event, item) => {
    event.stopPropagation();
    setItem(item);
    handleSelects([]);
    switch (item.toUpperCase()) {
      case LABEL.COMMERCIAL_UPDATE:
        const qs = {
          equipmentNumber: container.equipmentNumber,
          billOfLadingNumbers: container.billOfLadingNumbers,
          releaseIdentification: container.releaseIdentification,
        };
        let updateLink = '/commercial-release/update?' + queryString.stringify(qs);
        history.push(updateLink);
        break;
      case LABEL.TRANSFER.toUpperCase():
        forwardClick(container);
        break;
      case LABEL.REVOKE_LABEL:
        setConfirmationOpen(true);
        break;
      case LABEL.REVOKEPICKUP_LABEL:
        revokeContainerPickups([container]);
        break;
      case LABEL.RELEASE_LABEL:
        if (reactLocalStorage.get(DIALOGTYPES.RELEASE) === 'true')
          releaseContainers({
            containers: [container],
            actionType: ACTIONTYPES.RELEASE,
          });
        else {
          setContextMenuContainer(container);
          setActiveItemCallback(event, item);
        }
        break;
      case LABEL.SELECT_FOR_SCAN_LABEL:
        if (reactLocalStorage.get(DIALOGTYPES.SELECT_FOR_SCAN) === 'true')
          selectContainersForScan({
            containers: [container],
            actionType: ACTIONTYPES.SCANNING,
          });
        else {
          setContextMenuContainer(container);
          setActiveItemCallback(event, item);
        }
        break;
      case LABEL.BLOCK_LABEL:
        if (selectedUserType === LABEL.TERMINAL) {
          if (reactLocalStorage.get(DIALOGTYPES.BLOCK) === 'true')
            blockContainers({
              containers: [container],
              actionType: ACTIONTYPES.BLOCK,
            });
          else {
            setContextMenuContainer(container);
            setActiveItemCallback(event, item);
          }
        } else {
          setReleaseActionOpen(true);
        }
        break;
      case LABEL.PICKUP_LABEL:
        setPickupOpen();
        break;
      case LABEL.DECLINE_LABEL:
        if (reactLocalStorage.get(DIALOGTYPES.DECLINE) === 'true')
          declineReleases({
            containers: [container],
            actionType: ACTIONTYPES.DECLINE,
          });
        else {
          setConfirmationOpen(true);
        }
        break;
      case LABEL.ACCEPT_LABEL:
        if (reactLocalStorage.get(DIALOGTYPES.ACCEPT) === 'true')
          acceptReleases({
            containers: [container],
            actionType: ACTIONTYPES.ACCEPT,
          });
        else {
          setConfirmationOpen(true);
        }
        break;
      default:
        setItem(translateItemToCommercialAction(item));
        setReleaseActionOpen(true);
    }

    handleClose(event);
  };

  const handleCloseDialog = (event) => {
    event.stopPropagation();
    setReleaseActionOpen(false);
  };

  const forwardClick = (container) => {
    handleSelects([container]);
    history.push('/containers/company');
  };

  const setPickupOpen = () => {
    setContainerToPickup(container);
    setPickupDialogOpen(true);
  };

  const translateCommercialReleaseLabel = (label) => {
    switch (label.toUpperCase()) {
      case LABEL.COMMERCIAL_BLOCK:
        return LABEL.COMMERCIAL_BLOCK_LABEL;
      case LABEL.COMMERCIAL_UNBLOCK:
        return LABEL.COMMERCIAL_UNBLOCK_LABEL;
      case LABEL.COMMERCIAL_DELETE:
        return LABEL.COMMERCIAL_DELETE_LABEL;
      case LABEL.COMMERCIAL_REVOKE:
        return LABEL.COMMERCIAL_REVOKE_LABEL;
      case LABEL.COMMERCIAL_UPDATE:
        return LABEL.COMMERCIAL_UPDATE_LABEL;
      default:
        break;
    }
  };

  const translateItemToCommercialAction = (item) => {
    switch (item.toUpperCase()) {
      case LABEL.COMMERCIAL_BLOCK:
        return ACTIONTYPES.BLOCK;
      case LABEL.COMMERCIAL_UNBLOCK:
        return ACTIONTYPES.UNBLOCK;
      case LABEL.COMMERCIAL_DELETE:
        return ACTIONTYPES.DELETE;
      case LABEL.COMMERCIAL_REVOKE:
        return ACTIONTYPES.REVOKE;
      case LABEL.COMMERCIAL_UPDATE:
        return ACTIONTYPES.UPDATE;
      default:
        return item;
    }
  };

  const handleConfirmationDialog = () => {
    let item = activeItem.toUpperCase();
    if (item === LABEL.DECLINE_LABEL || item === LABEL.REVOKE_LABEL) setReasonDialogOpen(true);
    else performReleaseRightAction();
  };

  const returnDialogType = () => {
    switch (activeItem.toUpperCase()) {
      case LABEL.DECLINE_LABEL:
        return DIALOGTYPES.DECLINE;
      case LABEL.ACCEPT_LABEL:
        return DIALOGTYPES.ACCEPT;
      default:
        break;
    }
  };

  const performReleaseRightAction = (reason) => {
    switch (activeItem.toUpperCase()) {
      case LABEL.REVOKE_LABEL:
        revokeContainers({
          containers: [container],
          reason: reason,
          actionType: ACTIONTYPES.REVOKE,
        });
        break;
      case LABEL.DECLINE_LABEL:
        declineReleases({
          containers: [container],
          reason: reason,
          actionType: ACTIONTYPES.DECLINE,
        });
        break;
      case LABEL.ACCEPT_LABEL:
        acceptReleases({
          containers: [container],
          actionType: ACTIONTYPES.ACCEPT,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmitReasonDialog = (values) => {
    let reason = values.reason;
    performReleaseRightAction(reason);
    setReasonDialogOpen(false);
  };

  const handleCloseReasonDialog = () => {
    setReasonDialogOpen(false);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        ...
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        {selectedUserType === LABEL.SHIPPING_AGENT_LABEL && (
          <MenuItem disabled={true}>{LABEL.COMMERCIAL_RELEASE}</MenuItem>
        )}
        {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
          container.commercialReleaseActions &&
          container.commercialReleaseActions
            .filter((c) => c.isEnabled)
            .map((item) => {
              return (
                <MenuItem
                  onClick={(e) => {
                    handleItemClick(e, item.label);
                  }}
                  key={item.label}
                >
                  {translateCommercialReleaseLabel(item.label)}
                </MenuItem>
              );
            })}
        {selectedUserType === LABEL.SHIPPING_AGENT_LABEL &&
          container.releaseRightActions.length && (
            <MenuItem disabled={true}>{LABEL.RELEASE_RIGHT}</MenuItem>
          )}
        {container.releaseRightActions
          .filter((c) => c.isEnabled)
          .map((item) => {
            return (
              <MenuItem
                onClick={(e) => {
                  handleItemClick(e, item.label);
                }}
                key={item.label}
              >
                {item.label}
              </MenuItem>
            );
          })}
      </Menu>
      <ReleaseActionDialog
        releaseActionOpen={releaseActionOpen}
        activeItem={activeItem}
        container={container}
        onClose={handleCloseDialog}
      />
      <PickupDialog
        multiple={false}
        callback={() => {
          pickupCallback(container);
          setPickupDialogOpen(false);
          setContainerToPickup(null);
        }}
        variant={'outlined'}
        container={container}
        selectedContainer={containerToPickup}
        buttonTitle={LABEL.PICKUP_BUTTON}
        hideButton={true}
        overridePickupDialogOpen={pickupDialogOpen}
        overrideToFalse={() => {
          setPickupDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        dialogType={returnDialogType()}
        dialogTitle={DIALOG.CONFIRM_ACTION_TITLE}
        contentText={DIALOG.CONFIRM_ACTION.replace(FORMATTING.ACTION, activeItem.toLowerCase())}
        callback={handleConfirmationDialog}
        setOpen={setConfirmationOpen}
      />
      <FormikDialog
        initialValues={{ reason: '' }}
        schema={addReasonSchema}
        open={reasonDialogOpen}
        handleSubmit={handleSubmitReasonDialog}
        handleClose={handleCloseReasonDialog}
        dialogTitle={DIALOG.PROVIDE_REASON_TITLE}
        dialogContentText={DIALOG.PROVIDE_REASON_CONTENT}
        classes={classes}
        columnSize={12}
      />
    </div>
  );
}

ContextMenu.propTypes = {
  container: PropTypes.object.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  loadSelectedSucceeded: PropTypes.func.isRequired,
  declineReleases: PropTypes.func,
  acceptReleases: PropTypes.func,
  revokeContainers: PropTypes.func.isRequired,
  blockContainers: PropTypes.func.isRequired,
  revokeContainerPickups: PropTypes.func.isRequired,
};

export default ContextMenu;
