import { GetContainersByTerminalParams } from 'modules/global/adapters/crpAPI';

export const assignPickUpKeys = {
  root: ['assign-pickup'] as const,
  containersForUpdateRoot: () => [...assignPickUpKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (terminalParams: GetContainersByTerminalParams) =>
    [...assignPickUpKeys.containersForUpdateRoot(), terminalParams] as const,
  postContainersAssignBargeRailPickUp: () =>
    [...assignPickUpKeys.root, 'post-containers-assign-barge-rail-pickup'] as const,
  postContainersAssignPickUp: () =>
    [...assignPickUpKeys.root, 'post-containers-assign-pickup'] as const,
  getTerminals: () => [...assignPickUpKeys.root, 'get-terminals'] as const,
  getGreenLightValues: () => [...assignPickUpKeys.root, 'get-greenlight-values'] as const,
  getContainerDetail: (id: string) => [...assignPickUpKeys.root, 'get-container-detail', id],
};
