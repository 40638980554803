import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.selectedReleases, action) {
  switch (action.type) {
    case ActionTypes.SELECTED_RELEASES_REQUEST_SUCCEEDED: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
