import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'modules/global/adapters';
import {
  BulkUpdatePickUpRequest,
  GetContainersByPickUpParams,
} from 'modules/global/adapters/crpAPI';
import { updatePickUpKeys } from './queryKeys';

export const useGetContainersUpdatePickUp = (terminalParams: GetContainersByPickUpParams) => {
  const isSearchEntered =
    (terminalParams.terminalNxtEntityId !== undefined &&
      terminalParams.terminalNxtEntityId !== '') ||
    (terminalParams.meansOfTransport !== undefined && terminalParams.meansOfTransport !== '') ||
    (terminalParams.creationDate !== undefined && terminalParams.creationDate !== '');

  return useQuery(
    updatePickUpKeys.containersForUpdate(terminalParams),
    () => api.containers.getContainersForUpdatePickUp(terminalParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersUpdateBargeRailPickUp = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePickUpKeys.postContainersUpdateBargeRailPickUp(), {
    mutationFn: (data: BulkUpdatePickUpRequest) =>
      api.pickuprights.postContainersUpdateBargeRailPickUp(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(updatePickUpKeys.containersForUpdateRoot());
    },
  });
};

export const usePostContainersUpdatePickUp = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePickUpKeys.postContainersUpdatePickUp(), {
    mutationFn: (data: BulkUpdatePickUpRequest) =>
      api.pickuprights.postContainersUpdatePickUp(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(updatePickUpKeys.containersForUpdateRoot());
    },
  });
};
