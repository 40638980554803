import { FilterFn, Row, createColumnHelper } from '@tanstack/react-table';
import { ExtendedContainer, LightColor } from '../../../global/adapters/crpAPI';
import { LightColor as Color } from 'theme/constants';
import i18n from '../../../global/config/i18n';
import { FiberManualRecord } from '@material-ui/icons';
import { DataGrid } from 'modules/global/components';

const arrayFilter: FilterFn<ExtendedContainer> = (
  row: Row<ExtendedContainer>,
  columnId: string,
  filterValue: string
) => {
  const values: string[] = row.getValue(columnId);

  return values.some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
};

const columnHelper = createColumnHelper<ExtendedContainer>();

export const greenLightValueStyle = { display: 'flex', alignItems: 'center' };

export const showIcon = (color: LightColor | undefined) => {
  switch (color) {
    case Color.Grey:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
    case Color.Yellow:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#dfc920'} />;
    case Color.Orange:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#f57b00'} />;
    case Color.Red:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#e60000'} />;
    case Color.Green:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#4AB55F'} />;

    case undefined:
    default:
      return <FiberManualRecord fontSize={'default'} htmlColor={'#808080'} />;
  }
};

export const containerOverviewColumns = [
  columnHelper.accessor('equipmentNumber', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:equipmentNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('billOfLadingNumbers', {
    cell: (info) => info.getValue()?.join(', '),
    header: () => i18n.t('glossary:billOfLadingNumbers'),
    filterFn: arrayFilter,
  }),
  columnHelper.accessor('terminalName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:terminalName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('shippingAgentName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:shippingAgentName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('status', {
    cell: ({ row }) => <div style={greenLightValueStyle}>{showIcon(row.original.status)}</div>,
    header: () => i18n.t('glossary:status'),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('releaseRightStatus', {
    cell: (info) => info.getValue(),
    size: 240,
    header: () => i18n.t('glossary:releaseRightStatus'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('imoNumber', {
    cell: (info) => info.getValue(),
    size: 230,
    header: () => i18n.t('glossary:imoNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('stayNumber', {
    cell: (info) => info.getValue(),
    size: 230,
    header: () => i18n.t('glossary:stayNumber'),
    filterFn: 'includesString',
  }),
];

export const containerOverviewColumnsWithCheckbox = [
  columnHelper.display({
    id: 'actions',
    cell: (cellProps) => <DataGrid.CheckboxRow {...cellProps} />,
    header: (headerProps) => <DataGrid.CheckboxHeader {...headerProps} />,
    size: 60,
    enableResizing: false,
  }),
  ...containerOverviewColumns,
];
