import { Box, FormLabel } from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { WeekPicker } from '../../../global/components/WeekPicker';

const SearchStep = () => {
  const { t } = useTranslation(['common', 'updateExpiryDate']);

  return (
    <Box marginTop={4}>
      <Field name="weekFilter" id="weekFilter">
        {() => (
          <Box display="flex" flexDirection="column">
            <FormLabel>{t('general.week')}</FormLabel>
            <WeekPicker fieldName={'weekFilter'} />
          </Box>
        )}
      </Field>
    </Box>
  );
};

export default SearchStep;
