import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.counts, action) {
    switch (action.type) {
        case ActionTypes.PENDINGRELEASECOUNT_SUCCEEDED: {
            return {
                ...state,
                pendingReleaseCount: action.payload.pendingReleaseCount
            }
        }
        case ActionTypes.UPDATE_PENDINGRELEASECOUNT: {
            return {
                pendingReleaseCount: state.pendingReleaseCount + action.payload.IncreaseWith
            }
        }
        default: {
            return state;
        }
    }
}