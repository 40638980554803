import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import { DataGrid } from 'modules/global/components';
import EmptyState from 'modules/global/components/EmptyState/EmptyState';
import { useLocalTableValues } from 'modules/global/hooks';
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { BillingInfoRequest } from '../../global/adapters/crpAPI';
import { useBillingInfoRequest } from '../adapters/display-billing-info/queries';
import displayBillingInfoSchema from '../schemas/displayBillingInfoSchema';
import StepperDisplayBillingInfo from '../components/display-billing-info/StepperDisplayBillingInfo';
import { billingInfoColumns } from '../../shipping-line/constants/shared/billingInfoColumns';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: BillingInfoRequest = {
  month: 'January',
  year: 2023,
};

const DisplayBillingInfo: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['displayBillingInfo', 'common', 'validation']);

  const formRef = useRef<FormikProps<BillingInfoRequest>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-update-voyage',
    {}
  );

  const { data, isLoading, isFetching, isError } = useBillingInfoRequest({
    month: formRef?.current?.values.month ?? '',
    year: formRef?.current?.values.year ?? 0,
  });

  const handleOnSubmit = () => {
    // This double setDisplay is used to force a formRef update, to refetch with the new values
    // setDisplayGrid(false);
    setDisplayGrid(true);
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorDisplayBillingInfo')));
  }, [isError, t]);

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFields}
          validationSchema={displayBillingInfoSchema}
          innerRef={formRef as React.Ref<FormikProps<BillingInfoRequest>>}
          onSubmit={handleOnSubmit}
        >
          {({ submitForm, resetForm, values }: FormikProps<BillingInfoRequest>) => {
            return (
              <StepperDisplayBillingInfo
                submitForm={submitForm}
                values={values}
                isFetching={isFetching}
                resetForm={() => {
                  resetForm();
                  setDisplayGrid(false);
                }}
                isError={isError || !displayGrid}
                isEmptyData={data == null || data.length === 0}
                numberOfContainers={data?.length}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={billingInfoColumns}
                  columnSizing={columnSizing}
                  data={data}
                  isLoading={isLoading}
                  onColumnSizingChange={setColumnSizing}
                  rowSelection={{}}
                />
              </Paper>
            </React.Fragment>
          )
        ) : (
          <EmptyState
            title={t('displayBillingInfo:emptyState.title')}
            subTitle={t('displayBillingInfo:emptyState.subTitle')}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayBillingInfo;
