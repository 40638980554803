import React from 'react';
import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { VALIDATION, LABEL, ADDITIONALDATAFIELDS } from '../../../theme/constants';
import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditableList from '../../molecules/EditableList';
import { ThemeProvider } from '@material-ui/core/styles';
import { CRPTheme } from '../../../theme/theme';

function UpdateContainerRelease(props) {
  const {
    classes,
    container,
    terminals,
    handleInpContainerTypeChange,
    setAutoCompleteValue,
    handleInpReleaseValidFromChange,
    handleInpReleaseValidUntilChange,
    handleInpEmptyReturnReference,
    handleInpReleaseOrderReference,
    handleInpEquipmentSupplier,
    handleInpEquipmentStatus,
    handleInpFullEmptyIndicator,
    handleInpEquipmentOperator,
    handleInpVesselCallSign,
    handleInpVesselName,
    handleInpImoNumber,
    handleInpStayNumber,
    handleInpVoyageNumber,
    handleInpIsCarrierHaulage,
    handleSubmit,
    terminalCodeError,
    releaseDateTimeUtcError,
    expirationDateTimeUtcError,
  } = props;

  const replaceAdditionalDataFieldValue = (newValue, code) => {
    container.additionalDataFields.forEach((splittedFields) => {
      let result = splittedFields.find((f) => f.code == code);
      if (result) result.value = newValue;
    });
  };

  const handleOnChange = (e, code) => {
    e.stopPropagation();
    switch (code) {
      case ADDITIONALDATAFIELDS.COREOR_EQUIPMENTOPERATOR:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpEquipmentOperator(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_EQUIPMENTSTATUS:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpEquipmentStatus(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_EQUIPMENTSUPPLIER:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpEquipmentSupplier(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_FULLEMPTYINDICATOR:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpFullEmptyIndicator(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_VESSELCALLSIGN:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpVesselCallSign(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_VESSELNAME:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpVesselName(e);
        break;
      case ADDITIONALDATAFIELDS.COMMERCIAL_RELEASE_IMONUMBER:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpImoNumber(e);
        break;
      case ADDITIONALDATAFIELDS.COMMERCIAL_RELEASE_STAYNUMBER:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpStayNumber(e);
        break;
      case ADDITIONALDATAFIELDS.COREOR_VOYAGENUMBER:
        replaceAdditionalDataFieldValue(e.target.value, code);
        handleInpVoyageNumber(e);
        break;
      case ADDITIONALDATAFIELDS.COMMERCIAL_RELEASE_ISCARRIERHAULAGE:
        replaceAdditionalDataFieldValue(e.target.checked, code);
        handleInpIsCarrierHaulage(e);
        break;
      default:
        break;
    }
  };

  const convertDateTime = (dateTime) => {
    return moment(dateTime).local().format('YYYY-MM-DD');
  };

  const checkDatesAndHandleSubmit = (event) => {
    if (container.releaseDateTimeUtc == '') {
      container.releaseDateTimeUtc = null;
    }

    if (container.expirationDateTimeUtc == '') {
      container.expirationDateTimeUtc = null;
    }
    handleSubmit(event);
  };

  return (
    <ThemeProvider theme={CRPTheme}>
      <Typography variant={'h5'}>{LABEL.COMMERCIAL_RELEASE_UPDATE_TITLE}</Typography>
      <Divider />
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={6}>
          <TextField
            id="inpContainerNumber"
            type="text"
            label={LABEL.CONTAINER_NUMBER}
            value={container.equipmentNumber}
            inputProps={{
              readOnly: true,
            }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="inpReleaseIdentification"
            type="text"
            value={container.releaseIdentification}
            label={LABEL.RELEASE_IDENTIFICATION}
            inputProps={{
              readOnly: true,
            }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <EditableList
            headers={[LABEL.BL_NUMBERS]}
            newItem={{ blNumber: undefined }}
            addLabel={LABEL.ADD_BL_NUMBER}
            settings={{
              blNumber: {
                type: 'text',
              },
            }}
            items={container.billOfLadingNumbers.map((blNumber) => ({ blNumber }))}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={4}>
          <TextField
            id="inpValidFromDate"
            error={releaseDateTimeUtcError}
            label={LABEL.RELEASE_VALID_FROM}
            helperText={releaseDateTimeUtcError ? VALIDATION.REL_DATE_MUST_BE_BEFORE_EXP_DATE : ''}
            type="date"
            defaultValue={convertDateTime(container.releaseDateTimeUtc)}
            onChange={(e) => handleInpReleaseValidFromChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpValidUntilDate"
            label={LABEL.RELEASE_VALID_UNTIL}
            error={expirationDateTimeUtcError}
            helperText={expirationDateTimeUtcError ? VALIDATION.EXP_DATE_MUST_BE_GREATER_THAN : ''}
            type="date"
            defaultValue={convertDateTime(container.expirationDateTimeUtc)}
            onChange={(e) => handleInpReleaseValidUntilChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="inpTerminalCode"
            options={terminals}
            getOptionLabel={(terminal) => terminal.name + ' (' + terminal.code + ')'}
            value={terminals.find((t) => t.code == container.terminalCode)}
            onChange={(e, value) => setAutoCompleteValue(e, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={LABEL.CHOOSE_TERMINAL}
                fullWidth
                error={terminalCodeError}
                helperText={terminalCodeError ? VALIDATION.TERMINAL_REQUIRED : ''}
              />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="inpContainerType"
            type="text"
            value={container.equipmentType}
            onChange={(e) => handleInpContainerTypeChange(e)}
            label={LABEL.CONTAINER_TYPE}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="inpEmptyReturnReference"
            label={LABEL.EMPTY_RETURN_REFERENCE}
            type="text"
            value={container.emptyReturnReference}
            onChange={(e) => handleInpEmptyReturnReference(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="inpReleaseOrderReference"
            label={LABEL.RELEASE_ORDER_REFERENCE}
            type="text"
            value={container.releaseOrderReference}
            onChange={(e) => handleInpReleaseOrderReference(e)}
            fullWidth
          />
        </Grid>
        {container.additionalDataFields.map((splittedFields) => {
          return (
            <>
              {splittedFields.map((field) => {
                if (field.code == ADDITIONALDATAFIELDS.COMMERCIAL_RELEASE_ISCARRIERHAULAGE) {
                  return (
                    <Grid item xs={8}>
                      <FormGroup style={{ margin: 'auto', position: 'relative' }}>
                        <FormControlLabel
                          style={{ position: 'absolute', top: '20px', right: '100px' }}
                          control={
                            <Checkbox
                              color="primary"
                              onChange={(e) => handleOnChange(e, field.code)}
                              checked={
                                field.value === true ||
                                field.value == 'true' ||
                                field.value == 'True'
                                  ? true
                                  : false
                              }
                            />
                          }
                          label={LABEL.CARRIER_HAULAGE}
                        />
                      </FormGroup>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={6} md={4}>
                    <TextField
                      id={'inp' + field.label.replace(/ /g, '')}
                      label={field.label.replace(/ /g, '')}
                      type="text"
                      value={field.value}
                      onChange={(e) => handleOnChange(e, field.code)}
                      fullWidth
                    />
                  </Grid>
                );
              })}
            </>
          );
        })}

        <Grid item xs={12}>
          <Button color="primary" variant="contained" onClick={(e) => checkDatesAndHandleSubmit(e)}>
            {LABEL.UPDATE}
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UpdateContainerRelease;
