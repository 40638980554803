import { mapPage } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.subscriptions, action) {
  switch (action.type) {
    case ActionTypes.SUBSCRIPTIONS_REQUESTED: {
      return {
        ...state,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.POLICE_SUBSCRIPTIONS_REQUESTED: {
      return {
        ...state,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.SUBSCRIPTION_ADD: {
      return {
        ...state,
        loadingNew: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.SUBSCRIPTION_DELETE: {
      return {
        ...state,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.SUBSCRIPTIONS_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        loadingNew: false,
        items: action.payload.result,
        paging: mapPage(action.payload),
      };
    }
    case ActionTypes.POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        loadingNew: false,
        items: action.payload.items,
        paging: mapPage(action.payload),
      };
    }
    case ActionTypes.POLICE_SUBSCRIPTIONS_REQUEST_SUCCEEDED_NO_CONTENT: {
      return {
        ...state,
        loading: false,
        loadingNew: false,
      };
    }
    default: {
      return state;
    }
  }
}
