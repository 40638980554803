import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { maxNumberOfContainersProcessed } from '../../constants';
import useStyles from '../shared/styles';

type Props = {
  numberOfContainers: number;
  numberOfContainersSelected: number;
};

const FilterStep: React.FC<Props> = ({ numberOfContainers, numberOfContainersSelected }: Props) => {
  const { t } = useTranslation(['declineReleaseRight']);
  const classes = useStyles();

  const moreThanXSelected = numberOfContainersSelected > maxNumberOfContainersProcessed;

  return (
    <div>
      <Typography className={classes.subText}>
        {t('containers.searchReturned', { numberOfContainers })}
      </Typography>
      {moreThanXSelected && (
        <Alert className={classes.subText} severity="warning">
          {t('containers.moreThanXSelected', {
            maxNumber: maxNumberOfContainersProcessed,
            numberOfContainersSelected,
          })}
        </Alert>
      )}
    </div>
  );
};
export default FilterStep;
