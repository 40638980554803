import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function CollapsibleRow(props) {
  const { row, title } = props;
  const [open, setOpen] = React.useState(false);

  const numberOfKeys = Object.keys(row).length;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {Object.entries(row).map(([key, value], index) => {
          return Array.isArray(value) == true ? (
            ''
          ) : index == 0 ? (
            <TableCell component="th" scope="row">
              {value}
            </TableCell>
          ) : (
            <TableCell align="right">{value}</TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={numberOfKeys}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, padding: '10px' }}>
              <Typography variant="h6" gutterBottom component="div">
                {title}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {row.items &&
                      Object.keys(row.items[1]).map((key, index) => {
                        return index == 0 ? (
                          <TableCell component="th" scope="row">
                            {key.toUpperCase()}
                          </TableCell>
                        ) : (
                          <TableCell align="right">{key.toUpperCase()}</TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items &&
                    row.items.map((item) => (
                      <TableRow>
                        {Object.entries(item).map(([key, value], index) => {
                          return index == 0 ? (
                            <TableCell component="th" scope="row">
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell align="right">{value}</TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CollapsibleRow;
