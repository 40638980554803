import React, { useEffect } from 'react';
import EditableList from '../../molecules/EditableList';
import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { VALIDATION, LABEL } from '../../../theme/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';

function CreateContainerRelease(props) {
  const {
    classes,
    container,
    terminals,
    handleInpContainerNumberChange,
    handleInpReleaseIdentification,
    handleInpBillOfLadingNumbersChange,
    handleInpContainerTypeChange,
    setAutoCompleteValue,
    handleInpReleaseValidFromChange,
    handleInpReleaseValidUntilChange,
    handleInpEmptyReturnReference,
    handleInpReleaseOrderReference,
    handleInpEquipmentSupplier,
    handleInpEquipmentStatus,
    handleInpFullEmptyIndicator,
    handleInpEquipmentOperator,
    handleInpVesselCallSign,
    handleInpVesselName,
    handleInpVoyageNumber,
    handleInpImoNumber,
    handleInpStayNumber,
    handleInpIsCarrierHaulage,
    handleSubmit,
    equipmentNumberError,
    releaseIdentificationError,
    billOfLadingNumbersError,
    terminalCodeError,
    releaseDateTimeUtcError,
    expirationDateTimeUtcError,
  } = props;

  useEffect(() => {
    terminals.sort((a, b) => (a.name > b.name ? 1 : -1));
    // to be discussed uniqueTerminals = [...new Map(terminals.map(item => [item['Name'], item])).values()];
    // to be discussed  console.log(uniqueTerminals)
  }, []);

  const checkDatesAndHandleSubmit = (event) => {
    if (container.releaseDateTimeUtc == '') {
      container.releaseDateTimeUtc = null;
    }

    if (container.expirationDateTimeUtc == '') {
      container.expirationDateTimeUtc = null;
    }
    handleSubmit(event);
  };

  return (
    <>
      <Typography variant={'h5'}>{LABEL.COMMERCIAL_RELEASE_CREATE_TITLE}</Typography>
      <Divider />
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={6}>
          <TextField
            id="inpContainerNumber"
            error={equipmentNumberError}
            helperText={equipmentNumberError ? VALIDATION.EQUIPMENT_NUMBER_LENGTH : ''}
            type="text"
            label={LABEL.CONTAINER_NUMBER}
            value={container.equipmentNumber}
            onChange={(e) => handleInpContainerNumberChange(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="inpReleaseIdentification"
            error={releaseIdentificationError}
            helperText={releaseIdentificationError ? VALIDATION.MIN_LENGTH_3 : ''}
            type="text"
            value={container.releaseIdentification}
            onChange={(e) => handleInpReleaseIdentification(e)}
            label={LABEL.RELEASE_IDENTIFICATION}
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={classes.formTitle}
            variant="subtitle1"
            color={billOfLadingNumbersError ? 'error' : 'initial'}
          >
            {LABEL.BL_NUMBERS}
          </Typography>
          <EditableList
            headers={[LABEL.NUMBER]}
            editable
            newItem={{ blNumber: undefined }}
            addLabel={LABEL.ADD_BL_NUMBER}
            settings={{
              blNumber: {
                type: 'text',
              },
            }}
            onChange={(blNumbers) => handleInpBillOfLadingNumbersChange(blNumbers)}
            inputProps={{ maxLength: 100 }}
          />
          {billOfLadingNumbersError && (
            <Typography variant="caption" color={'error'}>
              {VALIDATION.ADD_VALID_BL_NUMBERS}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpValidFromDate"
            error={releaseDateTimeUtcError}
            label={LABEL.RELEASE_VALID_FROM}
            helperText={releaseDateTimeUtcError ? VALIDATION.REL_DATE_MUST_BE_BEFORE_EXP_DATE : ''}
            type="date"
            onChange={(e) => handleInpReleaseValidFromChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpValidUntilDate"
            label={LABEL.RELEASE_VALID_UNTIL}
            error={expirationDateTimeUtcError}
            helperText={expirationDateTimeUtcError ? VALIDATION.EXP_DATE_MUST_BE_GREATER_THAN : ''}
            type="date"
            value={container.expirationDateTimeUtc}
            onChange={(e) => handleInpReleaseValidUntilChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="inpTerminalCode"
            options={terminals}
            getOptionLabel={(terminal) => terminal.name + ' (' + terminal.code + ')'}
            value={terminals.find((t) => t.code == container.terminalCode)}
            onChange={(e, value) => setAutoCompleteValue(e, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={LABEL.CHOOSE_TERMINAL}
                fullWidth
                error={terminalCodeError}
                helperText={terminalCodeError ? VALIDATION.TERMINAL_REQUIRED : ''}
              />
            )}
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpContainerType"
            type="text"
            value={container.equipmentType}
            onChange={(e) => handleInpContainerTypeChange(e)}
            label={LABEL.CONTAINER_TYPE}
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpEmptyReturnReference"
            label={LABEL.EMPTY_RETURN_REFERENCE}
            type="text"
            value={container.emptyReturnReference}
            onChange={(e) => handleInpEmptyReturnReference(e)}
            fullWidth
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpReleaseOrderReference"
            label={LABEL.RELEASE_ORDER_REFERENCE}
            type="text"
            value={container.releaseOrderReference}
            onChange={(e) => handleInpReleaseOrderReference(e)}
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpEquipmentSupplier"
            label={LABEL.EQUIPMENT_SUPPLIER}
            type="text"
            value={container.equipmentSupplier}
            onChange={(e) => handleInpEquipmentSupplier(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpEquipmentStatus"
            label={LABEL.EQUIPMENT_STATUS}
            type="text"
            value={container.equipmentStatus}
            onChange={(e) => handleInpEquipmentStatus(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <React.Fragment>
            <InputLabel id="inpFullEmptyIndicator">{LABEL.FULLEMPTYINDICATOR}</InputLabel>
            <Select
              labelId="inpFullEmptyIndicator"
              value={container.fullEmptyIndicator}
              onChange={(e) => handleInpFullEmptyIndicator(e)}
            >
              <MenuItem value={LABEL.FULLEMPTYINDICATOR_FULL}>
                {LABEL.FULLEMPTYINDICATOR_FULL}
              </MenuItem>
              <MenuItem value={LABEL.FULLEMPTYINDICATOR_EMPTY}>
                {LABEL.FULLEMPTYINDICATOR_EMPTY}
              </MenuItem>
              <MenuItem value={LABEL.FULLEMPTYINDICATOR_UNKNOWN}>
                {LABEL.FULLEMPTYINDICATOR_UNKNOWN}
              </MenuItem>
            </Select>
          </React.Fragment>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpEquipmentOperator"
            label={LABEL.EQUIPMENT_OPERATOR}
            type="text"
            value={container.equipmentOperator}
            onChange={(e) => handleInpEquipmentOperator(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpVesselCallSign"
            label={LABEL.VESSEL_CALLSIGN}
            type="text"
            value={container.vesselCallSign}
            onChange={(e) => handleInpVesselCallSign(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpVesselName"
            label={LABEL.VESSEL_NAME}
            type="text"
            value={container.vesselName}
            onChange={(e) => handleInpVesselName(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpVoyageNumber"
            label={LABEL.VOYAGE_NUMBER}
            type="text"
            value={container.voyageNumber}
            onChange={(e) => handleInpVoyageNumber(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpStayNumber"
            label={LABEL.STAY_NUMBER}
            type="text"
            value={container.stayNumber}
            onChange={(e) => handleInpStayNumber(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="inpImoNumber"
            label={LABEL.IMO_NUMBER}
            type="text"
            value={container.imoNumber}
            onChange={(e) => handleInpImoNumber(e)}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="primary" onChange={(e) => handleInpIsCarrierHaulage(e)} />}
              label={LABEL.CARRIER_HAULAGE}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup style={{ margin: 'auto', position: 'relative' }}>
            <FormControlLabel
              style={{ position: 'absolute', top: '10px', right: '10px' }}
              control={
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => checkDatesAndHandleSubmit(e)}
                >
                  {LABEL.CREATE}
                </Button>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateContainerRelease;
