import { Grid, Typography } from '@material-ui/core';
import React, { ErrorInfo } from 'react';
import { ERROR } from '../../../../theme/constants';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

type State = {
  hasError: boolean;
};

/**
 * Error boundary component that catches React errors (during rendering, in lifecycle methods, and in constructors of the whole tree below them)
 * and displays a more user-friendly message to the user opposed to a white screen.
 *
 * Can be used to wrap any component (and as many) within the application.
 *
 * More info: https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // Uses regular static const label in case i18n fails to load
      return (
        <Grid spacing={4} container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs sm>
            <Typography>{ERROR.GENERAL_TEXT}</Typography>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
