import { Box, Typography } from '@material-ui/core';
import useStyles from './style';

type Props = {
  title: string;
  subTitle?: string;
};

const EmptyState = ({ title, subTitle }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
    </Box>
  );
};
export default EmptyState;
