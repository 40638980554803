import { GetContainersByTerminalAndReleaseRightParams } from 'modules/global/adapters/crpAPI';

export const acceptReleaseRightKeys = {
  root: ['accept-releaseright'] as const,
  containersForUpdateRoot: () => [...acceptReleaseRightKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (terminalParams: GetContainersByTerminalAndReleaseRightParams) =>
    [...acceptReleaseRightKeys.containersForUpdateRoot(), terminalParams] as const,
  postContainersAccept: () =>
    [...acceptReleaseRightKeys.root, 'post-containers-accept-releaseright'] as const,
  postContainersAssign: () => [...acceptReleaseRightKeys.root, 'post-containers-assign'] as const,
  getTerminals: () => [...acceptReleaseRightKeys.root, 'get-terminals'] as const,
  getGreenLightValues: () => [...acceptReleaseRightKeys.root, 'get-greenlight-values'] as const,
  getContainerDetail: (id: string) =>
    [...acceptReleaseRightKeys.root, 'get-container-detail', id] as const,
  getPendingContainersForReleaseRightAction: () => [
    ...acceptReleaseRightKeys.containersForUpdateRoot(),
    'get-pending-containers',
  ],
};
