import { Paper } from '@material-ui/core';
import { Row } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  GetContainersByVoyageParams,
  ReleaseParty,
} from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { usePostContainersUpdateReleaseParty } from '../../adapters/assign-release-party/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from '../../constants';
import { FilterStep, ResultStep, VesselSearchStep } from '../shared';
import UpdateStep from './UpdateStep';

export const identificationType: string[] = ['NxtEntityId', 'Tin', 'Duns', 'SCAC', 'Eori', 'Apcs'];
export const defaultType = identificationType[0];

type Props = {
  data: Container[] | undefined;
  errors: FormikErrors<GetContainersByVoyageParams>;
  isFetching?: boolean;
  selectedRows: Row<Container>[] | null;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByVoyageParams;
  selectedUserType: any;
};

const StepperAssignReleaseParty = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
  selectedUserType,
}: Props) => {
  const { t } = useTranslation(['assignReleaseParty', 'common', 'useCase', 'validation']);
  const [selectedReleaseParty, setSelectedReleaseParty] = React.useState<ReleaseParty | undefined>(
    undefined
  );

  const [selectedIdentification, setSelectedIdentification] = React.useState<
    | {
        type: string;
        value: string;
      }
    | undefined
  >({ type: defaultType || '', value: '' });

  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const vesselNameError = Boolean(errors.vesselName);
  const voyageNumberError = Boolean(errors.voyageNumber);
  const imoNumberError = Boolean(errors.imoNumber);
  const stayNumberError = Boolean(errors.stayNumber);

  const selectedNewRelease: any = selectedIdentification?.value
    ? selectedIdentification
    : selectedReleaseParty;

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const { mutate, status } = usePostContainersUpdateReleaseParty();

  const assignReleaseParty = () => {
    setContainersUpdated(numberOfContainersProcessed);

    if (selectedNewRelease?.nxtEntityId) {
      mutate({
        identificationCode: selectedReleaseParty?.nxtEntityId,
        identificationType: 'NxtEntityId',
        ids: listOfCheckedContainers,
      });
    } else if (selectedNewRelease?.value) {
      mutate({
        identificationCode: selectedNewRelease.value,
        identificationType: selectedNewRelease.type,
        ids: listOfCheckedContainers,
      });
    }
  };

  const resetFormAndReleaseParty = () => {
    setSelectedReleaseParty(undefined);
    resetForm();
    setContainersUpdated(0);
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.ASSIGN_RELEASE_PARTY}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.voyageSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              vesselNameError ||
              voyageNumberError ||
              imoNumberError ||
              stayNumberError ||
              (values.vesselName === '' &&
                values.voyageNumber === '' &&
                values.imoNumber === '' &&
                values.stayNumber === ''),
            content: <VesselSearchStep />,
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t('actions.selectReleaseParty'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('common:general.performUpdate'),
            stepFlavorText: t('useCase:containers.performUpdateFlavorText'),
            nextCustomText: t('useCase:containers.updateContainers'),
            disableNext: !selectedNewRelease,
            nextCallBack: assignReleaseParty,
            content: (
              <UpdateStep
                setSelectedReleaseParty={setSelectedReleaseParty}
                selectedReleaseParty={selectedReleaseParty}
                selectedIdentification={selectedIdentification}
                setSelectedIdentification={setSelectedIdentification}
                selectedNewRelease={selectedNewRelease}
                selectedUserType={selectedUserType}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                setActiveStep={setActiveStep}
                retryFunction={assignReleaseParty}
                setDisplayGrid={setDisplayGrid}
                numberOfContainersUpdated={containersUpdated}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                  newReleaseParty: selectedReleaseParty?.name,
                })}
                callBackOnReturnToSearch={resetFormAndReleaseParty}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
export default StepperAssignReleaseParty;
