import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { RowModel, RowSelectionState } from '@tanstack/react-table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReleaseParty } from '../../../global/adapters/crpAPI';
import { DataGrid } from '../../../global/components';
import { useGetRelevantParties } from '../../adapters/assign-release-party/queries';
import { releasePartyColumns } from '../../constants/shared/releasePartyColumns';

type Props = {
  selectedNewRelease: any;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedReleaseParty: ReleaseParty | undefined;
  setSelectedReleaseParty: React.Dispatch<React.SetStateAction<ReleaseParty | undefined>>;
  selectedIdentification: { type: string; value: string } | undefined;
  setSelectedIdentification: React.Dispatch<
    React.SetStateAction<
      | {
          type: string;
          value: string;
        }
      | undefined
    >
  >;
  selectedUserType: any;
};

const ReleasePartyModal = ({
  selectedNewRelease,
  setModalOpen,
  selectedReleaseParty,
  setSelectedReleaseParty,
  selectedIdentification,
  setSelectedIdentification,
  selectedUserType,
}: Props) => {
  const { t } = useTranslation(['common', 'assignReleaseParty']);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<ReleaseParty>) | null>(null);

  const { data: releaseParties, isLoading } = useGetRelevantParties(selectedUserType);

  const handleCellClick = (rowData: ReleaseParty) => {
    setSelectedReleaseParty(rowData);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setSelectedIdentification({ type: 'NxtEntityId', value: '' });
    setSelectedReleaseParty(undefined);
  };

  React.useEffect(() => {
    setRowSelection({});
  }, [selectedIdentification?.value]);

  return (
    <React.Fragment>
      <DialogTitle>
        {t('assignReleaseParty:selectParty.title')}
        <Typography>{t('assignReleaseParty:selectParty.subtitle')}</Typography>
      </DialogTitle>

      <DialogContent>
        <DataGrid.Client
          columns={releasePartyColumns}
          customTableProps={{ lowerHeight: true, highlightSelectedCell: true }}
          data={releaseParties}
          getRowSelection={getRowSelection}
          isLoading={isLoading}
          onCellClick={handleCellClick}
          onRowSelectionChange={setRowSelection}
          rowSelection={rowSelection}
          enableMultiRowSelect={false}
          enableColumnResizing={false}
        />
      </DialogContent>
      <DialogActions>
        <Typography style={{ left: 0, position: 'absolute', paddingBottom: 5, paddingLeft: 10 }}>
          {(selectedNewRelease?.nxtEntityId != null || selectedNewRelease?.value != null) && (
            <>
              {t('assignReleaseParty:selectParty.haveSelected')}
              <Typography color="primary" display="inline">
                {(selectedNewRelease?.name || selectedNewRelease?.name) +
                  ' (' +
                  (selectedNewRelease?.nxtEntityId || selectedNewRelease?.value) +
                  ')'}
              </Typography>
            </>
          )}
        </Typography>

        <Button onClick={handleCancel} color="default">
          {t('general.cancel')}
        </Button>
        <Button onClick={() => setModalOpen(false)} color="primary" disabled={!selectedNewRelease}>
          {t('general.apply')}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
export default ReleasePartyModal;
