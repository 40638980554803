import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import { DIALOG } from '../../theme/constants';

const ApiErrors = ({ open, onClose, errorResponse }) => {
  const error = errorResponse;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="request-errors">
      <DialogTitle id="request-errors">{DIALOG.GENERAL_ERROR_TITLE}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" gutterBottom>
          <List dense>
            {error !== null && errorResponse.title && (
              <ListItem>
                <ListItemText primary={errorResponse.title} />
              </ListItem>
            )}
            {error !== null && !errorResponse.title && errorResponse.message && (
              <ListItem>
                <ListItemText
                  primary={errorResponse.message}
                  style={{ color: 'red', fontWeight: 'bold' }}
                />
              </ListItem>
            )}
          </List>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApiErrors.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
};

export default withMobileDialog()(ApiErrors);
