import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getStatus } from '../../utils/role';
import ActionTypes from '../actions/actionTypes';
import {
  revokeContainersSucceeded,
  loadContainers,
  loadContainersSucceeded,
  loadContainerSucceeded,
  loadEventHistorySucceeded,
} from '../actions/containerActions';
import { loadReleases } from '../actions/releaseActions';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import {
  getContainers,
  getContainer,
  getEventHistory,
  putBlockContainers,
  putReleaseContainers,
  putSelectContainersForScan,
  submitReleaseRightAction,
} from '../api/containersApi';
import {
  getContainersFilters,
  getContainersPaging,
  getSelectedUserType,
  getUserProfile,
  getSelectedContainers,
} from '../selectors/selectors';
import { needsRefresh } from '../../utils/mapper';

function getContainersQuery(filters, paging) {
  return {
    equipmentnumberfilter: filters.equipmentNumber || undefined,
    billofladingnumberfilter: filters.billOfLading || undefined,
    shippingagentnamefilter: filters.shippingAgent || undefined,
    currentstakeholdernamefilter: filters.receivedFrom || undefined,
    terminalnamefilter: filters.terminal || undefined,
    releaserightstatusfilter: filters.status || undefined,
    actionStatusFilter: filters.actionStatus || undefined,
    page: paging.page + 1 || undefined,
    pageSize: paging.rowsPerPage || undefined,
    status: filters.userRoleStatus || undefined,
  };
}

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function hasActiveFilters(filters) {
  if (
    (filters.equipmentNumber !== '' ||
      filters.billOfLading !== '' ||
      filters.shippingAgent !== '' ||
      filters.receivedFrom !== '' ||
      filters.terminal !== '' ||
      filters.status !== '') &&
    filters.userRoleStatus !== ''
  ) {
    return true;
  }
  return false;
}

function* loadAllContainers(action) {
  try {
    let paging = yield select(getContainersPaging);
    let filters = yield select(getContainersFilters);
    let actor = yield select(getUserProfile);
    let selectedRole = yield select(getSelectedUserType);
    if (action.payload.filters) {
      filters = action.payload.filters;
    }
    let activeFilter = hasActiveFilters(filters);
    if (activeFilter) {
      paging.page = 0;
    }
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }

    filters.userRoleStatus = getStatus(selectedRole);

    const containers = yield call(getContainers, {
      query: getContainersQuery(filters, paging),
      nxtEntityId: actor.nxtEntityId,
    });
    yield put(loadContainersSucceeded(containers));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* loadContainer(action) {
  try {
    const container = yield call(getContainer, action.payload);
    yield put(loadContainerSucceeded(container));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* loadEventDetails(action) {
  try {
    const eventHistory = yield call(getEventHistory, action.payload);
    yield put(loadEventHistorySucceeded(eventHistory));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* acceptAllReleases(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(submitReleaseRightAction, {
      containers: action.payload.containers,
      nxtEntityId: actor.nxtEntityId,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* declineAllReleases(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(submitReleaseRightAction, {
      containers: action.payload.containers,
      nxtEntityId: actor.nxtEntityId,
      actionType: action.payload.actionType,
      reason: action.payload.reason,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* revokeAllContainers(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(submitReleaseRightAction, {
      containers: action.payload.containers,
      nxtEntityId: actor.nxtEntityId,
      actionType: action.payload.actionType,
      reason: action.payload.reason,
    });
    yield put(revokeContainersSucceeded());
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* forwardAllContainers(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(submitReleaseRightAction, {
      containers: action.payload.containers,
      selectedCompany: action.payload.selectedCompany,
      taxIdentificationNumber: action.payload.taxIdentificationNumber,
      nxtEntityId: actor.nxtEntityId,
      actionType: action.payload.actionType,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* selectAllContainersForScan(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(putSelectContainersForScan, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
      nxtEntityId: actor.nxtEntityId,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* releaseAllContainers(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(putReleaseContainers, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
      nxtEntityId: actor.nxtEntityId,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* blockAllContainers(action) {
  try {
    let actor = yield select(getUserProfile);
    yield call(putBlockContainers, {
      containers: action.payload.containers,
      actionType: action.payload.actionType,
      nxtEntityId: actor.nxtEntityId,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* refreshAllContainers(action) {
  let selectedContainers = yield select(getSelectedContainers);
  let doRefresh = selectedContainers.every((container) => {
    needsRefresh(Date.now(), container.equipmentNumber);
  });
  if (doRefresh) {
    if (action.payload.isReleaseItem) {
      yield put(loadReleases(false));
      yield put(loadContainers(false));
    } else {
      yield put(loadContainers(false));
    }
  }
}

function* containerSaga() {
  yield takeEvery(ActionTypes.CONTAINERS_REQUESTED, loadAllContainers);
  yield takeEvery(ActionTypes.CONTAINER_REQUESTED, loadContainer);
  yield takeLatest(ActionTypes.EVENT_HISTORY_REQUESTED, loadEventDetails);
  yield takeEvery(ActionTypes.CONTAINERS_REVOKE, revokeAllContainers);
  yield takeEvery(ActionTypes.CONTAINERS_FORWARD, forwardAllContainers);
  yield takeEvery(ActionTypes.CONTAINERS_RELEASE, releaseAllContainers);
  yield takeEvery(ActionTypes.RELEASES_ACCEPT, acceptAllReleases);
  yield takeEvery(ActionTypes.RELEASES_DECLINE, declineAllReleases);
  yield takeEvery(ActionTypes.CONTAINERS_BLOCK, blockAllContainers);
  yield takeEvery(ActionTypes.CONTAINERS_SELECT_FOR_SCAN, selectAllContainersForScan);
  yield takeEvery(ActionTypes.REFRESH_CONTAINERS, refreshAllContainers);
}

export default containerSaga;
