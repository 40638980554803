import { Box, Grid } from '@material-ui/core';
import { ReleaseParty } from 'modules/global/adapters/crpAPI';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../shared/styles';
import { IUploadReleaseRightsFileRequestUi } from 'modules/global/adapters/crpAPI';

type Props = {
  values: IUploadReleaseRightsFileRequestUi;
  selectedReleaseParty: ReleaseParty;
};

const UploadCsvStep = ({ values, selectedReleaseParty }: Props) => {
  const { t } = useTranslation(['uploadcsv']);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box style={{ paddingLeft: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {t('uploadcsv:uploadStep.labelReadyToUpload')} {values.file?.name}
          </Grid>
          {values.file?.type !== 'text/csv' && (
            <Grid item xs={12} sm={12} style={{ color: 'red' }}>
              {t('uploadcsv:uploadStep.labelWarningNotCsvFile')}
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            {t('uploadcsv:uploadStep.labelSelectedReleaseParty')}{' '}
            {selectedReleaseParty == null
              ? t('uploadcsv:uploadStep.labelSelectedReleasePartyNone')
              : selectedReleaseParty.name + '(' + selectedReleaseParty.nxtEntityId + ')'}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default UploadCsvStep;
