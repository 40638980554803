import {
  FormControlLabel,
  Checkbox,
  Paper,
  Toolbar,
  Typography,
  Box,
  TextField,
  Collapse,
  Divider,
  FormGroup,
  Grid,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditableList from '../molecules/EditableList';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { COLORS, LABEL, ALERT, FORMATTING, ADDITIONALDATAFIELDS } from '../../theme/constants';
import * as moment from 'moment';
import LensIcon from '@material-ui/icons/Lens';
import { StyledTableRow } from '../../theme/theme';
import { getEventHistory } from '../../store/selectors/selectors';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  toolbar: {
    paddingRight: theme.spacing(1),
    minHeight: theme.spacing(10),
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
  },
  paperContent: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  iconText: {
    margin: '0px',
    marginTop: '5px',
    fontSize: '11px',
    color: COLORS.GREY,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  iconTextValue: {
    margin: '0px',
    fontSize: '11px',
    color: COLORS.GREY,
    textAlign: 'center',
  },
  withTopMargin: {
    marginTop: '8px !important',
  },
  lightsBar: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  alertBox: {
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
});

const ContainerDetail = (props) => {
  const { classes, container, eventHistory, selectedUserType, loadEventsAction } = props;

  const [openAlert, setOpenAlert] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [eventHistoryResult, setEventHistoryResult] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const mapLightColor = (color) => {
    if (color === 'Green') return '#4AB55F'; //green
    if (color === 'Red') return '#B54A56'; //red
    if (color === 'Orange') return '#F2A33A'; //orange
    if (color === 'Grey') return '#AAAAAA'; //grey
    if (color === 'Yellow') return '#F2A33A'; //yellow
  };

  const showLightIcon = (light) => {
    return (
      <div className={classes.icons}>
        <LensIcon fontSize={'default'} htmlColor={mapLightColor(light.color)} />
        <p className={classes.iconText}>{light.label}</p>
        <p className={classes.iconTextValue}>{light.greenLightValue}</p>
        <p className={`${classes.iconTextValue} ${classes.withTopMargin}`}>
          {light.dateLastUpdated != undefined && light.dateLastUpdated != null
            ? LABEL.LIGHT_LAST_UPDATED.replace(
                FORMATTING.LIGHT_LASTUPDATED,
                moment(light.dateLastUpdated).format('DD/MM/YYYY HH:mm')
              )
            : ''}
        </p>
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (eventHistory.result != null) {
      setEventHistoryResult(eventHistory.result);
      setLoading(false);
    }
  }, [eventHistory.result]);

  const handleChange = (e, newValue) => {
    e.stopPropagation();
    setSelectedTab(newValue);
    if (loadEventsAction != null && newValue == 1) {
      setLoading(true);
      loadEventsAction();
    }
  };

  const renderValue = (field) => {
    if (field.code == ADDITIONALDATAFIELDS.COREOR_FULLEMPTYINDICATOR) {
      return Boolean(field.value) ? field.value : LABEL.FULLEMPTYINDICATOR_UNKNOWN;
    }
    return field.value;
  };

  return (
    <Paper>
      <div>
        <div style={{ padding: '24px' }}>
          <Tabs
            value={selectedTab}
            onChange={(e, value) => handleChange(e, value)}
            aria-label="Vertical tabs example"
            indicatorColor="primary"
          >
            <Tab label={LABEL.GENERAL} {...a11yProps(0)} />
            <Tab label={LABEL.EVENT_HISTORY} {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={selectedTab} index={0}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                {container.equipmentNumber}
              </Typography>
            </div>
            <div className={classes.spacer} />
          </Toolbar>
          <div className={classes.alertBox}>
            <Collapse in={openAlert}>
              <Alert
                severity="info"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {ALERT.CONTAINER_DETAIL_LIGHT_CHANGES}
              </Alert>
            </Collapse>
          </div>
          <div className={classes.paperContent}>
            <Box className={classes.lightsBar}>
              {container.lights.map((light, index) => {
                return (
                  <Box pl={0.5} pr={0.5} key={index}>
                    {showLightIcon(light)}
                  </Box>
                );
              })}
            </Box>
            <Divider className={classes.divider} />
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.EQUIPMENT_NUMBER}
                  value={container.equipmentNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.RELEASE_IDENTIFICATION}
                  value={container.releaseIdentification}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.RELEASE_ORDER_REFERENCE}
                  value={container.releaseOrderReference}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.EMPTY_RETURN_REFERENCE}
                  value={container.emptyReturnReference}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <EditableList
                  headers={[LABEL.BL_NUMBERS]}
                  newItem={{ blNumber: undefined }}
                  addLabel={LABEL.ADD_BL_NUMBER}
                  settings={{
                    blNumber: {
                      type: 'text',
                    },
                  }}
                  items={container.billOfLadingNumbers.map((blNumber) => ({ blNumber }))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.SHIPPING_AGENT}
                  value={container.shippingAgentName}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.PORT_CODE}
                  value={container.portLoCode}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.TERMINAL_NAME}
                  value={container.terminalName}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.TERMINAL_CODE}
                  value={container.terminalCode}
                  disabled
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={5}>
              {selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    label={LABEL.ALFAPASS_NUMBER_MASK_LABEL}
                    value={container.alfapassNumberMask}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.DRIVER_DESCRIPTION_LABEL}
                  value={container.alias}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  label={LABEL.EQUIPMENT_TYPE}
                  value={container.equipmentType}
                  disabled
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <FormGroup>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    label={LABEL.RELEASE_VALID_FROM}
                    value={moment(container.releaseDateTimeUtc).local().format('DD/MM/YYYY')}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    label={LABEL.RELEASE_VALID_UNTIL}
                    value={
                      container.expirationDateTimeUtc != null
                        ? moment(container.expirationDateTimeUtc).local().format('DD/MM/YYYY')
                        : ''
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            </FormGroup>
            {container.additionalDataFields &&
              container.additionalDataFields.length > 0 &&
              container.additionalDataFields.map((splittedFields, index) => {
                return (
                  <>
                    <Divider className={classes.divider} />
                    <Grid container spacing={5}>
                      {splittedFields &&
                        splittedFields.length > 0 &&
                        splittedFields.map((field, index) => {
                          if (
                            field.code == ADDITIONALDATAFIELDS.COMMERCIAL_RELEASE_ISCARRIERHAULAGE
                          ) {
                            return (
                              <Grid item xs={6} md={3}>
                                <FormGroup style={{ margin: 'auto', position: 'relative' }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        color="primary"
                                        checked={
                                          field.value === true ||
                                          field.value == 'true' ||
                                          field.value == 'True'
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={LABEL.CARRIER_HAULAGE}
                                  />
                                </FormGroup>
                              </Grid>
                            );
                          }
                          return (
                            <Grid item xs={6} md={3}>
                              <TextField
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type="text"
                                label={field.label}
                                value={renderValue(field)}
                                disabled
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                );
              })}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Event History
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{LABEL.EQUIPMENT_NUMBER_LABEL}</TableCell>
                  <TableCell>{LABEL.RELEASE_IDENTIFICATION.toUpperCase()}</TableCell>
                  <TableCell>{LABEL.BILL_OF_LADING_LABEL}</TableCell>
                  <TableCell>{LABEL.EVENT}</TableCell>
                  <TableCell>{LABEL.DESCRIPTION.toUpperCase()}</TableCell>
                  <TableCell>{LABEL.TIMESTAMP.toUpperCase()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <div style={{ margin: '20px', alignItems: 'center' }}>
                    <CircularProgress size="20px" />
                  </div>
                )}
                {!loading && eventHistoryResult.length == 0 && (
                  <div style={{ margin: '20px', alignItems: 'center' }}>
                    {LABEL.EVENT_NOHISTORY}
                  </div>
                )}
                {!loading &&
                  eventHistoryResult.map((event) => {
                    return (
                      <StyledTableRow tabIndex={-1} key={event.id} className={classes.tableRow}>
                        <TableCell>{event.equipmentNumber} </TableCell>
                        <TableCell>{event.releaseIdentification}</TableCell>
                        <TableCell>{event.billOfLadingNumbers}</TableCell>
                        <TableCell>{event.messageLabel}</TableCell>
                        <TableCell>{event.description}</TableCell>
                        <TableCell>
                          {moment(event.timeStamp).local().format('DD/MM/YYYY H:mm:ss')}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </TabPanel>
      </div>
    </Paper>
  );
};

ContainerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    eventHistory: getEventHistory(state),
  };
}

export default connect(mapStateToProps, null)(withStyles(styles)(ContainerDetail));
