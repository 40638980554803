import * as Yup from 'yup';

const updateExpiryDateSchema = Yup.object().shape({
  weekFilter: Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
  }),
});

export default updateExpiryDateSchema;
