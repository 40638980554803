import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.signalR, action) {
  switch (action.type) {
    case ActionTypes.SIGNALR_ID_RECEIVED: {
      return {
        id: action.payload,
        error: '',
      };
    }
    case ActionTypes.SIGNALR_SETUP_FAILED: {
      return {
        id: null,
        error: 'Couldn\'t connect to server. ',
      };
    }
    default: {
      return state;
    }
  }
}
