import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  loadPoliceSubscriptionsSucceeded,
  loadPoliceSubscriptionsSucceededButNoContent,
} from 'store/actions/subscriptionActions';
import ActionTypes from '../actions/actionTypes';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { getPoliceSubscriptions, getSubscriptions, putSubscription } from '../api/subscriptionsApi';
import { getSubscriptionsPaging } from '../selectors/selectors';

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

function* loadSubscriptions(action) {
  try {
    let paging = yield select(getSubscriptionsPaging);
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    yield call(getSubscriptions, {
      page: paging.page + 1 || undefined,
      pageSize: paging.rowsPerPage || undefined,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* loadPoliceSubscriptions(action) {
  try {
    let paging = yield select(getSubscriptionsPaging);
    if (action.payload.paging) {
      paging = { ...paging, ...action.payload.paging };
    }
    var response = yield call(getPoliceSubscriptions, {
      page: paging.page + 1 || undefined,
      pageSize: paging.rowsPerPage || undefined,
    });
    if (response != null) yield put(loadPoliceSubscriptionsSucceeded(response));
    else yield put(loadPoliceSubscriptionsSucceededButNoContent());
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* addSubscription(action) {
  try {
    yield call(putSubscription, {
      billOfLadingNumber: action.payload.billOfLadingNumber,
      equipmentNumber: action.payload.equipmentNumber,
      portLoCode: 'BEANR',
      action: 'Subscribe',
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* removeSubscription(action) {
  try {
    yield call(putSubscription, {
      billOfLadingNumber: action.payload.billOfLadingNumber,
      equipmentNumber: action.payload.equipmentNumber,
      portLoCode: 'BEANR',
      action: 'Unsubscribe',
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* subscriptionSaga() {
  yield takeEvery(ActionTypes.SUBSCRIPTIONS_REQUESTED, loadSubscriptions);
  yield takeEvery(ActionTypes.POLICE_SUBSCRIPTIONS_REQUESTED, loadPoliceSubscriptions);
  yield takeEvery(ActionTypes.SUBSCRIPTION_ADD, addSubscription);
  yield takeEvery(ActionTypes.SUBSCRIPTION_DELETE, removeSubscription);
}

export default subscriptionSaga;
