import { createColumnHelper, FilterFn, Row } from '@tanstack/react-table';
import i18n from '../../../global/config/i18n';
import { Container, ExtendedContainer } from '../../../global/adapters/crpAPI';
import { DataGrid } from '../../../global/components';
import {
  greenLightValueStyle,
  showIcon,
} from 'modules/global/constants/shared/containerOverviewColumns';

const columnHelper = createColumnHelper<Container>();
const columnHelperExtendedContainer = createColumnHelper<ExtendedContainer>();

const arrayFilter: FilterFn<Container> = (
  row: Row<Container>,
  columnId: string,
  filterValue: string
) => {
  const values: string[] = row.getValue(columnId);

  return values.some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
};

const booleanFilter: FilterFn<Container> = (
  row: Row<Container>,
  columnId: string,
  filterValue: string
) => {
  const value: boolean = row.getValue(columnId);

  if (value === false) {
    return 'empty'.includes(filterValue.toLowerCase());
  } else if (value === true) {
    return 'full'.includes(filterValue.toLowerCase());
  }

  return false;
};

export const baseShippingLineColumns = [
  columnHelper.accessor('equipmentNumber', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:containerNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('vesselName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:vesselName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('voyageNumber', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:voyageNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('imoNumber', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:imoNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('stayNumber', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:stayNumber'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('terminalName', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:terminalName'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('billOfLadingNumbers', {
    cell: (info) => info.getValue().join(', '),
    size: 200,
    header: () => i18n.t('glossary:billOfLadingNumbers'),
    filterFn: arrayFilter,
  }),
  columnHelper.accessor('releaseIdentification', {
    cell: (info) => info.getValue(),
    size: 300,
    header: () => i18n.t('glossary:releaseIdentification'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('releaseOrderReference', {
    size: 250,
    header: () => i18n.t('glossary:releaseOrderReference'),
    filterFn: 'includesString',
  }),
];

export const baseShippingLineColumnsWithStatus = [
  columnHelperExtendedContainer.accessor('status', {
    cell: ({ row }) => <div style={greenLightValueStyle}>{showIcon(row.original.status)}</div>,
    header: () => i18n.t('glossary:status'),
    enableColumnFilter: false,
    size: 60,
    enableResizing: false,
  }),
  ...baseShippingLineColumns,
];

export const baseShippingLineColumnsWithCheckbox = [
  columnHelper.display({
    id: 'actions',
    cell: (cellProps) => <DataGrid.CheckboxRow {...cellProps} />,
    header: (headerProps) => <DataGrid.CheckboxHeader {...headerProps} />,
    size: 60,
    enableResizing: false,
  }),
  ...baseShippingLineColumns,
];

export const updateExpiryDateColumns = [
  ...baseShippingLineColumnsWithCheckbox,
  columnHelper.accessor('expirationDateTimeUtc', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:expirationDateTimeUtc'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('fullEmpty', {
    cell: (info) => (Boolean(info.getValue()) ? info.getValue() : i18n.t('glossary:unknown')),
    size: 250,
    header: () => i18n.t('glossary:fullEmpty'),
    filterFn: booleanFilter,
  }),
];
