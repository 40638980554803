/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponse } from './crpAPI';

export interface BaseApiResponse {
  publicReferenceId?: string;
  externalReferenceId?: string;
}

export interface CreateDriverAlias {
  alfapassNumber?: string;
  description?: string;
}

export interface UpdateDriverAlias {
  /** @format uuid */
  driverAliasId?: string;
  description?: string;
}

export enum PartyType {
  Truck = 'Truck',
  Rail = 'Rail',
  Barge = 'Barge',
}

export interface PickupParty {
  partyType?: PartyType;
  identificationType?: string;
  identificationCode?: string;
}

export enum PickupRightActionType {
  Assign = 'Assign',
  Update = 'Update',
  Revoke = 'Revoke',
}

export interface SubmitPickupRight {
  terminalCode?: string;
  billOfLadingNumbers?: string[];
  releaseIdentification?: string;
  pickupParty?: PickupParty;

  /** @format date-time */
  timestamp?: string;
  actionType?: PickupRightActionType;
  equipmentNumber?: string;
  portLoCode?: string;
}

export interface ReleaseRightWithTerminalBulkIdentifier {
  terminalCode?: string;
  billOfLadingNumbers?: string[];
  equipmentNumber?: string;
  releaseIdentification?: string;
}

export interface BulkSubmitPickupRight {
  portLoCode?: string;
  releaseRights?: ReleaseRightWithTerminalBulkIdentifier[];
  pickupParty?: PickupParty;
  actionType?: PickupRightActionType;
}

export enum PoliceActionType {
  Block = 'Block',
  Unblock = 'Unblock',
}

export interface PoliceBlock {
  equipmentNumber?: string;
  releaseIdentification?: string;
  billOfLadingNumbers?: string[];
  policeAction?: PoliceActionType;
}

export interface CompanySubscriptionRequest {
  /** @format date-time */
  fromDate?: string;

  /** @format date-time */
  toDate?: string;
  nxtEntityId?: string;
  reference?: string;
  dossier?: string;
}

export interface CompanySubscription {
  /** @format date-time */
  fromDate?: string;

  /** @format date-time */
  toDate?: string;
  nxtEntityId?: string;
  apcs?: string;
  vat?: string;
  name?: string;

  /** @format uuid */
  externalId?: string;
}

export interface CompanySubscriptionsResponse {
  /** @format int32 */
  rows?: number;

  /** @format int32 */
  pageCount?: number;
  subscriptions?: CompanySubscription[];
  endReached?: boolean;
}

export enum ActionType {
  Transfer = 'Transfer',
  Accept = 'Accept',
  Decline = 'Decline',
  Revoke = 'Revoke',
  Release = 'Release',
  Update = 'Update',
  Block = 'Block',
  Unblock = 'Unblock',
  Delete = 'Delete',
}

export enum IdentificationType {
  NxtEntityId = 'NxtEntityId',
  Tin = 'Tin',
  Eori = 'Eori',
  Duns = 'Duns',
  SCAC = 'SCAC',
  Apcs = 'Apcs',
  ExternalId = 'ExternalId',
}

export interface Identification {
  identificationType?: IdentificationType;
  identificationCode?: string;
}

export interface CommercialReleaseAction {
  releaseIdentification?: string;
  releaseOrderReference?: string;
  emptyReturnReference?: string;
  equipmentSupplier?: string;
  equipmentStatus?: string;
  fullEmptyIndicator?: string;
  equipmentOperator?: string;
  vesselName?: string;
  vesselCallSign?: string;
  voyageNumber?: string;
  imoNumber?: string;
  stayNumber?: string;
  equipmentType?: string;
  isCarrierHaulage?: boolean;
  billOfLadingNumbers?: string[];
  terminalCode?: string;
  actionType?: ActionType;
  carrier?: Identification;
  releaseTo?: Identification;

  /** @format date-time */
  releaseDateTimeUtc?: string;

  /** @format date-time */
  expirationDateTimeUtc?: string;
  equipmentNumber?: string;
  portLoCode?: string;
}

export interface CommercialReleaseBulkAction {
  portLoCode?: string;
  releaseRights?: ReleaseRightWithTerminalBulkIdentifier[];
  actionType?: ActionType;
}

export enum TerminalReleaseActionType {
  Release = 'Release',
  Scanning = 'Scanning',
  Block = 'Block',
}

export interface TerminalRelease {
  terminalCode?: string;
  actionType?: TerminalReleaseActionType;

  /** @format date-time */
  releaseDateTimeUtc?: string;
  equipmentNumber?: string;
  portLoCode?: string;
}

export interface ReleaseRightIdentifier {
  billOfLadingNumbers?: string[];
  equipmentNumber?: string;
  releaseIdentification?: string;
}

export interface TerminalReleaseBulkAction {
  portLoCode?: string;
  terminalCode?: string;
  actionType?: TerminalReleaseActionType;
  releaseRights?: ReleaseRightIdentifier[];
}

export interface ReleaseRightAction {
  releaseIdentification?: string;
  terminalCode?: string;
  actionType?: ActionType;
  reasonForAction?: string;
  billOfLadingNumbers?: string[];
  releaseFrom?: Identification;
  releaseTo?: Identification;
  carrier?: Identification;

  /** @format date-time */
  releaseDateTimeUtc?: string;

  /** @format date-time */
  expirationDateTimeUtc?: string;

  /** @format date-time */
  validFrom?: string;
  equipmentNumber?: string;
  portLoCode?: string;
}

export interface ReleaseRightBulkIdentifier {
  releaseFrom?: Identification;
  releaseTo?: Identification;
  terminalCode?: string;
  billOfLadingNumbers?: string[];
  equipmentNumber?: string;
  releaseIdentification?: string;
}

export interface ReleaseRightBulkAction {
  portLoCode?: string;
  releaseRights?: ReleaseRightBulkIdentifier[];
  actionType?: ActionType;
}

export enum ReleaseStatusFilter {
  Active = 'Active',
  Pending = 'Pending',
  Police = 'Police',
  Terminal = 'Terminal',
  Shipping = 'Shipping',
}

export enum ReleaseRightStatus {
  Unknown = 'Unknown',
  Assigned = 'Assigned',
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Revoked = 'Revoked',
  Archived = 'Archived',
  Expired = 'Expired',
}

export enum ActionStatusFilter {
  Undefined = 'Undefined',
  Pending = 'Pending',
  Revoke = 'Revoke',
  Transfer = 'Transfer',
}

export interface IdentificationRole {
  code?: string;
  name?: string;
  description?: string;
}

export enum SubscriptionAction {
  Subscribe = 'Subscribe',
  Unsubscribe = 'Unsubscribe',
}

export enum NotificationEvent {
  Unknown = 'Unknown',
  Release = 'Release',
  Update = 'Update',
  Delete = 'Delete',
  Block = 'Block',
  Unblock = 'Unblock',
  Revoke = 'Revoke',
  TerminalRelease = 'TerminalRelease',
  TerminalBlocked = 'TerminalBlocked',
  TerminalScan = 'TerminalScan',
  Transferred = 'Transferred',
  DeclinedByNextParty = 'DeclinedByNextParty',
  AcceptedByNextParty = 'AcceptedByNextParty',
  RevokedByPreviousParty = 'RevokedByPreviousParty',
  NotValidated = 'NotValidated',
  ThrownException = 'ThrownException',
  GetPagedContainers = 'GetPagedContainers',
  PickedUpByParty = 'PickedUpByParty',
  GetAllReleaseLights = 'GetAllReleaseLights',
  GetCombinedGreenLight = 'GetCombinedGreenLight',
  ExpiredReleaseRights = 'ExpiredReleaseRights',
  GateOut = 'GateOut',
  GetReleaseDetail = 'GetReleaseDetail',
  ReleaseLightChanged = 'ReleaseLightChanged',
  ReleaseLightRegistered = 'ReleaseLightRegistered',
}

export interface EquipmentSubscriptionAction {
  billOfLadingNumber?: string;
  action?: SubscriptionAction;
  equipmentNumber?: string;
  portLoCode?: string;
}

export interface SearchEntityFilter {
  eori?: string;
  duns?: string;
  vat?: string;
  entityName?: string;
  apcsCode?: string;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;
}

export interface EntityAddress {
  addressTypeCode?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  contactName?: string;
  contactEmail?: string;
}

export interface PhoneBookItem {
  apcsCode?: string;
  nxtEntityId?: string;
  name?: string;
  vat?: string;
  duns?: string;
  eori?: string;
  scac?: string;
  apcs?: string;
  externalId?: string;
  addresses?: EntityAddress[];
}

export interface PhoneBookResponse {
  searchFilter?: SearchEntityFilter;
  phoneBookItems?: PhoneBookItem[];

  /** @format int32 */
  totalRows?: number;

  /** @format int32 */
  totalPages?: number;
  publicReferenceId?: string;
  externalReferenceId?: string;
}

export interface TokenExchangeState {
  /** @format uuid */
  id?: string;
  nxtPortId?: string;
  terminalAck?: boolean;
  pickupPartyAck?: boolean;
}

export interface PickUpPartyIdentification {
  identificationType?: string;
  identificationCode?: string;
}

export interface NotificationRequest {
  notificationEvent?: string;
  equipmentNumber?: string;
  publicReferenceId?: string;
  releaseId?: string;
  storedOn?: string;
  timeWindowFrom?: string;
  timeWindowTo?: string;
}

export interface NotificationDetail {
  nxtEntityId: string;
  equipmentNumber: string;
  releaseId: string;
  publicReferenceId: string;
  externalReferenceId: string;
  storedOn: Date;
  message: string;
  type: string;
  event: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title NxtPort.Equipment.Import.CertifiedReleaseProject
 * @version v1
 * @baseUrl https://localhost:5010/certified-pickup/v1
 */
export class MainAPI<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  creditIdentification = {
    /**
     * No description
     *
     * @tags credit-identification
     * @name CreditIdentificationDetail
     * @request GET:/credit-identification/{nxtEntityId}
     * @secure
     */
    creditIdentificationDetail: (nxtEntityId: string, params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/credit-identification/${nxtEntityId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags credit-identification
     * @name RegisterCreate
     * @request POST:/credit-identification/register
     * @secure
     */
    registerCreate: (params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/credit-identification/register`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags credit-identification
     * @name PairCreate
     * @request POST:/credit-identification/pair
     * @secure
     */
    pairCreate: (params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/credit-identification/pair`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  driverAliases = {
    /**
     * No description
     *
     * @tags driver-alias
     * @name DriverAliasesList
     * @request GET:/DriverAliases
     * @secure
     */
    driverAliasesList: (params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/DriverAliases`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags driver-alias
     * @name DriverAliasesCreate
     * @request POST:/DriverAliases
     * @secure
     */
    driverAliasesCreate: (data?: CreateDriverAlias, params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/DriverAliases`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags driver-alias
     * @name DriverAliasesUpdate
     * @request PUT:/DriverAliases/{id}
     * @secure
     */
    driverAliasesUpdate: (id: string, data?: UpdateDriverAlias, params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/DriverAliases/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags driver-alias
     * @name DriverAliasesDelete
     * @request DELETE:/DriverAliases/{id}
     * @secure
     */
    driverAliasesDelete: (id: string, params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/DriverAliases/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  ping = {
    /**
     * No description
     *
     * @tags health
     * @name PingList
     * @request GET:/ping
     * @secure
     */
    pingList: (params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/ping`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags notifications
     * @name GetNotifications
     * @request GET:/notifications
     * @secure
     */
    getNotifications: (query?: NotificationRequest, params: RequestParams = {}) =>
      this.request<NotificationDetail[], ErrorResponse>({
        path: `/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  portLocode = {
    /**
     * No description
     *
     * @tags pickup-rights
     * @name ContainersEquipmentNumberImportPickupRightsUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/pickup-rights
     * @secure
     */
    containersEquipmentNumberImportPickupRightsUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: SubmitPickupRight,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/pickup-rights`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pickup-rights
     * @name ContainersImportPickupRightsUpdate
     * @request PUT:/{port-locode}/containers/import/pickup-rights
     * @secure
     */
    containersImportPickupRightsUpdate: (
      portLocode: string,
      data?: BulkSubmitPickupRight,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/pickup-rights`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersEquipmentNumberImportPoliceBlockUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/police-block
     * @secure
     */
    containersEquipmentNumberImportPoliceBlockUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: PoliceBlock,
      params: RequestParams = {}
    ) =>
      this.request<PoliceBlock, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/police-block`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersEquipmentNumberImportCommercialReleaseUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/commercial-release
     * @secure
     */
    containersEquipmentNumberImportCommercialReleaseUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: CommercialReleaseAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/commercial-release`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersImportCommercialReleaseUpdate
     * @request PUT:/{port-locode}/containers/import/commercial-release
     * @secure
     */
    containersImportCommercialReleaseUpdate: (
      portLocode: string,
      data?: CommercialReleaseBulkAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/commercial-release`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersEquipmentNumberImportTerminalReleaseUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/terminal-release
     * @secure
     */
    containersEquipmentNumberImportTerminalReleaseUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: TerminalRelease,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/terminal-release`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersImportTerminalReleaseUpdate
     * @request PUT:/{port-locode}/containers/import/terminal-release
     * @secure
     */
    containersImportTerminalReleaseUpdate: (
      portLocode: string,
      data?: TerminalReleaseBulkAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/terminal-release`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersEquipmentNumberImportReleaseLightsStatusList
     * @request GET:/{port-locode}/containers/{equipment-number}/import/release-lights-status
     * @secure
     */
    containersEquipmentNumberImportReleaseLightsStatusList: (
      portLocode: string,
      equipmentNumber: string,
      query?: { 'release-identification'?: string },
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/release-lights-status`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-lights
     * @name ContainersEquipmentNumberImportGreenLightStatusList
     * @request GET:/{port-locode}/containers/{equipment-number}/import/green-light-status
     * @secure
     */
    containersEquipmentNumberImportGreenLightStatusList: (
      portLocode: string,
      equipmentNumber: string,
      query?: { 'release-identification'?: string },
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/green-light-status`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-rights
     * @name ContainersEquipmentNumberImportReleaseRightsUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/release-rights
     * @secure
     */
    containersEquipmentNumberImportReleaseRightsUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: ReleaseRightAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/release-rights`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-rights
     * @name ContainersImportReleaseRightsList
     * @request GET:/{port-locode}/containers/import/release-rights
     * @secure
     */
    containersImportReleaseRightsList: (
      portLocode: string,
      query?: {
        page?: number;
        pageSize?: number;
        status?: any;
        equipmentnumberfilter?: string;
        billofladingnumberfilter?: string;
        shippingagentnamefilter?: string;
        shippingagenttinfilter?: string;
        terminalnamefilter?: string;
        currentstakeholdernamefilter?: string;
        currentstakeholdertinfilter?: string;
        releaserightstatusfilter?: any;
        actionStatusFilter?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/release-rights`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-rights
     * @name ContainersImportReleaseRightsUpdate
     * @request PUT:/{port-locode}/containers/import/release-rights
     * @secure
     */
    containersImportReleaseRightsUpdate: (
      portLocode: string,
      data?: ReleaseRightBulkAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/release-rights`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-rights
     * @name ContainersImportReleaseRightsEquipmentNumberList
     * @request GET:/{port-locode}/containers/import/release-rights/{equipment-number}
     * @secure
     */
    containersImportReleaseRightsEquipmentNumberList: (
      portLocode: string,
      equipmentNumber: string,
      query?: { billOfLadingNumbers?: string[]; releaseIdentification?: string },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/release-rights/${equipmentNumber}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags release-rights
     * @name ContainersImportReleaseRightsEquipmentNumberEventsList
     * @request GET:/{port-locode}/containers/import/release-rights/{equipment-number}/events
     * @secure
     */
    containersImportReleaseRightsEquipmentNumberEventsList: (
      portLocode: string,
      equipmentNumber: string,
      query?: { billOfLadingNumbers?: string[]; releaseIdentification?: string },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/release-rights/${equipmentNumber}/events`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name ContainersEquipmentNumberImportSubscriptionUpdate
     * @request PUT:/{port-locode}/containers/{equipment-number}/import/subscription
     * @secure
     */
    containersEquipmentNumberImportSubscriptionUpdate: (
      portLocode: string,
      equipmentNumber: string,
      data?: EquipmentSubscriptionAction,
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/${equipmentNumber}/import/subscription`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name ContainersImportSubscriptionsList
     * @request GET:/{port-locode}/containers/import/subscriptions
     * @secure
     */
    containersImportSubscriptionsList: (
      portLocode: string,
      query?: {
        page?: number;
        pageSize?: number;
        equipmentnumberfilter?: string;
        billofladingnumberfilter?: string;
        datestartfilter?: string;
        dateendfilter?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/${portLocode}/containers/import/subscriptions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  audit = {
    /**
     * No description
     *
     * @tags police-audit-release-rights
     * @name ContainersImportReleaseRightsList
     * @request GET:/audit/containers/import/release-rights
     * @secure
     */
    containersImportReleaseRightsList: (
      query?: {
        Page?: number;
        PageSize?: number;
        DateStart?: string;
        DateEnd?: string;
        EquipmentNumberFilter?: string;
        BillOfLadingNumberFilter?: string;
        ShippingAgentFilter?: string;
        TerminalCodeFilter?: string;
        AssignedToFilter?: string;
        ArchivedFilter?: boolean;
        IsBlockedFilter?: boolean;
        PoliceReferenceNumber?: string;
        PoliceCaseNumber?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/audit/containers/import/release-rights`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-audit-trail
     * @name ContainersImportTrailList
     * @request GET:/audit/containers/import​/trail
     * @secure
     */
    containersImportTrailList: (
      query?: {
        ReleaseId: string;
        PoliceReferenceNumber?: string;
        PoliceCaseNumber?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/audit/containers/import/trail`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name CompanySubscriptionCreate
     * @request POST:/audit/company/subscription
     * @secure
     */
    companySubscriptionCreate: (data?: CompanySubscriptionRequest, params: RequestParams = {}) =>
      this.request<void, ErrorResponse | void>({
        path: `/audit/company/subscription`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name CompanySubscriptionsDetail
     * @request GET:/audit/company/subscriptions/{pageCount}
     * @secure
     */
    companySubscriptionsDetail: (pageCount: number, params: RequestParams = {}) =>
      this.request<CompanySubscriptionsResponse, ErrorResponse | void>({
        path: `/audit/company/subscriptions/${pageCount}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name CompanySubscriptionDelete
     * @request DELETE:/audit/company/subscription/{externalId}
     * @secure
     */
    companySubscriptionDelete: (externalId: string, params: RequestParams = {}) =>
      this.request<void, ErrorResponse | void>({
        path: `/audit/company/subscription/${externalId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name CompanyEventsDetail
     * @request GET:/audit/company/{externalId}/events
     * @secure
     */
    companyEventsDetail: (externalId: string, params: RequestParams = {}) =>
      this.request<void, ErrorResponse | void>({
        path: `/audit/company/${externalId}/events`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  roles = {
    /**
     * No description
     *
     * @tags roles
     * @name RolesList
     * @request GET:/roles
     * @secure
     */
    rolesList: (params: RequestParams = {}) =>
      this.request<IdentificationRole[], ErrorResponse | void>({
        path: `/roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  stakeholders = {
    /**
     * No description
     *
     * @tags stakeholders
     * @name StakeholdersList
     * @request GET:/stakeholders
     * @secure
     */
    stakeholdersList: (params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/stakeholders`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stakeholders
     * @name SearchList
     * @request GET:/stakeholders/search
     * @secure
     */
    searchList: (
      query?: {
        EORI?: string;
        DUNS?: string;
        VAT?: string;
        ApcsCode?: string;
        EntityName?: string;
        Page?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/stakeholders/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  entities = {
    /**
     * No description
     *
     * @tags phone-book
     * @name EntitiesList
     * @request GET:/entities
     * @secure
     */
    entitiesList: (
      query?: {
        Eori?: string;
        Duns?: string;
        Vat?: string;
        EntityName?: string;
        ApcsCode?: string;
        Page?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PhoneBookResponse, ErrorResponse | void>({
        path: `/entities`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  terminals = {
    /**
     * No description
     *
     * @tags terminals
     * @name TerminalsList
     * @request GET:/terminals
     * @secure
     */
    terminalsList: (params: RequestParams = {}) =>
      this.request<BaseApiResponse, ErrorResponse | void>({
        path: `/terminals`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  tokenExchangeState = {
    /**
     * No description
     *
     * @tags token
     * @name NxtportTokenIdList
     * @request GET:/token-exchange-state/{nxtport-token-id}
     * @secure
     */
    nxtportTokenIdList: (nxtportTokenId: string, params: RequestParams = {}) =>
      this.request<TokenExchangeState, ErrorResponse | void>({
        path: `/token-exchange-state/${nxtportTokenId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags token
     * @name NxtportTokenIdUpdate
     * @request PUT:/token-exchange-state/{nxtport-token-id}
     * @secure
     */
    nxtportTokenIdUpdate: (
      nxtportTokenId: string,
      data?: TokenExchangeState,
      params: RequestParams = {}
    ) =>
      this.request<TokenExchangeState, ErrorResponse | void>({
        path: `/token-exchange-state/${nxtportTokenId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  broadcast = {
    /**
     * No description
     *
     * @tags token
     * @name NegotiateList
     * @request GET:/broadcast/negotiate
     * @secure
     */
    negotiateList: (params: RequestParams = {}) =>
      this.request<TokenExchangeState, ErrorResponse | void>({
        path: `/broadcast/negotiate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  validation = {
    /**
     * No description
     *
     * @tags pickup-identification-validation
     * @name PickupIdentificationCreate
     * @request POST:/validation/pickup/identification
     * @secure
     */
    pickupIdentificationCreate: (data?: PickUpPartyIdentification, params: RequestParams = {}) =>
      this.request<boolean, ErrorResponse | void>({
        path: `/validation/pickup/identification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * @description Check if alfapassnumber is valid
     *
     * @tags pickuprights
     * @name CheckAlfapassNumberIsValid
     * @summary GET Validate alfapass number
     * @request GET:/validation/alfapassNumber/{alfapassNumber}/isvalid
     * @secure
     */
    checkAlfapassNumberIsValid: (data: PickUpPartyIdentification, params: RequestParams = {}) =>
      this.request<boolean, ErrorResponse | void>({
        path: `/validation/pickup/identification`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
