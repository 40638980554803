import { takeEvery } from 'redux-saga/effects';
import { call, put } from 'redux-saga/es/internal/io';
import { ALFAPASSNUMBERSTATUSES } from '../../theme/constants';
import ActionTypes from '../actions/actionTypes';
import { alfapassNumberChecked, getAlfapassTokenSucceeded } from '../actions/alfapassActions';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';
import { getAlfapassNumberIsValid, putPickup, revokePickup } from '../api/pickUpValidationApi';
import { getAlfapassToken as getAlfapassToken } from 'store/api/alfapassApi';
import {
  pickupContainerSucceeded,
  pickupContainerRevokeSucceeded,
} from '../actions/alfapassActions';

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

export function* checkAlfapassNumber(action) {
  let status = ALFAPASSNUMBERSTATUSES.TO_BE_CHECKED;
  try {
    const body = {
      identificationType: action.payload.selectedAlfapassInput ? 'DriverAlias' : 'Alfapass',
      identificationCode: action.payload.selectedAlfapassInput
        ? action.payload.selectedAlfapassInput.externalId
        : action.payload.manualAlfapassInput,
    };

    const isValid = yield call(getAlfapassNumberIsValid, body);

    if (!isValid) {
      status = ALFAPASSNUMBERSTATUSES.INVALID;
    } else {
      status = ALFAPASSNUMBERSTATUSES.OK;
    }

    yield put(alfapassNumberChecked(status));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

export function* allContainerPickup(action) {
  try {
    yield call(putPickup, {
      containers: action.payload.containers,
      alfapassNumber: action.payload.alfapassNumber,
      selectedDriverAlias: action.payload.selectedDriverAlias,
    });
    yield put(pickupContainerSucceeded(action.payload));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

export function* allContainerPickupRevoke(action) {
  try {
    yield call(revokePickup, {
      containers: action.payload.containers,
    });
    yield put(pickupContainerRevokeSucceeded(action.payload));
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

export function* allGetToken(action) {
  try {
    const response = yield call(getAlfapassToken, action.payload);
    console.log(response);
    yield put(getAlfapassTokenSucceeded());
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* alfapassSaga() {
  yield takeEvery(ActionTypes.CHECK_ALFAPASS_NUMBER, checkAlfapassNumber);
  yield takeEvery(ActionTypes.ALFAPASS_CONTAINER_PICKUP, allContainerPickup);
  yield takeEvery(ActionTypes.CONTAINERS_PICKUP_REVOKE, allContainerPickupRevoke);
  yield takeEvery(ActionTypes.GET_ALFAPASS_TOKEN, allGetToken);
}

export default alfapassSaga;
