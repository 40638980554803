import * as Yup from 'yup';

const shippingAgentParamsSchema = Yup.object().shape(
  {
    equipmentNumber: Yup.string()
      .trim()
      .ensure()
      .when(['shippingAgentNxtEntityId'], {
        is: (shippingAgentNxtEntityId: string) => shippingAgentNxtEntityId === '',
        then: Yup.string().required(),
      }),
    shippingAgentNxtEntityId: Yup.string()
      .trim()
      .ensure()
      .when(['equipmentNumber'], {
        is: (equipmentNumber: string) => equipmentNumber === '',
        then: Yup.string().required(),
      }),
  },
  [['equipmentNumber', 'shippingAgentNxtEntityId']]
);

export default shippingAgentParamsSchema;
