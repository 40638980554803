import { mapEquipmentId, mapPage } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.archive, action) {
  switch (action.type) {
    case ActionTypes.ARCHIVE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.ARCHIVE_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        items: mapEquipmentId(action.payload.result),
        paging: mapPage(action.payload),
        filters: action.payload.filters,
      };
    }
    default: {
      return state;
    }
  }
}
