import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as PrototypeQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import showErrorMessage from '../../../shipping-line/helpers/showErrorMessage';
import { ErrorResponse } from '../../adapters/crpAPI';
import ErrorBoundary from '../ErrorBoundary';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (response) => {
      store.dispatch(errorMessage(showErrorMessage(response as { error: ErrorResponse })));
    },
  }),
  mutationCache: new MutationCache({
    onError: (response) => {
      store.dispatch(errorMessage(showErrorMessage(response as { error: ErrorResponse })));
    },
  }),
});

const QueryClientProvider: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <PrototypeQueryClientProvider client={queryClient}>
      <ErrorBoundary>{children}</ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </PrototypeQueryClientProvider>
  );
};

export default QueryClientProvider;
