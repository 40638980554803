import { call, put, select, takeEvery } from 'redux-saga/effects'
import { userIdToLabel, validRoles } from '../../utils/role'
import ActionTypes from '../actions/actionTypes'
import { apiErrorsMessage, errorMessage } from '../actions/messageActions'
import {
  loadUserProfileSucceeded,
  updateSelectedUserType,
} from '../actions/userProfileActions'
import {
  getCreditIdentificationResponse,
  registerCreditIdentification,
  pairCreditIdentification,
} from '../api/creditIdentificationApi'
import store from '../store'
import { TRANSPORTER_ROLE_ID } from '../../config/environment'
import { getUserProfile } from '../selectors/selectors'

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message)
  }
  return apiErrorsMessage(err)
}

function* loadUserProfile() {
  try {
    const oidcUserProfile = store.getState().oidc.user.profile
    const userProfile = {
      firstName: oidcUserProfile['nxt:user:firstName'],
      lastName: oidcUserProfile['nxt:user:lastName'],
      nxtEntityId: oidcUserProfile['nxt:entity:nxtentityid'],
      entityId: oidcUserProfile['nxt:entity:id'],
    }

    if (Array.isArray(oidcUserProfile['nxt:entity:roles'])) {
      userProfile.roles = oidcUserProfile['nxt:entity:roles']
        .map((r) => {
          const role = {}
          role['id'] = r
          return role
        })
        .filter(
          (role, i, arr) => arr.findIndex((t) => t.id === role.id) === i //filter out duplicate values
        )
    } else {
      const role = {}
      role['id'] = oidcUserProfile['nxt:entity:roles']
      userProfile.roles = [role]
    }

    yield put(loadUserProfileSucceeded(userProfile))
    const validUserRoles = validRoles(userProfile.roles)
    yield validUserRoles.length > 0 &&
      put(updateSelectedUserType(userIdToLabel(validUserRoles[0].id)))
  } catch (err) {
    yield put(getErrorAction(err))
  }
}

function* loadCreditIdentificationResponse() {
  try {
    const userProfile = yield select(getUserProfile)
    const hasTransporterRole = userProfile.roles.some(
      (r) => r.id == parseInt(TRANSPORTER_ROLE_ID)
    )
    if (hasTransporterRole) {
      yield call(getCreditIdentificationResponse, {
        nxtEntityId: userProfile.nxtEntityId,
      })
    }
  } catch (err) {
    yield put(getErrorAction(err))
  }
}

function* registerPaymentOption() {
  try {
    yield call(registerCreditIdentification)
  } catch (err) {
    yield put(getErrorAction(err))
  }
}

function* pairPaymentOption() {
  try {
    yield call(pairCreditIdentification)
  } catch (err) {
    yield put(getErrorAction(err))
  }
}

function* userProfileSaga() {
  yield takeEvery(ActionTypes.USER_PROFILE_REQUESTED, loadUserProfile)
  yield takeEvery(
    ActionTypes.CREDIT_IDENTIFICATION_REQUESTED,
    loadCreditIdentificationResponse
  )
  yield takeEvery(ActionTypes.REGISTER_PAYMENT_OPTION, registerPaymentOption)
  yield takeEvery(ActionTypes.PAIR_PAYMENT_OPTION, pairPaymentOption)
}

export default userProfileSaga
