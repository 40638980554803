import { Grid } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { errorMessage } from 'store/actions/messageActions';
import React, { useRef, useState } from 'react';
import store from 'store/store';
import { useTranslation } from 'react-i18next';
import { ActionTypes, IUploadReleaseRightsFileRequestUi } from '../../global/adapters/crpAPI';
import StepperCreateCommercialReleaseUploadCsv from '../components/upload-releaserights-csv/StepperCreateCommercialReleaseUploadCsv';
import { useGetActiveTerminals } from '../adapters/upload-releaserights-csv/queries';

import uploadCsvValidationSchema from '../schemas/uploadCsvValidationSchema';
import { getSelectedUserType } from 'store/selectors/selectors';
import { ConnectedProps, connect } from 'react-redux';

const initialFields: IUploadReleaseRightsFileRequestUi = {
  isTranshipment: false,
  fullEmptyIndicator: 'Full',
  vesselName: '',
  voyageNumber: '',
  isCarrierHaulage: false,
  terminalNxtEntityId: '',
  releaseDateTimeUtc: undefined,
  expirationDateTimeUtc: undefined,
  imoNumber: '',
  stayNumber: '',
  terminals: undefined,
};

type Props = React.ComponentPropsWithoutRef<'div'> & {
  commercialReleaseAction: ActionTypes;
};
interface IPropFromRedux extends PropsFromRedux {
  selectedUserType: any;
}

interface IProps extends IPropFromRedux, Props {}

const CreateCommercialReleaseUploadCsv: React.FC<IProps> = (props) => {
  const { t } = useTranslation(['common', 'validation', 'changeVoyage']);
  const formRef = useRef<FormikProps<IUploadReleaseRightsFileRequestUi>>();

  const [userType, _] = React.useState(props.selectedUserType);
  const { data, isFetching, isError } = useGetActiveTerminals();

  React.useEffect(() => {
    isError && store.dispatch(errorMessage('errorMelding'));
  }, [isError, t]);

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={12} sm={12}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<IUploadReleaseRightsFileRequestUi>>}
          onSubmit={() => {}}
          validationSchema={uploadCsvValidationSchema}
        >
          {({
            errors,
            submitForm,
            resetForm,
            values,
          }: FormikProps<IUploadReleaseRightsFileRequestUi>) => {
            return (
              <StepperCreateCommercialReleaseUploadCsv
                errors={errors}
                data={data}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
                submitForm={submitForm}
                selectedUserType={userType}
              />
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

const mapState = (state: any) => {
  return {
    selectedUserType: getSelectedUserType(state),
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateCommercialReleaseUploadCsv);
