import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import {
  GetContainersByVoyageParams,
  UpdateVoyageNumberRequest,
} from '../../../global/adapters/crpAPI';
import { updateVoyageKeys } from './queryKeys';

export const useGetContainersUpdateVoyage = (vesselParams: GetContainersByVoyageParams) => {
  const isSearchEntered =
    (vesselParams.vesselName !== undefined && vesselParams.vesselName !== '') ||
    (vesselParams.voyageNumber !== undefined && vesselParams.voyageNumber !== '') ||
    (vesselParams.imoNumber !== undefined && vesselParams.imoNumber !== '') ||
    (vesselParams.stayNumber !== undefined && vesselParams.stayNumber !== '');

  return useQuery(
    updateVoyageKeys.containersForUpdate(vesselParams),
    () => api.containers.getContainersByVoyage(vesselParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersUpdateVoyage = (successCallback?: Function) => {
  const queryClient = useQueryClient();

  return useMutation(updateVoyageKeys.postContainersUpdatevoyage(), {
    mutationFn: (data: UpdateVoyageNumberRequest) =>
      api.containers.postContainersUpdatevoyagenumber(data),
    onSuccess: () => {
      queryClient.invalidateQueries(updateVoyageKeys.containersForUpdateRoot());
      successCallback?.();
    },
  });
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(updateVoyageKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};
