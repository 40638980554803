import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    fontSize: '20px',
    marginLeft: '10px',
  },
  flavourText: {
    marginLeft: '10px',
    fontSize: '0.85rem',
    whiteSpace: 'break-spaces',
  },
  button: {
    marginRight: '10px',
  },
  errorText: {
    fontSize: '0.875rem',
    color: 'red',
  },
});

export default useStyles;
