import DisplayNotifications from 'modules/terminal/pages/DisplayNotifications';
import DisplayBillingInfo from '../pages/DisplayBillingInfo';
import React from 'react';
import { Route } from 'react-router-dom';
import store from 'store/store';
import { api, mainAPI } from '../../global/adapters';
import { ROUTE_PATHS } from '../constants';
import {
  AcceptReleaseRight,
  DeclineReleaseRight,
  TransferReleaseRight,
  UseCases,
  SearchContainers,
  RevokeReleaseRight,
} from '../pages';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const BetaReleasePartyRoutes: React.FunctionComponent<Props> = () => {
  const userState = store.getState().oidc.user;
  api.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });
  mainAPI.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });

  return (
    <React.Fragment>
      <Route path={ROUTE_PATHS.ACCEPT_RELEASE_RIGHT} exact component={AcceptReleaseRight} />
      <Route path={ROUTE_PATHS.REVOKE_RELEASE_RIGHT} exact component={RevokeReleaseRight} />
      <Route path={ROUTE_PATHS.TRANSFER_RELEASE_RIGHT} exact component={TransferReleaseRight} />
      <Route path={ROUTE_PATHS.DECLINE_RELEASE_RIGHT} exact component={DeclineReleaseRight} />
      <Route path={ROUTE_PATHS.DISPLAY_NOTIFICATIONS} exact component={DisplayNotifications} />
      <Route path={ROUTE_PATHS.DISPLAY_BILLING_INFO} exact component={DisplayBillingInfo} />
      <Route path={ROUTE_PATHS.SEARCH_CONTAINERS} exact component={SearchContainers} />
      <Route path={ROUTE_PATHS.BASE_ROUTE} exact component={UseCases} />
    </React.Fragment>
  );
};

export default BetaReleasePartyRoutes;
