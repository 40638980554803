import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  groupCategory: {
    margin: '60px 0 20px',
    fontWeight: 400,
  },
  container: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  pageTitle: {
    fontWeight: 400,
  },
  actionCard: {
    minHeight: '160px',
  },
  actionCardWrapper: {
    border: '1px solid',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      boxShadow: '0px 6px 20px 0px #dfdfdf',
    },
    '&.READ': {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[400],
    },
    '&.UPDATE': {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
    },
    '&.CREATE': {
      // TODO: fix this any situation if possible
      backgroundColor: (theme.palette.primary as any).extraLight,
      borderColor: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
