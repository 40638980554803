// In docker containers we generate a js file from the config that gets inserted into the index.html
const ENV = window.ENV ? window.ENV : process.env;

export const IDENTITY_URL = ENV.REACT_APP_IDENTITY_URL;
export const CLIENT_ID = ENV.REACT_APP_CLIENT_ID;
export const CALLBACK_URL = ENV.REACT_APP_CALLBACK_URL;
export const API_URL = ENV.REACT_APP_API_URL;
export const BFF_API_ROOT_URL = ENV.REACT_APP_BFF_API_ROOT_URL;
export const SUBSCRIPTION_KEY = ENV.REACT_APP_SUBSCRIPTION_KEY;
export const APP_ENVIRONMENT = ENV.REACT_APP_ENVIRONMENT;
export const FORWARDER_ROLE_ID = ENV.REACT_APP_FORWARDER_ROLE_ID;
export const TRANSPORTER_ROLE_ID = ENV.REACT_APP_TRANSPORTER_ROLE_ID;
export const TERMINAL_ROLE_ID = ENV.REACT_APP_TERMINAL_ROLE_ID;
export const SHIPPING_ROLE_ID = ENV.REACT_APP_SHIPPING_ROLE_ID;
export const POLICE_ROLE_ID = ENV.REACT_APP_POLICE_ROLE_ID;
export const NOTIFICATIONS_RETENTION_TIME = ENV.REACT_APP_NOTIFICATIONS_RETENTION_TIME;
export const USECPOINT2FA = ENV.REACT_APP_USECPOINT2FA;
export const ENABLEACTIONFILTER = ENV.REACT_APP_ENABLEACTIONFILTER;
export const EXTERNALPRODUCTPAGE = ENV.REACT_APP_EXTERNALPRODUCTPAGE;
export const POACPUPAGE = ENV.REACT_APP_POACPUPAGE;
export const USERISEXPIRING_WARNING_INMINUTES = ENV.REACT_APP_USERISEXPIRING_WARNING_INMINUTES;
export const USE_BETA_SCREENS = ENV.REACT_APP_USE_BETA_SCREENS;
export const TERMINAL_OVERRULEMAXITEMS = ENV.REACT_APP_TERMINAL_OVERRULEMAXITEMS;
export const ALFAPASS_AUTHORIZE_ENDPOINT = ENV.REACT_APP_ALFAPASS_AUTHORIZE_ENDPOINT;
export const ALFAPASS_TOKEN_ENDPOINT = ENV.REACT_APP_ALFAPASS_TOKEN_ENDPOINT;
export const ALFAPASS_CALLBACK_ENDPOINT = ENV.REACT_APP_ALFAPASS_CALLBACK_ENDPOINT;
export const TERMS_AND_CONDITIONS_VERSION = ENV.REACT_APP_TERMS_AND_CONDITIONS_VERSION;
