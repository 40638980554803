import { mainAPI } from 'modules/global/adapters';
import { ModalContainer } from 'modules/global/adapters/crpAPI';
import { PickUpPartyIdentification } from 'modules/global/adapters/mainAPI';
import React, { useEffect } from 'react';

function useValidateAlfapass() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [container, setContainer] = React.useState<ModalContainer>({} as ModalContainer);

  const onValidate = () => setIsLoading(true);

  useEffect(() => {
    const validateAlfappass = async () => {
      try {
        const identification = {
          identificationCode: container.alfapassNumber,
          identificationType: 'Alfapass',
        } as PickUpPartyIdentification;
        const response = await mainAPI.validation.checkAlfapassNumberIsValid(identification);
        container.isValid = response;
        container.isValidated = true;
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      validateAlfappass();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return {
    isLoading,
    setContainer,
    onValidate,
  };
}

export default useValidateAlfapass;
