import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import {
  GetContainersByVoyageParams,
  UpdateTerminalRequest,
} from '../../../global/adapters/crpAPI';
import { changeTerminalKeys } from './queryKeys';

export const useGetContainersUpdateTerminal = (vesselParams: GetContainersByVoyageParams) => {
  const isSearchEntered =
    (vesselParams.vesselName !== undefined && vesselParams.vesselName !== '') ||
    (vesselParams.voyageNumber !== undefined && vesselParams.voyageNumber !== '') ||
    (vesselParams.imoNumber !== undefined && vesselParams.imoNumber !== '') ||
    (vesselParams.stayNumber !== undefined && vesselParams.stayNumber !== '');

  return useQuery(
    changeTerminalKeys.containersForUpdate(vesselParams),
    () => api.containers.getContainersByVoyage(vesselParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersUpdateterminal = () => {
  const queryClient = useQueryClient();

  return useMutation(changeTerminalKeys.postContainersUpdateTerminal(), {
    mutationFn: (data: UpdateTerminalRequest) => api.containers.postContainersUpdateterminal(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(changeTerminalKeys.containersForUpdateRoot());
    },
  });
};

export const useGetActiveTerminals = () => {
  return useQuery(changeTerminalKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetContainerDetail = (id: string) => {
  return useQuery(changeTerminalKeys.getContainerDetail(id), () =>
    api.containers.getContainerDetail(id)
  );
};
