import { Card, CardActionArea, CardContent, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

export type DashboardTile = {
  title: string;
  textBody?: string;
  number?: number;
};

export type DashboardGroup = {
  tiles: DashboardTile[];
  fetchingText?: string;
};

type Props = React.ComponentPropsWithoutRef<'div'> & {
  dashboardGroup: DashboardGroup;
  isFetching: boolean;
};

const DashboardScreen = ({ dashboardGroup, isFetching }: Props) => {
  const { t } = useTranslation('useCase');
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h1 className={classes.pageTitle}>{t('general.dashboard')}</h1>
      <React.Fragment>
        <Grid container spacing={3}>
          {dashboardGroup?.tiles?.map((actionCard) => (
            <Grid item xs={3} key={actionCard.title}>
              <Card variant="outlined" className={classes.actionCardWrapper}>
                <CardActionArea>
                  <CardContent className={classes.actionCard}>
                    <h3>{actionCard.title}</h3>
                    <p>{isFetching ? dashboardGroup.fetchingText : actionCard.textBody}</p>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    </Container>
  );
};

export default DashboardScreen;
