import { Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import {
  acceptReleases,
  declineReleases,
  loadReleases,
  loadReleasesPage,
  loadReleasesRowsPerPage
} from '../../store/actions/releaseActions';
import { loadSelectedReleasesSucceeded } from '../../store/actions/selectedReleaseActions';
import {
  getReleases,
  getReleasesLoading,
  getReleasesPaging,
  getSelectedReleases,
  getCreditIdentification,
  getCreditIdentificationLoading
} from '../../store/selectors/selectors';
import { LABEL } from '../../theme/constants';
import ContainerTable from '../organisms/ContainerTable';
import ReleasesFilter from "../organisms/ReleasesFilter";
import LoaderTimeout from '../organisms/LoaderTimeout';
import CreditInfo from '../molecules/CreditInfo';

const styles = theme => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%'
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  }
});

const columns = [
  { id: 'billOfLading', label: LABEL.BILL_OF_LADING_LABEL },
  { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
  { id: 'shippingAgent', label: LABEL.SHIPPING_AGENT_LABEL },
  { id: 'receivedFrom', label: LABEL.RECEIVED_FROM_LABEL },
  { id: 'receivedOn', label: LABEL.RECEIVED_ON_LABEL },
  { id: 'validUntil', label: LABEL.VALID_UNTIL_LABEL },
  { id: 'terminal', label: LABEL.TERMINAL_LABEL },
  { id: 'action', label: LABEL.ACTION_LABEL, colSpan: 2 },
];

class Releases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAccept: false
    }
  }
  componentDidMount() {
    this.props.loadReleases();
  };

  componentDidUpdate() {
    if(this.props.creditIdentificationLoading == false && this.props.creditIdentification != null && this.props.creditIdentification != undefined) {
      if(((this.props.creditIdentification.hasPaidSubscription == false && this.props.creditIdentification.isPaired == false)
      || this.props.creditIdentification.creditsAmount == 0) && this.state.disableAccept == false) {
        this.setState({disableAccept: true});
      }
    }
  }

  render() {
    const { items, paging, classes, acceptReleases, declineReleases, loadReleases, loadReleasesRowsPerPage, loadReleasesPage, selectedReleases, loadSelectedReleasesSucceeded, loading } = this.props;
    return (
      <React.Fragment>
        <div className={classes.breadBar}>
          <CreditInfo />
        </div>
        <main className={classes.content}>
          <Paper>
            <ReleasesFilter />
          </Paper>
        </main>
        <div className={classes.divider} />
        <main className={classes.content}>
          {loading ?
            <CircularProgress className={classes.circularProgress} /> :
            <Paper>
              <ContainerTable
                columns={columns}
                items={items}
                paging={paging}
                acceptReleases={acceptReleases}
                declineReleases={declineReleases}
                loadContainers={loadReleases}
                loadContainersPage={loadReleasesPage}
                loadContainersRowsPerPage={loadReleasesRowsPerPage}
                selected={selectedReleases}
                loadSelectedSucceeded={loadSelectedReleasesSucceeded}
                headerTitle={LABEL.PENDING_RELEASES}
                containers={false}
                disableAccept
                extendedToolbar
              />
            </Paper>
          }
        </main>
        <LoaderTimeout
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

Releases.propTypes = {
  items: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  loadReleases: PropTypes.func.isRequired,
  loadReleasesPage: PropTypes.func.isRequired,
  loadReleasesRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    selectedReleases: getSelectedReleases(state),
    items: getReleases(state),
    paging: getReleasesPaging(state),
    loading: getReleasesLoading(state),
    creditIdentificationLoading: getCreditIdentificationLoading(state),
    creditIdentification: getCreditIdentification(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadReleases,
      loadReleasesPage,
      loadReleasesRowsPerPage,
      loadSelectedReleasesSucceeded,
      acceptReleases,
      declineReleases,
      errorMessage,
      successMessage
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Releases));
