import { CircularProgress, Paper } from '@material-ui/core';
import { Row } from '@tanstack/react-table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TerminalContainer, IContainersToBeOverruled } from '../../../global/adapters/crpAPI';
import { api } from '../../../global/adapters';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import AddContainersStep from './AddContainersStep';
import SelectStep from './SelectStep';
import IdentifyAlfapass from './IdentifyAlfapass';
import ConfirmStep from './ConfirmStep';
import { FormikErrors, useFormikContext } from 'formik';
import { parseJwt } from '../../../../components/pages/Alfapass';
import { ReasonTypes, Reason } from './ReasonTypes';
import { OVERRULE_CUSTOMS_LIGHT_FAQ_PAGES } from '../../constants/documentationFaqs';

type Props = {
  values: IContainersToBeOverruled;
  errors: FormikErrors<IContainersToBeOverruled>;
  isFetching?: boolean;
  selectedRows: Row<TerminalContainer>[] | null;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  confirmSelection: Function;
  confirmError: string | null;
  confirmMessage: string | null;
  partyPath: string;
};

const StepperOverruleCustomsLight = ({
  values,
  errors,
  isFetching,
  selectedRows,
  setDisplayGrid,
  submitForm,
  resetForm,
  confirmSelection,
  confirmError,
  confirmMessage,
  partyPath,
}: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);
  const [containersToBeOverruled, setContainersToBeOverruled] = React.useState<
    string[] | undefined
  >(values.containers);
  const [hasValidContainers, setHasValidContainers] = React.useState<boolean>(false);
  const [reasons, setReasons] = React.useState<Reason[] | undefined>(undefined);
  const [reason, setReason] = React.useState<string | undefined>(undefined);
  const [freeText, setFreeText] = React.useState<string | undefined>(undefined);
  const [isTokenExpired, setIsTokenExpired] = React.useState<boolean>(false);
  const [clientId, setClientId] = React.useState<string | undefined>(undefined);
  const [fetchingClientId, setFetchingClientId] = React.useState<boolean>(true);

  const [activeStep, setActiveStep] = React.useState(0);
  const { setFieldValue } = useFormikContext();

  const displayContainers = () => {
    setFieldValue('containers', containersToBeOverruled);
    submitForm();
  };

  const resetStepper = () => {
    setActiveStep(1);
    setContainersToBeOverruled(undefined);
    setHasValidContainers(false);
  };

  const setSetOverruleReason = (reason: string) => {
    setReason(reason);
  };
  const setOverruleFreeText = (text: string) => {
    setFreeText(text);
  };

  const goHome = () => {
    window.location.assign('/');
  };

  const containersAreValid = (v: boolean, c: string[]) => {
    setHasValidContainers(v);
    setContainersToBeOverruled(c);
  };

  const confirmClick = () => {
    confirmSelection(reason, freeText);
  };

  const getReasons = () => {
    let reasonList: Reason[] = [];
    ReasonTypes.forEach((item) => {
      reasonList.push(new Reason(t('overruleCustomsLight:sidebarConfirm.reasons.' + item), item));
    });
    setReasons(reasonList);
    return reasonList;
  };

  React.useEffect(() => {
    //clientId
    api.greenLights
      .getAlfapassClientId()
      .then((id) => {
        setClientId(id);
        setFetchingClientId(false);
      })
      .catch((e) => {
        setFetchingClientId(false);
      });

    //token
    var reasonList = getReasons();
    setReason(reasonList[0].reasonType);
    const alfapassToken = localStorage.getItem('alfapassToken');
    if (alfapassToken != null) {
      var decodedJwt = parseJwt(alfapassToken);
      if (decodedJwt == null || decodedJwt.exp * 1000 < Date.now()) {
        setIsTokenExpired(true);
      } else {
        setIsTokenExpired(false);
      }
    } else {
      setIsTokenExpired(true);
    }
  }, []);

  return (
    <Paper>
      <StepUseCase
        faqLink={OVERRULE_CUSTOMS_LIGHT_FAQ_PAGES.OVERRULE_LIGHT}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('overruleCustomsLight:sidebarAlfapass.stepTitle'),
            stepFlavorText: t('overruleCustomsLight:sidebarAlfapass.stepFlavorText'),
            disableNext: true,
            content: <IdentifyAlfapass clientId={clientId} />,
            backCallBack: goHome,
            hideStep: !isTokenExpired || clientId === undefined,
          },
          {
            stepTitle: t('overruleCustomsLight:sidebarAlfapass.stepTitleNoAuthorization'),
            stepFlavorText: t('overruleCustomsLight:sidebarAlfapass.stepFlavorTextNoAuthorization'),
            disableNext: true,
            content: (
              <div>
                <span style={{ color: 'red' }}>
                  {t('overruleCustomsLight:sidebarAlfapass.notAuthorized')}
                </span>
              </div>
            ),
            backCallBack: goHome,
            hideStep: clientId !== undefined || fetchingClientId,
          },
          {
            stepTitle: t('overruleCustomsLight:sidebarAddContainers.stepTitle'),
            stepFlavorText: t('overruleCustomsLight:sidebarAddContainers.stepFlavorText'),
            nextCallBack: displayContainers,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('overruleCustomsLight:sidebarAddContainers.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: false,
            disableNext: !hasValidContainers,
            backCustomText: t('overruleCustomsLight:sidebarAddContainers.buttonBack'),
            content: (
              <AddContainersStep
                ContainersToBeOverruled={containersToBeOverruled}
                AllContainersAreValid={containersAreValid}
                partyPath={partyPath}
              />
            ),
            backCallBack: goHome,
          },
          {
            stepTitle: t('overruleCustomsLight:sidebarSelectContainers.stepTitle'),
            stepFlavorText: t('overruleCustomsLight:sidebarSelectContainers.stepFlavorTextShort'),
            nextCustomText: t('overruleCustomsLight:sidebarSelectContainers.buttonNext'),
            backCallBack: () => {
              setDisplayGrid(false);
              setContainersToBeOverruled(containersToBeOverruled);
            },
            disableNext: selectedRows == null || selectedRows.length === 0,
            content: <SelectStep ContainersToBeOverruled={containersToBeOverruled} />,
          },
          {
            stepTitle: t('overruleCustomsLight:sidebarConfirm.stepTitle'),
            stepFlavorText: t('overruleCustomsLight:sidebarConfirm.stepFlavorText'),
            nextCustomText: t('overruleCustomsLight:sidebarConfirm.buttonNext'),
            nextCallBack: confirmClick,
            content: (
              <ConfirmStep
                SetOverruleReason={setSetOverruleReason}
                SetOverruleFreeText={setOverruleFreeText}
                Reasons={reasons!}
                FreeText={freeText}
              />
            ),
          },
          {
            stepTitle: t('overruleCustomsLight:sidebarResult.stepTitle'),
            stepFlavorText: t('overruleCustomsLight:sidebarResult.stepFlavorText'),
            content: (
              <div>
                {confirmError == null && confirmMessage == null && (
                  <div>
                    {' '}
                    <CircularProgress size="16px" />
                    <span> {'      loading'}</span>{' '}
                  </div>
                )}
                {confirmError != null && <span style={{ color: 'red' }}>{confirmError}</span>}
                {confirmMessage != null && (
                  <span style={{ color: 'green' }}>
                    {t('overruleCustomsLight:sidebarResult.confirmed')}
                  </span>
                )}
              </div>
            ),
            noNextButton: true,
            backCallBack: resetStepper,
            backCustomText: t('overruleCustomsLight:sidebarResult.backButtonText'),
          },
        ]}
      />
    </Paper>
  );
};
export default StepperOverruleCustomsLight;
