export const ReasonTypes: string[] = [
  'CustomsIssues',
  'TerminalIssues',
  'ShippingAgentIssues',
  'CpuIssues',
  'Other',
];

export class Reason {
  constructor(text: string, reasonType: string) {
    this.text = text;
    this.reasonType = reasonType;
  }
  public text: string | undefined;
  public reasonType: string | undefined;
}
