import {
  Box,
  InputLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReleaseRightStatus } from '../../../global/adapters/mainAPI';
import FormikSelect from '../../../global/components/FormikSelect/FormikSelect';

type Props = {
  withReleaseRightStatus?: boolean;
  customHelperText?: string;
  showIsArchived?: boolean;
};

const VesselSearchStep = ({ withReleaseRightStatus, customHelperText, showIsArchived }: Props) => {
  const { t } = useTranslation(['glossary', 'validation']);

  return (
    <React.Fragment>
      <Box marginTop={6}>
        <Field name="vesselName" id="vesselName">
          {({ field }: FieldProps) => (
            <TextField {...field} variant="outlined" label={t('vesselName')} fullWidth />
          )}
        </Field>
      </Box>

      <Box marginTop={2}>
        {!withReleaseRightStatus && (
          <Field name="voyageNumber" id="voyageNumber">
            {({ field }: FieldProps) => (
              <TextField {...field} variant="outlined" label={t('voyageNumber')} fullWidth />
            )}
          </Field>
        )}
      </Box>
      <Box marginTop={2}>
        <Field name="imoNumber" id="imoNumber">
          {({ field }: FieldProps) => (
            <TextField {...field} variant="outlined" label={t('imoNumber')} fullWidth />
          )}
        </Field>
      </Box>
      <Box marginTop={2}>
        <Field name="stayNumber" id="stayNumber">
          {({ field }: FieldProps) => (
            <TextField
              {...field}
              variant="outlined"
              label={t('stayNumber')}
              fullWidth
              helperText={customHelperText || t('validation:atLeastOneField')}
            />
          )}
        </Field>
      </Box>
      {withReleaseRightStatus && (
        <Box marginTop={2}>
          <Field name="releaseRightStatus" id="releaseRightStatus">
            {({ field, form }: FieldProps) => (
              <React.Fragment>
                <InputLabel id="releaseRightStatus">{t('releaseRightStatus')}</InputLabel>
                <FormikSelect defaultValue={undefined} form={form} field={field} error={false}>
                  {Object.keys(ReleaseRightStatus)
                    // As per request, Archived is disabled for this select
                    .filter(
                      (status) =>
                        status !== ReleaseRightStatus.Archived &&
                        status !== ReleaseRightStatus.Assigned &&
                        status !== ReleaseRightStatus.Revoked &&
                        status !== ReleaseRightStatus.Unknown
                    )
                    .map((status) => (
                      <MenuItem value={status} key={status}>
                        {status}
                      </MenuItem>
                    ))}
                </FormikSelect>
              </React.Fragment>
            )}
          </Field>
        </Box>
      )}
      <Box>
        {showIsArchived && (
          <Field name="isArchived" id="isArchived">
            {({ field }: FieldProps) => (
              <FormControlLabel
                style={{ fontWeight: '200' }}
                control={
                  <Checkbox {...field} checked={field.value} name="isArchived" color="primary" />
                }
                label="Archived containers"
              />
            )}
          </Field>
        )}
      </Box>
    </React.Fragment>
  );
};
export default VesselSearchStep;
