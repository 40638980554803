import { useQuery } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import { ReleaseParty } from '../../../global/adapters/crpAPI';
import moment from 'moment-timezone';
import {
  IUploadReleaseRightsFileRequestUi,
  UploadReleaseRightsFileRequest,
} from '../../../global/adapters/crpAPI';
import { uploadReleaseRightsFileRequestUiKeys } from './queryKeys';
import { fileToByteArray } from '../../../global/helpers/fileToByteArray';

function uint8ArrayToBase64(uint8Array: Uint8Array): string {
  let binaryString = '';
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  const result = btoa(binaryString);
  return result;
}

async function convertToUploadReleaseRightsFileRequest(
  value: IUploadReleaseRightsFileRequestUi,
  releaseTo: ReleaseParty | undefined
) {
  const byteArray = await fileToByteArray(value.file!);
  var b = new UploadReleaseRightsFileRequest();
  const terminalValues: string[] | undefined = value.terminalNxtEntityId?.split('|');
  b.file = uint8ArrayToBase64(byteArray);
  b.portLoCode = 'BEANR';
  b.isTranshipment = value.isTranshipment;
  b.fullEmptyIndicator = value.fullEmptyIndicator;
  b.vesselName = value.vesselName;
  b.voyageNumber = value.voyageNumber;
  b.isCarrierHaulage = value.isCarrierHaulage;
  b.terminalNxtEntityId = terminalValues !== undefined ? terminalValues[0] : undefined;
  b.terminalCode = terminalValues !== undefined ? terminalValues[1] : undefined;
  b.releaseToNxtEntityId = releaseTo?.nxtEntityId;
  b.releaseDateTimeUtc = moment(value.releaseDateTimeUtc).toDate();
  b.expirationDateTimeUtc = moment(value.expirationDateTimeUtc).toDate();
  b.imoNumber = value.imoNumber;
  b.stayNumber = value.stayNumber;
  return b;
}

async function uploadFile(
  value: IUploadReleaseRightsFileRequestUi,
  releaseTo: ReleaseParty | undefined
) {
  const uploadRequest = await convertToUploadReleaseRightsFileRequest(value, releaseTo);
  const response = await api.uploadCsvCreateReleaseRight.postUploadReleaseRightsFileRequest(
    uploadRequest
  );
  return response;
}

export function uploadReleaseRightsFileRequestUiRequest({
  uploadCsvParams,
  releaseTo,
}: {
  uploadCsvParams: IUploadReleaseRightsFileRequestUi;
  releaseTo: ReleaseParty | undefined;
}) {
  return uploadFile(uploadCsvParams, releaseTo);
}

export const useGetActiveTerminals = () => {
  return useQuery(uploadReleaseRightsFileRequestUiKeys.getTerminals(), () =>
    api.terminals.getTerminals()
  );
};
