import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteDriverAlias, loadDriverList } from '../../store/actions/driverListActions';
import {
  getDriverList,
  getDriverListLoading,
  getRefreshDriverList,
} from '../../store/selectors/selectors';
import { Paper, Typography, Button, Box } from '@material-ui/core';
import { LABEL } from '../../theme/constants';
import AddDriverAliasDialog from '../organisms/AddDriverAliasDialog';
import UpdateDriverAliasDialog from '../organisms/UpdateDriverAliasDialog';
import DataTable from '../organisms/DataTable';
import AddIcon from '@material-ui/icons/Add';
import DeleteDriverAliasConfirmationDialog from '../organisms/DeleteDriverAliasConfirmationDialog';

const styles = (theme) => ({
  paperContent: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  titleBoxLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  addButtonBoxRight: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  dataGrid: {
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      color: '#A2A2A2',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderStyle: 'hidden',
    },
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
});

const DriverList = (props) => {
  const { driverList, loadDriverList, refreshList, loading, classes } = props;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);

  const [driverDialogOpen, setDriverDialogOpen] = React.useState(false);
  const [driverUpdateDialogOpen, setDriverUpdateDialogOpen] = React.useState(false);
  const [driverDeleteDialogConfirmationOpen, setDriverDeleteDialogConfirmationOpen] =
    React.useState(false);
  const [selectedDriverAlias, setSelectedDriverAlias] = React.useState({});

  useEffect(() => {
    if (!refreshList) loadDriverList();
  }, []);

  useEffect(() => {
    if (refreshList) {
      loadDriverList();
    }
  }, [refreshList]);

  const openDriverDialog = () => {
    setDriverDialogOpen(true);
  };

  const closeDialog = (e) => {
    e.stopPropagation();
    setDriverDialogOpen(false);
  };

  const closeUpdateDialog = (e) => {
    e.stopPropagation();
    setDriverUpdateDialogOpen(false);
  };

  const closeDeleteDialog = (e) => {
    e.stopPropagation();
    setDriverDeleteDialogConfirmationOpen(false);
  };

  const openUpdateDriverAliasDialog = (e, driverAlias) => {
    e.stopPropagation();
    setDriverUpdateDialogOpen(true);
    setSelectedDriverAlias(driverAlias);
  };

  const deleteDriverAlias = (e, driverAlias) => {
    e.stopPropagation();
    setDriverDeleteDialogConfirmationOpen(true);
    setSelectedDriverAlias(driverAlias);
  };

  const performPageCallback = (newPage) => {
    setPage(newPage);
  };

  const performPageSizeCallback = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getColumns = () => {
    return [
      {
        field: 'externalId',
        hide: true,
      },
      {
        field: 'description',
        headerName: LABEL.DRIVER_DESCRIPTION_LABEL,
        headerClassName: 'columnHeader',
        width: 300,
        editable: false,
        sortable: false,
      },
      {
        field: 'alfapassNumber',
        headerName: LABEL.DRIVER_ALFAPASS_NUMBER_LABEL,
        headerClassName: 'columnHeader',
        width: 250,
        editable: false,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: LABEL.ACTION,
        renderCell: (params) => {
          return (
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={(e) => openUpdateDriverAliasDialog(e, params.row)}
              >
                {LABEL.UPDATE}
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={(e) => deleteDriverAlias(e, params.row)}
              >
                {LABEL.DELETE}
              </Button>
            </div>
          );
        },
        width: 200,
        editable: false,
        sortable: false,
      },
    ];
  };

  const createDriver = (index, driver) => {
    if (driver == undefined)
      return {
        id: index,
        externalId: '',
        description: '',
        alfapassNumber: '',
      };

    return {
      id: index,
      externalId: driver.externalId,
      description: driver.description,
      alfapassNumber: driver.alfapassNumber,
    };
  };

  const getRows = () => {
    const rows = [];

    if (driverList) {
      for (let i = 0; i < driverList.length; i++) {
        const driver = driverList[i];
        rows.push(createDriver(i, driver));
      }
    }

    return rows;
  };

  return (
    <React.Fragment>
      <Paper>
        <Box display="flex">
          <Box width="100%" p={2}>
            <Typography variant="h6" id="tableTitle">
              {LABEL.DRIVERLIST_TITLE}
            </Typography>
          </Box>
          <Box flexShrink={0} p={2}>
            <Button
              style={{ padding: '14px 12px' }}
              size="large"
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={(e) => openDriverDialog(e)}
            >
              <AddIcon /> {LABEL.ADD_DRIVER_DESCRIPTION}
            </Button>
          </Box>
        </Box>
        {driverList && (
          <DataTable
            columns={getColumns()}
            rows={getRows()}
            page={page}
            pageSize={pageSize}
            classes={classes}
            loading={loading}
            setPageCallback={performPageCallback}
            setPageSizeCallback={performPageSizeCallback}
            enableSelection={false}
            disableRowClick={true}
            disableColumnMenu={true}
            disableColumnFilter={true}
          />
        )}
        <AddDriverAliasDialog
          openAddDriverAliasDialog={driverDialogOpen}
          closeDialog={closeDialog}
        />
        <DeleteDriverAliasConfirmationDialog
          openDeleteDriverAliasDialog={driverDeleteDialogConfirmationOpen}
          closeDialog={closeDeleteDialog}
          selectedDriverAlias={selectedDriverAlias}
        />
        <UpdateDriverAliasDialog
          openUpdateDriverAliasDialog={driverUpdateDialogOpen}
          closeDialog={closeUpdateDialog}
          selectedDriverAlias={selectedDriverAlias}
        />
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    driverList: getDriverList(state),
    refreshList: getRefreshDriverList(state),
    loading: getDriverListLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadDriverList,
      deleteDriverAlias,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DriverList));
