import { mapPage, mapEnitityFilter } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.companies, action) {
  switch (action.type) {
    case ActionTypes.COMPANIES_REQUESTED: {
      return {
        ...state,
        filters: action.payload.filters !== undefined ? action.payload.filters : state.filters,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.COMPANIES_REQUEST_SUCCEEDED: {
      return {
        ...state,
        items: action.payload.phoneBookItems,
        paging: mapPage({...action.payload.searchFilter, totalRows:action.payload.totalRows}),
        filters: mapEnitityFilter(action.payload.searchFilter),
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
