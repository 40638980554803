import { Link, TableBody, TablePagination, Box } from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { CRPCompanyTableTheme, StyledTableRow } from '../../theme/theme';
import CenterTableCell from '../atoms/CenterTableCell';
import TableHead from '../molecules/ContainerTableHead';
import ContainerToolbar from '../molecules/ContainerToolbar';
import { DIALOGTYPES, LABEL, DIALOG } from '../../theme/constants';
import YesNoDialog from '../organisms/YesNoDialog';
import TransferToUnknownDialog from '../organisms/TransferToUnknownDialog';

const styles = (theme) => ({
  tableRow: {
    height: 40,
  },
  pointer: {
    cursor: 'pointer',
  },
  checkbox: {
    padding: 8,
    margin: '0 4px',
    value: 'secondary',
    color: 'primary',
  },
  nextMode: {
    fill: '#606060',
  },
  content: {
    width: '100%',
  },
  responsiveCell: {
    maxWidth: '0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  contentBig: {
    flex: 5,
    paddingLeft: theme.spacing(3),
    width: '100%',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wideCell: {
    padding: '20px 8px',
  },
  buttonNotInList: {
    float: 'right',
  },
});

const CompanyTable = (props) => {
  const {
    classes,
    columns,
    paging,
    items,
    headerTitle,
    containers,
    loadCompaniesRowsPerPage,
    loadCompaniesPage,
    onTransfer,
    onSelect,
  } = props;

  const handleChangePage = (event, page) => {
    loadCompaniesPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    loadCompaniesRowsPerPage(event.target.value);
  };

  const transferCompany = (company) => {
    onTransfer(company);
  };

  const selectCompany = (company) => {
    onSelect(company);
  };

  return (
    <ThemeProvider theme={CRPCompanyTableTheme}>
      <div>
        {onTransfer !== undefined && onTransfer !== null && (
          <Box className={classes.buttonNotInList}>
            {
              <TransferToUnknownDialog
                callback={transferCompany}
                buttonTitle={LABEL.MY_OPTION_IS_NOT_IN_THE_LIST}
              />
            }
          </Box>
        )}
        <ContainerToolbar
          selected={[]}
          className={classes.wideCell}
          headerTitle={headerTitle}
          containers={containers}
        />
        <Table aria-labelledby="tableTitle" style={{ width: '100%' }}>
          <TableHead
            className={classes.wideCell}
            columns={columns}
            checkboxes={false}
            centerAlign={true}
          />
          <TableBody>
            {items.map((company, index) => {
              return (
                <StyledTableRow tabIndex={-1} key={index} className={classes.tableRow}>
                  <CenterTableCell text={company.nxtEntityId ? company.nxtEntityId : ''} />
                  <CenterTableCell text={company.apcsCode ? company.apcsCode : ''} />
                  <CenterTableCell text={company.eori ? company.eori : ''} />
                  <CenterTableCell text={company.duns ? company.duns : ''} />
                  <CenterTableCell text={company.name ? company.name : ''} />
                  <CenterTableCell text={company.vat ? company.vat : ''} />
                  {onTransfer !== undefined && onTransfer !== null && (
                    <CenterTableCell
                      text={
                        <YesNoDialog
                          callback={() => {
                            transferCompany(company);
                          }}
                          dialogType={DIALOGTYPES.ASSIGN_CONTAINER_COMPANY}
                          variant={'text'}
                          dialogTitle={DIALOG.ASSIGN_TITLE}
                          contentText={`${DIALOG.ASSIGN_CONTENT.replace(
                            '%COMPANY%',
                            company.name
                          )}`}
                          buttonTitle={LABEL.ASSIGN_LABEL}
                        />
                      }
                    />
                  )}
                  {onSelect !== undefined && onSelect !== null && (
                    <CenterTableCell
                      text={
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            selectCompany(company);
                          }}
                        >
                          {LABEL.SELECT_LABEL}
                        </Link>
                      }
                    />
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        {paging && (
          <TablePagination
            component="div"
            count={paging.count}
            rowsPerPage={paging.rowsPerPage}
            page={paging.page}
            rowsPerPageOptions={[25, 50, 100, 250]}
            backIconButtonProps={{ 'aria-label': LABEL.PREVIOUS_PAGE }}
            nextIconButtonProps={{ 'aria-label': LABEL.NEXT_PAGE }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

CompanyTable.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  loadCompaniesRowsPerPage: PropTypes.func,
  loadCompaniesPage: PropTypes.func,
  paging: PropTypes.object,
};

export default withStyles(styles)(CompanyTable);
