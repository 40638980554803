import { GetContainersByVoyageParams } from '../../../global/adapters/crpAPI';

export const updateVoyageKeys = {
  root: ['update-voyage'] as const,
  containersForUpdateRoot: () => [...updateVoyageKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (vesselParams: GetContainersByVoyageParams) =>
    [...updateVoyageKeys.containersForUpdateRoot(), vesselParams] as const,
  postContainersUpdatevoyage: () =>
    [...updateVoyageKeys.root, 'post-containers-update-voyage'] as const,
  getContainerDetail: (id: string) => [...updateVoyageKeys.root, 'get-container-detail', id],
};
