import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { Container, GetContainersByVoyageParams } from '../../global/adapters/crpAPI';
import { DataGrid } from '../../global/components';
import { Drawer } from '../../global/components/Drawer';
import EmptyState from '../../global/components/EmptyState/EmptyState';
import { useLocalTableValues } from '../../global/hooks';
import { useGetContainersUpdateTerminal } from '../adapters/change-terminal/queries';
import StepperChangeTerminal from '../components/change-terminal/StepperChangeTerminal';
import ContainerDetailDrawer from '../components/shared/ContainerDetailDrawer';
import { baseShippingLineColumnsWithCheckbox } from '../constants/shared/shippingLineColumns';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: GetContainersByVoyageParams = {
  vesselName: '',
  voyageNumber: '',
  imoNumber: '',
  stayNumber: '',
  isArchived: false,
};

const ChangeTerminal: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['changeTerminal', 'common', 'validation']);
  const formRef = useRef<FormikProps<GetContainersByVoyageParams>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: Container;
  }>({
    open: false,
    data: undefined,
  });

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<Container>) | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<Row<Container>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-change-terminal',
    {}
  );

  const { data, isLoading, isFetching, isError } = useGetContainersUpdateTerminal({
    vesselName: formRef?.current?.values.vesselName,
    voyageNumber: formRef?.current?.values.voyageNumber,
    imoNumber: formRef?.current?.values.imoNumber,
    stayNumber: formRef?.current?.values.stayNumber,
    isArchived: formRef?.current?.values.isArchived,
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: Container) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<GetContainersByVoyageParams>>}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            submitForm,
            resetForm,
            values,
          }: FormikProps<GetContainersByVoyageParams>) => {
            return (
              <StepperChangeTerminal
                errors={errors}
                selectedRows={selectedRows}
                setDisplayGrid={setDisplayGrid}
                submitForm={submitForm}
                data={data}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={baseShippingLineColumnsWithCheckbox}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                  onCellClick={handleCellClick}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default ChangeTerminal;
