import { Checkbox } from "@material-ui/core";
import PropTypes from 'prop-types';
import React from 'react';

const CrpCheckbox = (props) => {
  const { onChange, onClick, checked, style } = props;
  return (
    <Checkbox
      value='secondary'
      color='primary'
      checked={checked}
      onChange={onChange}
      style={style}
      onClick={onClick} />
  );
};

CrpCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object
};

export default CrpCheckbox;
