import { CONSTANTS, LABEL } from '../../theme/constants';
import { Get, Put } from './authorizedRequest';

const endpoint = '/' + CONSTANTS.PORT_LOCODE + '/containers/';

export async function getSubscriptions(obj) {
  const request = Get(endpoint + 'import/subscriptions', obj);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response;
}

export async function getPoliceSubscriptions(obj) {
  const request = Get('/police-subscriptions', obj);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let status = response.status;
  let responseJson = null;

  if (status === 200) responseJson = await response.json();

  return responseJson;
}

export async function putSubscription(obj) {
  const request = Put(endpoint + obj.equipmentNumber + '/import/subscription', obj);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}
