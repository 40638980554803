import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import { rootSaga } from './sagas';
import { routerMiddleware } from 'react-router-redux';
import userManager from '../utils/userManager';
import history from './../history/browserHistory';
import { loadUser } from 'redux-oidc';
import { APP_ENVIRONMENT } from '../config/environment';

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (APP_ENVIRONMENT === 'PRODUCTION') {
  composeEnhancers = compose;
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

sagaMiddleware.run(rootSaga).done.catch((e) => {
  console.log({ message: e.message, source: 'sagaError', stacktrace: e.sagaStack });
});
loadUser(store, userManager);

export default store;
