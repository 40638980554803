import queryString from 'query-string';
import { API_URL, SUBSCRIPTION_KEY, BFF_API_ROOT_URL } from '../../config/environment';
import { loadTokenExpired } from '../actions/userProfileActions';
import store from '../store';

const apiUrl = API_URL.substr(-1) === '/' ? API_URL.slice(0, -1) : API_URL;

function AuthorizedHeaders() {
  const headers = new Headers();
  const token = store.getState().oidc.user;

  if (token == null || token.expired) {
    store.dispatch(loadTokenExpired(true));
  }

  headers.append('Authorization', `${token.token_type} ${token.access_token}`);
  headers.append('Ocp-Apim-Subscription-Key', SUBSCRIPTION_KEY);
  headers.append('Content-Type', 'application/json');
  headers.append('X-SIGNALR-ID', store.getState().signalR.id);
  return headers;
}

function Parameters(query) {
  let parameters = '';
  if (query) {
    parameters = queryString.stringify(query);
  }
  return parameters && '?' + parameters;
}

export function Get(resource, query) {
  return new Request(apiUrl + resource + Parameters(query), {
    method: 'GET',
    headers: AuthorizedHeaders(),
  });
}

export function GetWeb(resource, query) {
  return new Request(BFF_API_ROOT_URL + resource + Parameters(query), {
    method: 'GET',
    headers: AuthorizedHeaders(),
  });
}

export function GetBase(baseUrl, resource, query) {
  return new Request(baseUrl + resource + Parameters(query), {
    method: 'GET',
    headers: AuthorizedHeaders(),
  });
}

export function Put(resource, body) {
  return new Request(apiUrl + resource, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: AuthorizedHeaders(),
  });
}

export function Post(resource, body) {
  return new Request(apiUrl + resource, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: AuthorizedHeaders(),
  });
}

export function PostWeb(resource, body) {
  return new Request(BFF_API_ROOT_URL + resource, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: AuthorizedHeaders(),
  });
}

export function Delete(resource, body) {
  return new Request(apiUrl + resource, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: AuthorizedHeaders(),
  });
}
