import { TableRow } from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';

export const CRPTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#008efd', extraLight: '#f1f3ff' },
    red: { main: '#f44336' },
    success: { main: '#4caf50', light: '#f4fff9' },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '8px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        top: '10px',
      },
    },
  },
});

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export const CRPCompanyTableTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#008efd' },
    red: { main: '#f44336' },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '8px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px',
      },
    },
    MuiTable: {
      root: {
        marginTop: '10px',
      },
    },
  },
});

export const CRPTableTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#008efd' },
    red: { main: '#f44336' },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '8px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0px 10px',
      },
    },
    MuiTable: {
      root: {
        marginTop: '10px',
      },
    },
    MuiFormControl: {
      root: {
        border: '0 !important',
      },
    },
  },
});

export const CRPExpiredTokenTheme = createTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});
