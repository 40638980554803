import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker as MuiTimePicker } from '@material-ui/pickers';
import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import React from 'react';

type Props = {
  field: FieldInputProps<string>;
  form: FormikProps<FormikValues>;
  error: boolean;
  label: string;
};

const TimePicker = ({ field, form, error, label }: Props) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  React.useEffect(() => {
    setDefaultOptions({
      locale: enUS,
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiTimePicker
        label={label}
        value={value || null}
        onChange={(time) => {
          setFieldValue(name, time);
        }}
        variant="inline"
        inputVariant="outlined"
        fullWidth
        error={error}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
