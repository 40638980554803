import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionScreen } from '../../global/components/ActionScreen';
import { ActionGroup } from '../../global/components/ActionScreen/ActionScreen';
import { ROUTE_PATHS } from '../constants';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const UseCases: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation('useCase');

  const terminalActions: ActionGroup[] = [
    {
      category: t('actions.manage.title'),
      actions: [
        {
          title: t('actions.manage.overruleCustomsLight.title'),
          textBody: t('actions.manage.overruleCustomsLight.body'),
          isInBeta: true,
          type: 'UPDATE',
          url: ROUTE_PATHS.OVERRULE_CUSTOMS_LIGHT,
        },
      ],
    },
    {
      category: t('actions.consult.title'),
      actions: [
        {
          title: t('actions.consult.notification.title'),
          textBody: t('actions.consult.notification.body'),
          type: 'READ',
          url: ROUTE_PATHS.DISPLAY_NOTIFICATIONS,
        },
        {
          title: t('actions.consult.searchContainer.title'),
          textBody: t('actions.consult.searchContainer.body'),
          type: 'READ',
          url: ROUTE_PATHS.SEARCH_CONTAINERS,
        },
      ],
    },
  ];

  return <ActionScreen actionGroup={terminalActions} />;
};

export default UseCases;
