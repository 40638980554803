import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MUIDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import React from 'react';

type Props = {
  field: FieldInputProps<string>;
  form: FormikProps<FormikValues>;
  minDate: Date;
  error: boolean;
  label: string;
};

const DatePicker = ({ field, form, minDate, error, label }: Props) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  React.useEffect(() => {
    setDefaultOptions({
      locale: enUS,
      weekStartsOn: 1,
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MUIDatePicker
        label={label}
        value={value || null}
        onChange={(date) => {
          setFieldValue(name, date);
        }}
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        minDate={minDate}
        fullWidth
        error={error}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
