import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Drawer } from 'modules/global/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetContainerDetail } from '../../adapters/queries';
import { containerKeyFields, fields, greenLightFields } from '../../constants/shared/detailFields';
import useStyles from '../shared/styles';

type Props = {
  containerId: string;
};

const ContainerDetailDrawer = ({ containerId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation(['changeTerminal', 'validation']);
  const { data: containerDetail, isError, isLoading } = useGetContainerDetail(containerId);

  return (
    <div className={classes.detailWrapper}>
      {!isLoading ? (
        <React.Fragment>
          <Typography variant="h1">{t('containerDetail.title')}</Typography>
          {isError && (
            <Alert severity="error" style={{ marginBottom: '16px' }}>
              {t('validation:errorGetContainerDetail')}
            </Alert>
          )}
          {containerDetail && (
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Drawer.Content fieldModel={containerDetail} fields={containerKeyFields} />
              </Grid>
              <Grid item>
                <Drawer.Content fieldModel={containerDetail} fields={fields} />
              </Grid>
              <Grid item>
                <Drawer.GreenLightContent fieldModel={containerDetail} fields={greenLightFields} />
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default ContainerDetailDrawer;
export {};
