import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  subTitle: {
    padding: '15px 0 20px',
  },
  label: {
    display: '',
    marginBottom: '8px',
  },
  icon: {
    margin: '6px 0 0 10px',
    width: '40px',
    height: '40px',
  },
});

export default useStyles;
