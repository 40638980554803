import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import CrpCheckbox from '../atoms/CrpCheckbox';


const styles = theme => ({
  contentField: {
    marginTop: '30px',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block'
  }
});

const ChoiceCheckbox = props => {
  const [checked, setChecked] = React.useState(false);

  const { contentClassName, text, onCheckedChanged, storageId, classes } = props;

  const setLocalStorageChecked = (checked) => {
    reactLocalStorage.set(storageId, checked);
  };

  const onClick = (e) => {
    setChecked(!checked);
    setLocalStorageChecked(!checked);
    e.stopPropagation();
    onCheckedChanged && onCheckedChanged(!checked)
  };

  return (
    <div
      onClick={onClick}
      className={contentClassName ? contentClassName : classes.contentField}
    >
      <CrpCheckbox
        checked={checked}
        style={{ marginRight: '10px' }}
      />
      {text}
    </div>
  )
};

ChoiceCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  storageId: PropTypes.string.isRequired,
  onCheckedChanged: PropTypes.func
};

export default withStyles(styles)(ChoiceCheckbox);
