import * as Yup from 'yup';

const pickupModalSchema = Yup.object().shape({
  containers: Yup.array().of(
    Yup.object().shape({
      alfapassNumber: Yup.string().matches(/^\d+$/, 'digits only').max(6, 'max 6 chars long'),
    })
  ),
});

export default pickupModalSchema;
