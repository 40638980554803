import { Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { blockContainers, releaseContainers } from '../../store/actions/archiveActions';
import { declineReleases, acceptReleases } from '../../store/actions/releaseActions';
import {
  loadContainers,
  loadContainersPage,
  loadContainersRowsPerPage,
  revokeContainers,
  selectContainersForScan,
} from '../../store/actions/containerActions';
import { revokeContainerPickups } from '../../store/actions/alfapassActions';
import { loadTerminals } from '../../store/actions/terminalActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import {
  loadSelectedContainersSucceeded,
  clearSelectedContainers,
} from '../../store/actions/selectedContainerActions';
import { loadDriverList } from '../../store/actions/driverListActions';
import {
  getContainers,
  getContainersLoading,
  getContainersPaging,
  getSelectedContainers,
  getSelectedUserType,
  getRefreshContainers,
  getTerminals,
  getDriverList,
} from '../../store/selectors/selectors';
import { LABEL } from '../../theme/constants';
import ContainersFilter from '../organisms/ContainersFilter';
import ContainerTable from '../organisms/ContainerTable';
import CreditInfo from '../molecules/CreditInfo';
import PickupConfirmationDialog from '../organisms/PickupConfirmationDialog';
import LoaderTimeout from '../organisms/LoaderTimeout';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  circularProgress: {
    marginLeft: '50%',
    marginTop: '5%',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
    padding: '12px 24px',
  },
});

function Containers(props) {
  const {
    classes,
    items,
    paging,
    terminals,
    drivers,
    loadTerminals,
    loadDriverList,
    loadContainers,
    revokeContainers,
    declineReleases,
    acceptReleases,
    revokeContainerPickups,
    blockContainers,
    releaseContainers,
    loadContainersRowsPerPage,
    loadContainersPage,
    loading,
    selectedContainers,
    loadSelectedContainersSucceeded,
    selectContainersForScan,
    selectedUserType,
  } = props;

  useEffect(() => {
    loadContainers();
    clearSelectedContainers();
  }, []);

  useEffect(() => {
    if (terminals && terminals.length === 0) loadTerminals();
  }, []);

  useEffect(() => {
    if (selectedUserType === LABEL.TRANSPORTOPERATOR_LABEL && drivers && drivers.length === 0) {
      loadDriverList();
    }
  }, []);

  const getColumns = () => {
    let releaseRightColumns = [
      { id: 'billOfLading', label: LABEL.BILL_OF_LADING_LABEL },
      { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
      { id: 'shippingAgent', label: LABEL.SHIPPING_AGENT_LABEL },
      { id: 'receivedFrom', label: LABEL.RECEIVED_FROM_LABEL },
      { id: 'firstReleaseParty', label: LABEL.FIRST_RELEASE_PARTY_LABEL },
      { id: 'receivedOn', label: LABEL.RECEIVED_ON_LABEL },
      { id: 'validUntil', label: LABEL.VALID_UNTIL_LABEL },
      { id: 'terminal', label: LABEL.TERMINAL_LABEL },
      { id: 'alfapassNumberMask', label: LABEL.ALFAPASS_NUMBER_MASK_LABEL },
      { id: 'status', label: LABEL.RELEASE_STATUS.toUpperCase() },
      { id: 'action', label: LABEL.ACTION_LABEL, colSpan: 3 },
    ];

    if (selectedUserType !== LABEL.TRANSPORTOPERATOR_LABEL) {
      releaseRightColumns = releaseRightColumns.filter(
        (column) => column.id !== 'alfapassNumberMask'
      );
    }

    if (selectedUserType === LABEL.TERMINAL) {
      return releaseRightColumns.filter(
        (column) =>
          column.id !== 'terminal' &&
          column.id !== 'receivedFrom' &&
          column.id !== 'firstReleaseParty'
      );
    } else if (selectedUserType === LABEL.POLICE) {
      return releaseRightColumns.map((column) => {
        if (column.id === 'receivedFrom') {
          column.label = LABEL.CURRENT_OWNER_LABEL;
        }
        return column;
      });
    } else if (selectedUserType === LABEL.SHIPPING_AGENT_LABEL) {
      return releaseRightColumns.filter(
        (column) => column.id !== 'shippingAgent' && column.id !== 'receivedFrom'
      );
    } else {
      return releaseRightColumns.filter((column) => column.id !== 'firstReleaseParty');
    }
  };

  return (
    <React.Fragment>
      <div className={classes.breadBar}>
        <CreditInfo />
      </div>
      <main className={classes.content}>
        <Paper>
          <ContainersFilter
            terminal={selectedUserType === LABEL.TERMINAL}
            shipping={selectedUserType === LABEL.SHIPPING}
          />
        </Paper>
      </main>
      <div className={classes.divider} />
      <main className={classes.content}>
        {loading ? (
          <CircularProgress className={classes.circularProgress} />
        ) : (
          <Paper>
            <ContainerTable
              columns={getColumns()}
              items={items}
              paging={paging}
              loadContainers={loadContainers}
              loadContainersPage={loadContainersPage}
              loadContainersRowsPerPage={loadContainersRowsPerPage}
              revokeContainers={revokeContainers}
              declineReleases={declineReleases}
              acceptReleases={acceptReleases}
              revokeContainerPickups={revokeContainerPickups}
              blockContainers={blockContainers}
              releaseContainers={releaseContainers}
              selected={selectedContainers}
              loadSelectedSucceeded={loadSelectedContainersSucceeded}
              selectContainersForScan={selectContainersForScan}
              selectedUserType={selectedUserType}
              headerTitle={LABEL.RELEASE_RIGHT_OVERVIEW}
              containers
              extendedToolbar
            />
            <PickupConfirmationDialog />
          </Paper>
        )}
      </main>
      <LoaderTimeout loading={loading} />
    </React.Fragment>
  );
}

Containers.propTypes = {
  items: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  loadContainers: PropTypes.func.isRequired,
  loadContainersPage: PropTypes.func.isRequired,
  loadContainersRowsPerPage: PropTypes.func.isRequired,
  loadSelectedContainersSucceeded: PropTypes.func.isRequired,
  selectContainersForScan: PropTypes.func.isRequired,
  blockContainers: PropTypes.func.isRequired,
  releaseContainers: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  selectedUserType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    refreshContainers: getRefreshContainers(state),
    selectedContainers: getSelectedContainers(state),
    items: getContainers(state),
    paging: getContainersPaging(state),
    loading: getContainersLoading(state),
    selectedUserType: getSelectedUserType(state),
    terminals: getTerminals(state),
    drivers: getDriverList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadTerminals,
      loadContainers,
      loadDriverList,
      loadContainersPage,
      loadContainersRowsPerPage,
      loadSelectedContainersSucceeded,
      revokeContainers,
      declineReleases,
      acceptReleases,
      revokeContainerPickups,
      blockContainers,
      releaseContainers,
      selectContainersForScan,
      errorMessage,
      successMessage,
      clearSelectedContainers,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Containers));
