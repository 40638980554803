import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import {
  GetContainersByTerminalParams,
  GreenLightDefinitionType,
  TerminalContainer,
  ModalContainer,
  MeansOfTransport,
  BulkPickUpActionRequestItem,
} from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import {
  useGetActiveTerminals,
  useGetGreenLightValues,
  usePostContainersAssignBargeRailPickUp,
  usePostContainersAssignPickUp,
} from 'modules/transport-operator/adapters/assign-pickup/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/transport-operator/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResultStep } from '../shared';
import TerminalSearchStep from '../shared/TerminalSearchStep';
import FilterStep from './FilterStep';
import UpdateStep from './UpdateStep';

type Props = {
  data: TerminalContainer[] | undefined;
  errors: FormikErrors<GetContainersByTerminalParams>;
  isFetching?: boolean;
  selectedRows: Row<TerminalContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByTerminalParams;
};

const StepperAssignPickUp = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
}: Props) => {
  const { t } = useTranslation(['assignPickUp', 'common', 'useCase', 'validation']);
  const [newMeansOfTransport, setMeansOfTransport] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);
  const [pickupAssignments, setPickupAssignments] = React.useState<BulkPickUpActionRequestItem[]>(
    []
  );

  const isTruck = newMeansOfTransport === MeansOfTransport.Truck;
  const terminalNxtEntityIdError = Boolean(errors.terminalNxtEntityId);

  const selectedContainers =
    data &&
    selectedRows?.map(
      (row) =>
        ({
          releaseId: row.original.releaseId,
          equipmentNumber: row.original.equipmentNumber,
          billOfLadingNumbers: row.original.billOfLadingNumbers,
          alfapassNumber: '',
          isValid: undefined,
          isValidated: false,
        } as ModalContainer)
    );
  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const resetFormAndContainersUpdated = () => {
    resetForm();
    setRowSelection({});
    setContainersUpdated(0);
  };

  const {
    data: terminalData,
    isFetching: isFetchingTerminals,
    isError: isErrorTerminals,
  } = useGetActiveTerminals();

  const greenLightNames = [
    GreenLightDefinitionType.Customs,
    GreenLightDefinitionType.TerminalOperation,
    GreenLightDefinitionType.TerminalReady,
  ];

  const {
    data: greenLightValuesResponse,
    isFetching: isFetchingGreenLightValues,
    isError: isErrorGreenLightValues,
  } = useGetGreenLightValues(greenLightNames);

  const { mutate: mutateBargeRail, status: bargeRailStatus } =
    usePostContainersAssignBargeRailPickUp();
  const { mutate: mutateTruck, status: truckStatus } = usePostContainersAssignPickUp();

  const assignBargeRailPickUp = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutateBargeRail({
      releaseIds: listOfCheckedContainers,
      meansOfTransport: newMeansOfTransport,
    });
  };

  const assignPickUp = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutateTruck({
      items: pickupAssignments,
    });
  };

  const resetMeansOfTransportAndAssignments = () => {
    setPickupAssignments([]);
    setMeansOfTransport('');
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.ASSIGN_PICKUP}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.transportOperatorSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              terminalNxtEntityIdError ||
              (values.terminalNxtEntityId === '' &&
                values.terminalOperationValue === '' &&
                values.terminalReadyValue === '' &&
                values.customsValue === ''),
            content: (
              <TerminalSearchStep
                terminals={terminalData}
                greenLightValues={greenLightValuesResponse?.items}
                terminalNxtEntityIdError={errors.terminalNxtEntityId}
                isLoadingError={isErrorTerminals || isErrorGreenLightValues}
                isFetching={isFetchingTerminals || isFetchingGreenLightValues}
              />
            ),
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectUnAssignedContainersFlavorText'),
            nextCustomText: t('actions.selectMeansOfTransport'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('assignPickUp:general.title'),
            stepFlavorText: t('useCase:containers.assignContainersFlavorText'),
            backCallBack: resetMeansOfTransportAndAssignments,
            nextCallBack: isTruck ? assignPickUp : assignBargeRailPickUp,
            nextCustomText: t('useCase:containers.assignContainers', {
              numberOfContainersSelected:
                numberOfContainersSelected > maxNumberOfContainersProcessed
                  ? maxNumberOfContainersProcessed
                  : numberOfContainersSelected,
            }),
            disableNext:
              newMeansOfTransport === '' ||
              numberOfContainersSelected < 1 ||
              (isTruck && pickupAssignments.length === 0),
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                selectedContainers={selectedContainers}
                setMeansOfTransport={setMeansOfTransport}
                newMeansOfTransport={newMeansOfTransport}
                setPickupAssignments={setPickupAssignments}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={isTruck ? truckStatus : bargeRailStatus}
                numberOfContainersUpdated={isTruck ? pickupAssignments.length : containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={isTruck ? assignPickUp : assignBargeRailPickUp}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                  meansOfTransport: newMeansOfTransport,
                })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperAssignPickUp;
