import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React from 'react';

const CenterTableCell = (props) => {
  const { text, className, onClick, style } = props;
  return (
    <TableCell
      style={style}
      onClick={onClick}
      className={className}
      align={'center'}>
      {text}
    </TableCell>
  );
};

CenterTableCell.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CenterTableCell;
