import { useQuery } from '@tanstack/react-query';
import { BillingInfoRequest } from 'modules/global/adapters/crpAPI';
import { api } from 'modules/global/adapters';
import { displayBillingInfoKeys } from './queryKeys';
import moment from 'moment';
import {
  BillingInformationRequest,
  IBillingInformationRequest,
} from '../../../global/adapters/crpAPI';
import { MonthsByDescription } from '../../../global/helpers/datesHelper';

function convertToBillingInformationRequest(value: BillingInfoRequest): IBillingInformationRequest {
  const m = MonthsByDescription.indexOf(value.month) + 1;
  var b = new BillingInformationRequest();
  b.month = m;
  b.year = value.year;
  return b;
}

export const useBillingInfoRequest = (billingInfoRequest: BillingInfoRequest) => {
  const year = moment().year();
  const isSearchEntered =
    billingInfoRequest.month !== undefined &&
    billingInfoRequest.month != '' &&
    billingInfoRequest.month != null &&
    billingInfoRequest.year !== undefined &&
    billingInfoRequest.year >= year - 1 &&
    billingInfoRequest.year <= year + 1;

  //todo werner
  return useQuery(
    displayBillingInfoKeys.getBillingInfo(billingInfoRequest),
    () => api.billingInfo.getBillingInfo(convertToBillingInformationRequest(billingInfoRequest)),
    {
      enabled: isSearchEntered,
    }
  );
};
