import { GetContainersByReceivedFromParams } from 'modules/global/adapters/crpAPI';

export const transferReleaseRightKeys = {
  root: ['transfer-releaseright'] as const,
  containersForUpdateRoot: () =>
    [...transferReleaseRightKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (receivedFromParams: GetContainersByReceivedFromParams) =>
    [...transferReleaseRightKeys.containersForUpdateRoot(), receivedFromParams] as const,
  postContainersTransfer: () =>
    [...transferReleaseRightKeys.root, 'post-containers-transfer-releaseright'] as const,
  getTerminals: () => [...transferReleaseRightKeys.root, 'get-terminals'] as const,
  getContainerDetail: (id: string) =>
    [...transferReleaseRightKeys.root, 'get-container-detail', id] as const,
  getRelevantParties: (currrentRole: string) =>
    [...transferReleaseRightKeys.root, 'get-relevant-parties'] as const,
};
