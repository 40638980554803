import { GetContainersForUpdatecommercialexpirydateParams } from '../../../global/adapters/crpAPI';

export const changeExpiryDateKeys = {
  root: ['update-expiry-date'] as const,
  containersForUpdateRoot: () =>
    [...changeExpiryDateKeys.root, 'containers-for-update-expiry-date'] as const,
  containersForUpdate: (vesselParams: GetContainersForUpdatecommercialexpirydateParams) =>
    [...changeExpiryDateKeys.containersForUpdateRoot(), vesselParams] as const,
  postContainersUpdatecommercialexpirydate: () =>
    [...changeExpiryDateKeys.root, 'post-containers-update-commercial-expiry-date'] as const,
};
