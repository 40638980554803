import { TextField } from '@material-ui/core';
import React from 'react';
import useStyles from './style';

export type Field<T extends { [key: string]: any }> = {
  accessor: keyof T;
  label: string | (() => string);
  formatter?: (value: T[keyof T]) => string;
};

export type ContentFields<T extends { [key: string]: any }> = Array<Field<T>>;

type Props<T extends { [key: string]: any }> = React.ComponentPropsWithoutRef<'div'> & {
  fieldModel: T;
  fields: ContentFields<T>;
};

const DrawerContent = <T extends { [key: string]: any }>({ fieldModel, fields }: Props<T>) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {fields.map(({ accessor, label, formatter }, index) => (
        <TextField
          key={index}
          className={classes.textField}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          size={'small'}
          fullWidth
          label={typeof label === 'function' ? label() : label}
          defaultValue={
            (formatter !== undefined ? formatter(fieldModel[accessor]) : fieldModel[accessor]) ||
            '-'
          }
        />
      ))}
    </React.Fragment>
  );
};

export default DrawerContent;
