import { useQuery } from '@tanstack/react-query';
import { api } from '../../../global/adapters';
import { GetSearchContainersParams } from '../../../global/adapters/crpAPI';
import { searchContainersKeys } from './queryKeys';
import { LABEL } from 'theme/constants';

export const useSearchContainers = (searchParams: GetSearchContainersParams) => {
  const isSearchEntered =
    (searchParams.vesselName !== undefined && searchParams.vesselName !== '') ||
    searchParams.releaseRightStatus !== undefined ||
    (searchParams.imoNumber !== undefined && searchParams.imoNumber !== '') ||
    (searchParams.stayNumber !== undefined && searchParams.stayNumber !== '');

  searchParams.role = LABEL.SHIPPING_ROLE;

  return useQuery(
    searchContainersKeys.getContainersByVessel(searchParams),
    () => api.containers.getSearchContainers(searchParams),

    {
      enabled: isSearchEntered,
    }
  );
};
