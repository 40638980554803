import React from 'react';
import CheckboxHeader from './CheckboxHeader';
import CheckboxRow from './CheckboxRow';
import ClientGrid from './ClientGrid';
import ServerGrid from './ServerGrid';

type DataGridComponent = React.FunctionComponent<React.ComponentPropsWithoutRef<'div'>> & {
  Client: typeof ClientGrid;
  Server: typeof ServerGrid;
  CheckboxHeader: typeof CheckboxHeader;
  CheckboxRow: typeof CheckboxRow;
};

const DataGrid: DataGridComponent = (props) => {
  return <React.Fragment {...props} />;
};

DataGrid.Client = ClientGrid;
DataGrid.Server = ServerGrid;
DataGrid.CheckboxHeader = CheckboxHeader;
DataGrid.CheckboxRow = CheckboxRow;

export default DataGrid;
