import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import LensIcon from '@material-ui/icons/Lens';
import { GreenLight } from 'modules/global/adapters/crpAPI';
import React from 'react';
import { Field } from './Content';
import useStyles from './style';

export type GreenLightContentFields<T extends { [key: string]: GreenLight }> = Array<Field<T>>;

type Props<T extends { [key: string]: any }> = React.ComponentPropsWithoutRef<'div'> & {
  fieldModel: T;
  fields: GreenLightContentFields<T>;
};

const GreenLightContent = <T extends { [key: string]: any }>({ fieldModel, fields }: Props<T>) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {fields.map(({ accessor, label }, index) => {
        const value = fieldModel[accessor] as GreenLight;

        if (typeof value !== 'object') {
          return null;
        }

        return (
          <TextField
            key={`${index}_${value?.greenLightValue}`}
            className={classes.textField}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LensIcon htmlColor={value.color || 'Grey'} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size={'small'}
            fullWidth
            label={typeof label === 'function' ? label() : label}
            defaultValue={value.greenLightValue || '-'}
          />
        );
      })}
    </React.Fragment>
  );
};

export default GreenLightContent;
