import { mapEquipmentId, mapPage } from '../../utils/mapper';
import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.containers, action) {
  switch (action.type) {
    case ActionTypes.CONTAINERS_REQUESTED: {
      return {
        ...state,
        filters: action.payload.filters !== undefined ? action.payload.filters : state.filters,
        loading: action.payload.loading !== undefined ? action.payload.loading : true,
      };
    }
    case ActionTypes.CONTAINERS_REQUEST_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        items: mapEquipmentId(action.payload.result),
        paging: mapPage(action.payload),
      };
    }
    case ActionTypes.CONTAINER_REQUESTED: {
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true,
        }
      };
    }
    case ActionTypes.SET_ADDITIONAL_FIELDS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          additionalDataFields: action.payload,
        }
      };
    }
    case ActionTypes.CONTAINER_REQUEST_SUCCEEDED: {
      return {
        ...state,
        selected: {
          ...action.payload,
          loading: false
        }
      };
    }
    case ActionTypes.CONTAINERS_PICKUP_SUCCEEDED: {
      return {
        ...state,
        confirmationDialogs: {
          ...state.confirmationDialogs,
          pickup: {
            open: true,
            containers: action.payload.containers,
          },
        }
      }
    }
    case ActionTypes.CLOSE_PICKUP_CONFIRMATION: {
      return {
        ...state,
        confirmationDialogs: {
          ...state.confirmationDialogs,
          pickup: {
            open: false,
            containers: [],
          },
        }
      }
    }
    case ActionTypes.CONTAINERS_PICKUP_REVOKE_SUCCEEDED: {
      return {
        ...state,
        confirmationDialogs: {
          ...state.confirmationDialogs,
          pickupRevoke: {
            open: true,
            containers: action.payload.containers,
          },
        }
      }
    }
    case ActionTypes.CLOSE_PICKUP_REVOKE_CONFIRMATION: {
      return {
        ...state,
        confirmationDialogs: {
          ...state.confirmationDialogs,
          pickupRevoke: {
            open: false,
            containers: [],
          },
        }
      }
    }

    case ActionTypes.SET_REFRESH_CONTAINERS: {
      return {
        ...state,
        refreshContainers: action.payload
      }
    }
    
    
    default: {
      return state;
    }
  }
}
