import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import "react-phone-input-2/lib/style.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  DIALOGTYPES,
  LABEL,
  FORMATTING,
  DIALOG,
  CHECKBOX
} from "../../theme/constants";
import ChoiceCheckbox from "../molecules/ChoiceCheckbox";
import { clearSelectedContainers } from "../../store/actions/selectedContainerActions";

const styles = (theme) => ({
  pickupContentFields: {
    marginTop: "50px",
  },
  countryList: {
    ...theme.typography.body1,
  },
  lowerCheckbox: {
    marginTop: "5px",
    cursor: "pointer",
    userSelect: "none",
    display: "block",
  },
  statusWarning: {
    marginLeft: "50px",
    color: "red",
  },
  dialogContentWithNewLines: {
    whiteSpace: "pre-wrap",
  },
});

const BasePickUpDialog = (props) => {
  const {
    classes,
    container,
    className,
    disabled,
    variant,
    buttonTitle,
    number,
    checkNumber,
    resetNumberStatus,
    resetNumber,
    checkedContainers,
    callback,
    hideButton,
    overridePickupDialogOpen,
    setNumberError,
    contentFields,
    inputOpen,
    confirmationOpen,
    setInputOpen,
    setConfirmationOpen,
    performPickUpCall,
    clearSelectedContainers,
    selectedDriverAlias
  } = props;

  const [lawsChecked, setLawsChecked] = React.useState(false);
  const [termsChecked, setTermsChecked] = React.useState(false);

  const handleClose = useCallback(() => {
    runCallback();
    resetNumberStatus();
    setNumberError(false);
    resetFields();
  }, [resetNumberStatus]);

  const runCallback = () => {
    if (callback) {
      callback();
    }
  };

  const handleClickOpen = () => {
    setInputOpen(true);
  };

  const resetFields = () => {
    setLawsChecked(false);
    setTermsChecked(false);
    setInputOpen(false);
    setConfirmationOpen(false);
    clearSelectedContainers();
  };

  const onLawsCheckedChanged = (ch) => {
    setLawsChecked(ch);
  };

  const onTermsCheckedChanged = (ch) => {
    setTermsChecked(ch);
  };

  const handleSend = () => {
    resetNumberStatus();
    if (number!=undefined && number!='') {
      var onlyNumbers = /^[0-9]+$/.test(number);
      if (!onlyNumbers) {
        setNumberError(true);
        return;
      }
    }
    checkNumber({
      manualAlfapassInput: number, 
      selectedAlfapassInput: selectedDriverAlias
    });
  }

  const handleYes = () => {
    performPickUpCall();
    handleClose();
  };

  const closeSendActions = (
    <div>
      <Button
        onClick={() => {
          handleClose();
          resetNumber();
        }}
        color="primary"
      >
        {LABEL.CLOSE}
      </Button>
      <Button disabled={!number && !selectedDriverAlias} onClick={handleSend} color="primary">
        {LABEL.SEND}
      </Button>
    </div>
  );

  const noYesActions = (
    <div>
      <Button
        onClick={() => {
          handleClose();
          resetNumber();
        }}
        color="primary"
      >
        {LABEL.NO}
      </Button>
      <Button
        disabled={!(lawsChecked && termsChecked)}
        onClick={handleYes}
        color="primary"
      >
        {LABEL.YES}
      </Button>
    </div>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!hideButton && (
        <Button
          disabled={disabled}
          className={className}
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleClickOpen();
            callback && callback();
          }}
          variant={variant}
        >
          {buttonTitle}
        </Button>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={inputOpen || (overridePickupDialogOpen && !confirmationOpen)}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            reactLocalStorage.set(DIALOGTYPES.PICKUP_DIALOG, false);
          }
          handleClose();
          resetNumber();
        }}
        aria-labelledby={"pickup-dialog-" + container.id}
      >
        <DialogTitle id={"pickup-dialog-title-" + container.id}>
          {DIALOG.PICKUP_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines}>
            {checkedContainers.length > 0
              ? DIALOG.PICKUP_MULTIPLE_CONTENT.replace(
                FORMATTING.CONTAINER_BL_NUMBER_LIST,
                checkedContainers
                  .map((container) => {
                    return (
                      container.equipmentNumber +
                      "\t - \t [" +
                      container.billOfLadingNumbers.join(", ") +
                      "]"
                    );
                  })
                  .join("\n ")
              )
              : DIALOG.PICKUP_CONTENT.replace(
                FORMATTING.CONTAINER_NUMBER,
                container.equipmentNumber
              ).replace(
                FORMATTING.BL_NUMBER,
                "[" + container.billOfLadingNumbers.join(", ") + "]"
              )}
          </DialogContentText>
          {contentFields}
        </DialogContent>
        <DialogActions>{closeSendActions}</DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={confirmationOpen}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            reactLocalStorage.set(DIALOGTYPES.PICKUP_CONFIRM, false);
          }
          handleClose();
          resetNumber();
        }}
        aria-labelledby={"confirm-dialog-" + container.id}
      >
        <DialogTitle id={"confirm-dialog-title-" + container.id}>
          {DIALOG.PICKUP_CONFIRM_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines}>
            {checkedContainers.length > 0
              ? DIALOG.PICKUP_CONFIRM_MULTIPLE_CONTENT.replace(
                FORMATTING.PHONE_NUMBER,
                number
              ).replace(
                FORMATTING.CONTAINER_BL_NUMBER_LIST,
                checkedContainers
                  .map((container) => {
                    return (
                      container.equipmentNumber +
                      "\t - [" +
                      container.billOfLadingNumbers.join(", ") +
                      "]"
                    );
                  })
                  .join("\n ")
              )
              : DIALOG.PICKUP_CONFIRM_CONTENT.replace(
                FORMATTING.CONTAINER_NUMBER,
                container.equipmentNumber
              )
                .replace(
                  FORMATTING.BL_NUMBER,
                  "[" + container.billOfLadingNumbers.join(", ") + "]"
                )
                .replace(FORMATTING.PHONE_NUMBER, number)}
          </DialogContentText>
          <ChoiceCheckbox
            text={CHECKBOX.LAWS}
            onCheckedChanged={onLawsCheckedChanged}
            storageId={DIALOGTYPES.PICKUP_LAWS_CHANGED}
          />
          <ChoiceCheckbox
            contentClassName={classes.lowerCheckbox}
            text={CHECKBOX.TERMS_AND_CONDITIONS}
            onCheckedChanged={onTermsCheckedChanged}
            storageId={DIALOGTYPES.PICKUP_TERMS_CHANGED}
          />
        </DialogContent>
        <DialogActions>{noYesActions}</DialogActions>
      </Dialog>
    </div>
  );
};

BasePickUpDialog.propTypes = {
  container: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  numberStatus: PropTypes.string.isRequired,
  checkNumber: PropTypes.func.isRequired,
  resetNumber: PropTypes.func.isRequired,
  resetNumberStatus: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  contentFields: PropTypes.object.isRequired,
  setNumberError: PropTypes.func.isRequired,
  overridePickupDialogOpen: PropTypes.bool.isRequired,
  inputOpen: PropTypes.bool.isRequired,
  confirmationOpen: PropTypes.bool.isRequired,
  setInputOpen: PropTypes.func.isRequired,
  setConfirmationOpen: PropTypes.func.isRequired,
  performPickUpCall: PropTypes.func.isRequired,
  selectedDriverAlias: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearSelectedContainers
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(BasePickUpDialog));