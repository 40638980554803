import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'modules/global/adapters';
import {
  BulkReleaseRightActionRequest,
  GetContainersByReceivedFromParams,
} from 'modules/global/adapters/crpAPI';
import { ReleaseRightStatus } from 'modules/global/adapters/mainAPI';
import { declineReleaseRightKeys } from './queryKeys';

export const useGetActiveTerminals = () => {
  return useQuery(declineReleaseRightKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetPendingContainers = () => {
  return useQuery(declineReleaseRightKeys.getPendingContainersForReleaseRightAction(), () =>
    api.containers.getPendingContainersForReleaseRightAction()
  );
};

export const useGetContainersDeclineReleaseRight = (
  receivedFromParams: GetContainersByReceivedFromParams
) => {
  const isSearchEntered =
    (receivedFromParams.terminalNxtEntityId !== undefined &&
      receivedFromParams.terminalNxtEntityId !== '') ||
    (receivedFromParams.identificationCode !== undefined &&
      receivedFromParams.identificationCode !== '' &&
      receivedFromParams.identificationType !== undefined &&
      receivedFromParams.identificationType !== '');

  receivedFromParams.status = ReleaseRightStatus.Pending;

  return useQuery(
    declineReleaseRightKeys.containersForUpdate(receivedFromParams),
    () => api.containers.getContainersByReceivedFromParams(receivedFromParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const usePostContainersDecline = () => {
  const queryClient = useQueryClient();

  return useMutation(declineReleaseRightKeys.postContainersDecline(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersDecline(data),
    onSuccess: () => {
      // We will invalidate all queries that are used in the update terminal action
      // not a specific filtered one
      queryClient.invalidateQueries(declineReleaseRightKeys.containersForUpdateRoot());
    },
  });
};
