import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { transferReleaseRightKeys } from './queryKeys';
import { api } from 'modules/global/adapters';
import {
  BulkReleaseRightActionRequest,
  GetContainersByReceivedFromParams,
  ReleaseToPartyByRole,
} from 'modules/global/adapters/crpAPI';
import { ReleaseRightStatus } from 'modules/global/adapters/mainAPI';

export const useGetActiveTerminals = () => {
  return useQuery(transferReleaseRightKeys.getTerminals(), () => api.terminals.getTerminals());
};

export const useGetContainersTransferReleaseRight = (
  receivedFromParams: GetContainersByReceivedFromParams
) => {
  const isSearchEntered =
    Boolean(receivedFromParams.terminalNxtEntityId) ||
    (Boolean(receivedFromParams.identificationCode) &&
      Boolean(receivedFromParams.identificationType)) ||
    Boolean(receivedFromParams.billOfLadingNumber);

  receivedFromParams.status = ReleaseRightStatus.Accepted;

  return useQuery(
    transferReleaseRightKeys.containersForUpdate(receivedFromParams),
    () => api.containers.getContainersByReceivedFromParams(receivedFromParams),
    {
      enabled: isSearchEntered,
    }
  );
};

export const useGetRelevantParties = (currrentRole: string) => {
  return useQuery(transferReleaseRightKeys.getRelevantParties(currrentRole), () => {
    var releaseToPartyByRole = new ReleaseToPartyByRole(currrentRole);
    return api.releaseparties.getReleaseToParties(releaseToPartyByRole);
  });
};

export const usePostContainersTransfer = () => {
  const queryClient = useQueryClient();

  return useMutation(transferReleaseRightKeys.postContainersTransfer(), {
    mutationFn: (data: BulkReleaseRightActionRequest) =>
      api.releaserights.postContainersTransfer(data),
    onSuccess: () => {
      queryClient.invalidateQueries(transferReleaseRightKeys.containersForUpdateRoot());
    },
  });
};
