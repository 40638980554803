import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { ColumnSizingState, Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import { Formik, FormikProps } from 'formik';
import {
  ExtendedContainer,
  GetContainersByShippingAgentParams,
} from 'modules/global/adapters/crpAPI';
import { DataGrid, Drawer } from 'modules/global/components';
import EmptyState from 'modules/global/components/EmptyState/EmptyState';
import { useLocalTableValues } from 'modules/global/hooks';
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import { ContainerDetailDrawer } from '../components/shared';
import { LABEL } from 'theme/constants';
import { useGetContainersByShippingAgent } from '../adapters/revoke-releaseright/queries';
import { StepperRevokeReleaseRight } from '../components/revoke-releaseright';
import { containerOverviewColumnsWithCheckbox } from 'modules/global/constants/shared/containerOverviewColumns';
import shippingAgentParamsSchema from 'modules/global/constants/shared/shippingAgentParamsSchema';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const initialFields: GetContainersByShippingAgentParams = {
  equipmentNumber: '',
  shippingAgentNxtEntityId: LABEL.UNKNOWN,
  billOfLadingNumber: '',
};

const RevokeReleaseRight: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation(['revokeReleaseRight', 'common', 'validation']);
  const formRef = useRef<FormikProps<GetContainersByShippingAgentParams>>();

  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState<{
    open: boolean;
    data?: ExtendedContainer;
  }>({
    open: false,
    data: undefined,
  });

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const getRowSelection = React.useRef<(() => RowModel<ExtendedContainer>) | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<Row<ExtendedContainer>[] | null>(null);

  const [columnSizing, setColumnSizing] = useLocalTableValues<ColumnSizingState>(
    'column-sizing-assign-release-party',
    {}
  );

  const { data, isLoading, isFetching, isError } = useGetContainersByShippingAgent({
    equipmentNumber: formRef?.current?.values.equipmentNumber,
    shippingAgentNxtEntityId: formRef?.current?.values.shippingAgentNxtEntityId,
    billOfLadingNumber: formRef?.current?.values.billOfLadingNumber,
  });

  const handleOnSubmit = () => {
    setDisplayGrid(true);
    setRowSelection({});
  };

  React.useEffect(() => {
    isError && store.dispatch(errorMessage(t('validation:errorGetContainers')));
  }, [isError, t]);

  React.useEffect(() => {
    if (getRowSelection.current !== null) {
      setSelectedRows(getRowSelection.current().rows);
    }
  }, [rowSelection]);

  const handleCellClick = (myRowData: ExtendedContainer) => {
    setShowDetail({ open: true, data: myRowData });
  };

  return (
    <Grid container spacing={2} style={{ margin: '20px 0 0 5px' }}>
      <Grid item xs={4} sm={3}>
        <Formik
          initialValues={initialFields}
          innerRef={formRef as React.Ref<FormikProps<GetContainersByShippingAgentParams>>}
          onSubmit={handleOnSubmit}
          validationSchema={shippingAgentParamsSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            errors,
            submitForm,
            resetForm,
            values,
          }: FormikProps<GetContainersByShippingAgentParams>) => {
            return (
              <StepperRevokeReleaseRight
                errors={errors}
                selectedRows={selectedRows}
                setRowSelection={setRowSelection}
                setDisplayGrid={setDisplayGrid}
                data={data}
                values={values}
                isFetching={isFetching}
                resetForm={resetForm}
                submitForm={submitForm}
              />
            );
          }}
        </Formik>
      </Grid>
      <Grid item xs={8} sm={9}>
        {displayGrid ? (
          isFetching ? (
            <CircularProgress />
          ) : isError ? (
            <EmptyState title={t('validation:errorGetContainers')} />
          ) : (
            <React.Fragment>
              <Paper>
                <DataGrid.Client
                  columns={containerOverviewColumnsWithCheckbox}
                  columnSizing={columnSizing}
                  data={data}
                  getRowSelection={getRowSelection}
                  isLoading={isLoading}
                  onCellClick={handleCellClick}
                  onColumnSizingChange={setColumnSizing}
                  onRowSelectionChange={setRowSelection}
                  rowSelection={rowSelection}
                />
              </Paper>
              <Drawer setShowDrawer={setShowDetail} showDrawer={showDetail.open}>
                {showDetail.data !== undefined && (
                  <ContainerDetailDrawer containerId={showDetail.data.releaseId} />
                )}
              </Drawer>
            </React.Fragment>
          )
        ) : (
          <EmptyState title={t('emptyState.title')} subTitle={t('emptyState.subTitle')} />
        )}
      </Grid>
    </Grid>
  );
};

export default RevokeReleaseRight;
