import ActionTypes from './actionTypes'

export function loadUserProfile() {
  return {
    type: ActionTypes.USER_PROFILE_REQUESTED,
    payload: {},
  }
}

export function registerPaymentOption() {
  return {
    type: ActionTypes.REGISTER_PAYMENT_OPTION,
    payload: {},
  }
}

export function loadRegisterPaymentOptionSucceeded() {
  return {
    type: ActionTypes.REGISTER_PAYMENT_OPTION_SUCCEEDED
  }
}

export function pairPaymentOption() {
  return {
    type: ActionTypes.PAIR_PAYMENT_OPTION,
    payload: {},
  }
}

export function loadCreditIdentificationResponse() {
  return {
    type: ActionTypes.CREDIT_IDENTIFICATION_REQUESTED,
    payload: {},
  }
}

export function loadCreditIdentificationResponseSucceeded(
  creditIdentificationResponse
) {
  return {
    type: ActionTypes.CREDIT_IDENTIFICATION_REQUESTED_SUCCEEDED,
    payload: creditIdentificationResponse,
  }
}

export function loadPairCreditIdentificationSucceeded() {
  return {
    type: ActionTypes.PAIR_CREDIT_IDENTIFICATION_SUCCEEDED
  }
}

export function loadUserProfileSucceeded(userProfile) {
  return {
    type: ActionTypes.USER_PROFILE_REQUEST_SUCCEEDED,
    payload: userProfile,
  }
}

export function loadTokenExpired(tokenExpired) {
  return {
    type: ActionTypes.TOKEN_EXPIRED,
    payload: tokenExpired
  }
}

export function loadTokenExpiring(tokenExpiring) {
  return {
    type: ActionTypes.TOKEN_EXPIRING,
    payload: tokenExpiring
  }
}

export function updateSelectedUserType(userType) {
  return {
    type: ActionTypes.UPDATE_SELECTED_USER_TYPE,
    payload: userType,
  }
}
