import { GetContainersByPickUpParams } from 'modules/global/adapters/crpAPI';

export const updatePickUpKeys = {
  root: ['assign-pickup'] as const,
  containersForUpdateRoot: () => [...updatePickUpKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (terminalParams: GetContainersByPickUpParams) =>
    [...updatePickUpKeys.containersForUpdateRoot(), terminalParams] as const,
  postContainersUpdateBargeRailPickUp: () =>
    [...updatePickUpKeys.root, 'post-containers-update-barge-rail-pickup'] as const,
  postContainersUpdatePickUp: () =>
    [...updatePickUpKeys.root, 'post-containers-update-pickup'] as const,
};
