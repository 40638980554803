import { MenuItem, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSelectedUserType } from '../../store/actions/userProfileActions';
import { getSelectedUserType, getUserProfile } from '../../store/selectors/selectors';
import { COLORS, LABEL } from '../../theme/constants';
import { userIdToLabel, validRoles } from '../../utils/role';
import { loadSelectedContainersSucceeded } from '../../store/actions/selectedContainerActions';

const styles = (theme) => ({
  formControl: {
    minWidth: 120,
    verticalAlign: 'middle',
  },
  changeUser: {
    color: 'white',
  },
  select: {
    color: 'white',
  },
});

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: COLORS.BLACK,
      borderWidth: '1px',
    },
    '&:hover $notchedOutline': {
      borderColor: COLORS.BLACK,
      borderWidth: '1px',
    },
    '&$focused $notchedOutline': {
      borderColor: COLORS.BLACK,
      borderWidth: '1px',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const UserProfileSelect = (props) => {
  const {
    classes,
    userProfile,
    selectedUserType,
    updateSelectedUserType,
    loadSelectedContainersSucceeded,
  } = props;

  const handleChange = (event) => {
    updateSelectedUserType(event.target.value);
    loadSelectedContainersSucceeded([]);
  };

  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <>
      <FormControl className={classes.formControl}>
        <Select
          classes={{ select: classes.select }}
          value={selectedUserType}
          displayEmpty
          onChange={handleChange}
          input={<OutlinedInput classes={outlinedInputClasses} />}
        >
          <MenuItem value={''} disabled>
            {LABEL.CHANGE_USER}
          </MenuItem>
          {validRoles(userProfile.roles).map((role) => {
            switch (userIdToLabel(role.id)) {
              case LABEL.FORWARDER:
                return (
                  <MenuItem key={LABEL.FORWARDER} value={LABEL.FORWARDER}>
                    {LABEL.FORWARDER}
                  </MenuItem>
                );
              case LABEL.TERMINAL:
                return (
                  <MenuItem key={LABEL.TERMINAL} value={LABEL.TERMINAL}>
                    {LABEL.TERMINAL}
                  </MenuItem>
                );
              case LABEL.SHIPPING:
                return (
                  <MenuItem key={LABEL.SHIPPING} value={LABEL.SHIPPING}>
                    {LABEL.SHIPPING_AGENT}
                  </MenuItem>
                );
              case LABEL.POLICE:
                return (
                  <MenuItem key={LABEL.POLICE} value={LABEL.POLICE}>
                    {LABEL.POLICE}
                  </MenuItem>
                );
              case LABEL.TRANSPORTOPERATOR_LABEL:
                return (
                  <MenuItem
                    key={LABEL.TRANSPORTOPERATOR_LABEL}
                    value={LABEL.TRANSPORTOPERATOR_LABEL}
                  >
                    {LABEL.TRANSPORTOPERATOR_LABEL}
                  </MenuItem>
                );
              default:
                return <div />;
            }
          })}
        </Select>
      </FormControl>
    </>
  );
};

UserProfileSelect.propTypes = {
  userProfile: PropTypes.object.isRequired,
  selectedUserType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userProfile: getUserProfile(state),
    selectedUserType: getSelectedUserType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSelectedUserType,
      loadSelectedContainersSucceeded,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfileSelect));
