import ActionTypes from '../actions/actionTypes';
import InitialState from './initialState';

export default function (state = InitialState.driverList, action) {
  switch (action.type) {
    case ActionTypes.DRIVERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.DRIVERLIST_REQUEST_SUCCEEDED: {
      return {
        items: action.payload,
        refreshDriverList: false,
        loading: false,
      };
    }
    case ActionTypes.ADD_TO_DRIVER_LIST: {
      return {
        ...state,
        refreshDriverList: true,
      };
    }
    case ActionTypes.DELETE_FROM_DRIVER_LIST: {
      return {
        ...state,
        refreshDriverList: true,
      };
    }
    case ActionTypes.UPDATE_DRIVER_LIST: {
      return {
        ...state,
        refreshDriverList: true,
      };
    }

    default: {
      return state;
    }
  }
}
