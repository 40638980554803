import { dateTime } from 'date-fns/locale/af';

export default class DateTimeHelper {
  static isInFuture = (dateTime) => {
    return new Date(dateTime) > new Date();
  };

  static isFromToday = (dateTime) => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    return new Date(dateTime) >= d;
  };

  static greaterThanOrEqual = (dateTime1, dateTime2) => {
    return new Date(dateTime1) >= new Date(dateTime2);
  };

  static greaterThan = (dateTime1, dateTime2) => {
    return new Date(dateTime1) > new Date(dateTime2);
  };

  static lessThanOrEqual = (dateTime1, dateTime2) => {
    return new Date(dateTime1) <= new Date(dateTime2);
  };

  static lessThan = (dateTime1, dateTime2) => {
    return new Date(dateTime1) < new Date(dateTime2);
  };
}
