import DisplayNotifications from 'modules/terminal/pages/DisplayNotifications';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import store from 'store/store';
import { api, mainAPI } from '../../global/adapters';
import { ROUTE_PATHS } from '../constants';
import { AssignPickUp, UseCases, SearchContainers } from '../pages';
import AcceptReleaseRight from '../pages/AcceptReleaseRight';
import RevokeReleaseRight from '../pages/RevokeReleaseRight';
import DeclineReleaseRight from '../pages/DeclineReleaseRight';
import UpdatePickUp from '../pages/UpdatePickUp';
import TransferReleaseRight from '../pages/TransferReleaseRight';

type Props = React.ComponentPropsWithoutRef<'div'> & { creditIdentificationIsValid: boolean };

const BetaTransportOperatorRoutes: React.FunctionComponent<Props> = ({
  creditIdentificationIsValid,
}) => {
  const userState = store.getState().oidc.user;
  api.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });
  mainAPI.setSecurityData({ token: userState.access_token || '', tokenType: 'Bearer' });

  return (
    <React.Fragment>
      <Route path={ROUTE_PATHS.UPDATE_PICKUP} exact component={UpdatePickUp} />
      <Route path={ROUTE_PATHS.ASSIGN_PICKUP} exact component={AssignPickUp} />
      <Route path={ROUTE_PATHS.REVOKE_RELEASE_RIGHT} exact component={RevokeReleaseRight} />
      <Route
        path={ROUTE_PATHS.ACCEPT_RELEASE_RIGHT}
        exact
        render={() =>
          creditIdentificationIsValid ? <AcceptReleaseRight /> : <Redirect to="/blocked" />
        }
      />
      <Route path={ROUTE_PATHS.TRANSFER_RELEASE_RIGHT} exact component={TransferReleaseRight} />
      <Route path={ROUTE_PATHS.DECLINE_RELEASE_RIGHT} exact component={DeclineReleaseRight} />
      <Route path={ROUTE_PATHS.DISPLAY_NOTIFICATIONS} exact component={DisplayNotifications} />
      <Route path={ROUTE_PATHS.BASE_ROUTE} exact component={UseCases} />
      <Route path={ROUTE_PATHS.SEARCH_CONTAINERS} exact component={SearchContainers} />
    </React.Fragment>
  );
};

export default BetaTransportOperatorRoutes;
