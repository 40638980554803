import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { reactLocalStorage } from 'reactjs-localstorage';
import { LABEL } from '../../theme/constants';
import ChoiceCheckbox from '../molecules/ChoiceCheckbox';

function ConfirmationDialog(props) {
  const { open, fullWidth, maxWidth, dialogType, dialogTitle, contentText, callback, setOpen } =
    props;

  const contentFields = <ChoiceCheckbox storageId={dialogType} text={LABEL.REMEMBER_MY_CHOICE} />;

  const handleClose = () => {
    setOpen(false);
  };

  const runCallback = () => {
    if (callback) {
      callback();
    }
  };

  const actions = (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          reactLocalStorage.set(dialogType, false);
          handleClose();
        }}
        variant="contained"
        style={{ background: '#ebebeb', marginRight: '5px' }}
      >
        {LABEL.NO}
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          runCallback();
          handleClose();
        }}
        color="primary"
        variant="contained"
      >
        {LABEL.YES}
      </Button>
    </div>
  );

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          reactLocalStorage.set(dialogType, false);
        }
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {contentFields}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
