import { createUserManager } from 'redux-oidc';
import { CALLBACK_URL, CLIENT_ID, IDENTITY_URL, USECPOINT2FA, USERISEXPIRING_WARNING_INMINUTES } from '../config/environment';
import { loadTokenExpired, loadTokenExpiring } from '../store/actions/userProfileActions';
import store from '../store/store';
import React from 'react';
import {
  Link
} from '@material-ui/core';

const userManagerConfig = {
  client_id: CLIENT_ID,
  redirect_uri: CALLBACK_URL,
  response_type: 'code',
  scope: 'openid nxt:user nxt:cpu:main:api',
  authority: IDENTITY_URL,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  acr_values: USECPOINT2FA === 'true' ? 'idp:apcs2fa' : '',
  accessTokenExpiringNotificationTime: USERISEXPIRING_WARNING_INMINUTES == null ? 300 : USERISEXPIRING_WARNING_INMINUTES * 60
};

const userManager = createUserManager(userManagerConfig);

userManager.events.addAccessTokenExpired(() => {
  store.dispatch(loadTokenExpired(true));
})

userManager.events.addAccessTokenExpiring(() => {
  store.dispatch(loadTokenExpiring(true));
})

export default userManager;
