import { createColumnHelper, FilterFn, Row } from '@tanstack/react-table';
import { ReleaseParty } from '../../../global/adapters/crpAPI';
import i18n from '../../../global/config/i18n';
import { mapToFriendlyRoles } from 'modules/global/helpers/mapper';

const columnHelper = createColumnHelper<ReleaseParty>();

const arrayFilter: FilterFn<ReleaseParty> = (
  row: Row<ReleaseParty>,
  columnId: string,
  filterValue: string
) => {
  const values: string[] = row.getValue(columnId);

  return values.some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
};

export const partyColumns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    size: 250,
    header: () => i18n.t('glossary:name'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('nxtEntityId', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:nxtEntityId'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('vat', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:taxId'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('apcs', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:apcsCode'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('duns', {
    cell: (info) => info.getValue(),
    header: () => i18n.t('glossary:duns'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('eori', {
    cell: (info) => info.getValue(),
    size: 240,
    header: () => i18n.t('glossary:eori'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('scac', {
    cell: (info) => info.getValue(),
    size: 230,
    header: () => i18n.t('glossary:scac'),
    filterFn: 'includesString',
  }),
  columnHelper.accessor('cpuRoles', {
    cell: (info) =>
      info
        .getValue()
        ?.map((v, i) => mapToFriendlyRoles(v))
        .join(', '),
    size: 200,
    header: () => i18n.t('glossary:cpuRoles'),
    filterFn: arrayFilter,
  }),
];
