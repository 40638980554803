import { Dialog, Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import HourglassFull from '@material-ui/icons/HourglassFull';
import PropTypes from 'prop-types';
import React from 'react';
import history from '../../history/browserHistory';
import { DIALOG } from '../../theme/constants';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
    minWidth: '300px',
  },
  sentimentIcon: {
    fontSize: '60px',
  },
  sentimentIconWrap: {
    textAlign: 'center',
  },
});
let loadingTimeout = {};

class LoaderTimeout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  homeClick = () => {
    history.push('/');
    this.setState({ dialogOpen: false });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { loading, classes } = this.props;
    const { dialogOpen } = this.state;
    clearTimeout(loadingTimeout);
    if (loading === true) {
      loadingTimeout = setTimeout(() => {
        this.setState({ dialogOpen: true });
      }, 199000);
    }
    return (
      <Dialog
        open={dialogOpen}
        onClose={(e) => {
          this.handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{DIALOG.LOADING_TIMEOUT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentWithNewLines}>
            {DIALOG.TIMEOUT_TEXT}
            <br />
          </DialogContentText>
          <div className={classes.sentimentIconWrap}>
            <br />
            <HourglassFull color="disabled" className={classes.sentimentIcon} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.homeClick}>
            Home
          </Button>
          <Button color="primary" onClick={this.handleClose}>
            Keep waiting
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LoaderTimeout.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LoaderTimeout);
