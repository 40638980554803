import { Fragment, useState, useEffect } from 'react';
import {
  TextField,
  TableRow,
  TableCell,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Done as DoneIcon, Delete as DeleteIcon } from '@material-ui/icons';

export interface IEditItem {
  Key: number;
  Value: string;
  Validated: boolean;
}

export class EditItem implements IEditItem {
  Key: number;
  Value: string;
  Validated: boolean = false;

  constructor(key: number, value: string, isValidated: boolean) {
    this.Key = key;
    this.Value = value;
    this.Validated = isValidated;
  }
}

type Props = {
  DeleteHandler: Function;
  ChangeHandler: Function;
  Validate: Function;
  ItemValue: string;
  ItemKey: number;
  ItemIsValid: boolean;
  TextMaxLength: number;
  ValidationDone: boolean;
};

const EditableListItem = ({
  DeleteHandler,
  ChangeHandler,
  Validate,
  ItemValue,
  ItemKey,
  ItemIsValid,
  TextMaxLength,
  ValidationDone,
}: Props) => {
  const [itemValue, setItemValue] = useState<string | undefined>(ItemValue);
  const [itemKey, setItemKey] = useState<number>(ItemKey);
  const [itemIsValid, setItemIsValid] = useState<boolean>(ItemIsValid);
  const [validationDone, setValidationDone] = useState<boolean>(ValidationDone);

  const valueIsEmpty = () => {
    return (
      itemValue == null || itemValue == '' || itemValue.trim() == '' || itemValue.trim().length < 4
    );
  };

  const clickDone = () => {
    if (valueIsEmpty()) return;
    setItemIsValid(false);
    setValidationDone(false);
    Validate(new EditItem(itemKey, itemValue!, false)).then((editItem: EditItem) => {
      if (editItem === undefined) {
        setValidationDone(true);
        return;
      }
      setItemValue(editItem.Value);
      setItemKey(editItem.Key);
      setItemIsValid(editItem.Validated);
      setValidationDone(true);
    });
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell key={itemKey}>
          {itemIsValid && (
            <Typography>
              <p style={{ color: 'green' }}>{itemValue}</p>
            </Typography>
          )}
          {!itemIsValid && (
            <TextField
              fullWidth
              name={itemKey.toString()}
              margin="normal"
              value={itemValue}
              onChange={(e) => {
                ChangeHandler(+e.target.name, e.target.value);
                setItemValue(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: TextMaxLength }}
            />
          )}
        </TableCell>
        <TableCell align="right">
          {validationDone && (
            <Button
              onClick={() => {
                clickDone();
              }}
              style={valueIsEmpty() ? { color: 'lightgray' } : { color: 'black' }}
            >
              {!itemIsValid && <DoneIcon />}
            </Button>
          )}
          {!validationDone && <CircularProgress size="14px" />}
          <Button onClick={() => DeleteHandler(itemKey)}>
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default EditableListItem;
