import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionScreen } from '../../global/components/ActionScreen';
import { ActionGroup } from '../../global/components/ActionScreen/ActionScreen';
import { ROUTE_PATHS } from '../constants';
import { useDashboardRequest } from '../adapters/dashboard/queries';
import DashboardScreen, {
  DashboardGroup,
} from 'modules/global/components/ActionScreen/DashboardScreen';

type Props = React.ComponentPropsWithoutRef<'div'> & {};

const UseCases: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation('useCase');

  const { data, isLoading, isFetching, isError } = useDashboardRequest();

  const outgoingReleaseRights = data?.numberOfOutgoingReleaseRights;
  const numberOfCommercialReleases = data?.numberOfCommercialReleases;
  const numberOfBlockedReleases = data?.numberOfBlockedReleaseRights;
  const declinedReleaseRights = data?.numberOfDeclinedReleaseRights;

  const shippingAgentTiles: DashboardGroup = {
    tiles: [
      {
        title: t('tiles.outgoingReleaseRights.title'),
        textBody: t('tiles.outgoingReleaseRights.body', { number: outgoingReleaseRights }),
      },
      {
        title: t('tiles.declinedReleaseRights.title'),
        textBody: t('tiles.declinedReleaseRights.body', { number: declinedReleaseRights }),
      },
      {
        title: t('tiles.numberOfCommercialReleases.title'),
        textBody: t('tiles.numberOfCommercialReleases.body', {
          number: numberOfCommercialReleases,
        }),
      },
      {
        title: t('tiles.numberOfBlockedReleases.title'),
        textBody: t('tiles.numberOfBlockedReleases.body', {
          number: numberOfBlockedReleases,
        }),
      },
    ],
    fetchingText: t('tiles.isFetching.body'),
  };

  const shippingLineActions: ActionGroup[] = [
    {
      category: t('actions.manage.title'),
      actions: [
        {
          title: t('actions.manage.changeVoyageTerminal.title'),
          textBody: t('actions.manage.changeVoyageTerminal.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.CHANGE_TERMINAL,
        },
        {
          title: t('actions.manage.updateCommercialExpiryDate.title'),
          textBody: t('actions.manage.updateCommercialExpiryDate.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.UPDATE_EXPIRY_DATE,
        },
        {
          title: t('actions.manage.updateVoyage.title'),
          textBody: t('actions.manage.updateVoyage.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.UPDATE_VOYAGE,
        },
        {
          title: t('actions.manage.blockVoyage.title'),
          textBody: t('actions.manage.blockVoyage.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.BLOCK_VOYAGE,
        },
        {
          title: t('actions.manage.unblockVoyage.title'),
          textBody: t('actions.manage.unblockVoyage.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.UNBLOCK_VOYAGE,
        },
        {
          title: t('actions.manage.assignRelease.title'),
          textBody: t('actions.manage.assignRelease.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.ASSIGN_RELEASE_PARTY,
        },
        {
          title: t('actions.manage.deleteVoyage.title'),
          textBody: t('actions.manage.deleteVoyage.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.DELETE_VOYAGE,
        },
        {
          title: t('actions.manage.revokeVoyage.title'),
          textBody: t('actions.manage.revokeVoyage.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.REVOKE_VOYAGE,
        },
        {
          title: t('actions.manage.createMultipleCommercialReleasesPerVessel.title'),
          textBody: t('actions.manage.createMultipleCommercialReleasesPerVessel.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.CREATE_MULTIPLE_RELEASES_UPLOAD,
        },
        {
          title: t('actions.manage.overruleCustomsLight.title'),
          textBody: t('actions.manage.overruleCustomsLight.body'),
          type: 'UPDATE',
          url: ROUTE_PATHS.OVERRULE_CUSTOMS_LIGHT,
        },
      ],
    },
    {
      category: t('actions.consult.title'),
      actions: [
        {
          title: t('actions.consult.notification.title'),
          textBody: t('actions.consult.notification.body'),
          type: 'READ',
          url: ROUTE_PATHS.DISPLAY_NOTIFICATIONS,
        },
        {
          title: t('actions.manage.searchContainersPerVoyage.title'),
          textBody: t('actions.manage.searchContainersPerVoyage.body'),
          type: 'READ',
          url: ROUTE_PATHS.SEARCH_PER_VOYAGE,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <DashboardScreen dashboardGroup={shippingAgentTiles} isFetching={isFetching} />
      <ActionScreen actionGroup={shippingLineActions} />
    </React.Fragment>
  );
};

export default UseCases;
