import {
  Paper,
  Table,
  Typography,
  Tooltip,
  Button,
  TableBody,
  TablePagination,
  Box,
  TableRow,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import TableHead from '../molecules/ContainerTableHead';
import CenterTableCell from '../atoms/CenterTableCell';
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTableRow } from '../../theme/theme';
import ActionDialog from '../organisms/ActionDialog';
import {
  loadPoliceSubscriptions,
  addPoliceSubscription,
  removePoliceSubscription,
  loadSubscriptionsPage,
  loadSubscriptionsRowsPerPage,
} from '../../store/actions/subscriptionActions';
import { setPoliceCase, loadPoliceReleaseAudit } from '../../store/actions/policeContainerActions';
import { errorMessage, successMessage } from '../../store/actions/messageActions';
import {
  getSubscriptions,
  getSubscriptionsLoading,
  getSubscriptionsLoadingNew,
  getSubscriptionsPaging,
  getPoliceContainersPoliceCase,
} from '../../store/selectors/selectors';
import * as moment from 'moment';
import { COLORS, LABEL, TOOLTIPS, DIALOG, LightColor } from '../../theme/constants';
import LoaderTimeout from '../organisms/LoaderTimeout';
import CreditInfo from '../molecules/CreditInfo';
import FormikDialog from '../organisms/formikDialog';
import createPoliceSubscriptionSchema from '../../utils/validation/schemes/createPoliceSubscription';
import updatePoliceCaseSchema from '../../utils/validation/schemes/updatePoliceCase';
import { reactLocalStorage } from 'reactjs-localstorage';

const styles = (theme) => ({
  content: {
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  titleBar: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  iconText: {
    margin: '0px',
    marginTop: '5px',
    fontSize: '11px',
    color: COLORS.GREY,
  },
  errorText: {
    fontSize: '0.750rem',
    color: 'red',
  },
  breadBar: {
    marginBottom: theme.spacing(2),
  },
  xsTextPlaceholder: {
    width: '100%',
    maxWidth: '60px',
    height: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  smTextPlaceholder: {
    width: '100%',
    maxWidth: '90px',
    height: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  mdTextPlaceholder: {
    width: '100%',
    maxWidth: '120px',
    height: theme.spacing(2),
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  iconButtonPlaceholder: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  statusIconPlaceholder: {
    width: theme.spacing(6),
    height: theme.spacing(5),
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    display: 'inline-block',
  },
  '@keyframes shine': {
    '0%': { backgroundPosition: '100%' },
    '100%': { backgroundPosition: '-100%' },
  },
});

const skeletalRows = (number, classes) => {
  const rows = [];
  for (let index = 0; index < number; index++) {
    rows.push(
      <TableRow>
        <CenterTableCell text={<div className={classes.mdTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.xsTextPlaceholder} />} />
        <CenterTableCell text={<div className={classes.statusIconPlaceholder} />} />
      </TableRow>
    );
  }
  return rows;
};

const statusLogo = (status) => {
  switch (status) {
    case LightColor.Grey:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#808080'} />;
    case LightColor.Yellow:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#dfc920'} />;
    case LightColor.Orange:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#f57b00'} />;
    case LightColor.Red:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#e60000'} />;
    case LightColor.Green:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#4AB55F'} />;
    default:
      return <FiberManualRecordIcon fontSize={'default'} htmlColor={'#808080'} />;
  }
};

function PoliceContainerSubscriptions(props) {
  const {
    classes,
    items,
    paging,
    loading,
    loadingNew,
    loadPoliceSubscriptions,
    addPoliceSubscription,
    removePoliceSubscription,
    loadSubscriptionsPage,
    loadSubscriptionsRowsPerPage,
    policeCase,
    setPoliceCase,
    loadPoliceReleaseAudit,
  } = props;

  useEffect(() => {
    loadPoliceSubscriptions();
  }, []);

  let columns = [
    { id: 'subscribedOn', label: LABEL.SUBSCRIBED_ON },
    { id: 'billOfLadingNumber', label: LABEL.BILL_OF_LADING_LABEL },
    { id: 'equipmentNumber', label: LABEL.EQUIPMENT_NUMBER_LABEL },
    { id: 'shippingAgent', label: LABEL.SHIPPING_AGENT_LABEL },
    { id: 'firstReleaseParty', label: LABEL.FIRST_RELEASE_PARTY_LABEL },
    { id: 'transportOperator', label: LABEL.TRANSPORTOPERATOR_LABEL },
    { id: 'terminal', label: LABEL.TERMINAL_LABEL },
    { id: 'status', label: LABEL.RELEASE_STATUS.toUpperCase() },
    { id: 'action', label: LABEL.ACTION_LABEL },
  ];

  const createEmptySubscription = () => {
    return {
      equipmentNumber: '',
      billOfLadingNumbers: '',
    };
  };

  const [newSubscription, setNewSubscription] = React.useState(createEmptySubscription());
  const [selectedSubscription, setSelectedSubscription] = React.useState(createEmptySubscription());
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const handleChangePage = (event, page) => {
    loadSubscriptionsPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    loadSubscriptionsRowsPerPage(event.target.value);
  };

  const handleSubmit = (values) => {
    addPoliceSubscription(values);
    closeAddDialog();
  };

  const handleSubmitPoliceCase = (values) => {
    var json = JSON.stringify(values);
    reactLocalStorage.set('policeCase', json);
    setPoliceCase(values);
    loadPoliceReleaseAudit(
      values,
      selectedSubscription.releaseIdentification,
      selectedSubscription.billOfLadingNumbers,
      selectedSubscription.equipmentNumber,
      false
    );
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
    setNewSubscription(createEmptySubscription());
  };

  const closeDetailDialog = () => {
    setDetailDialogOpen(false);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogOpen(false);
    setSelectedSubscription(createEmptySubscription());
  };

  const deleteSubscription = () => {
    removePoliceSubscription(selectedSubscription);
    closeRemoveDialog();
  };

  const removeSubscriptionActions = (
    <div>
      <Button onClick={closeRemoveDialog}>cancel</Button>
      <Button onClick={deleteSubscription}>Yes</Button>
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.breadBar}>
        <CreditInfo />
      </div>
      <main className={classes.content}>
        <Paper>
          <div className={classes.titleBar}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h6" id="tableTitle">
                  {LABEL.SUBSCRIPTIONS}
                </Typography>
              </Box>
              <Box pl={0.5} pr={0.5}>
                <Button
                  style={{ padding: '14px 12px' }}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setAddDialogOpen(true)}
                >
                  <AddIcon /> {LABEL.NEW_SUBSCRIPTION}
                </Button>
              </Box>
            </Box>
          </div>
          <Divider />
          <Table aria-labelledby="tableTitle" style={{ width: '100%' }}>
            <TableHead className={classes.wideCell} columns={columns} centerAlign={true} />
            <TableBody>
              {loading && skeletalRows(25, classes)}
              {!loading && loadingNew && skeletalRows(1, classes)}
              {!loading &&
                items &&
                items.map((subscription, index) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={index} className={classes.tableRow}>
                      <CenterTableCell
                        text={moment(subscription.subscribedOn).format('DD-MM-YYYY  HH:mm')}
                      />
                      <CenterTableCell text={subscription.billOfLadingNumbers} />
                      <CenterTableCell text={subscription.equipmentNumber} />
                      <CenterTableCell text={subscription.shippingAgentName} />
                      <CenterTableCell text={subscription.firstReleasePartyName} />
                      <CenterTableCell text={subscription.transportOperatorName} />
                      <CenterTableCell text={subscription.terminalName} />
                      <CenterTableCell
                        text={subscription.status !== '' ? statusLogo(subscription.status) : ''}
                      />
                      <CenterTableCell
                        text={
                          <main>
                            <Tooltip title={TOOLTIPS.OPEN_DETAIL}>
                              <span>
                                <Button
                                  variant="text"
                                  color="primary"
                                  className={classes.wideCell}
                                  disabled={subscription.releaseIdentification == null}
                                  onClick={() => {
                                    setDetailDialogOpen(true);
                                    setSelectedSubscription(subscription);
                                  }}
                                >
                                  <LaunchIcon />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title={TOOLTIPS.REMOVE_SUBSCRIPTION}>
                              <Button
                                variant="text"
                                color="primary"
                                className={classes.wideCell}
                                onClick={() => {
                                  setRemoveDialogOpen(true);
                                  setSelectedSubscription(subscription);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </main>
                        }
                      />
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
          {paging && (
            <TablePagination
              component="div"
              count={paging.count}
              rowsPerPage={paging.rowsPerPage}
              page={paging.page}
              rowsPerPageOptions={[25, 50, 100, 250]}
              backIconButtonProps={{
                'aria-label': LABEL.PREVIOUS_PAGE,
              }}
              nextIconButtonProps={{
                'aria-label': LABEL.NEXT_PAGE,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </main>
      <FormikDialog
        initialValues={newSubscription}
        open={addDialogOpen}
        handleClose={closeAddDialog}
        handleSubmit={handleSubmit}
        dialogTitle={DIALOG.ADD_SUBSCRIPTION_TITLE}
        dialogContentText={DIALOG.ADD_SUBSCRIPTION_TEXT}
        inEditMode={editMode}
        schema={createPoliceSubscriptionSchema}
        classes={classes}
        columnSize={6}
      />
      <ActionDialog
        dialogTitle={DIALOG.REMOVE_SUBSCRIPTION_TITLE}
        contentText={DIALOG.REMOVE_SUBSCRIPTION_TEXT}
        actions={removeSubscriptionActions}
        handleClose={closeRemoveDialog}
        open={removeDialogOpen}
      />
      <FormikDialog
        initialValues={policeCase}
        dialogTitle={DIALOG.ACTIONDIALOG_TITLE}
        dialogContentText={DIALOG.ACTIONDIALOG_CONTENT}
        classes={classes}
        handleClose={closeDetailDialog}
        handleSubmit={handleSubmitPoliceCase}
        open={detailDialogOpen}
        schema={updatePoliceCaseSchema}
        columnSize={6}
      />
      <LoaderTimeout loading={loading || loadingNew} />
    </React.Fragment>
  );
}

PoliceContainerSubscriptions.propTypes = {
  items: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  loadPoliceSubscriptions: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
  removeSubscription: PropTypes.func.isRequired,
  loadSubscriptionsPage: PropTypes.func.isRequired,
  loadSubscriptionsRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    items: getSubscriptions(state),
    paging: getSubscriptionsPaging(state),
    loading: getSubscriptionsLoading(state),
    loadingNew: getSubscriptionsLoadingNew(state),
    policeCase: getPoliceContainersPoliceCase(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPoliceSubscriptions,
      addPoliceSubscription,
      removePoliceSubscription,
      setPoliceCase,
      loadPoliceReleaseAudit,
      loadSubscriptionsPage,
      loadSubscriptionsRowsPerPage,
      errorMessage,
      successMessage,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PoliceContainerSubscriptions));
