import { GetContainersByVoyageParams } from '../../../global/adapters/crpAPI';

export const assignReleasePartyKeys = {
  root: ['assign-release-party'] as const,
  containersForUpdateRoot: () => [...assignReleasePartyKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (vesselParams: GetContainersByVoyageParams) =>
    [...assignReleasePartyKeys.containersForUpdateRoot(), vesselParams] as const,
  postContainersForUpdateReleaseParty: () => [
    ...assignReleasePartyKeys.root,
    'post-containers-update-release-party',
  ],
  getRelevantParties: (currrentRole: string) =>
    [...assignReleasePartyKeys.root, 'get-relevant-parties'] as const,
};
