import userManager from '../utils/userManager';
import { ALFAPASS_TOKEN_ENDPOINT } from '../config/environment.js';

export function logOut() {
  const alfapassToken = localStorage.getItem('alfapassToken');
  var redirect_uri = null;
  if (alfapassToken != null) {
    const authorizeEndpoint = ALFAPASS_TOKEN_ENDPOINT;
    const alfapassLogoutUri = authorizeEndpoint.replace('token', 'logout');
    const postLogoutRedirectUri = window.location.origin;
    const obj = JSON.parse(alfapassToken);
    const idTokenHint = obj.id_token;
    redirect_uri = `${alfapassLogoutUri}?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${postLogoutRedirectUri}`;
  }
  localStorage.removeItem('codeVerifier');
  localStorage.removeItem('alfapassToken');
  userManager.revokeAccessToken();
  if (redirect_uri == null) {
    userManager.signoutRedirect();
    return;
  }
  userManager.signoutRedirectCallback().then((response) => {
    if (redirect_uri != null) {
      window.location.href = redirect_uri;
      userManager.signoutRedirect();
    }
  });
}
