import { Box, FormHelperText, InputLabel, MenuItem, TextField } from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Terminal } from 'modules/global/adapters/crpAPI';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import useStyles from '../shared/styles';
import IdentificationSelect from 'modules/global/components/IdentificationSelect/IdentificationSelect';
import { LABEL } from 'theme/constants';

type Props = {
  terminals: Terminal[] | undefined;
  terminalNxtEntityIdError: string | undefined;
  isLoadingError: boolean;
  isFetching: boolean;
};

const SearchStep = ({ terminals, terminalNxtEntityIdError, isLoadingError, isFetching }: Props) => {
  const { t } = useTranslation(['glossary', 'useCase']);
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const terminalNxtEntityIdHasError = Boolean(terminalNxtEntityIdError);

  React.useEffect(() => {
    isLoadingError &&
      store.dispatch(errorMessage(t('messages.errorGetTransferReleaseRightFilterData')));
  }, [isLoadingError, t]);

  React.useEffect(() => {
    console.log(values);
  }, []);

  return (
    <React.Fragment>
      <Box marginTop={2}>
        <Field name="terminalNxtEntityId" id="terminalNxtEntityId">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <InputLabel id="terminalName">{t('terminalName')}</InputLabel>
              <FormikSelect
                defaultValue={undefined}
                form={form}
                field={field}
                error={terminalNxtEntityIdHasError}
              >
                {terminals &&
                  terminals.map((terminal: Terminal, index: number) => (
                    <MenuItem value={terminal.nxtEntityId} key={`terminal_${index}`}>
                      {terminal.name + '( ' + terminal.nxtEntityId + ' )'}
                    </MenuItem>
                  ))}
              </FormikSelect>
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>{terminalNxtEntityIdError}</FormHelperText>
      </Box>
      <Box marginTop={2}>
        <IdentificationSelect
          showText={false}
          label={LABEL.RECEIVED_FROM}
          translationKey="transferReleaseRight"
          textLabel=""
        />
      </Box>
      <Box marginTop={2}>
        <Field name="billOfLadingNumber" id="billOfLadingNumber">
          {({ field }: FieldProps) => (
            <TextField {...field} variant="outlined" label={t('billOfLadingNumber')} fullWidth />
          )}
        </Field>
      </Box>
    </React.Fragment>
  );
};
export default SearchStep;
