import * as Yup from 'yup';
import moment from 'moment';

const displayBillingInfoSchema = Yup.object().shape(
  {
    month: Yup.string().required('Required'),
    year: Yup.number()
      .integer()
      .min(moment().year() - 1)
      .max(moment().year() + 1)
      .required('Required'),
  },
  [
    ['month', 'month'],
    ['year', 'year'],
  ]
);

export default displayBillingInfoSchema;
