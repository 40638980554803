import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CrpCheckbox from "../atoms/CrpCheckbox";
import { LABEL } from '../../theme/constants';

const styles = {
  checkBoxCell: {
    width: 90,
  },
  headerTitle: {
    color: '#A2A2A2',
    borderStyle: 'hidden',
  }
};

class ContainerTableHead extends React.Component {
  render() {
    const { onSelectAllClick, classes, selectAllChecked, columns, checkboxes, centerAlign } = this.props;

    return (
      <TableHead>
        <TableRow>
          { checkboxes &&
          <TableCell className={classes.headerTitle}>
            <CrpCheckbox
              onChange={onSelectAllClick}
              checked={selectAllChecked}
            />
          </TableCell>
          }
          {columns.map(column => {
            return (
              <TableCell
                align={centerAlign ? 'center' : 'inherit'}
                className={classes.headerTitle}
                key={column.id}
                colSpan = {column.colSpan?column.colSpan:1}
                numeric={column.numeric}>
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

ContainerTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  checkboxes: PropTypes.bool,
  onSelectAllClick: PropTypes.func,
  selectAllChecked: PropTypes.bool
};

export default withStyles(styles)(ContainerTableHead);
