import { Paper } from '@material-ui/core';
import { Row, RowSelectionState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import {
  ModalContainer,
  MeansOfTransport,
  GetContainersByPickUpParams,
  PickUpContainer,
  BulkPickUpActionRequestItem,
} from 'modules/global/adapters/crpAPI';
import StepUseCase from 'modules/global/components/Stepper/StepUseCase';
import { useGetActiveTerminals } from 'modules/transport-operator/adapters/assign-pickup/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from 'modules/transport-operator/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResultStep } from '../shared';
import FilterStep from './FilterStep';
import UpdateStep from './UpdateStep';
import PickUpSearchStep from './PickUpSearchStep';
import {
  usePostContainersUpdateBargeRailPickUp,
  usePostContainersUpdatePickUp,
} from 'modules/transport-operator/adapters/update-pickup/queries';

type Props = {
  data: PickUpContainer[] | undefined;
  errors: FormikErrors<GetContainersByPickUpParams>;
  isFetching?: boolean;
  selectedRows: Row<PickUpContainer>[] | null;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByPickUpParams;
};

const StepperUpdatePickUp = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setRowSelection,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
}: Props) => {
  const { t } = useTranslation(['assignPickUp', 'common', 'useCase', 'validation']);
  const [newMeansOfTransport, setMeansOfTransport] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);
  const [pickupAssignments, setPickupAssignments] = React.useState<BulkPickUpActionRequestItem[]>(
    []
  );

  const isTruck = newMeansOfTransport === MeansOfTransport.Truck;
  const terminalNxtEntityIdError = Boolean(errors.terminalNxtEntityId);

  const selectedContainers =
    data &&
    selectedRows?.map(
      (row) =>
        ({
          releaseId: row.original.releaseId,
          equipmentNumber: row.original.equipmentNumber,
          billOfLadingNumbers: row.original.billOfLadingNumbers,
          alfapassNumber: '',
          isValid: undefined,
          isValidated: false,
        } as ModalContainer)
    );
  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const resetFormAndContainersUpdated = () => {
    resetForm();
    setRowSelection({});
    setContainersUpdated(0);
    resetPickUpAssignments();
    setMeansOfTransport('');
  };

  const {
    data: terminals,
    isFetching: isFetchingTerminals,
    isError: isErrorTerminals,
  } = useGetActiveTerminals();

  const { mutate: mutateBargeRail, status: bargeRailStatus } =
    usePostContainersUpdateBargeRailPickUp();
  const { mutate: mutateTruck, status: truckStatus } = usePostContainersUpdatePickUp();

  const updateBargeRail = () => {
    setContainersUpdated(numberOfContainersProcessed);
    if (listOfCheckedContainers !== undefined && listOfCheckedContainers.length > 0)
      mutateBargeRail({
        meansOfTransport: newMeansOfTransport,
        items: listOfCheckedContainers!.map(
          (id) => ({ releaseId: id, alfapassNumber: '' } as BulkPickUpActionRequestItem)
        ),
      });
  };

  const updateTruck = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutateTruck({
      meansOfTransport: newMeansOfTransport,
      items: pickupAssignments,
    });
  };

  const resetPickUpAssignments = () => {
    setPickupAssignments([]);
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.UPDATE_PICKUP}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.updatePickUpSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              terminalNxtEntityIdError ||
              (values.terminalNxtEntityId === '' &&
                values.meansOfTransport === '' &&
                values.creationDate === ''),
            content: (
              <PickUpSearchStep
                terminals={terminals}
                terminalNxtEntityIdError={errors.terminalNxtEntityId}
                meansOfTransportError={errors.meansOfTransport}
                creationDateError={errors.creationDate}
                isLoadingError={isErrorTerminals}
                isFetching={isFetchingTerminals}
              />
            ),
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t('actions.selectMeansOfTransport'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('updatePickUp:general.title'),
            stepFlavorText: t('useCase:containers.updatePickUpContainersFlavorText'),
            backCallBack: resetPickUpAssignments,
            nextCallBack: isTruck ? updateTruck : updateBargeRail,
            nextCustomText: t('useCase:containers.updateContainers', {
              numberOfContainersSelected:
                numberOfContainersSelected > maxNumberOfContainersProcessed
                  ? maxNumberOfContainersProcessed
                  : numberOfContainersSelected,
            }),
            disableNext:
              newMeansOfTransport === '' ||
              numberOfContainersSelected < 1 ||
              (isTruck && pickupAssignments.length === 0),
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                selectedContainers={selectedContainers}
                setMeansOfTransport={setMeansOfTransport}
                newMeansOfTransport={newMeansOfTransport}
                setPickupAssignments={setPickupAssignments}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={isTruck ? truckStatus : bargeRailStatus}
                numberOfContainersUpdated={isTruck ? pickupAssignments.length : containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={isTruck ? updateTruck : updateBargeRail}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndContainersUpdated}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                  meansOfTransport: newMeansOfTransport,
                })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default StepperUpdatePickUp;
