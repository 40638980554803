import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getSignalRSetupError, } from "../../store/selectors/selectors";
import { ERROR } from '../../theme/constants';

const styles = () => ({
  content: {
    width: '100%',
    paddingTop: '85px',
    paddingLeft: '35px',
    textAlign: 'left',
  },
  errorHeader: {
    color: '#ff5975',
  },
  errorText: {
    color: '#797676'
  }
});

class ErrorPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.content}>
          <div>
            <h1 className={classes.errorHeader}>{ERROR.GENERAL_TITLE}</h1>
            <h2 className={classes.errorText}>{ERROR.GENERAL_TEXT}</h2>
          </div>
        </main>
      </React.Fragment>);
  }
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    error: getSignalRSetupError(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(styles)(ErrorPage));
