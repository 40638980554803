import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GetContainersByVesselParams } from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { VesselSearchStep } from '../shared';

type Props = {
  isFetching?: boolean;
  resetForm: VoidFunction;
  submitForm: VoidFunction;
  values: GetContainersByVesselParams;
};

const StepperSearchContainers = ({ isFetching, resetForm, submitForm, values }: Props) => {
  const { t } = useTranslation(['changeVoyage', 'common', 'useCase']);

  const resetFormAndValues = () => {
    resetForm();
  };

  const { vesselName, stayNumber, imoNumber } = values;

  const searchAvailable = vesselName !== '' || imoNumber !== '' || stayNumber !== '';

  return (
    <Paper>
      <StepUseCase
        activeStep={0}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.voyageSearchFlavorText'),
            backCallBack: resetFormAndValues,
            backCustomText: t('common:general.reset'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('useCase:containers.loadingContainers')
              : t('useCase:containers.searchContainers'),
            nextIsLoading: isFetching,
            content: (
              <VesselSearchStep
                showIsArchived={true}
                customHelperText={t('validation:atLeastNonStatusField')}
                withReleaseRightStatus
              />
            ),
            disableNext: !searchAvailable,
          },
        ]}
      />
    </Paper>
  );
};
export default StepperSearchContainers;
