import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { LABEL, DIALOG, FORMATTING } from '../../theme/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateDriverAlias } from '../../store/actions/driverListActions';

const styles = (theme) => ({
  dialogContentWithNewLines: {
    whiteSpace: 'pre-wrap',
  },
  content: {
    '& span': {
      width: '100%',
      display: 'block',
      margin: '0px',
      fontWeight: 'normal',
    },
  },
  heading: {
    width: '100%',
    display: 'block',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    margin: '0px',
  },
  headingContent: {
    width: '100%',
    fontWeight: 'bold',
    display: 'block',
    padding: '10px 0',
  },
  noBlock: {
    display: 'initial !important',
  },
});

const UpdateDriverAliasDialog = (props) => {
  const {
    classes,
    openUpdateDriverAliasDialog,
    closeDialog,
    updateDriverAlias,
    selectedDriverAlias,
  } = props;

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const handleInpDriverDescriptionChange = (e) => {
    e.stopPropagation();
    selectedDriverAlias.description = e.target.value;
  };

  const update = (e) => {
    e.stopPropagation();
    setConfirmationOpen(true);
  };

  const closeConfirmationDialog = (e) => {
    e.stopPropagation();
    setConfirmationOpen(false);
  };

  const handleYes = (e) => {
    updateDriverAlias(selectedDriverAlias);
    setConfirmationOpen(false);
    closeDialog(e);
  };

  const noYesActions = (
    <div>
      <Button
        onClick={(e) => {
          closeConfirmationDialog(e);
        }}
        color="primary"
      >
        {LABEL.NO}
      </Button>
      <Button onClick={handleYes} color="primary">
        {LABEL.YES}
      </Button>
    </div>
  );

  return (
    <div>
      <Dialog
        open={openUpdateDriverAliasDialog}
        onClose={(e) => {
          closeDialog(e);
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{DIALOG.UPDATE_DRIVER_DESCRIPTION}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  id="inpDriverAlfapassNumber"
                  label={LABEL.ALFAPASS_NUMBER_MASK_LABEL}
                  defaultValue={selectedDriverAlias.alfapassNumber}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="inpDriverDescription"
                  type="text"
                  autoFocus={true}
                  label={LABEL.DRIVER_DESCRIPTION_LABEL}
                  defaultValue={selectedDriverAlias.description}
                  onChange={(e) => handleInpDriverDescriptionChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => update(e)}>{LABEL.UPDATE}</Button>
          <Button onClick={(e) => closeDialog(e)}>{LABEL.CANCEL}</Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={confirmationOpen}
        onClose={(e) => closeConfirmationDialog(e)}
      >
        <DialogTitle id={'confirm-dialog-title-' + selectedDriverAlias.externalId}>
          {DIALOG.CONFIRM_UPDATE_DRIVER_DESCRIPTION}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {DIALOG.GENERAL_DRIVERALIAS_CONFIRM_TEXT.replace(FORMATTING.ACTION, 'update')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{noYesActions}</DialogActions>
      </Dialog>
    </div>
  );
};

UpdateDriverAliasDialog.propTypes = {
  openUpdateDriverAliasDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectedDriverAlias: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDriverAlias,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(UpdateDriverAliasDialog));
