import { GetContainersByShippingAgentParams } from 'modules/global/adapters/crpAPI';

export const revokeReleaseRightQueryKeys = {
  root: ['revoke-releaseright'] as const,
  containersForUpdateRoot: () =>
    [...revokeReleaseRightQueryKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (shippingAgentParams: GetContainersByShippingAgentParams) =>
    [...revokeReleaseRightQueryKeys.containersForUpdateRoot(), shippingAgentParams] as const,
  getShippingAgents: () => [...revokeReleaseRightQueryKeys.root, 'get-shipping-agents'] as const,
  postContainersRevoke: () =>
    [...revokeReleaseRightQueryKeys.root, 'post-containers-revoke-releaseright'] as const,
};
