import * as React from 'react';
import { useTranslation } from 'react-i18next';
import EditableList from '../overrule-customs-light/EditableList';
import { EditItem } from './EditableListItem';
import { TERMINAL_OVERRULEMAXITEMS } from '../../../../config/environment.js';
import { checkContainerToBeOverruled } from './adapters/queries';

type Props = {
  ContainersToBeOverruled?: string[] | undefined;
  AllContainersAreValid: Function;
  partyPath: string;
};

const AddContainersStep = ({
  ContainersToBeOverruled,
  AllContainersAreValid,
  partyPath,
}: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);
  const maxItems = +TERMINAL_OVERRULEMAXITEMS!;
  const [items, setItems] = React.useState<EditItem[]>([]);

  const [validationMessage, setValidationMessage] = React.useState<string | undefined>();
  const [validationDone, setValidationDone] = React.useState<boolean>(true);
  const [canAddItem, setCanAddItem] = React.useState<boolean>(true);
  const [keygen, setKeygen] = React.useState<number>(10000);

  React.useEffect(() => {
    if (ContainersToBeOverruled == null || ContainersToBeOverruled.length == 0) {
      setItems([]);
      return;
    }
    let itemList = [...items];
    let cnt = 0;
    ContainersToBeOverruled!.forEach((i) => {
      itemList.push(new EditItem(cnt, i, false));
      cnt++;
    });
    setItems(itemList);
    setKeygen(Math.max(...itemList.map((o) => o.Key), 0) + 1);
  }, []);

  const checkContainerValidation = (containersList: EditItem[]) => {
    return (
      containersList.find((i) => i.Value === '' || i.Value == null || !i.Validated) === undefined
    );
  };

  const setContainers = (isValid: boolean, containersList: EditItem[]) => {
    if (!isValid) {
      AllContainersAreValid(isValid, []);
      return;
    }
    let newContainers: string[] = [];
    containersList.forEach((i) => newContainers.push(i.Value));
    AllContainersAreValid(true, newContainers);
  };

  const deleteItem = (key: number) => {
    let itemList = [...items];
    let index = itemList.findIndex((i) => i.Key === key);
    itemList.splice(index, 1);
    if (itemList.length <= 0) {
      setCanAddItem(true);
      setValidationMessage(undefined);
      setContainers(false, []);
    } else if (checkContainerValidation(itemList)) {
      setCanAddItem(true);
      setValidationMessage(undefined);
      setContainers(true, itemList);
    }
    setItems(itemList);
  };

  const addItem = () => {
    let itemList = [...items];
    if (itemList.length >= maxItems) return;
    setKeygen(keygen + 1);
    const newItem = new EditItem(keygen, '', false);
    itemList.push(newItem);
    setItems(itemList);
    setCanAddItem(false);
  };

  const changeItem = (key: number, newValue: string) => {
    let itemList = [...items];
    let found = itemList.find((i) => i.Key === key);
    found!.Value = newValue;
    setItems(itemList);
  };

  const validateItem = async (key: number) => {
    let itemList = [...items];
    let keyFound = itemList.find((i) => i.Key === key);
    if (keyFound !== undefined) {
      let exists = itemList.find(
        (i) => i.Value.toLowerCase() === keyFound?.Value.toLowerCase() && i.Key !== keyFound.Key
      );
      if (exists !== undefined) {
        setContainers(false, []);
        setValidationMessage('ContainerAlreadyAdded');
        setCanAddItem(false);
        setValidationDone(true);
        setItems(itemList);
        return undefined;
      }
    }
    let found = itemList.find((i) => !i.Validated);
    if (found === undefined) return undefined;
    setValidationDone(false);
    var response = await checkContainerToBeOverruled(partyPath, found.Value);
    if (response.accepted) {
      found.Validated = true;
      setContainers(true, itemList);
      setValidationMessage(undefined);
      setCanAddItem(true);
    } else {
      setContainers(false, []);
      setValidationMessage(response.message);
      setCanAddItem(false);
    }
    setValidationDone(true);
    setItems(itemList);
    return found;
  };

  return (
    <React.Fragment>
      <EditableList
        EditItems={items}
        Headers={[t('overruleCustomsLight:sidebarAddContainers.labelContainerNumber')]}
        TextMaxLength={20}
        ButtonAddLabel={t('overruleCustomsLight:sidebarAddContainers.buttonAddContainer')}
        MaxItemReachedText={t('overruleCustomsLight:sidebarAddContainers.maxItemReachedText', {
          maxItemReached: maxItems,
        })}
        MaxItemsReached={items.length >= maxItems}
        ValidationMessage={validationMessage}
        CanAddItem={canAddItem}
        AddItem={() => {
          addItem();
        }}
        DeleteItem={(key: number) => {
          deleteItem(key);
        }}
        ChangeItem={(key: number, value: string) => {
          changeItem(key, value);
        }}
        ValidateItem={(key: number) => validateItem(key)}
        ValidationDone={validationDone}
      />
    </React.Fragment>
  );
};

export default AddContainersStep;
