import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import StringHelper from 'utils/string';

const FormikDialog = (props) => {
  const {
    initialValues,
    open,
    handleClose,
    handleSubmit,
    dialogTitle,
    dialogContentText,
    inEditMode,
    schema,
    classes,
    columnSize,
  } = props;

  const { t } = useTranslation(['glossary']);

  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth={'lg'}
        open={open}
        onClose={(e, reason) => {
          handleClose(e, reason);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <DialogContentText className={classes.dialogContentWithNewLines}>
            {dialogContentText}
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleReset,
                dirty,
                isSubmitting,
                isValid,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {Object.keys(initialValues).map((key) => {
                      return (
                        <Grid item xs={columnSize}>
                          <TextField
                            id={key}
                            name={key}
                            value={values[key]}
                            onChange={handleChange}
                            label={StringHelper.toTitleString(t(key))}
                            error={errors[key]}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {errors[key] && (
                            <FormHelperText className={classes.errorText}>
                              {t(errors[key])}
                            </FormHelperText>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                  <DialogActions>
                    {!inEditMode && (
                      <Button
                        type="button"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                        variant="contained"
                      >
                        Reset
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormikDialog;
