import { GetContainersByVoyageParams } from '../../../global/adapters/crpAPI';

export const changeTerminalKeys = {
  root: ['change-terminal'] as const,
  containersForUpdateRoot: () => [...changeTerminalKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (vesselParams: GetContainersByVoyageParams) =>
    [...changeTerminalKeys.containersForUpdateRoot(), vesselParams] as const,
  postContainersUpdateTerminal: () =>
    [...changeTerminalKeys.root, 'post-containers-update-terminal'] as const,
  getTerminals: () => [...changeTerminalKeys.root, 'get-terminals'] as const,
  getContainerDetail: (id: string) => [...changeTerminalKeys.root, 'get-container-detail', id],
};
