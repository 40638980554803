import {
  Box,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Field, FieldProps, FormikProps } from 'formik';
import { MeansOfTransport, Terminal } from 'modules/global/adapters/crpAPI';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorMessage } from 'store/actions/messageActions';
import store from 'store/store';
import useStyles from './styles';
import { LABEL } from 'theme/constants';

type Props = {
  terminals: Terminal[] | undefined;
  terminalNxtEntityIdError: string | undefined;
  meansOfTransportError: string | undefined;
  creationDateError: string | undefined;
  isLoadingError: boolean;
  isFetching: boolean;
};

const PickUpSearchStep = ({
  terminals,
  terminalNxtEntityIdError,
  isLoadingError,
  isFetching,
}: Props) => {
  const { t } = useTranslation(['glossary', 'useCase']);
  const classes = useStyles();

  const terminalNxtEntityIdHasError = Boolean(terminalNxtEntityIdError);

  React.useEffect(() => {
    isLoadingError && store.dispatch(errorMessage(t('messages.errorGetAssignPickUpFilterData')));
  }, [isLoadingError, t]);

  const handleOnChange = (
    _event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    form: FormikProps<any>
  ) => {
    form.handleChange(_event);
  };

  return (
    <React.Fragment>
      <Box marginTop={2}>
        <Field name="terminalNxtEntityId" id="terminalNxtEntityId">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <InputLabel id="terminalName">{t('terminalName')}</InputLabel>
              <FormikSelect
                defaultValue={undefined}
                form={form}
                field={field}
                error={terminalNxtEntityIdHasError}
              >
                {terminals &&
                  terminals.map((terminal: Terminal, index: number) => (
                    <MenuItem value={terminal.nxtEntityId} key={`terminal_${index}`}>
                      {terminal.name + '( ' + terminal.nxtEntityId + ' )'}
                    </MenuItem>
                  ))}
              </FormikSelect>
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>{terminalNxtEntityIdError}</FormHelperText>
      </Box>
      <Box marginTop={2}>
        <Field name="meansOfTransport" id="meansOfTransport">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <InputLabel id="meansOfTransport">{t('meansOfTransport')}</InputLabel>
              <Select
                name="meansOfTransport"
                value={field.value}
                fullWidth
                variant="outlined"
                onChange={form.handleChange}
              >
                {Object.keys(MeansOfTransport).map((status) => (
                  <MenuItem value={status} key={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>{terminalNxtEntityIdError}</FormHelperText>
      </Box>
      <Box marginTop={2}>
        <Field name="creationDate" id="creationDate">
          {({ field, form }: FieldProps) => (
            <React.Fragment>
              <InputLabel id="creationDate">{t('creationDate')}</InputLabel>
              <TextField
                id="creationDate"
                fullWidth
                type="date"
                value={field.value}
                onChange={form.handleChange}
                variant="outlined"
              />
            </React.Fragment>
          )}
        </Field>
        <FormHelperText className={classes.errorText}>{terminalNxtEntityIdError}</FormHelperText>
      </Box>
    </React.Fragment>
  );
};
export default PickUpSearchStep;
