import { Paper } from '@material-ui/core';
import { Row } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateStep } from '.';
import { Container, GetContainersByVoyageParams, Terminal } from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import {
  useGetActiveTerminals,
  usePostContainersUpdateterminal,
} from '../../adapters/change-terminal/queries';
import { FAQ_PAGES, maxNumberOfContainersProcessed } from '../../constants';
import { FilterStep, ResultStep, VesselSearchStep } from '../shared';

type Props = {
  data: Container[] | undefined;
  errors: FormikErrors<GetContainersByVoyageParams>;
  isFetching?: boolean;
  selectedRows: Row<Container>[] | null;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: GetContainersByVoyageParams;
};

const StepperChangeTerminal = ({
  data,
  errors,
  isFetching,
  selectedRows,
  setDisplayGrid,
  submitForm,
  resetForm,
  values,
}: Props) => {
  const { t } = useTranslation(['changeTerminal', 'common', 'useCase', 'validation']);
  const [newTerminal, setNewTerminal] = React.useState<Terminal | undefined>({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [containersUpdated, setContainersUpdated] = React.useState(0);

  const vesselNameError = Boolean(errors.vesselName);
  const voyageNumberError = Boolean(errors.voyageNumber);
  const imoNumberError = Boolean(errors.imoNumber);
  const stayNumberError = Boolean(errors.stayNumber);

  const listOfCheckedContainers = data && selectedRows?.map((row) => row.original.releaseId);
  const numberOfContainersSelected = selectedRows?.length || 0;
  const numberOfContainersProcessed =
    numberOfContainersSelected > maxNumberOfContainersProcessed
      ? maxNumberOfContainersProcessed
      : numberOfContainersSelected;

  const resetFormAndTerminal = () => {
    setNewTerminal(undefined);
    resetForm();
    setContainersUpdated(0);
  };

  const { mutate, status } = usePostContainersUpdateterminal();

  const {
    data: terminalData,
    isFetching: isFetchingTerminals,
    isError: isErrorTerminals,
  } = useGetActiveTerminals();

  const updateTerminals = () => {
    setContainersUpdated(numberOfContainersProcessed);
    mutate({
      ids: listOfCheckedContainers,
      code: newTerminal?.code,
      nxtEntityId: newTerminal?.nxtEntityId,
    });
  };

  return (
    <Paper>
      <StepUseCase
        faqLink={FAQ_PAGES.CHANGE_TERMINAL}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.voyageSearchFlavorText'),
            nextCallBack: submitForm,
            nextCustomText: isFetching
              ? t('actions.loadingContainers')
              : t('actions.searchContainers'),
            nextIsLoading: isFetching,
            disableBack: true,
            disableNext:
              vesselNameError ||
              voyageNumberError ||
              imoNumberError ||
              stayNumberError ||
              (values.vesselName === '' &&
                values.voyageNumber === '' &&
                values.imoNumber === '' &&
                values.stayNumber === ''),
            content: <VesselSearchStep />,
          },
          {
            stepTitle: t('useCase:containers.selectContainers'),
            stepFlavorText: t('useCase:containers.selectContainersFlavorText'),
            nextCustomText: t('actions.selectTerminal'),
            disableNext: numberOfContainersSelected === 0,
            backCallBack: () => setDisplayGrid(false),
            content: (
              <FilterStep
                numberOfContainersSelected={numberOfContainersSelected}
                numberOfContainers={data?.length || 0}
              />
            ),
          },
          {
            stepTitle: t('common:general.performUpdate'),
            stepFlavorText: t('useCase:containers.performUpdateFlavorText'),
            nextCallBack: updateTerminals,
            nextCustomText: t('useCase:containers.updateContainers', {
              numberOfContainersSelected:
                numberOfContainersSelected > maxNumberOfContainersProcessed
                  ? maxNumberOfContainersProcessed
                  : numberOfContainersSelected,
            }),
            disableNext: newTerminal?.nxtEntityId === undefined || numberOfContainersSelected < 1,
            content: (
              <UpdateStep
                numberOfContainersSelected={numberOfContainersSelected}
                setNewTerminal={setNewTerminal}
                newTerminal={newTerminal}
                data={terminalData}
                isFetching={isFetchingTerminals}
                isError={isErrorTerminals}
              />
            ),
          },
          {
            stepTitle: t('common:general.result'),
            noStepButtons: true,
            content: (
              <ResultStep
                status={status}
                numberOfContainersUpdated={containersUpdated}
                setActiveStep={setActiveStep}
                retryFunction={updateTerminals}
                setDisplayGrid={setDisplayGrid}
                callBackOnReturnToSearch={resetFormAndTerminal}
                resultText={t('sideBar.containersUpdated', {
                  numberOfContainersUpdated: containersUpdated,
                  newTerminal: newTerminal?.name,
                })}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
export default StepperChangeTerminal;
