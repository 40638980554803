import { NotificationRequest, NotificationEvent } from 'modules/global/adapters/mainAPI';
import { FormikErrors, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormHelperText, MenuItem, Paper, Typography } from '@material-ui/core';
import { Box, TextField, Button } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import useStyles from './style';
import React, { useEffect } from 'react';
import FormikSelect from 'modules/global/components/FormikSelect/FormikSelect';
import DatePicker from 'modules/global/components/DatePicker/DatePicker';
import { NOTIFICATIONS_RETENTION_TIME } from '../../../../config/environment';
import { LABEL } from 'theme/constants';
import TimePicker from 'modules/global/components/TimePicker/TimePicker';

type Props = {
  errors: FormikErrors<NotificationRequest>;
  isFetching?: boolean;
  setDisplayGrid: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: VoidFunction;
  resetForm: VoidFunction;
  values: NotificationRequest;
};

const FilterNotifications = ({ errors, submitForm, resetForm, values }: Props) => {
  const { t } = useTranslation(['displayNotifications', 'common', 'validation']);
  const classes = useStyles();

  const notificationEventError = Boolean(errors.notificationEvent);
  const equipmentNumberError = Boolean(errors.equipmentNumber);
  const publicReferenceIdError = Boolean(errors.publicReferenceId);
  const releaseIdError = Boolean(errors.releaseId);
  const storedOnError = Boolean(errors.storedOn);
  const timeWindowFromError = Boolean(errors.timeWindowFrom);
  const timeWindowToError = Boolean(errors.timeWindowTo);

  const minDate = new Date();
  const notificationsRetentionTime = parseInt(NOTIFICATIONS_RETENTION_TIME!);
  minDate.setDate(minDate.getDate() - notificationsRetentionTime);

  const hasErrors =
    notificationEventError || equipmentNumberError || publicReferenceIdError || storedOnError;

  const storedOnTimeWindowError = !errors.storedOn?.includes('required');
  const hasTimeWindowError = timeWindowFromError || timeWindowToError || storedOnTimeWindowError;

  const { setFieldValue } = useFormikContext();

  const getPublicReferenceIdQuery = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let publicReferenceId = params.get('publicreferenceid');
    return publicReferenceId;
  };

  useEffect(() => {
    var queryPublicReferenceId = getPublicReferenceIdQuery();
    if (queryPublicReferenceId) setFieldValue('publicReferenceId', queryPublicReferenceId);
  }, []);

  return (
    <Paper>
      <div style={{ padding: '18px 24px' }}>
        <Box marginX={4}>
          <Typography variant="h6">{t('common:general.search')}</Typography>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Typography variant="body2">{t('sideBar.searchGuidingText')}</Typography>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field
            name="notificationEvent"
            id="notificationEvent"
            component={FormikSelect}
            error={notificationEventError}
          >
            {Object.values(NotificationEvent).map((e) => {
              return (
                <MenuItem value={e} disabled={e === 'Unknown'}>
                  {e}
                </MenuItem>
              );
            })}
          </Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field name="equipmentNumber" id="equipmentNumber">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                variant="outlined"
                label={t('equipmentNumber')}
                fullWidth
                error={equipmentNumberError}
              />
            )}
          </Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field name="publicReferenceId" id="publicReferenceId">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                variant="outlined"
                label={t('publicReferenceId')}
                fullWidth
                error={publicReferenceIdError}
              />
            )}
          </Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field name="releaseId" id="releaseId">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                variant="outlined"
                label={t('releaseId')}
                fullWidth
                error={releaseIdError}
              />
            )}
          </Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field
            name="storedOn"
            id="storedOn"
            component={DatePicker}
            minDate={minDate}
            error={storedOnError}
            label={LABEL.STORED_ON}
          ></Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field
            name="timeWindowFrom"
            id="timeWindowFrom"
            component={TimePicker}
            error={timeWindowFromError}
            label={LABEL.TIME_WINDOW_FROM}
          ></Field>
        </Box>
        <Box marginTop={2} marginX={4}>
          <Field
            name="timeWindowTo"
            id="timeWindowTo"
            component={TimePicker}
            error={timeWindowToError}
            label={LABEL.TIME_WINDOW_TO}
          ></Field>
        </Box>
        {hasErrors && !hasTimeWindowError && (
          <Box marginTop={2} marginX={4}>
            <FormHelperText className={classes.errorText}>
              {t('validation:atLeastOneField')}
            </FormHelperText>
          </Box>
        )}

        {hasTimeWindowError && (
          <Box marginTop={2} marginX={4}>
            <FormHelperText className={classes.errorText}>
              {t(errors.timeWindowFrom as string)}
              {t(errors.timeWindowTo as string)}
              {storedOnTimeWindowError && t(errors.storedOn as string)}
            </FormHelperText>
          </Box>
        )}
        <Box marginTop={4} marginX={4}>
          <Button className={classes.button} onClick={() => resetForm()}>
            {t('actions.clear')}
          </Button>
          <Button disabled={hasErrors} className={classes.button} onClick={() => submitForm()}>
            {t('actions.search')}
          </Button>
        </Box>
      </div>
    </Paper>
  );
};

export default FilterNotifications;
