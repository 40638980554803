import { IconButton, SnackbarContent } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MESSAGETYPES } from '../../theme/constants';

const variantIcon = {
  [MESSAGETYPES.ERROR]: ErrorIcon,
  [MESSAGETYPES.WARNING]: WarningIcon,
  [MESSAGETYPES.INFO]: InfoIcon,
  [MESSAGETYPES.SUCCESS]: CheckCircleIcon,
};

const styles = (theme) => ({
  [MESSAGETYPES.ERROR]: {
    backgroundColor: theme.palette.error.dark,
  },
  [MESSAGETYPES.WARNING]: {
    backgroundColor: amber[700],
  },
  [MESSAGETYPES.INFO]: {
    backgroundColor: theme.palette.primary.main,
  },
  [MESSAGETYPES.SUCCESS]: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function Message(props) {
  const { classes, className, message, onClose, variant } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="message"
      message={
        <span id="message" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}

Message.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(MESSAGETYPES)).isRequired,
};

export default withStyles(styles)(Message);
