import { Get } from './authorizedRequest';
import store from '../store.js'
import { loadCompaniesSucceeded } from "../actions/companiesActions";
import queryString from 'query-string';

const endpoint = '/entities';

export async function getCompanies(obj) {
  const request = Get(endpoint + '?' + queryString.stringify(obj));
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  const result = await response.json()
  store.dispatch(loadCompaniesSucceeded(result));
  return response;
}
