import { Button, Dialog } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReleaseParty } from '../../../global/adapters/crpAPI';
import ReleasePartyModal from './ReleasePartyModal';
import useStyles from './styles';

type Props = {
  selectedNewRelease: any;
  setSelectedReleaseParty: React.Dispatch<React.SetStateAction<ReleaseParty | undefined>>;
  selectedReleaseParty: ReleaseParty | undefined;
  selectedIdentification: { type: string; value: string } | undefined;
  setSelectedIdentification: React.Dispatch<
    React.SetStateAction<
      | {
          type: string;
          value: string;
        }
      | undefined
    >
  >;
  selectedUserType: any;
};

const UpdateStep = ({
  selectedNewRelease,
  setSelectedReleaseParty,
  selectedReleaseParty,
  selectedIdentification,
  setSelectedIdentification,
  selectedUserType,
}: Props) => {
  const { t } = useTranslation(['common', 'assignReleaseParty']);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        color="default"
        variant="outlined"
        size="large"
        endIcon={<ArrowDropDownIcon />}
        onClick={() => setModalOpen(true)}
      >
        {t('assignReleaseParty:actions.selectReleaseParty')}
      </Button>
      <Dialog open={modalOpen} className={classes.dialog} maxWidth="lg">
        <ReleasePartyModal
          selectedReleaseParty={selectedReleaseParty}
          setSelectedReleaseParty={setSelectedReleaseParty}
          selectedIdentification={selectedIdentification}
          setSelectedIdentification={setSelectedIdentification}
          setModalOpen={setModalOpen}
          selectedNewRelease={selectedNewRelease}
          selectedUserType={selectedUserType}
        />
      </Dialog>
      <p>
        {t('assignReleaseParty:selectParty.haveSelected')}
        {selectedNewRelease?.nxtEntityId
          ? selectedNewRelease?.nxtEntityId
          : selectedNewRelease?.value ?? t('assignReleaseParty:selectParty.nothingYet')}
        {selectedNewRelease && t('assignReleaseParty:selectParty.asNewParty')}
      </p>
    </React.Fragment>
  );
};

export default UpdateStep;
