import TextField from '@material-ui/core/TextField';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pickupContainer } from '../../store/actions/alfapassActions';
import {
  setAlfapassNumber,
  setOldAlfapassNumber,
  resetAlfapassNumber,
  resetAlfapassNumberStatus,
  checkAlfapassNumber,
} from '../../store/actions/alfapassActions';
import {
  getAlfapassNumber,
  getAlfapassNumberStatus,
  getSelectedContainers,
  getDriverList,
} from '../../store/selectors/selectors';
import { ALFAPASSNUMBERSTATUSES, LABEL, VALIDATION } from '../../theme/constants';
import classNames from 'classnames';
import BasePickUpDialog from './BasePickUpDialog';

const styles = (theme) => ({
  pickupContentFields: {
    marginTop: '20px',
  },
});

const PickupDialog = (props) => {
  const {
    classes,
    container,
    selectedContainer,
    className,
    disabled,
    variant,
    buttonTitle,
    alfapassNumber,
    alfapassNumberStatus,
    checkAlfapassNumber,
    resetAlfapassNumberStatus,
    resetAlfapassNumber,
    setAlfapassNumber,
    setOldAlfapassNumber,
    checkedContainers,
    pickupContainer,
    callback,
    multiple,
    hideButton,
    overridePickupDialogOpen,
    overrideToFalse,
    driverList,
  } = props;

  const [hasAlfapassNumberError, setAlfapassNumberError] = React.useState(false);

  const [inputOpen, setAlfapassInputOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [driverAlias, setDriverAlias] = React.useState(null);
  const [inputChoice, setInputChoice] = React.useState(10);

  const setParentAlfapassNumberError = (setError) => {
    setAlfapassNumberError(setError);
  };

  const setParentInputOpen = (doInputOpen) => {
    setAlfapassInputOpen(doInputOpen);
  };

  const setParentConfirmationOpen = (doConfirmationOpen) => {
    setConfirmationOpen(doConfirmationOpen);
  };

  const setOverrideToFalse = () => {
    if (overrideToFalse) {
      overrideToFalse();
    }
  };

  const performPickUpCall = () => {
    pickupContainer({
      containers: checkedContainers.length > 0 ? checkedContainers : [container],
      alfapassNumber: alfapassNumber,
      selectedDriverAlias: driverAlias,
    });
    setOldAlfapassNumber(alfapassNumber);
    resetAlfapassNumber();
  };

  useEffect(() => {
    if (alfapassNumberStatus === ALFAPASSNUMBERSTATUSES.OK && container === selectedContainer) {
      !multiple && setConfirmationOpen(true);
      setOverrideToFalse();
      setAlfapassInputOpen(false);
      setConfirmationOpen(true);
    } else if (alfapassNumberStatus === ALFAPASSNUMBERSTATUSES.INVALID) {
      setAlfapassNumberError(true);
    }
  }, [alfapassNumberStatus, container, selectedContainer, multiple]);

  const handleAlfapassNumberChange = (e) => {
    e.stopPropagation();
    let alfapassNumberValue = e.target.value;
    if (!Number(alfapassNumberValue) && alfapassNumberValue !== '') {
      setAlfapassNumberError(true);
    } else {
      setAlfapassNumberError(false);
      setAlfapassNumber(alfapassNumberValue);
    }
  };

  const setAutoCompleteValue = (event, value, reason) => {
    event.stopPropagation();
    if (reason == 'select-option') {
      let driverAlias = driverList.find((x) => x.externalId === value.externalId);
      setDriverAlias(driverAlias);
    }
    if (reason == 'clear') setDriverAlias(null);
  };

  const handleInputChoice = (e) => {
    e.stopPropagation();
    setAlfapassNumberError(false);
    setAlfapassNumber('');
    setDriverAlias(null);
    setInputChoice(e.target.value);
  };

  const contentFields = (
    <Grid container spacing="2">
      <Grid item sm="3" xs="12">
        <FormControl variant="outlined" className={classes.formControl} fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">By</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={inputChoice}
            onChange={handleInputChoice}
            label="By"
          >
            <MenuItem value={10}>Number</MenuItem>
            <MenuItem value={20}>Description</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm="9" xs="12">
        {inputChoice == 10 && (
          <TextField
            fullWidth
            type="number"
            className={classNames.TextField}
            id="inpAlfapassNumber"
            label={LABEL.ALFAPASS_NUMBER}
            error={hasAlfapassNumberError}
            helperText={hasAlfapassNumberError ? VALIDATION.INVALID_ALFAPASS_NUMBER : ''}
            onChange={(e) => handleAlfapassNumberChange(e)}
            InputProps={{
              inputProps: { min: 0, max: 100000000, maxLength: 16 },
            }}
          />
        )}
        {inputChoice == 20 && (
          <Autocomplete
            id="inpDriverAlias"
            options={driverList}
            getOptionLabel={(driver) =>
              driver.description + ' ' + '(' + driver.alfapassNumber + ')'
            }
            onChange={(e, value, reason) => setAutoCompleteValue(e, value, reason)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={LABEL.SELECT_DRIVER_ALIAS}
                error={hasAlfapassNumberError}
                helperText={hasAlfapassNumberError ? VALIDATION.INVALID_ALFAPASS_NUMBER : ''}
                fullWidth
              />
            )}
            fullWidth
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <BasePickUpDialog
      container={container}
      number={alfapassNumber}
      selectedDriverAlias={driverAlias}
      numberStatus={alfapassNumberStatus}
      checkNumber={checkAlfapassNumber}
      pickupContainer={pickupContainer}
      buttonTitle={buttonTitle}
      variant={variant}
      disabled={disabled}
      className={className}
      classes={classes}
      contentFields={contentFields}
      callback={callback}
      checkedContainers={checkedContainers}
      hideButton={hideButton}
      resetNumber={resetAlfapassNumber}
      resetNumberStatus={resetAlfapassNumberStatus}
      overridePickupDialogOpen={overridePickupDialogOpen}
      setNumberError={setParentAlfapassNumberError}
      inputOpen={inputOpen}
      confirmationOpen={confirmationOpen}
      setInputOpen={setParentInputOpen}
      setConfirmationOpen={setParentConfirmationOpen}
      performPickUpCall={performPickUpCall}
    />
  );
};

PickupDialog.propTypes = {
  container: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    alfapassNumber: getAlfapassNumber(state),
    alfapassNumberStatus: getAlfapassNumberStatus(state),
    checkedContainers: getSelectedContainers(state),
    driverList: getDriverList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkAlfapassNumber,
      resetAlfapassNumber,
      resetAlfapassNumberStatus,
      setAlfapassNumber,
      setOldAlfapassNumber,
      pickupContainer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PickupDialog));
