import { CustomsLightManualOverwriteRequest } from '../../../../global/adapters/crpAPI';

export const overrulingLightsKeys = {
  root: ['overrule-customs-light'] as const,
  containersForOverrulingLightsKeysRoot: () =>
    [...overrulingLightsKeys.root, 'overrule-customs-light'] as const,
  getContainersForOverrulingLights: (equipmentNumbers: string[] | undefined) =>
    [...overrulingLightsKeys.containersForOverrulingLightsKeysRoot(), equipmentNumbers] as const,
  getAlfapassClientId: () => [...overrulingLightsKeys.root, 'overrule-customs-light'] as const,
  getContainerDetail: (id: string) => [...overrulingLightsKeys.root, 'overrule-customs-light', id],
  setCustomsLightManualOverwrite: (request: CustomsLightManualOverwriteRequest) => [
    ...overrulingLightsKeys.root,
    'overrule-customs-light',
    request,
  ],
};

export const overruleCustomsLightKeys = {
  root: ['overrule-customs-light'] as const,
  checkContainerToBeOverruled: (equipmentNumber: string) => [
    ...overruleCustomsLightKeys.root,
    'check-container-to-be-overruled',
    equipmentNumber,
  ],
};
