import { GetContainersByReceivedFromParams } from 'modules/global/adapters/crpAPI';

export const acceptReleaseRightKeys = {
  root: ['accept-releaseright'] as const,
  containersForUpdateRoot: () => [...acceptReleaseRightKeys.root, 'containers-for-update'] as const,
  containersForUpdate: (receivedFromParams: GetContainersByReceivedFromParams) =>
    [...acceptReleaseRightKeys.containersForUpdateRoot(), receivedFromParams] as const,
  postContainersAccept: () =>
    [...acceptReleaseRightKeys.root, 'post-containers-accept-releaseright'] as const,
  getTerminals: () => [...acceptReleaseRightKeys.root, 'get-terminals'] as const,
  getContainerDetail: (id: string) =>
    [...acceptReleaseRightKeys.root, 'get-container-detail', id] as const,
  getPendingContainersForReleaseRightAction: () => [
    ...acceptReleaseRightKeys.containersForUpdateRoot(),
    'get-pending-containers',
  ],
};
