import { loadArchiveSucceeded } from '../store/actions/archiveActions';
import { loadCompaniesSucceeded } from '../store/actions/companiesActions';
import {
  loadContainers,
  loadContainersSucceeded,
  loadContainerSucceeded,
  loadEventHistorySucceeded,
  refreshContainers,
} from '../store/actions/containerActions';
import { apiErrorsMessage, apiSuccessMessage } from '../store/actions/messageActions';
import { broadcastMessage } from '../store/actions/messageActions';
import { loadReleases, loadReleasesSucceeded } from '../store/actions/releaseActions';
import {
  loadPendingReleaseCountSucceeded,
  updatePendingReleaseCount,
} from '../store/actions/countActions';
import { signalRIdReceived } from '../store/actions/signalRActions';
import store from '../store/store.js';
import { LABEL, ERROR, TEXT, FORMATTING, BROADCAST_API_MESSAGETYPE } from '../theme/constants';
import { creditAmountChanged } from '../store/actions/creditIdentificationActions';
import { loadTerminalsSucceeded } from '../store/actions/terminalActions';
import {
  loadSubscriptionsSucceeded,
  loadPoliceSubscriptions,
  loadSubscriptions,
} from '../store/actions/subscriptionActions';
import {
  loadPoliceReleaseAudit,
  loadPoliceContainersFilters,
} from '../store/actions/policeContainerActions';
import { CONNECTIONMESSAGES } from '../theme/constants';
import {
  addDriverToList,
  deleteFromDriverList,
  updateDriverList,
  loadDriverListSucceeded,
} from '../store/actions/driverListActions';
const connectionMessageAction = (connection, message, callback) => {
  connection.on(message, (value) => {
    try {
      callback(value);
    } catch (e) {}
  });
};

export function bindConnectionMessages(connection) {
  connection.onclose(() => {
    console.log('close connection web sockets');
  });

  connectionMessageAction(
    connection,
    CONNECTIONMESSAGES.EVENT_HISTORY_REQUESTED_SUCCEEDED,
    (input) => {
      const inputJson = JSON.parse(input);
      store.dispatch(loadEventHistorySucceeded(inputJson));
    }
  );

  connectionMessageAction(connection, CONNECTIONMESSAGES.CREATE_SUBSCRIPTION, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(inputJson.byPolice ? loadPoliceSubscriptions(false) : loadSubscriptions(false));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_SUBSCRIPTIONS, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(loadSubscriptionsSucceeded(inputJson));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_DRIVERSLIST, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(loadDriverListSucceeded(inputJson));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.SUBMIT_DRIVERALIAS_SUCCEEDED, (input) => {
    store.dispatch(addDriverToList());
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.DELETE_DRIVERALIAS_SUCCEEDED, (input) => {
    store.dispatch(deleteFromDriverList());
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.UPDATE_DRIVER_LIST_SUCCEEDED, (input) => {
    store.dispatch(updateDriverList());
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.EQUIPMENT_NUMBERS_CHANGED, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(refreshContainers({ isReleaseItem: inputJson.isReleaseItem }));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_EQUIPMENT_RELEASE_RIGHT, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(loadContainerSucceeded(inputJson));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.POLICE_ACTION_COMPLETED, (input) => {
    const inputJson = JSON.parse(input);
    store.dispatch(apiSuccessMessage(inputJson.message));
    store.dispatch(loadPoliceReleaseAudit(undefined, undefined, undefined, undefined));
    store.dispatch(loadPoliceContainersFilters(undefined, undefined));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.UPDATE_PENDING_RELEASE_COUNT, (input) => {
    const inputJson = JSON.parse(input.replace(/'/g, '"'));
    store.dispatch(updatePendingReleaseCount(inputJson));
    store.dispatch(loadReleases(false));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_EQUIPMENT_RELEASE_RIGHTS, (input) => {
    const inputJson = JSON.parse(input);
    switch (inputJson.status.toUpperCase()) {
      case LABEL.ACTIVE_LABEL:
        store.dispatch(loadContainersSucceeded(inputJson));
        store.dispatch(loadPendingReleaseCountSucceeded(inputJson));
        return;
      case LABEL.PENDING_LABEL:
        store.dispatch(loadReleasesSucceeded(inputJson));
        store.dispatch(loadPendingReleaseCountSucceeded(inputJson));
        return;
      case LABEL.TERMINAL_LABEL:
        store.dispatch(loadContainersSucceeded(inputJson));
        return;
      case LABEL.SHIPPING_LABEL:
        store.dispatch(loadContainersSucceeded(inputJson));
        return;
      case LABEL.POLICE_LABEL:
        store.dispatch(loadContainersSucceeded(inputJson));
        return;
      case LABEL.ARCHIVE_LABEL:
        store.dispatch(loadArchiveSucceeded(inputJson));
        return;
      default:
        throw Error(ERROR.INCORRECT_STATUS + inputJson.status.toUpperCase());
    }
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_TERMINALS, (terminals) => {
    const terminalsJson = JSON.parse(terminals);
    store.dispatch(loadTerminalsSucceeded(terminalsJson));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.GET_STAKEHOLDERS, (companies) => {
    const companiesJson = JSON.parse(companies.replace(/'/g, '"'));
    companiesJson.filters = store.getState().companies.filters;
    store.dispatch(loadCompaniesSucceeded(companiesJson));
  });

  connectionMessageAction(
    connection,
    CONNECTIONMESSAGES.INVALID_TRANSFER_RELEASETO_PARTY,
    (identification) => {
      const identificationJson = JSON.parse(identification.replace(/'/g, '"'));
      let text = TEXT.INVALID_TRANSFER_TEXT.replace(
        FORMATTING.IDENTIFICATION_CODE,
        identificationJson.IdentificationCode
      );
      store.dispatch(
        broadcastMessage({
          message: text,
          messageType: BROADCAST_API_MESSAGETYPE.WARNING,
        })
      );
    }
  );

  connectionMessageAction(connection, CONNECTIONMESSAGES.ACTION_COMPLETED, (input) => {
    store.dispatch(apiSuccessMessage());
    const inputJson = JSON.parse(input);
    store.dispatch(
      refreshContainers({
        isReleaseItem:
          inputJson.label.toLowerCase() == 'accept' || inputJson.label.toLowerCase() == 'decline',
      })
    );
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.EXPIRE_RELEASES, () => {
    store.dispatch(loadContainers(false));
    store.dispatch(loadReleases(false));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.BROADCAST_MESSAGE, (input) => {
    const inputJson = JSON.parse(input.replace(/'/g, '"'));
    store.dispatch(broadcastMessage(inputJson));
  });

  connectionMessageAction(connection, CONNECTIONMESSAGES.CREDIT_AMOUNT_CHANGED, (input) => {
    const inputJson = JSON.parse(input.replace(/'/g, '"'));
    store.dispatch(creditAmountChanged(inputJson));
  });

  connection.onreconnected((connectionId) => {
    store.dispatch(signalRIdReceived(connectionId));
  });
}
