import { CONSTANTS, LABEL } from '../../theme/constants';
import { Get, Put } from './authorizedRequest';

const endpoint = '/' + CONSTANTS.PORT_LOCODE + '/containers/';

export async function getContainers(obj) {
  const request = Get(endpoint + 'import/release-rights', obj.query);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return await response.json();
}

export async function getContainer(query) {
  const request = Get(endpoint + 'import/release-rights/' + query.equipmentNumber, {
    billOfLadingNumbers: query.billOfLadingNumbers,
    releaseIdentification: query.releaseIdentification,
  });
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return await response.json();
}

export async function getEventHistory(query) {
  const request = Get(endpoint + 'import/release-rights/' + query.releaseId + '/events', null);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  return response.json();
}

export async function putReleaseContainers(obj) {
  let multipleContainers = obj.containers.length > 1;

  if (!multipleContainers) {
    singleTerminalRelease(obj).then((response) => {
      return response;
    });
  } else {
    multipleTerminalRelease(obj).then((response) => {
      return response;
    });
  }
}

async function singleTerminalRelease(obj) {
  let container = obj.containers[0];

  const url = endpoint + container.equipmentNumber + '/import/terminal-release';
  const body = {
    portLoCode: CONSTANTS.PORT_LOCODE,
    terminalCode: container.terminalCode,
    equipmentNumber: container.equipmentNumber,
    actionType: obj.actionType,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

async function multipleTerminalRelease(obj) {
  let releaseRightIdentifiers = [];
  let terminalCode = '';

  obj.containers.map(function (container, index) {
    if (index == 0) {
      terminalCode = container.terminalCode;
    }
    releaseRightIdentifiers[index] = {
      billOfLadingNumbers: container.billOfLadingNumbers,
      equipmentNumber: container.equipmentNumber,
      releaseIdentification: container.releaseIdentification,
    };
  });

  const url = endpoint + 'import/terminal-release';
  const body = {
    portLoCode: CONSTANTS.PORT_LOCODE,
    terminalCode: terminalCode,
    releaseRights: releaseRightIdentifiers,
    actionType: obj.actionType,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

export async function putBlockContainers(obj) {
  let multipleContainers = obj.containers.length > 1;

  if (!multipleContainers) {
    singleTerminalRelease(obj).then((response) => {
      return response;
    });
  } else {
    multipleTerminalRelease(obj).then((response) => {
      return response;
    });
  }
}

async function singleReleaseRightActionCall(obj) {
  let container = obj.containers[0];

  let releaseParties = getReleaseParties(obj, container);

  const url = endpoint + container.equipmentNumber.trim() + '/import/release-rights/';
  const body = {
    portLoCode: CONSTANTS.PORT_LOCODE,
    terminalCode: container.terminalCode,
    billOfLadingNumbers: container.billOfLadingNumbers,
    equipmentNumber: container.equipmentNumber,
    releaseIdentification: container.releaseIdentification,
    releaseFrom: releaseParties.releaseFrom,
    releaseTo: releaseParties.releaseTo,
    releaseDateTimeUtc: container.startDate,
    expirationDateTimeUtc: container.expirationDateTimeUtc,
    actionType: obj.actionType,
    reason: obj.reason,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = response.json();

  return responseJson;
}

function getReleaseParties(obj, container) {
  if (obj.actionType === LABEL.TRANSFER.toUpperCase()) {
    if (obj.selectedCompany.isUnknown != null && obj.selectedCompany.isUnknown === true) {
      return {
        releaseFrom: {
          identificationType: 'NxtEntityId',
          identificationCode: obj.nxtEntityId,
        },
        releaseTo: {
          identificationType: obj.selectedCompany.identificationType,
          identificationCode: obj.selectedCompany.identificationCode,
        },
      };
    }
    return {
      releaseFrom: {
        identificationType: 'NxtEntityId',
        identificationCode: obj.nxtEntityId,
      },
      releaseTo: {
        identificationType: 'NxtEntityId',
        identificationCode: obj.selectedCompany.nxtEntityId,
      },
    };
  } else {
    return {
      releaseFrom: {
        identificationType: 'NxtEntityId',
        identificationCode: container.releaseFromNxtEntityId,
      },
      releaseTo: {
        identificationType: 'NxtEntityId',
        identificationCode: container.releaseToNxtEntityId,
      },
    };
  }
}

async function multipleReleaseRightActionCall(obj) {
  let releaseRightIdentifiers = [];

  obj.containers.map(function (container, index) {
    let releaseParties = getReleaseParties(obj, container);

    releaseRightIdentifiers[index] = {
      billOfLadingNumbers: container.billOfLadingNumbers,
      equipmentNumber: container.equipmentNumber,
      releaseIdentification: container.releaseIdentification,
      releaseTo: releaseParties.releaseTo,
      releaseFrom: releaseParties.releaseFrom,
      terminalCode: container.terminalCode,
    };
  });

  const url = endpoint + 'import/release-rights/';
  const body = {
    portLoCode: CONSTANTS.PORT_LOCODE,
    actionType: obj.actionType,
    releaseRights: releaseRightIdentifiers,
  };

  const request = Put(url, body);
  const response = await fetch(request);

  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }

  let responseJson = await response.json();

  return responseJson;
}

export async function submitReleaseRightAction(obj) {
  let multipleContainers = obj.containers.length > 1;

  if (!multipleContainers) {
    singleReleaseRightActionCall(obj).then((response) => {
      return response;
    });
  } else {
    multipleReleaseRightActionCall(obj).then((response) => {
      return response;
    });
  }
}

export async function putSelectContainersForScan(obj) {
  let multipleContainers = obj.containers.length > 1;

  if (!multipleContainers) {
    singleTerminalRelease(obj).then((response) => {
      return response;
    });
  } else {
    multipleTerminalRelease(obj).then((response) => {
      return response;
    });
  }
}
