import store from '../../store/store'
import { Get, Post } from './authorizedRequest'
import { loadCreditIdentificationResponseSucceeded, loadPairCreditIdentificationSucceeded, loadRegisterPaymentOptionSucceeded } from '../actions/userProfileActions'

export async function getCreditIdentificationResponse(obj) {
  const path = '/credit-identification/' + obj.nxtEntityId

  const request = Get(path)
  const response = await fetch(request)

  if (!response.ok) {
    let err
    try {
      err = await response.json()
    } catch {
      throw new Error(response.statusText)
    }
    throw err
  }

  // Fetch JSON and parse to Object
  const inputJson = await response.json()

  store.dispatch(loadCreditIdentificationResponseSucceeded(inputJson))
}

export async function registerCreditIdentification() {
  const path = '/credit-identification/register'

  const request = Post(path)
  const response = await fetch(request)

  if (!response.ok) {
    let err
    try {
      err = await response.json()
    } catch {
      throw new Error(response.statusText)
    }
    throw err
  }

  store.dispatch(loadRegisterPaymentOptionSucceeded())
}

export async function pairCreditIdentification() {
  const path = '/credit-identification/pair'

  const request = Post(path)
  const response = await fetch(request)

  if (!response.ok) {
    let err
    try {
      err = await response.json()
    } catch {
      throw new Error(response.statusText)
    }
    throw err
  }

  store.dispatch(loadPairCreditIdentificationSucceeded())
}
