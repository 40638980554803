import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableHead,
  Typography,
} from '@material-ui/core';
import { AddBox as AddIcon } from '@material-ui/icons';
import EditableListItem, { EditItem } from './EditableListItem';
import { useTranslation } from 'react-i18next';

type Props = {
  EditItems: EditItem[];
  Headers: string[];
  TextMaxLength: number;
  ButtonAddLabel: string;
  MaxItemReachedText: string;
  MaxItemsReached: boolean;
  ValidationMessage: string | undefined;
  CanAddItem: boolean;
  AddItem: Function;
  DeleteItem: Function;
  ChangeItem: Function;
  ValidateItem: Function;
  ValidationDone: boolean;
};

const EditableList = ({
  EditItems,
  Headers,
  TextMaxLength,
  ButtonAddLabel,
  MaxItemReachedText,
  MaxItemsReached,
  ValidationMessage,
  CanAddItem,
  AddItem,
  DeleteItem,
  ChangeItem,
  ValidateItem,
  ValidationDone,
}: Props) => {
  const { t } = useTranslation(['overruleCustomsLight']);

  useEffect(() => {
    var x = 1;
  }, []);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {Headers.map((header, index) => (
            <TableCell key={index} style={{ borderBottom: 'none' }}>
              <Typography color={'textPrimary'} style={{ fontSize: '12px' }}>
                {header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {EditItems.map((item) => {
          return (
            <EditableListItem
              ItemIsValid={item.Validated}
              ItemValue={item.Value}
              ItemKey={item.Key}
              ChangeHandler={(key: number, value: string) => {
                ChangeItem(key, value);
              }}
              DeleteHandler={() => DeleteItem(item.Key)}
              TextMaxLength={TextMaxLength}
              Validate={() => ValidateItem(item.Key)}
              ValidationDone={ValidationDone}
            />
          );
        })}
        <TableRow>
          {ValidationMessage !== undefined && ValidationDone && (
            <TableCell style={{ borderBottom: 'none' }} colSpan={3}>
              <p style={{ color: 'red' }}>
                {t(`overruleCustomsLight:containerOverruleResponseMessage:${ValidationMessage}`)}
              </p>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          {CanAddItem && !MaxItemsReached && (
            <TableCell style={{ borderBottom: 'none' }} colSpan={3}>
              <Button color="primary" onClick={() => AddItem()} style={{ float: 'right' }}>
                <AddIcon fontSize="small" /> {ButtonAddLabel}
              </Button>
            </TableCell>
          )}
          {MaxItemsReached && (
            <TableCell style={{ borderBottom: 'none' }} colSpan={3}>
              <p style={{ color: 'red' }}>{MaxItemReachedText}</p>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EditableList;
