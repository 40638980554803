import { takeEvery } from 'redux-saga/effects';
import { call, put } from 'redux-saga/es/internal/io';
import ActionTypes from '../actions/actionTypes';
import { putDriver } from '../api/driverApi';
import { apiErrorsMessage, errorMessage } from '../actions/messageActions';

function getErrorAction(err) {
  if (err instanceof Error) {
    return errorMessage(err.message);
  }
  return apiErrorsMessage(err);
}

export function* addDriver(action) {
  try {
    yield call(putDriver, {
      alfapassNumber: action.payload,
    });
  } catch (err) {
    yield put(getErrorAction(err));
  }
}

function* driverSaga() {
  yield takeEvery(ActionTypes.ADD_DRIVER, addDriver);
}

export default driverSaga;
