import { Box, Card, CardActionArea, CardContent, Chip, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStyles from './style';

export type ActionGroup = {
  category?: string;
  actions: {
    title: string;
    textBody?: string;
    url: string;
    type?: 'READ' | 'UPDATE' | 'CREATE';
    isInBeta?: boolean;
  }[];
};

type Props = React.ComponentPropsWithoutRef<'div'> & {
  actionGroup: ActionGroup[];
};

const ActionScreen = ({ actionGroup }: Props) => {
  const { t } = useTranslation('useCase');
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h1 className={classes.pageTitle}>{t('general.whatToAchieve')}</h1>
      {actionGroup?.map((group, index) => (
        <React.Fragment key={index}>
          {group.category && <h2 className={classes.groupCategory}>{group.category}</h2>}
          <Grid container spacing={3}>
            {group.actions.map((actionCard) => (
              <Grid item xs={3} key={actionCard.title}>
                <Link to={actionCard.url}>
                  <Card
                    variant="outlined"
                    className={`${classes.actionCardWrapper} ${actionCard.type}`}
                  >
                    <CardActionArea>
                      <CardContent className={classes.actionCard}>
                        <Box
                          alignItems="baseline"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <h3>{actionCard.title}</h3>
                        </Box>
                        <p>{actionCard.textBody}</p>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default ActionScreen;
