import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GetContainersByShippingAgentParams } from '../../../global/adapters/crpAPI';
import StepUseCase from '../../../global/components/Stepper/StepUseCase';
import { TransporterSearchStep } from '../shared';
import { FormikErrors } from 'formik';
import { useGetActiveShippingAgents } from 'modules/release-party/adapters/search-containers/queries';
import { LABEL } from 'theme/constants';

type Props = {
  isFetching?: boolean;
  resetForm: VoidFunction;
  submitForm: VoidFunction;
  values: GetContainersByShippingAgentParams;
  errors: FormikErrors<GetContainersByShippingAgentParams>;
};

const StepperSearchContainers = ({ isFetching, resetForm, submitForm, values, errors }: Props) => {
  const { t } = useTranslation(['common', 'useCase', 'validation']);

  const resetFormAndValues = () => {
    resetForm();
  };

  const {
    data: shippingAgents,
    isFetching: isFetchingAgents,
    isError: isErrorAgents,
  } = useGetActiveShippingAgents();

  const equipmentNumberError = Boolean(errors.equipmentNumber);
  const shippingAgentNxtEntityIdError = Boolean(errors.shippingAgentNxtEntityId);
  const billOfLadingNumberError = Boolean(errors.billOfLadingNumber);

  const hasValidationError =
    equipmentNumberError || shippingAgentNxtEntityIdError || billOfLadingNumberError;

  const { shippingAgentNxtEntityId, equipmentNumber, billOfLadingNumber } = values;
  const searchAvailable =
    Boolean(shippingAgentNxtEntityId && shippingAgentNxtEntityId !== LABEL.UNKNOWN) ||
    Boolean(equipmentNumber) ||
    Boolean(billOfLadingNumber);

  return (
    <Paper>
      <StepUseCase
        activeStep={0}
        steps={[
          {
            stepTitle: t('common:general.search'),
            stepFlavorText: t('useCase:containers.searchContainersFlavorText'),
            backCallBack: resetFormAndValues,
            nextCallBack: submitForm,
            backCustomText: t('common:general.reset'),
            nextCustomText: isFetching
              ? t('useCase:containers.loadingContainers')
              : t('useCase:containers.searchContainers'),
            nextIsLoading: isFetching,
            content: (
              <TransporterSearchStep
                shippingAgents={shippingAgents}
                hasValidationError={hasValidationError}
                validationMessage={t('validation:atLeastOneField')}
                isFetching={isFetchingAgents}
                isError={isErrorAgents}
                showIsArchived={true}
              />
            ),
            disableNext: !searchAvailable,
          },
        ]}
      />
    </Paper>
  );
};
export default StepperSearchContainers;
