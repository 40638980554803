// import { CONSTANTS } from "../../theme/constants";

// const endpoint = 'certified-pickup/v1/' + CONSTANTS.PORT_LOCODE + '/containers/';


export async function getArchive(obj) {
  /*
  obj.query['status'] = 'archive';
  const request = Get(endpoint + 'import/release-rights/' + obj.nxtEntityId, obj.query);
  const response = await fetch(request);
  if (!response.ok) {
    let err;
    try {
      err = await response.json();
    } catch {
      throw new Error(response.statusText);
    }
    throw err;
  }
  return response.json();

   */
}
